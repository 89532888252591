import {ApiStore} from "./apiStore";

const getHeaders = (headerFor) => {
	let headers = {};
	let apiStore = ApiStore.getInstance();
	if (headerFor === "auth") {
		headers = {
			language: apiStore.language
		};
	} else if (headerFor === "refreshtoken") {
		headers = {
			language: apiStore.language,
			userid: apiStore.userId,
			Authorization: apiStore.tokens.token,
			refreshauthorization: apiStore.tokens.refreshToken,
			region: apiStore.region
		};
	} else if (headerFor === "printable") {
		headers = {
			language: apiStore.language,
			userid: apiStore.userId,
			Authorization: apiStore.tokens.token,
			region: apiStore.region
		};
	} else if (headerFor === "sso") {
		headers = {
			language: apiStore.language,
			Authorization: apiStore.tokens.token
		};
	} else if (headerFor === "cognito") {
		headers = {};
	} else if (headerFor === "graphql") {
		headers = {
			language: apiStore.language,
			userid: apiStore.userId,
			Authorization: apiStore.tokens.token
		};
	} else {
		headers = {
			language: apiStore.language,
			userid: apiStore.userId,
			Authorization: apiStore.tokens.token,
			region: apiStore.region
		};
	}
	return headers;
};

export const getRegionForGraphQLURL = (region) => {
	const regions = {
		US: "graphql-customer-api-us",
		EU: "graphql-customer-api-eu",
		APAC: "graphql-customer-api-ap"
	};

	return regions[region];
};

export const getResponse = (url, method, headerFor, data = undefined, resolve, reject, contentType = "application/json") => {
	let timeout = constants.timeout;
	if (url.includes("logout")) {
		timeout = constants.timeoutLogout;
	}
	if (method === "GET") {
		jQuery.ajax({
			url: url,
			method: method,
			headers: getHeaders(headerFor),
			dataType: "json",
			contentType,
			success(response) {
				resolve(response);
			},
			error(errorObject) {
				reject(errorObject);
			},
			timeout: timeout
		});
	} else {
		jQuery.ajax({
			url: url,
			method: method,
			headers: getHeaders(headerFor),
			dataType: "json",
			contentType,
			data: JSON.stringify(data),
			success(response) {
				resolve(response);
			},
			error(errorObject) {
				reject(errorObject);
			},
			timeout: timeout
		});
	}

};

export const getResponseGraphQL = (url, method, headerFor, data, queryName, resolve, reject) => {
	jQuery.ajax({
		url: url,
		method: method,
		headers: getHeaders(headerFor),
		dataType: "json",
		contentType: "application/json",
		data: JSON.stringify(data),
		success(response) {
			if (response.data) { // Check if data isn't null (which means no error is thrown) and resolve
				resolve(response.data[queryName]);
			} else {
				reject(response.errors); // Errors will be an array with each item being an object with properties of locations, message and path
			}
		},
		error(errorObject) {
			reject(errorObject);
		},
		timeout: constants.timeout
	});
};