import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.logoutPopup, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SHOW_LOGOUT_POPUP :
			nextState.showLogoutPopup = action.payload;
			break;
		case actions.SAVE_LOGOUT_POPUP_CODE :
			nextState.code = action.payload;
			break;
		case actions.SAVE_LOGOUT_POPUP_PAGE :
			nextState.page = action.payload;
			break;
		default:
			nextState = state;
	}
	return nextState;
};