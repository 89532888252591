import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.sozoDeviceCommEnabled, action) => {
    let nextState;
    switch (action.type) {
        case actions.SAVE_SOZO_DEVICE_COMM_ENABLED :
            nextState = action.payload;
            break;
        default:
            nextState = state;
    }
    return nextState;
};