import {createContext, useContext} from "react";
import PropTypes from "prop-types";

const DevicePortContext = createContext();

export const DevicePortProvider = (props) => {

    return (
        <DevicePortContext.Provider value={props.messagePort}>
            {props.children}
        </DevicePortContext.Provider>
    );
};

export const useDevicePort = () => useContext(DevicePortContext);

DevicePortProvider.propTypes = {
    messagePort: PropTypes.object,
    children: PropTypes.object
};