import PropTypes from "prop-types";
import Xbutton from "../SVGs/Xbutton/Xbutton";

const ToastView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"flexColCenterStart toast"}>
		<div className={"flexRowSpaceBetwnCenter toast__header"}>
			<h3 className={"flexRowStartCenter toast__title"}>
				<i className={"toast__icon"}>{props.icon}</i>
				{props.title}
			</h3>
			<button className={"toast__closeBtn"} onClick={props.closeToast}><Xbutton/></button>
		</div>
		<p className={"toast__message"}>{props.message}</p>
	</div>;

};

ToastView.propTypes = {
	icon:PropTypes.object,
	title:PropTypes.string,
	closeToast:PropTypes.func,
	message:PropTypes.string,
};

export default ToastView;