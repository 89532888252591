import { Fragment } from "react";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import InputCode from "../InputCode/InputCode";
import PrivacyIcon from "../SVGs/PrivacyIcon/PrivacyIcon";

const MfaAuthenticationView = (props) => {

	//region VARIABLES
	const containerClassName = props.isForce ? "authentication--page" : "authentication--popup " + props.exitClass;
	//endregion

	//region FUNCTIONS
	const getAuthenticationParagraph = () => {
		let paragraph = "";
		if (props.targetUser === constants.targetUser.self) {
			paragraph =
				<p className={"authentication__paragraph"}>
					{dictionary[localStorage.language].mfaAuthenticationMethod}
					<span
						className={"authentication__paragraph--blue"}>{dictionary[localStorage.language].authenticatorApp}</span>
				</p>;
		} else {
			paragraph =
				<p className={"authentication__paragraph"}>
					<span
						className={"authentication__paragraph--warning"}>{dictionary[localStorage.language].mfaDeactivationWarning}</span>
					<span className={"authentication__paragraph--userEmail"}>{props.userEmail}</span>
				</p>;
		}
		return paragraph;
	};
	//endregion

	return (
        <div className={"flexColStartCenter loginBox portal authentication " + containerClassName} onAnimationEnd={props.handleAnimationEnd}>
            <div className={"flexColCenterStart authentication__header"}>
                <PrivacyIcon/>
                <h2 className={"authentication__title"}>{dictionary[localStorage.language].multiFactorAuthentication}</h2>
            </div>
            {getAuthenticationParagraph()}
            <form onSubmit={props.handleSubmit} className={"authentication__form"}>
                <Input
                    id={"mfaAuthenticationPassword"}
                    type={"password"}
                    withLabel={true}
                    labelInBox={true}
                    labelText={dictionary[localStorage.language].password}
                    placeholder={dictionary[localStorage.language].enterPassword}
                    value={props.password}
                    onChange={props.handleInput}
                />
                {!props.isForce ? <div className={"authentication__mfaCode"}>
                    {props.hasMFA ? <Fragment>
                            <label className={"labels__label"}>{dictionary[localStorage.language].enterMfaCode}</label>
                            <InputCode inputLength={6} setCode={props.handleMfaCodeChange} classes={"authentication__mfaCodeInput"}/>
                    </Fragment>: ""}
                </div> : ""}
                {props.isForce ?
                    <Fragment>
                        <input className={"button-blue authentication__btnBig authentication__btnBig--submit"} type={"submit"}
                               value={dictionary[localStorage.language].submit}/>
                        <button className={"button-white authentication__btnBig"} type={"button"} onClick={props.handleCancel}>{dictionary[localStorage.language].cancel}</button>
                    </Fragment> :
                    <div className={"flexRowCenterCenter authentication__buttons"}>
                        <input className={"button-blue authentication__btnSmall authentication__btnSmall--submit"} type={"submit"}
                               value={dictionary[localStorage.language].submit}/>
                        <button className={"button-white authentication__btnSmall"} type={"button"}
                                onClick={props.handleCancel}>{dictionary[localStorage.language].cancel}</button>
                    </div>
                }
            </form>

        </div>
    );

};

MfaAuthenticationView.propTypes = {
	password: PropTypes.string,
	exitClass: PropTypes.string,
	targetUser: PropTypes.string,
	userEmail: PropTypes.string,
	hasMFA: PropTypes.bool,
	isForce: PropTypes.bool,
	handleInput: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleCancel: PropTypes.func,
	handleAnimationEnd: PropTypes.func,
	handleMfaCodeChange: PropTypes.func
};

export default MfaAuthenticationView;