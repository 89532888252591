const ArrowBigView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={"arrowBig"}>
            <svg className="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 6 28 21">
                <path className="b" d="M0,4.5V6H16.5v4.5l6-5.25L16.5,0V4.5Z" transform="translate(4.5 10.5)"/>
            </svg>
        </div>
    );

};

ArrowBigView.propTypes = {};


export default ArrowBigView;