import UnlockedIconView from "./UnlockedIconView";

const UnlockedIcon = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <UnlockedIconView/>;

};

UnlockedIcon.propTypes = {};

export default UnlockedIcon;