import { Fragment } from "react";
import PropTypes from "prop-types";

const InputCodeView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <Fragment>
            <div className={`inputCode ${props.classes}`}>
                {props.values.map((value, i) =>
                    <input type={"number"}
                           key={i}
                           data-key={i}
                           className={"inputCode__input"}
                           onChange={props.handleInputChange}
                           onKeyUp={props.onBackspace}
                           value={props.values[i] === undefined || props.values[i] === null ? "" : props.values[i]}
                           maxLength={"1"}
                           autoComplete={"off"}
                           autoFocus={i === 0}
                    />)}

            </div>
        </Fragment>
    );

};

InputCodeView.propTypes = {
    values: PropTypes.array,
    inputValue: PropTypes.string,
    classes: PropTypes.string,
    inputLength: PropTypes.number,
    inputDisabled: PropTypes.bool,
    handleInputChange: PropTypes.func,
    onBackspace: PropTypes.func
};

export default InputCodeView;