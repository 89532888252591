import PropTypes from "prop-types";

const ArrowDownView = (props) => {
    //region VARIABLES
//endregion

//region FUNCTIONS
//endregion

    return (
        <div className={`arrowDown ${props.classes}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9">
                <path className="a"
                      d="M40.73,44.762a1.436,1.436,0,0,0,2.031,0l4.309-4.309a1.436,1.436,0,0,0-2.031-2.031l-3.294,3.294-3.294-3.294a1.436,1.436,0,0,0-2.031,2.031Z"
                      transform="translate(-36 -38)"/>
            </svg>
        </div>
    );

};
ArrowDownView.propTypes = {
    classes: PropTypes.string,
};
export default ArrowDownView;