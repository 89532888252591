import IpdIconView from "./IpdIconView";

const IpdIcon = () => {
    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <IpdIconView/>;
};

IpdIcon.propTypes = {};

export default IpdIcon;