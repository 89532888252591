import PropTypes from "prop-types";
import CheckIconView from "./CheckIconView";

const CheckIcon = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <CheckIconView type={props.type}/>;

};

CheckIcon.defaultProps = {
	type:"thin"
};
CheckIcon.propTypes = {
	type:PropTypes.oneOf(["thin","fat"])
};

export default CheckIcon;