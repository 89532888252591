import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.selectedMeasurement, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_MEASUREMENT_DATA :
            nextState = {...nextState, ...action.payload};
            break;
            case actions.CLEAR_MEASUREMENT_DATA :
            nextState = null;
            break;
        default:
            nextState = state;
    }
    return nextState;
};