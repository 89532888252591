import PropTypes from "prop-types";
import Portal from "../Portal/Portal";

const CustomPopupView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <Portal>
		{props.show && <>
		<div className={`popupBackground ${props.popupover && "popupBackground--over"}`}/>
		<div className={`popupCentered flexColStart customPopup ${props.containerClasses}`}>
			<h3 className={`customPopup__title ${props.titleClasses}`}>{props.title}</h3>
			<div className={`customPopup__body ${props.bodyClasses}`}>
				{props.children}
			</div>
			{props.defaultButtons && <div className={`flexRowEndCenter customPopup__defaultButtonsWrapper ${props.defaultButtonsWrapperClasses}`}>
				{props.withCancel && <button className={`button-grey customPopup__defaultCancel ${props.defaultCancelClasses}`} onClick={props.handleCancel}>{props.cancelText}</button>}
				<button className={`button-blue customPopup__defaultSubmit ${props.defaultSubmitClasses}`} onClick={props.handleSubmit}>{props.submitText}</button>
			</div>}
		</div>
		</>}
	</Portal>;

};

CustomPopupView.defaultProps = {
	defaultButtons: true,
	withCancel: true,
	popupover: false,
	cancelText: localStorage.language ? dictionary[localStorage.language].cancel : "",
	submitText: localStorage.language ? dictionary[localStorage.language].submit : "",
	title: "",
	containerClasses: "",
	titleClasses: "",
	bodyClasses: "",
	defaultButtonsWrapperClasses: "",
	defaultCancelClasses: "",
	defaultSubmitClasses: "",
};

CustomPopupView.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	defaultButtons: PropTypes.bool,
	withCancel: PropTypes.bool,
	show: PropTypes.bool,
	popupover: PropTypes.bool,
	cancelText: PropTypes.string,
	submitText: PropTypes.string,
	title: PropTypes.string,
	containerClasses: PropTypes.string,
	titleClasses: PropTypes.string,
	bodyClasses: PropTypes.string,
	defaultButtonsWrapperClasses: PropTypes.string,
	defaultCancelClasses: PropTypes.string,
	defaultSubmitClasses: PropTypes.string,
	handleCancel: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default CustomPopupView;