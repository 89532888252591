import Utils from "./DeviceComLib/utils";
import crc32 from "crc-32";
import Pako from "pako";
import DeviceAndroidConnector from "./DeviceComLib/Connectors/deviceAndroidConnector";
import DeviceConnector from "./DeviceComLib/Connectors/deviceConnector";
import DeviceMessage from "./DeviceComLib/Message/deviceMessage";
import ResultParser from "./DeviceComLib/ResultParser/deviceResultParser";
import Commands from "./DeviceComLib/ResultParser/deviceCommands";
import ErrorToUiCodeMapper from "./ErrorMapper/errorToUiCodeMapper";
import DeviceiOSConnector from "./DeviceComLib/Connectors/deviceiOSConnector";

class DeviceApi {
    constructor (device, sendLogs) {
        this.defaultFrequencyList = [
            3000, 3069, 3140, 3212, 3286, 3362, 3440, 3519, 3600, 3683, 3768, 3855,
            3943, 4034, 4127, 4221, 4320, 4419, 4521, 4625, 4730, 4840, 4952, 5066,
            5183, 5302, 5424, 5549, 5677, 5808, 5942, 6079, 6219, 6362, 6509, 6659,
            6812, 6970, 7130, 7294, 7463, 7634, 7809, 7990, 8175, 8363, 8554, 8753,
            8955, 9160, 9372, 9588, 9809, 10035, 10264, 10501, 10744, 10992, 11245,
            11504, 11768, 12041, 12319, 12602, 12891, 13188, 13491, 13803, 14119,
            14446, 14782, 15123, 15468, 15823, 16188, 16563, 16942, 17339, 17738,
            18141, 18561, 18993, 19427, 19871, 20336, 20801, 21277, 21775, 22272,
            22779, 23310, 23852, 24390, 24953, 25526, 26127, 26720, 27341, 27972,
            28612, 29283, 29940, 30628, 31348, 32077, 32814, 33557, 34335, 35119,
            35939, 36765, 37594, 38462, 39370, 40282, 41195, 42150, 43103, 44101,
            45147, 46189, 47226, 48309, 49444, 50569, 51746, 52910, 54127, 55402,
            56657, 57971, 59347, 60698, 62112, 63492, 64935, 66445, 68027, 69565,
            71174, 72860, 74488, 76190, 77973, 79840, 81633, 83507, 85470, 87336,
            89485, 91533, 93458, 95694, 97800, 100251, 102564, 104712, 107239, 109589,
            112360, 114943, 117647, 120120, 123077, 125786, 128617, 131579, 134680,
            137931, 140845, 144404, 147601, 150943, 154440, 158103, 161943, 165289,
            169492, 173160, 176991, 180995, 185185, 189573, 194175, 198020, 203046,
            207254, 212766, 217391, 222222, 227273, 232558, 238095, 243902, 248447,
            254777, 261438, 266667, 272109, 279720, 285714, 291971, 298507, 305344,
            312500, 320000, 327869, 336134, 341880, 350877, 357143, 366972, 373832,
            384615, 392157, 404040, 412371, 421053, 430108, 439560, 449438, 459770,
            470588, 481928, 493827, 506329, 519481, 526316, 540541, 555556, 563380,
            579710, 588235, 606061, 615385, 634921, 645161, 666667, 677966, 689655,
            714286, 727273, 740741, 754717, 784314, 800000, 816327, 833333, 851064,
            869565, 888889, 909091, 930233, 952381, 975610, 1000000
        ];
        this.device = device;
        this.sendLogs = sendLogs;
        this.utils = new Utils(device.keys);
        this.currentOS = this.device.currentOS;
        this.resultParser = new ResultParser(device);
        this.deviceMessage = new DeviceMessage(device);
        this.errorMapper = new ErrorToUiCodeMapper();
        this.deviceConnector = null;
        if (this.currentOS.isAndroid) {
            this.deviceConnector = new DeviceAndroidConnector(device, sendLogs);
        } else if (this.currentOS.isiOS) {
            this.deviceConnector = new DeviceiOSConnector(device, sendLogs);
        } else {
            this.deviceConnector = new DeviceConnector(device, sendLogs);
        }
        this.isSOZOProDevice = null;
        this.isDeviceAuthenticated = false;
        this.getSOZOBluetoothDevicesTimeout = 6000;
        this.basicCommandTimeout = 20000;
        this.selfTestAndScaleWeightCommandTimeout = 30000;
        this.calibrationHistoryAndMeasurementCommandTimeout = 40000;
        this.pairingTimeout = 20000;
        this.setSOZOBluetoothDeviceTimeout = 15000;
        this.firmwareCommandTimeout = 1200000;
        this.applicationUpdateCommandTimeout = 120000;
        this.messageIndex = 0;
        this.messageType = {
            ACK: 0x0001,
            NAK: 0x0002,
            REQUEST: 0x0003,
            FREQ_LIST: 0x0101,
            MEASUREMENT_SETUP: 0x0102,
            RESET_FREQ_LIST_DEFAULT: 0x0103,
            START_MEASUREMENT: 0x0201,
            STOP_MEASUREMENT: 0x0202,
            MEASUREMENT_RESULTS: 0x0203,
            PROGRESS: 0x0204,
            PCM_ID: 0x0301,
            CALCULATE_OFFSETS: 0x0401,
            OFFSET_TABLE: 0x0402,
            CALIBRATION_TABLE: 0x0403,
            CLEAR_CALIBRATION_ENTRY: 0x0404,
            SETTINGS: 0x0501,
            SELF_TEST: 0x0601,
            SELF_TEST_GET_DETAILED_RESULT: 0x0605,
            STATUS_DATA: 0x0701,
            ZERO_SCALE: 0x0801,
            SCALE_WEIGHT: 0x0802,
            REACQUIRE_SCALE_WEIGHT: 0x803,
            AUTHENTICATION_INIT: 0xA001,
            AUTHENTICATION_DEVICE_CHALLENGE: 0xA002,
            AUTHENTICATION_CONTROLLER_RESPONSE: 0xA003,
            FIRMWARE_UPDATE_DATA: 0xF001,
            FIRMWARE_UPDATE_FINISH: 0xF002,
            FIRMWARE_UPDATE_REVERT: 0xF003,
            FIRMWARE_UPDATE_COMPRESSED_DATA: 0xF004,
            FIRMWARE_UPDATE_COMPRESSED_FINISH: 0xF005,
            SCALE_HIGH: 0x0812,
            SCALE_LOW: 0x0813,
            SCALE_CALIBRATION_ACCEPT: 0x0814,
            SCALE_CALIBRATION_REJECT: 0x0815,
            ENTER_CALIBRATION_MODE: 0x810,
            EXIT_CALIBRATION_MODE: 0x811,
            CONTACT_TEST_DETAILS: 0x902,
            SCALE_LOG_REQUEST: 0x820,
            SCALE_LOG_UPDATE: 0x821,
            SCALE_LOG_CALIBRATION: 0x822,
            SCALE_LOG_END: 0x823
        };
    }

    // Private Methods ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    _getResponse = (response) => {
        if (response === "sleep_timeout") {
            this.isDeviceAuthenticated = false;
            throw new Error(10030);
        }
        return response;
    };

    _sendLogs = (...args) => {
        if (this.sendLogs) {
            this.sendLogs(...args);
        }
    };

    _getScaleWeightResponse = (response) => {
        if (response === "sleep_timeout") {
            this.isDeviceAuthenticated = false;
            throw new Error(10033);
        }
        return response;
    };

    _shouldBreakConnection = (deviceConnection) => {
        let message = "";
        if (deviceConnection.error && deviceConnection.error.message) {
            message = deviceConnection.error.message.toLowerCase();
        }
        let shouldBreakConnection = false;
        if (this.currentOS.isWindows) {
            if (message.includes("authentication failed") || message.includes("not paired") || message.includes("connection attempt failed")) {
                shouldBreakConnection = true;
            } else {
                shouldBreakConnection = false;
            }
        } else {
            shouldBreakConnection = false;
        }
        return shouldBreakConnection;
    };

    _setPairing = async (bluetoothDevice) => {
        if (bluetoothDevice === undefined) {
            throw new Error("Bluetooth Device required.");
        }
        if (this.deviceConnector.isDeviceConnected) {
            await this._disconnectDevice(true);
        }
        this.deviceConnector.setDefaultAllValues(this.device);
        await this.deviceConnector.setBluetoothDevice(bluetoothDevice);
        this.messageIndex = 0;
        await this._setAuthConnection();
        await this._disconnectDevice(true);
    };

    _setSOZOBluetoothDevice = async (bluetoothDevice) => {
        if (bluetoothDevice === undefined) {
            this.deviceConnector.setDefaultAllValues(this.device);
        }
        await this.deviceConnector.setBluetoothDevice(bluetoothDevice);
        await this.deviceConnector.setDisconnection();
        const result = {
            isSozoProDevice: this.deviceConnector.isSOZOProDevice,
            bluetoothDevice: this.deviceConnector.bluetoothDevice
        };
        return result;
    };

    // #region authentication-retry
    // Todo: To check if the authentication retry is necessary. Because it is seen in android that the implementation
    // without retry works 4/10 times. This is because sometimes the SOZOPro in android disconnects the device when a
    // command execution is requested. But on the second or third retry it works. Thats why we have added a retry of
    // maximum 3 times for setConnection
    // _setAuthConnection = async () => {
    //     await this.deviceConnector.setConnection();
    //     const authInitMessage = this.deviceMessage.getAuthenticationInitMessage(this.messageIndex, this.messageType.AUTHENTICATION_INIT);
    //     const authInitResponse = await this.deviceConnector.setMessage(authInitMessage);
    //     const authChallengeMessage = this.deviceMessage.getAuthenticationChallengeMessage(authInitResponse[5], this.messageIndex++, this.messageType.AUTHENTICATION_CONTROLLER_RESPONSE);
    //     const authChallengeResponse = await this.deviceConnector.setMessage(authChallengeMessage);
    //     this.isDeviceAuthenticated = this.deviceMessage.getExpectedResponseMessage(authChallengeResponse[1].data, this.messageType.ACK);
    //     if (!this.isDeviceAuthenticated) {
    //         throw new Error("The Device could not authenticated successfully while retrieving Device Status");
    //     }
    // };
    _setAuthConnection = async () => {
        let shouldRetry = true;
        let index = 0;
        let deviceConnection = {};
        this._sendLogs("_setAuthConnection - Starting Authentication");
        while (index++ < 5 && shouldRetry) {
            this._sendLogs("_setAuthConnection - Retrying connection");
            deviceConnection = await this.deviceConnector.setConnection();
            this._sendLogs("_setAuthConnection - Device Connection Response: ", JSON.stringify(this.device));
            if (deviceConnection === undefined || deviceConnection?.error?.message === "10027") {
                shouldRetry = false;
                deviceConnection = {
                    isDeviceConnected: false,
                    error: {
                        name: "NetworkError",
                        message: deviceConnection?.error?.message ?? "DeviceNotConnected"
                    }
                };
            }
            if (index === 5 || deviceConnection.isDeviceConnected || this._shouldBreakConnection(deviceConnection)) {
                this._sendLogs("_setAuthConnection - Setting retry connection to false");
                shouldRetry = false;
            }
        }
        this._sendLogs("_setAuthConnection - Auth isDeviceConnected", deviceConnection.isDeviceConnected);
        if (deviceConnection.isDeviceConnected) {
            try {
                const authInitMessage = this.deviceMessage.getAuthenticationInitMessage(this.messageIndex, this.messageType.AUTHENTICATION_INIT);
                this._sendLogs("_setAuthConnection - Auth Init Message Generated", authInitMessage);
                const authInitResponse = await this.deviceConnector.setMessage(authInitMessage);
                this._sendLogs("_setAuthConnection - Auth Init Response received: ", JSON.stringify(authInitResponse));
                const authChallengeMessage = this.deviceMessage.getAuthenticationChallengeMessage(authInitResponse[5], this.messageIndex++, this.messageType.AUTHENTICATION_CONTROLLER_RESPONSE);
                this._sendLogs("_setAuthConnection - Auth Challenge Message Generated", authChallengeMessage);
                const authChallengeResponse = await this.deviceConnector.setMessage(authChallengeMessage);
                this._sendLogs("_setAuthConnection - Auth Challenge Response Received", JSON.stringify(authChallengeResponse));
                this.isDeviceAuthenticated = this.deviceMessage.getExpectedResponseMessage(authChallengeResponse[1].data, this.messageType.ACK);
                this._sendLogs("_setAuthConnection - Is Device Authenticated? ", JSON.stringify(this.isDeviceAuthenticated));
                if (!this.isDeviceAuthenticated) {
                    this._sendLogs("_setAuthConnection - Sending device error 10001");
                    throw new Error(10001);
                }
                return this.isDeviceAuthenticated;
            } catch (error) {
                this._sendLogs("_setAuthConnection - Error Authenticating ", JSON.stringify(error));
                this._throwConnectionOrAuthError(error);
            }
        } else {
            const error = deviceConnection.error;
            this._sendLogs("_setAuthConnection - Error making device connection", JSON.stringify(error));
            this._throwConnectionOrAuthError(error);
        }
    };
    // #endregion authentication-retry

    _throwConnectionOrAuthError = (error) => {
        if (error && error === "10028") {
            throw new Error(10028);
        } else if (error && error === "10027") {
            throw new Error(10027);
        } else if (!error || !error.name || !error.message) {
            throw new Error(10002);
        } else if (error.name && error.name.toLowerCase() === "networkerror" && error.message.toLowerCase().includes("connection attempt failed")) {
            throw new Error(10027);
        } else if (error.name.toLowerCase() === "networkerror" && (error.message.toLowerCase().includes("not paired") || error.message.toLowerCase().includes("authentication failed"))) {
            throw new Error(10028);
        } else if (error.message) {
            throw new Error(error.message);
        } else {
            throw new Error(10002);
        }
    };

    _disconnectDevice = async (shouldCloseConnection) => {
        if (shouldCloseConnection) {
            await this.deviceConnector.setDisconnection();
        }
    };

    _getMeasurementProgress = async () => {
        this._sendLogs("_getMeasurementProgress - Getting measurement progress");
        let progress = 0;
        let previousProgress = 0;
        // #region timeoutcount
        // When the timeoutCount is 1 we have added a delay of 1 second at the bottom. Suppose that we have requested a progress
        // for measurement and we have received 99 as the progress. After that what we will do is wait for a second to request the
        // progress again. But the measurement was already completed within 10 milliseconds meaning, the progress from 99-100 has been
        // done in 10 milliseconds, but due to the 1 second delay we have waited for extra time. Therefore we send the
        // request for progress as soon as we receive the response. And for somereason for 100 times the progress was same then
        // there must be something wrong then we can throw an error.
        // let timeoutCount = 1;
        let timeoutCount = 100;
        // #endregion timeoutcount
        do {
            const message = this.deviceMessage.getRequestMessage(this.messageIndex++, this.messageType.PROGRESS, this.messageType.REQUEST);
            const response = await this.deviceConnector.setMessage(message);
            const isRequestRetrieved = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.PROGRESS);

            if (!isRequestRetrieved) {
                throw new Error(response[5][1]);
            }

            previousProgress = progress;
            progress = Object.values(response[5])[0];
            // this._sendLogs("Progress: ", JSON.stringify(progress));
            // #region measurementprogress
            // if (previousProgress === progress) {
            //     timeoutCount--;
            // }
            // await this.utils.setSleep(1000);
            if (previousProgress !== progress) {
                timeoutCount = 100;
            }
            // #endregion measurementprogress
        } while (progress < 100 && timeoutCount-- >= 0);

        if (progress < 100 && timeoutCount <= 0) {
            this._sendLogs("_getMeasurementProgress - Throwing measurement progress timeout error 10030");
            throw new Error(10030);
        }

        return progress;
    };

    _getMeasurementResult = async () => {
        this._sendLogs("_getMeasurementResult - Getting measurement result");
        const message = this.deviceMessage.getRequestMessage(this.messageIndex++, this.messageType.MEASUREMENT_RESULTS, this.messageType.REQUEST);
        const response = await this.deviceConnector.setMessage(message);
        this._sendLogs("_getMeasurementResult - Measurement result received:", JSON.stringify(response));
        const isRequestNotRetrieved = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.NAK);

        if (isRequestNotRetrieved) {
            this._sendLogs("_getMeasurementResult - Throwing error:", JSON.stringify(response[5][1]));
            throw new Error(response[5][1]);
        }

        const measurementResult = response[5];
        const parseResultRequest = {
            command: Commands.GET_MEASUREMENT_RESULT,
            measurementResult
        };
        try {
            return this.resultParser.parseCommandResult(parseResultRequest);
        } catch (error) {
            this._sendLogs("_getMeasurementResult - Throwing error 10011:", JSON.stringify(error));
            throw new Error(10011);
        }
    };

    _getDeviceStatus = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        let statusTypes = [];
        if (this.deviceConnector.isSOZOProDevice) {
            statusTypes = [0x01, 0x02, 0x03, 0x04, 0x05, 0x06];
        } else {
            statusTypes = [0x01, 0x02, 0x03, 0x04];
        }
        const deviceStatusMessage = this.deviceMessage.getDeviceStatusMessage(this.messageIndex++, statusTypes,
            this.messageType.STATUS_DATA, this.messageType.REQUEST);
        this._sendLogs("_getDeviceStatus - Sending Device Status Message..", JSON.stringify(deviceStatusMessage));
        const deviceStatusResponse = await this.deviceConnector.setMessage(deviceStatusMessage);
        this._sendLogs("_getDeviceStatus - Received Response from device: ", JSON.stringify(deviceStatusResponse));
        const isDeviceStatusRetrieved = this.deviceMessage.getExpectedResponseMessage(deviceStatusResponse[1].data, this.messageType.STATUS_DATA);

        if (!isDeviceStatusRetrieved) {
            throw new Error(deviceStatusResponse[5][1]);
        }

        const deviceStatus = deviceStatusResponse[5];
        // The message type STATUS_DATA gives us data related to bluetoothMacAddress, firmwareVersion, selfTestStatus, impedenceCalibrationDate
        // but not about the serial number and friendly name. To get the serial number and friendly name, settings command is used.
        const settingTypes = [0x01, 0x02];
        const settingsMessage = this.deviceMessage.getDeviceSettingMessage(this.messageIndex++, settingTypes, this.messageType.SETTINGS, this.messageType.REQUEST);
        this._sendLogs("_getDeviceStatus - Sending Settings message for serial number", JSON.stringify(settingsMessage));
        const settingResponse = await this.deviceConnector.setMessage(settingsMessage);
        this._sendLogs("_getDeviceStatus - Received Settings response from device: ", JSON.stringify(settingResponse));
        const isDeviceSettingsRetrieved = this.deviceMessage.getExpectedResponseMessage(settingResponse[1].data, this.messageType.SETTINGS);
        if (!isDeviceSettingsRetrieved) {
            this._sendLogs("_getDeviceStatus - Throwing error: ", JSON.stringify(settingResponse[5][1]));
            throw new Error(settingResponse[5][1]);
        }
        const deviceSettings = settingResponse[5];
        this._sendLogs("_getDeviceStatus - Parsing response..");
        const parseResultRequest = {
            command: Commands.GET_DEVICE_STATUS,
            deviceStatus,
            deviceSettings,
            isSOZOProDevice: this.deviceConnector.isSOZOProDevice
        };
        this._sendLogs("_getDeviceStatus - Response parsed", JSON.stringify(parseResultRequest));
        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _getMeasurement = async (driveChannel, senseChannel) => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        if (senseChannel.length > 4) {
            throw new Error(10009);
        }
        const message = this.deviceMessage.getMeasurementMessage(
            this.messageIndex++,
            this.messageType.START_MEASUREMENT,
            driveChannel, senseChannel);
        this._sendLogs("_getMeasurement - Sending measurement start message..", JSON.stringify(message));
        const response = await this.deviceConnector.setMessage(message);
        this._sendLogs("_getMeasurement - Measurement start response received:", JSON.stringify(response));
        const isMeasurementRetrieved = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.ACK);

        if (!isMeasurementRetrieved) {
            this._sendLogs("_getMeasurement - Throwing Measurement start error:", JSON.stringify(response[5][1]));
            throw new Error(response[5][1]);
        }

        const measurementProgressResponse = await this._getMeasurementProgress();
        if (measurementProgressResponse === 100) {
            return await this._getMeasurementResult();
        } else {
            throw new Error(10012);
        }
    };

    _setFirmwareUpdate = async (binaryFileData, setfirmwareUpdateProgress) => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const compressedFirmwareFileBytes = Pako.deflate(binaryFileData);
        for (let index = 0; index < compressedFirmwareFileBytes.length; index += 1024) {
            const firmwareUpdateMessage = this.deviceMessage.getFirmwareUpdateMessage(
                this.messageIndex++, this.messageType.FIRMWARE_UPDATE_COMPRESSED_DATA, index, compressedFirmwareFileBytes.slice(index, index + 1024)
            );
            const response = await this.deviceConnector.setMessage(firmwareUpdateMessage);
            const isAckReceived = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.ACK);
            if (!isAckReceived) {
                throw new Error(response[5][1]);
            }
            if (setfirmwareUpdateProgress) {
                setfirmwareUpdateProgress(index / compressedFirmwareFileBytes.length * 100);
            }
        }
        const crcValue = crc32.buf(binaryFileData);
        const firmaryFinishMessage = this.deviceMessage.getFirmwareUpdateFinishMessage(
            this.messageIndex++, this.messageType.FIRMWARE_UPDATE_COMPRESSED_FINISH, binaryFileData.length, crcValue,
            this.deviceConnector.isSOZOProDevice
        );
        const response = await this.deviceConnector.setMessage(firmaryFinishMessage);
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.ACK);
        if (!isAckReceived) {
            throw new Error(response[5][1]);
        }
        return this.device;
    };

    _setSettings = async (sozoSettings) => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const setSettingsMessage = this.deviceMessage.getSetDeviceSettingMessage(this.messageType.SETTINGS, sozoSettings, this.messageIndex++);
        const setSettingsResponse = await this.deviceConnector.setMessage(setSettingsMessage);
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(setSettingsResponse[1].data, this.messageType.ACK);
        if (!isAckReceived) {
            throw new Error(setSettingsResponse[5][1]);
        }
        return true;
    };

    _getSettings = async (sozoSettings) => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const getSettingsMessage = this.deviceMessage.getDeviceSettingMessage(this.messageIndex++, sozoSettings, this.messageType.SETTINGS, this.messageType.REQUEST);
        this._sendLogs("_getSettings - Received Settings Message: ", JSON.stringify(getSettingsMessage));
        const settingResponse = await this.deviceConnector.setMessage(getSettingsMessage);
        this._sendLogs("_getSettings - Received Settings Reponse: ", JSON.stringify(settingResponse));
        const isDeviceSettingsRetrieved = this.deviceMessage.getExpectedResponseMessage(settingResponse[1].data, this.messageType.SETTINGS);

        if (!isDeviceSettingsRetrieved) {
            this._sendLogs("_getSettings - Sending error: ", JSON.stringify(settingResponse[5][1]));
            throw new Error(settingResponse[5][1]);
        }

        this._sendLogs("_getSettings - Parsing response..");
        const parseResultRequest = {
            command: Commands.GET_DEVICE_SETTING,
            requestedSettings: sozoSettings,
            receivedSettings: settingResponse[5]
        };
        this._sendLogs("_getSettings - Response parsed..", JSON.stringify(parseResultRequest));

        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _getSelfTest = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const timestamp = Math.floor(Date.now() / 1000);
        const getSelfTestMessage = this.deviceMessage.getRunSelfTestMessage(this.messageType.SELF_TEST, this.deviceConnector.isSOZOProDevice, timestamp, this.messageIndex++);
        this._sendLogs("_getSelfTest - Received Self Test Message: ", JSON.stringify(getSelfTestMessage));
        const selfTestResponse = await this.deviceConnector.setMessage(getSelfTestMessage);
        this._sendLogs("_getSelfTest - Received Self Test Reponse: ", JSON.stringify(selfTestResponse));
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(selfTestResponse[1].data, this.messageType.ACK);
        if (!isAckReceived) {
            this._sendLogs("_getSelfTest - Sending error: ", JSON.stringify(selfTestResponse[5][1]));
            throw new Error(selfTestResponse[5][1]);
        }
        return await this._getSelfTestStatus();
    };

    _getSelfTestStatus = async () => {
        const statusTypes = [0x03];
        const deviceStatusMessage = this.deviceMessage.getDeviceStatusMessage(this.messageIndex++, statusTypes,
            this.messageType.STATUS_DATA, this.messageType.REQUEST);
        this._sendLogs("_getSelfTestStatus - Sending Device Status Message..", JSON.stringify(deviceStatusMessage));
        const deviceStatusResponse = await this.deviceConnector.setMessage(deviceStatusMessage);
        this._sendLogs("_getSelfTestStatus - Received Response from device: ", JSON.stringify(deviceStatusResponse));
        if (deviceStatusResponse[5][1] === 52 || deviceStatusResponse[5][1] === 5) {
            return await this._getSelfTestStatus();
        } else {
            const isDeviceStatusRetrieved = this.deviceMessage.getExpectedResponseMessage(deviceStatusResponse[1].data, this.messageType.STATUS_DATA);
            if (!isDeviceStatusRetrieved) {
                this._sendLogs("_getSelfTestStatus - Throwing error.", JSON.stringify(deviceStatusResponse[5][1]));
                throw new Error(deviceStatusResponse[5][1]);
            }
            const parseSelfTestStatus = {
                command: Commands.RUN_SELF_TEST,
                selfTestResult: deviceStatusResponse[5],
                isSOZOProDevice: this.deviceConnector.isSOZOProDevice
            };
            const selfTestStatus = this.resultParser.parseCommandResult(parseSelfTestStatus);
            let selfTestErrorArray = [];
            if (!selfTestStatus) {
                selfTestErrorArray = this.deviceConnector.isSOZOProDevice ? await this._selfTestGetDetailedResult() : [];
            }
            this._sendLogs("_getSelfTestStatus - Parsing Self Test Result..");
            const parseSelfTestResult = {
                command: Commands.RUN_SELF_TEST_RESULT,
                selfTestResultExceptionArray: selfTestErrorArray,
                selfTestStatus
            };
            this._sendLogs("_getSelfTestStatus - Self Test Result parsed..", JSON.stringify(parseSelfTestResult));
            return this.resultParser.parseCommandResult(parseSelfTestResult);
        }
    };

    _getFrequency = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const message = this.deviceMessage.getRequestMessage(this.messageIndex++, this.messageType.FREQ_LIST, this.messageType.REQUEST);
        const response = await this.deviceConnector.setMessage(message);
        const isFrequencyRetrieved = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.FREQ_LIST);

        if (!isFrequencyRetrieved) {
            throw new Error(response[5][1]);
        }

        const parseResultRequest = {
            command: Commands.GET_FREQUENCY,
            frequencyList: response[5]
        };

        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _setFrequency = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        if (this.defaultFrequencyList.length === 0) {
            throw new Error(10004);
        } else if (this.defaultFrequencyList.length > 256) {
            throw new Error(10005);
        }

        this.defaultFrequencyList.forEach((frequency) => {
            if (frequency < 3000 || frequency > 1000000) {
                throw new Error(10006);
            }
        });

        const message = this.deviceMessage.getSetFrequencyListMessage(this.messageType.FREQ_LIST, this.messageIndex++, this.defaultFrequencyList);
        const response = await this.deviceConnector.setMessage(message);
        const isFrequencySet = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.ACK);

        if (!isFrequencySet) {
            throw new Error(response[5][1]);
        }
        return isFrequencySet;
    };

    _resetFrequency = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const message = this.deviceMessage.getResetFrequencyListMessage(this.messageType.RESET_FREQ_LIST_DEFAULT, this.messageIndex++);
        const response = await this.deviceConnector.setMessage(message);
        const isFrequencyReset = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.ACK);

        if (!isFrequencyReset) {
            throw new Error(response[5][1]);
        }

        return isFrequencyReset;
    };

    _getWeightCalibrationHistoryCommandSendingDelay = () => {
        if (this.currentOS.isWindows) {
            return 100;
        } else {
            return 0;
        }
    };

    _getWeightCalibrationHistory = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const sozoScaleLogs = [];
        let shouldSendScaleLogRequest = true;
        while (shouldSendScaleLogRequest) {
            const message = this.deviceMessage.getWeightCalibrationHistoryMessage(this.messageType.SCALE_LOG_REQUEST, this.messageIndex++);
            await this.utils.setSleep(this._getWeightCalibrationHistoryCommandSendingDelay());
            const response = await this.deviceConnector.setMessage(message);
            if (this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.NAK)) {
                throw new Error(response[5][1]);
            } else {
                if (this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.SCALE_LOG_CALIBRATION)) {
                    const parseResultRequest = {
                        command: Commands.GET_WEIGHT_CALIBRATION_HISTORY_SCALE_LOG,
                        scaleLogResponse: response
                    };
                    sozoScaleLogs.push(this.resultParser.parseCommandResult(parseResultRequest));
                } else if (this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.SCALE_LOG_END)) {
                    shouldSendScaleLogRequest = false;
                }
            }
        }
        const parseResultRequest = {
            command: Commands.GET_WEIGHT_CALIBRATION_HISTORY,
            weightCalibration: sozoScaleLogs
        };
        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _getScaleWeight = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const message = this.deviceMessage.getRequestMessage(this.messageIndex++, this.messageType.SCALE_WEIGHT, this.messageType.REQUEST);
        const response = await this.deviceConnector.setMessage(message);
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.SCALE_WEIGHT);
        if (!isAckReceived) {
            throw new Error(response[5][1]);
        }
        const parseResultRequest = {
            command: Commands.GET_SCALE_WEIGHT,
            scaleWeight: response[5]
        };
        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _reacquireScaleWeight = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const message = this.deviceMessage.getRequestMessage(this.messageIndex++, this.messageType.REACQUIRE_SCALE_WEIGHT, this.messageType.REQUEST);
        const response = await this.deviceConnector.setMessage(message);
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.SCALE_WEIGHT);
        if (!isAckReceived) {
            throw new Error(response[5][1]);
        }
        const parseResultRequest = {
            command: Commands.REACQUIRE_SCALE_WEIGHT,
            reacquireScaleWeight: response[5]
        };
        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _zeroScale = async () => {
        this.messageIndex = 0;
        await this._setAuthConnection();
        const message = this.deviceMessage.getZeroScaleMessage(this.messageType.ZERO_SCALE, this.messageIndex++);
        const response = await this.deviceConnector.setMessage(message);
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.ACK);
        if (!isAckReceived) {
            throw new Error(response[5][1]);
        }
        return isAckReceived;
    };

    _selfTestGetDetailedResult = async () => {
        this.messageIndex = 0;
        const message = this.deviceMessage.getSelfTestGetDetailedResultMessage(this.messageType.SELF_TEST_GET_DETAILED_RESULT, this.messageIndex++);
        const response = await this.deviceConnector.setMessage(message);
        const isAckReceived = this.deviceMessage.getExpectedResponseMessage(response[1].data, this.messageType.SELF_TEST_GET_DETAILED_RESULT);
        if (!isAckReceived) {
            throw new Error(response[5][1]);
        }
        const parseResultRequest = {
            command: Commands.SELF_TEST_GET_DETAILED_RESULT,
            selfTestDetailedResult: response[5]
        };
        return this.resultParser.parseCommandResult(parseResultRequest);
    };

    _setApplicationUpdate = async (applicationBinaryFileData, setApplicationUpdateProgress) => {
        this.messageIndex = 0;
        for (let index = 0; index < applicationBinaryFileData.length; index += 10024) {
            const apkUpdateMessage = applicationBinaryFileData.slice(index, index + 10024);
            await this.deviceConnector.setApplicationUpdateBytes(apkUpdateMessage);
            if (setApplicationUpdateProgress) {
                setApplicationUpdateProgress(index / applicationBinaryFileData.length * 100);
            }
        }
        const response = await this.deviceConnector.setApplicationUpdateFinish();
        return response;
    };

    _throwError = (error, defaultErrorCode) => {
        if (typeof error === "string") {
            error = new Error(error);
        }
        const uiCodes = this.errorMapper.map(error);
        const defaultCodes = {
            uiCodes: {
                tablet: defaultErrorCode,
                pc: defaultErrorCode
            },
            stack: error.stack
        };
        return new Error(uiCodes ? JSON.stringify(uiCodes) : JSON.stringify(defaultCodes));
    };

    _throwSelfTestError = (error) => {
        if (typeof error === "string") {
            error = new Error(error);
        }
        let errorMessage = error.message;
        if (this._isValidJsonString(error.message)) {
            errorMessage = JSON.parse(error.message);
        }
        if (errorMessage && errorMessage.status && errorMessage.status === "Failed") {
            const defaultCodes = {
                uiCodes: {
                    tablet: "00052",
                    pc: "00052"
                },
                codes: errorMessage.codes,
                stack: error.stack
            };
            throw new Error(JSON.stringify(defaultCodes));
        } else {
            throw this._throwError(error, "00215");
        }
    };

    _isValidJsonString = (string) => {
        try {
            JSON.parse(string);
            return true;
        } catch (error) {
            return false;
        }
    };

    _disconnectBecauseOfException = async () => {
        try {
            await this.deviceConnector.setDisconnection();
        } catch (ignored) {
            // This is ignored because when an exception is thrown while executing a command we call _disconnectBecauseOfException,
            // which will try to disconnect with the device, at this point we are not sure if disconnection will succed or not.
            // If the disconnection is not succeded then we should continue with the previous error and should not worry on why
            // the device was failed to disconnection. This can be because of the previous error only.
        }
    };

    _raceWithCancellation = async (sleepTimeoutPromise, commandPromise) => {
        const response = await Promise.race([sleepTimeoutPromise, commandPromise]);
        // When the response is sleep_timeout resolve the current task as the error will be thrown for sleep_timeout
        // but the current task is still ongoing.
        if (response === "sleep_timeout") {
            this._sendLogs("_raceWithCancellation - Operation Timeout");
            this.deviceConnector.resolveTask();
        } else {
            this.utils.resolveSleepTimeout();
        }
        return response;
    };

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    getSOZOBluetoothDevices = async () => {
        try {
            this.deviceConnector.setDefaultAllValues(this.device);
            this._sendLogs("getSOZOBluetoothDevices - Getting SOZO Bluetooth Devices");
            const response = await this._raceWithCancellation(this.utils.setSleep(this.getSOZOBluetoothDevicesTimeout), this.deviceConnector.getBluetoothDevices());
            this._sendLogs("getSOZOBluetoothDevices - Received Response: ", JSON.stringify(response));
            return this._getResponse(response);
        } catch (error) {
            this._sendLogs("getSOZOBluetoothDevices - Error while getting SOZO Bluetooth Devices: ", JSON.stringify(error));
            throw this._throwError(error, "00215");
        }
    };

    setSOZOBluetoothDevice = async (bluetoothDevice) => {
        try {
            this._sendLogs("setSOZOBluetoothDevice - Setting SOZO Bluetooth Device");
            const response = await this._raceWithCancellation(this.utils.setSleep(this.setSOZOBluetoothDeviceTimeout), this._setSOZOBluetoothDevice(bluetoothDevice));
            this._sendLogs("setSOZOBluetoothDevice - Received setSOZOBluetoothDevice response: ", JSON.stringify(response));
            return this._getResponse(response);
        } catch (error) {
            this._sendLogs("setSOZOBluetoothDevice - Error while setting SOZO Bluetooth Device: ", JSON.stringify(error));
            await this._disconnectBecauseOfException();
            if (error.name && error.name.toLowerCase() === "notfounderror" && error.message && error.message.toLowerCase().includes("cancelled the requestdevice()")) {
                const noSelectedDeviceError = new Error(10031);
                throw this._throwError(noSelectedDeviceError, "00425");
            } else {
                throw this._throwError(error, "00215");
            }
        }
    };

    setPairing = async (bluetoothDevice) => {
        try {
            this._sendLogs("setPairing - Pairing SOZO Device");
            const response = await this._raceWithCancellation(this.utils.setSleep(this.pairingTimeout), this._setPairing(bluetoothDevice));
            this._sendLogs("setPairing - Received setPairing device", JSON.stringify(response));
            return this._getResponse(response);
        } catch (error) {
            this._sendLogs("setPairing - Error while pairing SOZO Bluetooth Device: ", JSON.stringify(error));
            throw this._throwError(error, "00215");
        }
    };

    getDeviceStatus = async (shouldCloseConnection) => {
        try {
            this._sendLogs("getDeviceStatus - Getting SOZO Device Status");
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._getDeviceStatus());
            this._sendLogs("getDeviceStatus - Response received: ", JSON.stringify(response));
            this._sendLogs("getDeviceStatus - Disconnecting device..");
            await this._disconnectDevice(shouldCloseConnection);
            this._sendLogs("getDeviceStatus - Sending back Device status response Response");
            return this._getResponse(response);
        } catch (error) {
            this._sendLogs("getDeviceStatus - Error reveived: " , JSON.stringify(error));
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    getMeasurement = async (driveChannel, senseChannel, shouldCloseConnection) => {
        try {
            this._sendLogs("getMeasurement - Starting measurement");
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.calibrationHistoryAndMeasurementCommandTimeout), this._getMeasurement(driveChannel, senseChannel));
            this._sendLogs("getMeasurement - Disconnecting device..");
            await this._disconnectDevice(shouldCloseConnection);
            this._sendLogs("getMeasurement - Received response: ", JSON.stringify(response));
            return this._getResponse(response);
        } catch (error) {
            this._sendLogs("getMeasurement - Error reveived: " , JSON.stringify(error));
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    setFirmwareUpdate = async (binaryFileData, setfirmwareUpdateProgress, shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.firmwareCommandTimeout), this._setFirmwareUpdate(binaryFileData, setfirmwareUpdateProgress));
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    setSettings = async (sozoSettings, shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._setSettings(sozoSettings));
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    getSettings = async (settingTypes, shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._getSettings(settingTypes));
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    getSelfTest = async (shouldCloseConnection) => {
        try {
            this._sendLogs("getSelfTest - Performing self Test");
            const timeout = this.deviceConnector.isSOZOProDevice ? this.selfTestAndScaleWeightCommandTimeout : this.basicCommandTimeout;
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(timeout), this._getSelfTest());
            if (response.status === "Failed") {
                this._sendLogs("getSelfTest - Throwing self test error", JSON.stringify(response));
                throw new Error(JSON.stringify(response));
            } else {
                this._sendLogs("getSelfTest - Response received", JSON.stringify(response));
                await this._disconnectDevice(shouldCloseConnection);
                return this._getResponse(response);
            }
        } catch (error) {
            this._sendLogs("getSelfTest - Throwing self test error catch block", JSON.stringify(error));
            await this._disconnectBecauseOfException();
            this._throwSelfTestError(error);
        }
    };

    getFrequency = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._getFrequency());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    setFrequency = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._setFrequency());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    resetFrequency = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._resetFrequency());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    getWeightCalibrationHistory = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.calibrationHistoryAndMeasurementCommandTimeout), this._getWeightCalibrationHistory());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    getScaleWeight = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.selfTestAndScaleWeightCommandTimeout), this._getScaleWeight());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getScaleWeightResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    reacquireScaleWeight = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.selfTestAndScaleWeightCommandTimeout), this._reacquireScaleWeight());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getScaleWeightResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    zeroScale = async (shouldCloseConnection) => {
        try {
            await this.deviceConnector.setBluetoothDevice();
            const response = await this._raceWithCancellation(this.utils.setSleep(this.basicCommandTimeout), this._zeroScale());
            await this._disconnectDevice(shouldCloseConnection);
            return this._getResponse(response);
        } catch (error) {
            await this._disconnectBecauseOfException();
            throw this._throwError(error, "00215");
        }
    };

    openBluetoothSettings = async () => {
        return await this.deviceConnector.openBluetoothSettings();
    };

    setApplicationUpdate = async (applicationBinaryFileData, setApplicationUpdateProgress) => {
        try {
            const response = await this._raceWithCancellation(this.utils.setSleep(this.applicationUpdateCommandTimeout),
                this._setApplicationUpdate(applicationBinaryFileData, setApplicationUpdateProgress));
            return this._getResponse(response);
        } catch (error) {
            throw this._throwError(error, "00263");
        }
    };

    getApplicationBuildConfiguration = async () => {
        return await this.deviceConnector.getApplicationBuildConfiguration();
    };

}
export default DeviceApi;
