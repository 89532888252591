const ToastSuccessView = () => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"toastSuccess"}>
		<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
			<g id="Group_10951" data-name="Group 10951" transform="translate(-1573 -75)">
				<circle id="Ellipse_1194" data-name="Ellipse 1194" cx="14" cy="14" r="14" transform="translate(1573 75)" fill="#00b8b1"/>
				<path id="Icon" d="M11.561.44a1.5,1.5,0,0,0-2.124,0L4.5,5.379,2.56,3.441A1.5,1.5,0,0,0,.439,5.562l3,3a1.5,1.5,0,0,0,2.121,0l6-6A1.5,1.5,0,0,0,11.561.44Z" transform="translate(1581 85.5)" fill="#fff"/>
			</g>
		</svg>
	</div>;

};

ToastSuccessView.propTypes = {};

export default ToastSuccessView;