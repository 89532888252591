import PropTypes from "prop-types";
import PrivacyIcon from "../../Components/SVGs/PrivacyIcon/PrivacyIcon";

const MfaSetupView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={"flexColStartCenter loginBox mfaSetup"}>
            <div className={"flexColCenterStart mfaSetup__header"}>
                <PrivacyIcon/>
                <h2 className={"mfaSetup__title"}>{dictionary[localStorage.language].multiFactorAuthentication}</h2>
            </div>
            <div className={"flexColSpaceBetwnStart mfaSetup__paragraph"}>
                <p className={"mfaSetup__mfaStatus mfaSetup__mfaStatus--short"}>{dictionary[localStorage.language].mfaStatusOFF}</p>
                <p className={"mfaSetup__mfaStatus mfaSetup__mfaStatus--long"}>{dictionary[localStorage.language].mfaWarningForce}</p>
            </div>

            <button className={"button-blue mfaSetup__btnSubmit"}
                    onClick={props.handleTurnOnClick}>{dictionary[localStorage.language].mfaTurnON}</button>
            <button className={"button-white mfaSetup__btnCancel"}
                    onClick={props.handleCancelClick}>{dictionary[localStorage.language].cancel}</button>
        </div>
    );

};

MfaSetupView.propTypes = {
    handleTurnOnClick: PropTypes.func,
    handleCancelClick: PropTypes.func
};

export default MfaSetupView;