import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {checkProviderName} from "../../../API/authApi";
import {useFlip} from "../../Hooks/useFlip";
import Utilities from "../../Libraries/Custom/Utilities";
import SetProviderNameView from "./SetProviderNameView";
import {NODE_URL} from "../../../API/config";
import cognitoData from "../../../../cognitoData.json";

const SetProviderName = (props) => {

    //region VARIABLES
    const PAGE_PATH = "/SetProviderName";
    const [providerName, setProviderName] = useState("");
    const [flipClasses, setFlipClasses, handleAnimationEnd] = useFlip(PAGE_PATH);
    //endregion

    //region FUNCTIONS
    const handleInputChange = (event) => {
        setProviderName(event.currentTarget.value);
    };

    const resolveSubmit = (response, providerName) => {
        localStorage.setItem("providerName", providerName);
        localStorage.setItem("applicationVersionName", props.applicationBuild.versionName);

        const identityProvider = "identity_provider=" + providerName;
        const redirectUri = "redirect_uri=" + NODE_URL + "IntermediateLogin";
        const path = "https://" + cognitoData.customDomain + "/oauth2/authorize?"
            + identityProvider + "&" + redirectUri + "&response_type=code&client_id=" + cognitoData.clientId + "&scope=email+openid";

        location.href =  path;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!props.loading) {
            const providerNameClean = providerName.trim().toLowerCase();

            if (Utilities.isDomainNameInValid(providerNameClean)) {
                Utilities.customSweetAlertSimple(
                    "",
                    dictionary[localStorage.language].invalidDomainName,
                    "error",
                    false,
                    "",
                    dictionary[localStorage.language].ok
                );
            } else {
                let response = await Utilities.makeSingleCallAsync(checkProviderName, "checkProviderName", [providerNameClean]);
                if (response.status === constants.responseStatus.success) {
                    resolveSubmit(response.data, providerNameClean);
                } else {
                    Utilities.handleFailResponse(response, [providerNameClean], "checkProviderName");
                }
            }
        }
    };

    const handleCancelClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setFlipClasses(constants.routes.root);
    };
    //endregion

    useEffect(() => {
        const savedProviderName = localStorage.getItem("providerName");

        if (savedProviderName) {
            setProviderName(savedProviderName);
        }
    }, []);

    return <SetProviderNameView
        providerName={providerName}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        handleCancelClick={handleCancelClick}
        flipClasses={flipClasses}
        handleAnimationEnd={handleAnimationEnd}
    />;
};

SetProviderName.propTypes = {
    loading: PropTypes.bool,
    currentOS: PropTypes.object,
    applicationBuild: PropTypes.object
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    currentOS: state.currentOS,
    applicationBuild: state.applicationBuild
});

export default connect(mapStateToProps, null)(SetProviderName);