import PropTypes from "prop-types";
import Input from "../../Components/Input/Input";
import Languages from "../../Components/Languages/Languages";
import ArrowBig from "../../Components/SVGs/ArrowBig/ArrowBig";
import UpdatePopupChain from "../../Components/UpdatePopupChain/UpdatePopupChain";

export const LoginView = (props) => {
	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={`loginBox login ${props.flipClasses}`} onAnimationEnd={props.handleAnimationEnd}>
		<h2 className="sectionTitle login__titleBox">{dictionary[localStorage.language].signIn}</h2>
		<form onSubmit={(event) => props.handleLoginClick(event)}>
			<Input
				id={"inputEmail"}
				inputClasses={""}
				withLabel={true}
				labelInBox={true}
				labelText={dictionary[localStorage.language].email}
				value={props.email}
				placeholder={dictionary[localStorage.language].enterEmail}
				onChange={props.handleInputChange}
			/>

			<Input
				id={"inputPassword"}
				type={"password"}
				inputClasses={""}
				withLabel={true}
				labelInBox={true}
				labelText={dictionary[localStorage.language].password}
				value={props.password}
				placeholder={dictionary[localStorage.language].enterPassword}
				onChange={props.handleInputChange}
			/>

			<span className="link login__forgotPassword" onClick={(event) => props.handleForgotPasswordClick(event)}>
                            {dictionary[localStorage.language].forgotPassword}
                        </span>

			<Languages
				handleLanguageChange={props.handleLanguageChange}
				withLabel={true}
				labelText={dictionary[localStorage.language].language}
				labelInBox={true}
				showSelected={true}/>

			<input className={"button-blue login__btnLogin"}
				   value={dictionary[localStorage.language].signIn} type="submit"/>

			<button className={"button-white login__btnSsoLogin"} onClick={props.handleActiveDirectoryClick}>
				{dictionary[localStorage.language].secureSignInSSO}
				<ArrowBig/>
			</button>
		</form>
		<UpdatePopupChain chainState={constants.popupChainStates.applicationPreUpdate}
						  showPopup={props.showUpdatePopup}
						  appVersion={props.appVersion}
						  handleClose={() => props.handleCloseUpdatePopup(false)}/>
	</div>;
};

LoginView.propTypes = {
	email: PropTypes.string,
	password: PropTypes.string,
	parentProps: PropTypes.object,
	language: PropTypes.string,
	flipClasses: PropTypes.string,
	appVersion: PropTypes.string,
	showUpdatePopup: PropTypes.bool,
	handleLoginClick: PropTypes.func,
	handleInputChange: PropTypes.func,
	handleLanguageChange: PropTypes.func,
	handleForgotPasswordClick: PropTypes.func,
	handleActiveDirectoryClick: PropTypes.func,
	handleCloseUpdatePopup: PropTypes.func,
	handleAnimationEnd: PropTypes.func,
};

LoginView.defaultProps = {};

