const IpdIconView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={"ipdIcon"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="185.917" height="33.444" viewBox="0 0 185.917 33.444">
                <g transform="translate(-48.75 -37.5)">
                    <path className="ipdIcon__a"
                          d="M466.088,39.337c0-2.241-.073-4.15-.148-5.73h4.7l.256,2.424h.111a6.231,6.231,0,0,1,5.436-2.829,5.091,5.091,0,0,1,4.921,3.047h.073a8.039,8.039,0,0,1,2.314-2.168,6.479,6.479,0,0,1,3.416-.882c3.452,0,6.06,2.424,6.06,7.785V51.562h-5.436V41.8c0-2.608-.844-4.113-2.645-4.113a2.73,2.73,0,0,0-2.57,1.947,4.637,4.637,0,0,0-.221,1.432v10.5h-5.436V41.5c0-2.276-.809-3.819-2.57-3.819a2.841,2.841,0,0,0-2.608,2.02,3.7,3.7,0,0,0-.221,1.4V51.569h-5.436V39.337Z"
                          transform="translate(-307.629 12.107)"/>
                    <path className="ipdIcon__b"
                          d="M5.911,4.489A2.8,2.8,0,0,1,2.9,7.28,2.729,2.729,0,0,1,0,4.489,2.731,2.731,0,0,1,2.936,1.66,2.8,2.8,0,0,1,5.911,4.489ZM.145,27.443V9.483H5.727v17.96Z"
                          transform="translate(48.754 36.231)"/>
                    <path className="ipdIcon__b"
                          d="M39.046,39.337c0-2.241-.073-4.15-.146-5.73h4.7l.256,2.424h.111A6.231,6.231,0,0,1,49.4,33.2a5.091,5.091,0,0,1,4.921,3.047H54.4a8.039,8.039,0,0,1,2.314-2.168,6.479,6.479,0,0,1,3.417-.882c3.452,0,6.059,2.424,6.059,7.785V51.562H60.751V41.8c0-2.608-.844-4.113-2.645-4.113a2.73,2.73,0,0,0-2.57,1.947,4.639,4.639,0,0,0-.221,1.432v10.5H49.881V41.5c0-2.276-.809-3.819-2.57-3.819A2.841,2.841,0,0,0,44.7,39.7a3.705,3.705,0,0,0-.221,1.4V51.569H39.046Z"
                          transform="translate(18.996 12.107)"/>
                    <path className="ipdIcon__b"
                          d="M169.468,39.639c0-2.351-.073-4.371-.148-6.024h4.849l.256,2.5h.073a6.893,6.893,0,0,1,5.987-2.9c3.929,0,7.456,3.417,7.456,9.144,0,6.537-4.15,9.622-8.155,9.622a5.579,5.579,0,0,1-4.665-2.057h-.073v8.926h-5.582V39.639Zm5.582,4.223a5.614,5.614,0,0,0,.111,1.176,3.381,3.381,0,0,0,3.268,2.645c2.424,0,3.856-2.02,3.856-5.067,0-2.864-1.286-5.032-3.783-5.032a3.505,3.505,0,0,0-3.341,2.829,4.35,4.35,0,0,0-.111.992Z"
                          transform="translate(-80.757 12.099)"/>
                    <path className="ipdIcon__b"
                          d="M260.866,44.392c.183,2.314,2.462,3.417,5.07,3.417a15.837,15.837,0,0,0,4.959-.734l.734,3.783a17.75,17.75,0,0,1-6.5,1.1c-6.1,0-9.587-3.525-9.587-9.144,0-4.555,2.829-9.587,9.072-9.587,5.8,0,8.006,4.517,8.006,8.961a13.33,13.33,0,0,1-.183,2.2H260.866Zm6.574-3.821c0-1.359-.588-3.635-3.158-3.635-2.351,0-3.306,2.13-3.452,3.635Z"
                          transform="translate(-146.703 12.084)"/>
                    <path className="ipdIcon__b"
                          d="M352.539.41V21.126c0,2.02.073,4.15.146,5.361h-4.959l-.256-2.645H347.4a6.269,6.269,0,0,1-5.62,3.047c-4.261,0-7.677-3.635-7.677-9.22-.038-6.059,3.746-9.549,8.044-9.549,2.2,0,3.929.771,4.738,2.02h.073V.41Zm-5.582,15.719a9.369,9.369,0,0,0-.073-.992,3.354,3.354,0,0,0-3.268-2.753c-2.535,0-3.856,2.276-3.856,5.1,0,3.047,1.5,4.959,3.819,4.959a3.268,3.268,0,0,0,3.231-2.681,4.746,4.746,0,0,0,.148-1.286Z"
                          transform="translate(-206.79 37.187)"/>
                    <path className="ipdIcon__b"
                          d="M432.961,4.489a2.8,2.8,0,0,1-3.012,2.791,2.729,2.729,0,0,1-2.9-2.791,2.729,2.729,0,0,1,2.939-2.829A2.8,2.8,0,0,1,432.961,4.489Zm-5.768,22.954V9.483h5.582v17.96Z"
                          transform="translate(-277.883 36.231)"/>
                    <path className="ipdIcon__b"
                          d="M597.946,44.392c.183,2.314,2.462,3.417,5.069,3.417a15.839,15.839,0,0,0,4.959-.734l.734,3.783a17.75,17.75,0,0,1-6.5,1.1c-6.1,0-9.587-3.525-9.587-9.144,0-4.555,2.829-9.587,9.072-9.587,5.8,0,8.006,4.517,8.006,8.961a13.338,13.338,0,0,1-.183,2.2H597.946Zm6.574-3.821c0-1.359-.588-3.635-3.158-3.635-2.351,0-3.306,2.13-3.452,3.635Z"
                          transform="translate(-404.523 12.084)"/>
                    <path className="ipdIcon__b"
                          d="M691.876.41V21.126c0,2.02.073,4.15.148,5.361h-4.959l-.256-2.645h-.073a6.27,6.27,0,0,1-5.62,3.047c-4.261,0-7.677-3.635-7.677-9.22-.038-6.059,3.746-9.549,8.044-9.549,2.2,0,3.929.771,4.738,2.02h.073V.41Zm-5.582,15.719a9.369,9.369,0,0,0-.073-.992,3.354,3.354,0,0,0-3.268-2.753c-2.535,0-3.856,2.276-3.856,5.1,0,3.047,1.505,4.959,3.819,4.959a3.268,3.268,0,0,0,3.231-2.681,4.751,4.751,0,0,0,.148-1.286Z"
                          transform="translate(-466.337 37.187)"/>
                    <g transform="translate(227.538 37.5)">
                        <path className="ipdIcon__b"
                              d="M767.479,3.454a3.566,3.566,0,0,1-7.129,0,3.566,3.566,0,0,1,7.129,0Zm-6.066.019a2.513,2.513,0,1,0,5.022-.021,2.513,2.513,0,1,0-5.022.021ZM763.482,5.1H762.5V1.907a8.765,8.765,0,0,1,1.406-.1,2.4,2.4,0,0,1,1.286.242.831.831,0,0,1,.341.7c0,.322-.3.6-.743.724v.04a.97.97,0,0,1,.663.823,3.335,3.335,0,0,0,.221.762h-1.105a1.985,1.985,0,0,1-.221-.682.5.5,0,0,0-.541-.482h-.32V5.1Zm.04-1.827h.282c.362,0,.6-.141.6-.381,0-.261-.221-.4-.564-.4-.12,0-.24.021-.32.021Z"
                              transform="translate(-760.35)"/>
                    </g>
                </g>
            </svg>
        </div>
    );

};

IpdIconView.propTypes = {};

export default IpdIconView;