import PropTypes from "prop-types";
import Input from "../../Components/Input/Input";
import PasswordPolicy from "../../Components/PasswordPolicy/PasswordPolicy";

const NewPasswordView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"loginBox newPassword"}>
		<h2 className={"sectionTitle newPassword__title"}>{dictionary[localStorage.language].setNewPass}</h2>
		<label className={"newPassword__emailLabel"}>{dictionary[localStorage.language].email}</label>
		<p className={"overflowEllipsis newPassword__email"} title={props.email}>{props.email}</p>
		<form className={"newPassword__form"} onSubmit={(event) => props.handleSubmit(event)}>
			{props.newUser && <Input
				id={"inputTempPassword"}
				labelInBox={true}
				withLabel={true}
				labelText={dictionary[localStorage.language].temporaryPassword}
				placeholder={dictionary[localStorage.language].enterTemporaryPassword}
				onChange={(event) => props.handleInput(event)}
				value={props.values.inputTempPassword}
			/>}
			<Input
				id={"inputNewPassword"}
				type={"password"}
				labelInBox={true}
				withLabel={true}
				labelText={dictionary[localStorage.language].newPassword}
				placeholder={dictionary[localStorage.language].enterNewPassword}
				onChange={(event) => props.handleInput(event)}
				value={props.values.inputNewPassword}
			/>
			<Input
				id={"inputConfirmNewPassword"}
				type={"password"}
				labelInBox={true}
				withLabel={true}
				labelText={dictionary[localStorage.language].cPassword}
				placeholder={dictionary[localStorage.language].enterNewPassword}
				onChange={(event) => props.handleInput(event)}
				value={props.values.inputConfirmNewPassword}
			/>
			<input type={"submit"} className={`button-blue newPassword__submit ${!props.newUser && "newPassword__submit--extraSpace"}`} value={dictionary[localStorage.language].submit}/>
			<button className={"button-white newPassword__cancel"} onClick={(event) => props.handleCancel(event)}>{dictionary[localStorage.language].cancel}</button>
		</form>
		<PasswordPolicy/>
	</div>;

};

NewPasswordView.propTypes = {
	email: PropTypes.string,
	newUser: PropTypes.bool,
	handleSubmit: PropTypes.func,
	handleInput: PropTypes.func,
	handleCancel: PropTypes.func,
	values: PropTypes.object,
};

export default NewPasswordView;