import PropTypes from "prop-types";
import Input from "../../Components/Input/Input";

const SetProviderNameView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return (
		<div className={`loginBox setProviderName ${props.flipClasses}`} onAnimationEnd={props.handleAnimationEnd}>
			<h2 className="sectionTitle setProviderName__titleBox">{dictionary[localStorage.language].ssoSignIn}</h2>
			<form onSubmit={props.handleSubmit}>
				<p className="setProviderName__descriptionBox">{dictionary[localStorage.language].ssoSignInDescription}</p>
				<Input
					withLabel={true}
					labelInBox={true}
					labelText={dictionary[localStorage.language].domainName}
					value={props.providerName}
					placeholder={dictionary[localStorage.language].enterDomainName}
					onChange={props.handleInputChange}
				/>
				<div className="setProviderName__forgotBox">
					<p className="setProviderName__forgot">{dictionary[localStorage.language].forgotDomainName}</p>
				</div>
				<input
					className="button-blue setProviderName__btnLogin"
					value={dictionary[localStorage.language].signIn}
					type="submit"
				/>
				<button className="button-white setProviderName__btnCancel" onClick={props.handleCancelClick}>
					{dictionary[localStorage.language].cancel}
				</button>
			</form>
		</div>
	);
};

SetProviderNameView.propTypes = {
	providerName: PropTypes.string,
	handleInputChange: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleCancelClick: PropTypes.func,
	flipClasses: PropTypes.string,
	handleAnimationEnd: PropTypes.func,
};

export default SetProviderNameView;