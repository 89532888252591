import InputView from "./InputView";
import {usePasswordVisibility} from "../../Hooks/usePasswordVisibility";

const Input = (props) => {

	//region VARIABLES
	const [passwordType, togglePassword]=usePasswordVisibility();
	//endregion

	//region FUNCTIONS

	//endregion

	return <InputView {...props}
					  passwordType={passwordType}
					  togglePassword={togglePassword}></InputView>;

};

Input.propTypes = {};

export default Input;