import * as actions from "../../actions/actionTypes";
import initialState from "../initialState.js";

export default (state = initialState.isSharedPatient, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_IS_SHARED_PATIENT:
            nextState = action.payload;
            break;
        default:
            nextState = state;
    }
    return nextState;
};