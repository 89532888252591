import PropTypes from "prop-types";
import HelpIcon from "../../Components/SVGs/HelpIcon/HelpIcon";
import InfoIcon from "../../Components/SVGs/InfoIcon/InfoIcon";
import SettingsIcon from "../../Components/SVGs/SettingsIcon/SettingsIcon";
import ImpedimedHeader from "../../Components/SVGs/ImpedimedHeader/ImpedimedHeader";
import UpdatePopupChain from "../../Components/UpdatePopupChain/UpdatePopupChain";

const IntermediateLoginView = (props) => {

    //region VARIABLES
    const arrowIcon = !props.isCollapsed ? "left" : "right";
    const expandedSidebar = !props.isCollapsed ? "expanded" : "collapsed";
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className="intermediateLogin">
        <header className="header">
            <div className={"header__container"}>
                <div className={"header__ipd"}>
                    <ImpedimedHeader/>
                </div>
                <div className={"header__central"}>
                </div>
                <div className={"header__icons"}>
                    <div className={"header__icon-container"} title={dictionary[localStorage.language].help}>
                        <HelpIcon/>
                        <span className={"header__icons-title"}/>
                    </div>
                    <div className={"header__icon-container"} title={dictionary[localStorage.language].info}>
                        <InfoIcon/>
                        <span className={"header__icons-title"}/>
                    </div>
                    <div className={"header__icon-container"} title={dictionary[localStorage.language].settings}>
                        <SettingsIcon/>
                        <span className={"header__icons-title"}/>
                    </div>
                </div>
            </div>
        </header>
        <div className={"sidebar " + expandedSidebar}>
            {props.isCollapsed ? <span className={"icon-expand-arrow-" + arrowIcon + " sidebar__expand"}/> : ""}
        </div>
        <UpdatePopupChain chainState={constants.popupChainStates.applicationPreUpdate}
                          showPopup={props.showUpdatePopup}
                          devicePort={props.devicePort}
                          appVersion={props.appVersion}
                          isSSOLogin={true}
                          currentOS={props.currentOS}
                          handleClose={() => props.handleCloseUpdatePopup(false)}/>
    </div>;
};

IntermediateLoginView.propTypes = {
    isCollapsed: PropTypes.bool,
    appVersion: PropTypes.string,
    updateAppUrl: PropTypes.string,
    showUpdatePopup: PropTypes.bool,
    devicePort: PropTypes.object,
    currentOS: PropTypes.object,
    handleCloseUpdatePopup: PropTypes.func
};

export default IntermediateLoginView;