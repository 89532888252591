import InfoIconView from "./InfoIconView";

const InfoIcon = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <InfoIconView/>;

};

InfoIcon.propTypes = {};

export default InfoIcon;