import Utilities from "../Utilities";

export const getAxisOffsetMax = (value, axis) => {
	return value > axis ? value - axis : 0;
};

export const getAxisOffsetMin = (value, axis) => {
	return value < axis ? axis - value : 0;
};

export const getHFDexMaxMin = (hfDexArray) => {
	let max, min;
	max = Math.max(...hfDexArray) + 2;
	min = Math.min(...hfDexArray) - 2;
	max = max < 60 ? 60 : max;
	min = min > 38 ? 38 : min;
	return [max, min];
};

export const getLDexMaxMin = (baseline, lDexArray) => {
	let max, min;
	if (baseline !== null && baseline !== undefined) {
		max = Math.max(parseFloat(baseline) + 10, Math.max(...lDexArray)) + 5;
		min = Math.min(parseFloat(baseline) - 10, Math.min(...lDexArray)) - 5;
	} else {
		max = Math.max(...lDexArray) + 5;
		min = Math.min(...lDexArray) - 5;
		max = max < 20 ? 20 : max;
		min = min > -12 ? -12 : min;
	}
	return [max, min];
};

export const getHistoryBasicSettings = (type, measurementType, unit, range, containerID, yAxisTitleOffset, baselineValue) => {
	let configObject = {
		measurementType: measurementType,
		unit: unit,
		range: range,
		tooltipText: measurementType,
		containerID: `${containerID[0]}${measurementType}${containerID[1]}`,
		yAxisTitleOffset: yAxisTitleOffset,
		baselineValue: baselineValue,
		canHaveBaseline: baselineValue !== undefined
	};
	switch (type) {
		case "phaseAngle":
		case "proteinsMinerals":
		case "proteinsMineralsPerc":
		case "bmr":
			configObject.measurementType = measurementType;
			break;
		case "weight":
			configObject.seriesColor = "#C9CBCE";
			configObject.measurementType = measurementType.toUpperCase();
			break;
		case "hyDex":
			configObject.seriesLegend = measurementType.toUpperCase();
			configObject.measurementType = measurementType;
			break;
		case "lDex":
			configObject.measurementType = dictionary[localStorage.language].lDex;
			configObject.tooltipText = dictionary[localStorage.language].lDex;
			break;
		default:
			break;
	}
	return configObject;
};

const getHistoryRefRangesConfig = (type, plotBands, meanValue, minMax, gridLineColor, showStdValues) => {
	let configObject = {
		plotBands: plotBands,
		meanValue: meanValue,
		minValue: minMax[0],
		maxValue: minMax[1],
		gridLineColor: gridLineColor,
		showStdValues: showStdValues
	};
	switch (type) {
		case "fmPerc":
		case "bmi":
			configObject.setlegendExtraValuesEnd = false;
			configObject.legendExtraValues = Utilities.getLegendExtraValues(type.replace("Perc",""));
			break;
		case "ffmPerc":
			configObject = {
				minValue: minMax[0],
				maxValue: minMax[1],
			};
			break;
		default:
			break;
	}
	return configObject;
};

// eslint-disable-next-line complexity, max-params
export const getGraphSettingsBC = (data, unit, type, selectedPatient, baseline, measurementAge, yAxisTitleOffset, extraValues) => {
	let range = constants.graphRange.dynamic;
	let minMax = Utilities.getMinMax(data.data.map((v) => v.value), 0.1);
	let gridLineColor = "#D9DADB";
	let showStdValues = true;
	let refRanges = false;
	let chartConfig, refRangesConfig;

	switch (type) {
		case "tbwPerc":
			refRanges = Utilities.getRefRangesTBW(selectedPatient.gender, measurementAge, minMax[0], minMax[1]);
			break;
		case "ecfPerc":
			refRanges = Utilities.getRefRangesECF(selectedPatient.gender, measurementAge, minMax[0], minMax[1]);
			break;
		case "icfPerc":
			refRanges = Utilities.getRefRangesICF(selectedPatient.gender, measurementAge, minMax[0], minMax[1]);
			break;
		case "smmPerc":
			refRanges = Utilities.getRefRangesSMM(selectedPatient.gender, measurementAge, minMax[0], minMax[1]);
			break;
		case "hyDex":
			refRanges = Utilities.getRefRangesHyDex(minMax[0], minMax[1]);
			refRanges.meanValue = 0.0;
			break;
		case "fm":
			showStdValues = false;
			break;
		case "fmPerc":
			refRanges = Utilities.getRefRangesFM(selectedPatient.gender, measurementAge, minMax[0], minMax[1]);
			showStdValues = false;
			break;
		case "ffm":
			showStdValues = false;
			break;
		case "ffmPerc":
			refRanges = Utilities.getRefRangesFFM(selectedPatient.gender, measurementAge, minMax[0], minMax[1]);
			showStdValues = false;
			break;
		case "bmi":
			refRanges = Utilities.getRefRangesBMI(minMax[0], minMax[1]);
			showStdValues = false;
			break;
		case "phaseAngle":
			refRanges = Utilities.getRefRangesPhaseAngle(selectedPatient.gender, measurementAge, extraValues, minMax[0], minMax[1]);
			break;
		case "tbw":
		case "ecf":
		case "icf":
		case "smm":
		case "proteinsMinerals":
		case "proteinsMineralsPerc":
		case "atm":
		case "ecm":
		case "bmr":
		case "weight":
			break;
		//noDefault
	}
	chartConfig = getHistoryBasicSettings(type, data.measurementType, unit, range, ["divBC", "HistoryContainer"], yAxisTitleOffset, baseline);
	if (refRanges) {
		refRangesConfig = getHistoryRefRangesConfig(type, refRanges.plotBands, refRanges.meanValue, minMax, gridLineColor, showStdValues);
		chartConfig = {...chartConfig, ...refRangesConfig};
	}
	return chartConfig;
};

export const getGraphSettingsHFDex = (hfDexData, baseline, yAxisTitleOffset) => {
	const range = constants.graphRange.static;
	const unit = "%";
	const maxMin = getHFDexMaxMin(hfDexData.data.map((v) => v.value));
	let basicChartConfig;
	let chartConfig = {
		plotBands: Utilities.getPlotBands(constants.assessmentTypes.hfdex, baseline, maxMin[1], maxMin[0]),
		meanValue: 51.0,
		meanLegend: `51% ${dictionary[localStorage.language].hfDex}`,
		meanColor: "#F77474",
		gridLineColor: "#D9DADB",
	};

	basicChartConfig = getHistoryBasicSettings(null, hfDexData.measurementType, unit, range, ["divHFDex", "HistoryContainer"], yAxisTitleOffset, baseline);
	chartConfig = {...chartConfig, ...basicChartConfig};

	return chartConfig;
};

export const getGraphSettingsLDex = (lDexData, baseline, yAxisTitleOffset) => {
	const range = constants.graphRange.static;
	const unit = "";
	const maxMin = getLDexMaxMin(baseline, lDexData.data.map((v) => v.value));
	let basicChartConfig;
	let chartConfig = {
		plotBands: Utilities.getPlotBands(constants.assessmentTypes.ldex, baseline, maxMin[1], maxMin[0]),
		meanValue: 0,
		meanColor: "#D9DADB",
		showMeanLegend: false,
		gridLineColor: "#D9DADB",
	};

	basicChartConfig = getHistoryBasicSettings("lDex", lDexData.measurementType, unit, range, ["div", "HistoryContainer"], yAxisTitleOffset, baseline);
	chartConfig = {...chartConfig, ...basicChartConfig};

	return chartConfig;
};

export const getContainerConfig = (graphType, assessmentType, absoluteUnit, absoluteData, percentData, percentageOf, riskSideSelection) => {
	let hasUnitSelection = percentageOf !== "";
	let percentageData = percentData !== null ? percentData.data : null;
	let measurementType = absoluteData.containerMeasurementType !== undefined ? absoluteData.containerMeasurementType : absoluteData.measurementType;
	return {
		graphType: graphType,
		absoluteUnit: absoluteUnit,
		assessmentType: assessmentType,
		measurementType: measurementType,
		percentageOf: percentageOf,
		hasUnitSelection: hasUnitSelection,
		absoluteData: absoluteData.data,
		percentageData: percentageData,
		riskSideSelection: riskSideSelection
	};
};

export const getPointFormatForHFDex = (measurementType, colors, fluidsUnit, weightUnit) => {
	let pointFormat;

	switch (measurementType) {
		case dictionary[localStorage.language].ecf:
			pointFormat = `<ul class='highcharts__tooltip-list'>
                    <li><span style="color:${colors.hfDexColor}">\u25CF</span> ${dictionary[localStorage.language].hfDex}(%): <b>{point.hfdex}</b></li>
					<li><span style="color:${colors.tbwColor}">\u25CF</span> ${dictionary[localStorage.language].tbw}(${fluidsUnit}): <b>{point.tbw}</b></li>
					<li><span style="color:${colors.ecfColor}">\u25CF</span> ${dictionary[localStorage.language].ecf}(${fluidsUnit}): <b>{point.y}</b></li>
					<li><span style="color:${colors.icfColor}">\u25CF</span> ${dictionary[localStorage.language].icf}(${fluidsUnit}): <b>{point.icf}</b></li>
					<li><span style="color:${colors.weightColor}">\u25CF</span> ${dictionary[localStorage.language].weight.toUpperCase()}(${weightUnit}): <b>{point.weight}</b></li></ul>`;
			break;
		case dictionary[localStorage.language].icf:
			pointFormat = `<ul class='highcharts__tooltip-list'>
                    <li><span style="color:${colors.hfDexColor}">\u25CF</span> ${dictionary[localStorage.language].hfDex}(%): <b>{point.hfdex}</b></li>
					<li><span style="color:${colors.tbwColor}">\u25CF</span> ${dictionary[localStorage.language].tbw}(${fluidsUnit}): <b>{point.tbw}</b></li>
					<li><span style="color:${colors.ecfColor}">\u25CF</span> ${dictionary[localStorage.language].ecf}(${fluidsUnit}): <b>{point.ecf}</b></li>
					<li><span style="color:${colors.icfColor}">\u25CF</span> ${dictionary[localStorage.language].icf}(${fluidsUnit}): <b>{point.y}</b></li>
					<li><span style="color:${colors.weightColor}">\u25CF</span> ${dictionary[localStorage.language].weight.toUpperCase()}(${weightUnit}): <b>{point.weight}</b></li></ul>`;
			break;
		case dictionary[localStorage.language].tbw:
			pointFormat = `<ul class='highcharts__tooltip-list'>
                    <li><span style="color:${colors.hfDexColor}">\u25CF</span> ${dictionary[localStorage.language].hfDex}(%): <b>{point.hfdex}</b></li>
					<li><span style="color:${colors.tbwColor}">\u25CF</span> ${dictionary[localStorage.language].tbw}(${fluidsUnit}): <b>{point.y}</b></li>
					<li><span style="color:${colors.ecfColor}">\u25CF</span> ${dictionary[localStorage.language].ecf}(${fluidsUnit}): <b>{point.ecf}</b></li>
					<li><span style="color:${colors.icfColor}">\u25CF</span> ${dictionary[localStorage.language].icf}(${fluidsUnit}): <b>{point.icf}</b></li>
					<li><span style="color:${colors.weightColor}">\u25CF</span> ${dictionary[localStorage.language].weight.toUpperCase()}(${weightUnit}): <b>{point.weight}</b></li></ul>`;
			break;
		default:
			break;
	}

	return pointFormat;
};

export const getAxisRangesForESRD = (dataArray, userSettings) => {
	let fluidsAxisMin = Math.min(...dataArray[0].map((obj) => obj.value.y));
	let fluidsAxisMax = Math.max(...dataArray[2].map((obj) => obj.value.y));
	let fluidsRange = (fluidsAxisMax - fluidsAxisMin) * Utilities.getFactorForFluids(userSettings) / 2;
	let weightSum = dataArray[1].map((obj) => obj.value.y).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
	let weightAvg = weightSum / dataArray[1].length;
	let weightAxisMin = weightAvg - fluidsRange;
	let weightAxisMax = weightAvg + fluidsRange;

	let minDiff = 0, maxDiff = 0, finalDiff = 0;
	if (weightAxisMin > Math.min(...dataArray[1].map((obj) => obj.value.y))) {
		minDiff = weightAxisMin - Math.min(...dataArray[1].map((obj) => obj.value.y));
	}
	if (weightAxisMax < Math.max(...dataArray[1].map((obj) => obj.value.y))) {
		maxDiff = Math.max(...dataArray[1].map((obj) => obj.value.y)) - weightAxisMax;
	}
	if (maxDiff > minDiff) {
		finalDiff = maxDiff;
	} else {
		finalDiff = minDiff;
	}
	fluidsAxisMin -= finalDiff + 5;
	fluidsAxisMax += finalDiff + 5;
	weightAxisMin -= finalDiff + 5;
	weightAxisMax += finalDiff + 5;

	return {
		fluidsAxisMin,
		fluidsAxisMax,
		weightAxisMin,
		weightAxisMax,
	};
};

export const getTitleForConfig = (text, offset, xPosition = null) => {
	return {
		text: text,
		margin: 10,
		rotation: 0,
		offset: offset,
		y: -20,
		x: xPosition,
		align: "high",
		useHTML: true,
		style: {
			color: "#2E3040",
			fontSize: "11px",
			fontFamily: "InterSemiBold"
		}
	};
};