import CryptoJS from "crypto-js";

class Utils {
    constructor (keys) {
        if (!Utils._instance) {
            this.aesEncryptionKey = CryptoJS.enc.Hex.parse(keys.aesEncryptionKey);
            this.appIV = CryptoJS.enc.Hex.parse(keys.appIV);
            this.sozoIV = CryptoJS.enc.Hex.parse(keys.sozoIV);
            this.characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            this.resolveSleepTimeoutTask = null;
            Utils._instance = this;
        }
        return Utils._instance;
    }

    // Private Methods ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    _encryptArray = (value) => {
        return CryptoJS.AES.encrypt(value, this.aesEncryptionKey, {
            iv: this.appIV,
            padding: CryptoJS.pad.NoPadding,
            mode: CryptoJS.mode.CBC
        });
    };

    _decryptArray = (encrypted) => {
        return CryptoJS.AES.decrypt({ ciphertext: encrypted }, this.aesEncryptionKey, {
            iv: this.sozoIV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.NoPadding
        });
    };

    _convertWordToByteArray = (word, length) => {
        const byteArray = [];
        const xFF = 0xFF;

        if (length > 0) {
            byteArray.push(word >>> 24);
        }
        if (length > 1) {
            byteArray.push(word >>> 16 & xFF);
        }
        if (length > 2) {
            byteArray.push(word >>> 8 & xFF);
        }
        if (length > 3) {
            byteArray.push(word & xFF);
        }

        return byteArray;
    };

    _convertWordArrayToByteArray = (wordArray, length) => {
        const hasPropSigBytes = Object.prototype.hasOwnProperty.call(wordArray, "sigBytes");
        const hasPropWords = Object.prototype.hasOwnProperty.call(wordArray, "words");

        if (hasPropSigBytes && hasPropWords) {
            length = wordArray.sigBytes;
            wordArray = wordArray.words;
        }

        const result = [];
        let bytes;
        let index = 0;
        while (length > 0) {
            bytes = this._convertWordToByteArray(wordArray[index], Math.min(4, length));
            length -= bytes.length;
            result.push(bytes);
            index++;
        }
        return [].concat.apply([], result);
    };

    _convertByteArrayToWordArray = (byteArray) => {
        const wordArray = [];
        let index;
        for (index = 0; index < byteArray.length; index++) {
            wordArray[index / 4 | 0] |= byteArray[index] << 24 - 8 * index;
        }
        return CryptoJS.lib.WordArray.create(wordArray, byteArray.length);
    };

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    encrypt = (toBeEncryptedText) => {
        const ciphertext = this._encryptArray(toBeEncryptedText);
        return this._convertWordArrayToByteArray(ciphertext.ciphertext.words, ciphertext.ciphertext.sigBytes);
    };

    decrypt = (toBeDecryptedBytes) => {
        const dataWordArray = this._convertByteArrayToWordArray(toBeDecryptedBytes);
        return this._decryptArray(dataWordArray);
    };

    setSleep = (milliseconds) => {
        return new Promise((resolve) => {
            this.resolveSleepTimeoutTask = resolve;
            setTimeout(() => {
                resolve("sleep_timeout");
            }, milliseconds);
        });
    };
    
    resolveSleepTimeout = () => {
        if (this.resolveSleepTimeoutTask) {
            this.resolveSleepTimeoutTask();
            this.resolveSleepTimeoutTask = null;
        }
    };

    getRandomAlphaNumericString = (length) => {
        let result = "";
        let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        let values = new Uint32Array(1);
        for (let i = 0; i <= length; i++) {
            crypto.getRandomValues(values);
            result += characters.charAt(values[0] % charactersLength);
        }
        return result;
    };

    getBTMobileResponse = (parsedData) => {
        if (parsedData.status === "success") {
            return parsedData.data;
        } else {
            throw new Error("There an error in the Device response status by the Mobile software Bluetooth wrapper.");
        }
    };

    subtract = (numberOne, numberTwo) => {
        return this.roundDecimals(numberOne - numberTwo);
    };

    roundDecimals = (number) => {
        const roundedNumber = Math.round(Math.abs(number) * Math.pow(10, 1)) / Math.pow(10, 1);

        // Handle the case where the rounded value is exactly zero
        if (Object.is(roundedNumber, -0)) {
            return 0;
        }
        return roundedNumber;
    };
}
export default Utils;
