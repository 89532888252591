import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.selectedGroups, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_SELECTED_GROUPS :
			nextState.groups = action.payload;
			break;
		case actions.SAVE_SELECTED_GROUP_NAME:
			nextState.groupName = action.payload;
			break;
		case actions.CLEAR_SELECTED_GROUPS:
			nextState={groups: [], groupName: ""};
			break;
		default:
			nextState = state;
	}
	return nextState;
};