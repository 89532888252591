const HelpIconView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className={"helpIcon"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="help_outline" transform="translate(-780 -286)">
                <rect id="Path" width="24" height="24" transform="translate(780 286)" fill="rgba(0,0,0,0)"/>
                <path id="Icon" d="M792,308a10,10,0,1,1,10-10A10.011,10.011,0,0,1,792,308Zm0-18a8,8,0,1,0,8,8A8.009,8.009,0,0,0,792,290Zm1,14h-2v-2h2v2Zm0-3h-2a3.583,3.583,0,0,1,1.77-3.178c.66-.506,1.229-.942,1.229-1.821a2,2,0,1,0-4,0h-2a4,4,0,1,1,8,0,3.413,3.413,0,0,1-1.559,2.645A3.1,3.1,0,0,0,793,301Z" fill="#fff"/>
            </g>
        </svg>
    </div>;

};

HelpIconView.propTypes = {};

export default HelpIconView;