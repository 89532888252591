import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.selectedReceivers, action) => {
	let nextState = { ...state };
	switch (action.type) {
		case actions.SAVE_SELECTED_RECEIVERS:
			nextState = action.payload;
			break;
		case actions.CLEAR_SELECTED_RECEIVERS:
			nextState = [];
			break;
		default:
			nextState = state;
	}
	return nextState;
};