import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.selectedPatient, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_PATIENT_ID :
			nextState.user_id = action.payload;
			break;
		case actions.SAVE_PATIENT_DATA :
			nextState = {...nextState, ...action.payload};
			break;
		case actions.CLEAR_PATIENT_DATA :
			nextState = {
				user_id: "",
				firstName: "",
				lastName: "",
				middleName: "",
				mrn: "",
				gender: "",
				birthDateTime: "",
				lastAssessmentDateTime: "",
				phoneNumber: "",
				countryPhoneCode: "",
				userEmail: "",
				surgeryDate: "",
				lppStartDate: "",
				notes: []
			};
			break;
		case actions.SAVE_PATIENT_NOTES_OPENED :
			nextState.newNotesNotify = "0";
			break;
		default:
			nextState = state;
	}
	return nextState;
};