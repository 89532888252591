export const SAVE_FACILITY="SAVE_FACILITY";
export const SAVE_FACILITY_SSO_STATUS="SAVE_FACILITY_SSO_STATUS";
export const SAVE_FACILITY_LOCATION = "SAVE_FACILITY_LOCATION";
export const SAVE_FACILITY_TIMEZONE = "SAVE_FACILITY_TIMEZONE";
export const SAVE_FACILITY_SETTINGS = "SAVE_FACILITY_SETTINGS";
export const SAVE_FACILITY_LOGO = "SAVE_FACILITY_LOGO";
export const SAVE_FACILITY_LICENSE = "SAVE_FACILITY_LICENSE";
export const SAVE_FACILITY_EHR = "SAVE_FACILITY_EHR";

export const SAVE_FACILITY_ID="SAVE_FACILITY_ID";
export const SAVE_FACILITY_NAME="SAVE_FACILITY_NAME";
export const SAVE_FACILITY_ALIAS="SAVE_FACILITY_ALIAS";
export const SAVE_PATIENT_LIST="SAVE_PATIENT_LIST";
export const SAVE_DELETED_PATIENT_LIST="SAVE_DELETED_PATIENT_LIST";
export const SAVE_ACCEPT_ANY_DEVICE="SAVE_ACCEPT_ANY_DEVICE";
export const SAVE_LICENSED_DEVICES_LIST="SAVE_LICENSED_DEVICES_LIST";
export const SAVE_FACILITY_GPID="SAVE_FACILITY_GPID";
export const SAVE_PRE_UPDATE_AVAILABLE_VERSION="SAVE_PRE_UPDATE_AVAILABLE_VERSION";
export const SAVE_FACILITY_PATIENT_GROUPS_LIST="SAVE_FACILITY_PATIENT_GROUPS_LIST";
export const SAVE_FACILITY_IS_SSO="SAVE_FACILITY_IS_SSO";
export const SAVE_FACILITY_VERSION = "SAVE_FACILITY_VERSION";

export const SAVE_FACILITY_LOCATION_ADDRESS = "SAVE_FACILITY_LOCATION_ADDRESS";
export const SAVE_FACILITY_LOCATION_CITY = "SAVE_FACILITY_LOCATION_CITY";
export const SAVE_FACILITY_LOCATION_STATE_PROVINCE = "SAVE_FACILITY_LOCATION_STATE_PROVINCE";
export const SAVE_FACILITY_LOCATION_COUNTRY = "SAVE_FACILITY_LOCATION_COUNTRY";
export const SAVE_FACILITY_LOCATION_POSTAL_CODE = "SAVE_FACILITY_LOCATION_POSTAL_CODE";
export const SAVE_FACILITY_LOCATION_REGION = "SAVE_FACILITY_LOCATION_REGION";

export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_SETTINGS = "SAVE_USER_SETTINGS";
export const SAVE_USER_SESSION = "SAVE_USER_SESSION";
export const SAVE_USER_TOKEN = "SAVE_USER_TOKEN";
export const SAVE_USER_PRE_UPDATE_HAS_NOTICE = "SAVE_USER_PRE_UPDATE_HAS_NOTICE";
export const SAVE_USER_POST_UPDATE_HAS_NOTICE = "SAVE_USER_POST_UPDATE_HAS_NOTICE";
export const SAVE_CHANGELOG = "SAVE_CHANGELOG";

export const SAVE_SELECTED_GROUPS = "SAVE_SELECTED_GROUPS";
export const SAVE_SELECTED_GROUP_NAME = "SAVE_SELECTED_GROUP_NAME";
export const CLEAR_SELECTED_GROUPS = "CLEAR_SELECTED_GROUPS";

export const SAVE_SEARCH_VALUE = "SAVE_SEARCH_VALUE";
export const SAVE_ACTIVE_PATIENTS_SEARCH_VALUE = "SAVE_ACTIVE_PATIENTS_SEARCH_VALUE";

export const SAVE_PATIENT_ID = "SAVE_PATIENT_ID";
export const SAVE_PATIENT_NOTES_OPENED = "SAVE_PATIENT_NOTES_OPENED";
export const SAVE_PATIENT_DATA = "SAVE_PATIENT_DATA";
export const CLEAR_PATIENT_DATA = "CLEAR_PATIENT_DATA";

export const SAVE_LOADING = "SAVE_LOADING";

export const SAVE_PATH = "SAVE_PATH";

export const SAVE_COLLAPSIBLE_NAME = "SAVE_COLLAPSIBLE_NAME";
export const CLEAR_COLLAPSIBLE_NAME = "CLEAR_COLLAPSIBLE_NAME";

export const SAVE_TOTAL_PATIENT_COUNT = "SAVE_TOTAL_PATIENT_COUNT";
export const SAVE_TOTAL_SHARED_PATIENT_COUNT = "SAVE_TOTAL_SHARED_PATIENT_COUNT";

export const SAVE_MERGE_SOURCE_SEARCH = "SAVE_MERGE_SOURCE_SEARCH";
export const CLEAR_MERGE_SOURCE_SEARCH = "CLEAR_MERGE_SOURCE_SEARCH";
export const SAVE_MERGE_DESTINATION_SEARCH = "SAVE_MERGE_DESTINATION_SEARCH";
export const CLEAR_MERGE_DESTINATION_SEARCH = "CLEAR_MERGE_DESTINATION_SEARCH";
export const SAVE_MERGE_SOURCE_PATIENT = "SAVE_MERGE_SOURCE_PATIENT";
export const SAVE_MERGE_DESTINATION_PATIENT = "SAVE_MERGE_DESTINATION_PATIENT";
export const CLEAR_MERGE_SOURCE_PATIENT = "CLEAR_MERGE_SOURCE_PATIENT";
export const CLEAR_MERGE_DESTINATION_PATIENT = "CLEAR_MERGE_DESTINATION_PATIENT";

export const SAVE_PATIENT_IN_GROUP_SEARCH = "SAVE_PATIENT_IN_GROUP_SEARCH";
export const SAVE_PATIENT_OUT_OF_GROUP_SEARCH = "SAVE_PATIENT_OUT_OF_GROUP_SEARCH";
export const SAVE_SELECTED_GROUP_EDIT_MEMBERS = "SAVE_SELECTED_GROUP_EDIT_MEMBERS";

export const SAVE_MEASUREMENTS_FILTER_SELECTIONS = "SAVE_MEASUREMENTS_FILTER_SELECTIONS";

export const SAVE_FORCE_UPDATE_LIST_STATE = "SAVE_FORCE_UPDATE_LIST_STATE";

export const SAVE_MEASUREMENT_ID = "SAVE_MEASUREMENT_ID";
export const SAVE_MEASUREMENT_DATA = "SAVE_MEASUREMENT_DATA";
export const SAVE_MEASUREMENT_LIST = "SAVE_MEASUREMENT_LIST";
export const SAVE_MEASUREMENT_CURRENT_POSITION = "SAVE_MEASUREMENT_CURRENT_POSITION";
export const CLEAR_MEASUREMENT_LIST = "CLEAR_MEASUREMENT_LIST";
export const CLEAR_MEASUREMENT_DATA = "CLEAR_MEASUREMENT_DATA";
export const CLEAR_MEASUREMENT_ID = "CLEAR_MEASUREMENT_ID";
export const SAVE_FRX = "SAVE_FRX";
export const CLEAR_FRX = "CLEAR_FRX";

export const SAVE_SELECTED_TAB = "SAVE_SELECTED_TAB";

export const SAVE_ANALYTICS = "SAVE_ANALYTICS";
export const SAVE_ANALYTICS_IMPACTED = "SAVE_ANALYTICS_IMPACTED";

export const SAVE_SELECTED_TRENDINGS = "SAVE_SELECTED_TRENDINGS";
export const CLEAR_SELECTED_TRENDINGS = "CLEAR_SELECTED_TRENDINGS";

export const SHOW_LOGOUT_POPUP = "SHOW_LOGOUT_POPUP";
export const SAVE_LOGOUT_POPUP_CODE = "SAVE_LOGOUT_POPUP_CODE";
export const SAVE_LOGOUT_POPUP_PAGE = "SAVE_LOGOUT_POPUP_PAGE";

export const SAVE_SELECTED_DEVICE = "SAVE_SELECTED_DEVICE";
export const SAVE_DEVICE_PORT = "SAVE_DEVICE_PORT";
export const SAVE_SELF_TEST_STATUS = "SAVE_SELF_TEST_STATUS";
export const SAVE_DEVICE_STATUS = "SAVE_DEVICE_STATUS";
export const SAVE_DEVICE_KEYS = "SAVE_DEVICE_KEYS";
export const SAVE_DEVICE_LICENSE_STATUS = "SAVE_DEVICE_LICENSE_STATUS";
export const SAVE_DEVICE_BLUETOOTH_NAME = "SAVE_DEVICE_BLUETOOTH_NAME";
export const CLEAR_SELECTED_DEVICE = "CLEAR_SELECTED_DEVICE";
export const SAVE_PAIRED_DEVICE_LIST = "SAVE_PAIRED_DEVICE_LIST";
export const CLEAR_PAIRED_DEVICE_LIST = "CLEAR_PAIRED_DEVICE_LIST";

export const SAVE_CURRENT_OS = "SAVE_CURRENT_OS";
export const SAVE_SOZO_DEVICE_COMM_ENABLED = "SAVE_SOZO_DEVICE_COMM_ENABLED";

export const SAVE_SHARINGLIST_TYPE = "SAVE_SHARINGLIST_TYPE";
export const SAVE_SHARING_COUNT = "SAVE_SHARING_COUNT";
export const SAVE_IS_SHARED_PATIENT = "SAVE_IS_SHARED_PATIENT";

export const SAVE_SHARING_DATA = "SAVE_SHARING_DATA";
export const CLEAR_SHARING_DATA = "CLEAR_SHARING_DATA";
export const SAVE_SHARING_LICENSE = "SAVE_SHARING_LICENSE";

export const SAVE_SELECTED_RECEIVERS = "SAVE_SELECTED_RECEIVERS";
export const CLEAR_SELECTED_RECEIVERS = "CLEAR_SELECTED_RECEIVERS";

export const SAVE_APPLICATION_BUILD = "SAVE_APPLICATION_BUILD";
