import { Fragment } from "react";
import PropTypes from "prop-types";
import CustomPopup from "../CustomPopup/CustomPopup";
import PasswordProtection from "../PasswordProtection/PasswordProtection";
import WarningIcon from "../SVGs/WarningIcon/WarningIcon";

const UpdatePopupChainView = (props) => {

	//region VARIABLES
	let popupInfo = {};
	//endregion

	//region FUNCTIONS
	const getInfo = (chainState) => {
		let info = {};
		const dic = dictionary[localStorage.language];
		switch (chainState) {
			case constants.popupChainStates.adminUpdateAccept:
				//admin done
				info = {
					title: <><WarningIcon classes={"updatePopupChain__warningIcon"}/>{dic.warning}</>,
					cancelBtnTxt: dic.cancel,
					submitBtnTxt: dic.updateStrings.acceptAndUpdate,
					containerClass: "",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "",
					withCancel: true,
					submitFunc: props.handleUpdateClick,
					cancelFunc: props.handleCancelButton,
					body: <Fragment>
						<p>
							{dictionary[localStorage.language].updateStrings.acceptParagraph.part1}
							<br/>
							<br/>
							{dictionary[localStorage.language].updateStrings.acceptParagraph.part2}
						</p>
						{props.showPasswordPopup && <PasswordProtection
							handlePasswordChange={props.handlePasswordChange}
							authData={props.authData}
							passwordProtectionEnabled={props.passwordProtectionEnabled}
							small={true}
							withMFAcheck={false}
						/>}
					</Fragment>
				};
				break;
			case constants.popupChainStates.clinicianFirstLoginPre:
				//clinician done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.updateStrings.tellMeMore,
					submitBtnTxt: dic.ok,
					containerClass: "",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: true,
					submitFunc: props.handleCancelButton,
					cancelFunc: props.handleTellMeMoreNoButtonsClick,
					body: <p>
						{dictionary[localStorage.language].updateStrings.availableUpdateClinicianText}
					</p>
				};
				break;
			case constants.popupChainStates.adminFirstLoginPre:
				//admin done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.updateStrings.notAtThisTime,
					submitBtnTxt: dic.updateStrings.tellMeMore,
					containerClass: "",
					cancelClass: "",
					submitClass: "",
					withCancel: true,
					submitFunc: props.handleTellMeMoreClick,
					cancelFunc: props.handleCancelButton,
					body: <p>
						{dictionary[localStorage.language].updateStrings.availableUpdateAdminText.availableUpdate}
						{dictionary[localStorage.language].updateStrings.availableUpdateAdminText.wantToUpdate}
					</p>
				};
				break;
			case constants.popupChainStates.adminPreUpdateChanges:
				//admin done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.cancel,
					submitBtnTxt: dic.updateStrings.update,
					containerClass: "updatePopupChain__changelog-container",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: true,
					submitFunc: props.handlePreAcceptUpdateClick,
					cancelFunc: props.handleCancelButton,
					body: <Fragment>
						<h4 className={"updatePopupChain__changelog-title"}>{dictionary[localStorage.language].updateStrings.summaryChanges}</h4>
						<p className={"updatePopupChain__changelog-info"} dangerouslySetInnerHTML={{__html: props.htmlChangeLog.short}}></p>
					</Fragment>
				};
				break;
			case constants.popupChainStates.adminFirstLoginPost:
				//admin done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.updateStrings.tellMeMore,
					submitBtnTxt: dic.ok,
					containerClass: "",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: true,
					submitFunc: props.handleCancelButton,
					cancelFunc: props.handleTellMeMoreNoButtonsClick,
					body: <p>
						{dictionary[localStorage.language].updateStrings.softwareUpdateComplete}
					</p>
				};
				break;
			case constants.popupChainStates.userFirstLoginChanges:
				//admin done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: "",
					submitBtnTxt: dic.ok,
					containerClass: "updatePopupChain__changelog-container",
					cancelClass: "",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: false,
					submitFunc: props.handleCancelButton,
					cancelFunc: () => null,
					body: <Fragment>
						<h4 className={"updatePopupChain__changelog-title"}>{dictionary[localStorage.language].updateStrings.summaryChanges}</h4>
						<p className={"updatePopupChain__changelog-info"} dangerouslySetInnerHTML={{__html: props.htmlChangeLog.short}}></p>
					</Fragment>
				};
				break;
			case constants.popupChainStates.clinicianFirstLoginPost:
				//clinician done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.updateStrings.tellMeMore,
					submitBtnTxt: dic.ok,
					containerClass: "",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: true,
					submitFunc: props.handleCancelButton,
					cancelFunc: props.handleTellMeMoreNoButtonsClick,
					body: <p>
						{dictionary[localStorage.language].updateStrings.updateSuccessfullClinicianText.part1}
						<a id={"ifuLink"} href={constants.IfuLink} rel="noopener noreferrer"
						   target={"_blank"}>{dictionary[localStorage.language].updateStrings.updateSuccessfullClinicianText.link}</a>
						{dictionary[localStorage.language].updateStrings.updateSuccessfullClinicianText.part2}
					</p>
				};
				break;
			case constants.popupChainStates.applicationPreUpdateChanges:
				//admin done
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.cancel,
					submitBtnTxt: props.currentOS.isAndroid ? dic.updateStrings.update : dic.ok,
					containerClass: "updatePopupChain__changelog-container",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: props.currentOS.isAndroid,
					submitFunc: props.currentOS.isAndroid ? () => props.handleUpdateApplicationClick() : () => props.handleCancelAppUpdateClick(),
					cancelFunc: props.handleCancelAppUpdateClick,
					body: <Fragment>
						<h4 className={"updatePopupChain__changelog-title"}>{dic.updateStrings.summaryChanges}</h4>
						<p className={"updatePopupChain__changelog-info"} dangerouslySetInnerHTML={{__html: props.htmlChangeLog.short}}></p>
					</Fragment>
				};
				break;
			case constants.popupChainStates.applicationPreUpdate:
				info = {
					title: dic.updateStrings.softwareUpdateTitle,
					cancelBtnTxt: dic.cancel,
					submitBtnTxt: dic.updateStrings.tellMeMore,
					containerClass: "",
					cancelClass: "updatePopupChain__cancelBtn--small",
					submitClass: "updatePopupChain__submitBtn--small",
					withCancel: true,
					submitFunc: props.handleTellMeMoreAppUpdateClick,
					cancelFunc: props.handleCancelAppUpdateClick,
					body: <p>
						{props.currentOS.isAndroid ? dic.updateStrings.appUpdateAvailableAndroid : dic.updateStrings.appUpdateAvailableiOS}
					</p>
				};
				break;
			default:
				info = {
					title: "", cancelBtnTxt: "", submitBtnTxt: "",
					containerClass: "",
					cancelClass: "",
					submitClass: "",
					withCancel: true,
					submitFunc: () => null,
					cancelFunc: () => null,
					body: null
				};
				break;
		}
		return info;
	};

	//endregion
	popupInfo = getInfo(props.chainState);
	return <CustomPopup
		show={props.show}
		containerClasses={`updatePopupChain ${popupInfo.containerClass}`}
		title={popupInfo.title}
		titleClasses={"flexRowStartCenter updatePopupChain__title"}
		defaultCancelClasses={`updatePopupChain__cancelBtn ${popupInfo.cancelClass}`}
		defaultSubmitClasses={`updatePopupChain__submitBtn ${popupInfo.submitClass}`}
		defaultButtons={true}
		withCancel={popupInfo.withCancel}
		cancelText={popupInfo.cancelBtnTxt}
		submitText={popupInfo.submitBtnTxt}
		handleSubmit={popupInfo.submitFunc}
		handleCancel={popupInfo.cancelFunc}>
		{popupInfo.body}
	</CustomPopup>;
};

UpdatePopupChainView.propTypes = {
	chainState: PropTypes.string,
	show: PropTypes.bool,
	showPasswordPopup: PropTypes.bool,
	passwordProtectionEnabled: PropTypes.bool,
	info: PropTypes.object,
	authData: PropTypes.object,
	htmlChangeLog: PropTypes.object,
	currentOS: PropTypes.object,
	handleCancelButton: PropTypes.func,
	handleUpdateClick: PropTypes.func,
	handleTellMeMoreClick: PropTypes.func,
	handlePreAcceptUpdateClick: PropTypes.func,
	handleTellMeMoreNoButtonsClick: PropTypes.func,
	handlePasswordChange: PropTypes.func,
	handleUpdateApplicationClick: PropTypes.func,
	handleTellMeMoreAppUpdateClick: PropTypes.func,
	handleCancelAppUpdateClick: PropTypes.func,
};

export default UpdatePopupChainView;