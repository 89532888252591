import PropTypes from "prop-types";
import LanguagesView from "./LanguagesView";

const Languages = (props) => {

	//region VARIABLES
	let languages = [
		{text: dictionary[localStorage.language].english, value: "en", selected: localStorage.language === "en"},
		{text: dictionary[localStorage.language].englishUK, value: "en-rGB", selected: localStorage.language === "en-rGB"}
	];
	//endregion

	//region FUNCTIONS

	//endregion

	return <LanguagesView
	languages={languages}
	{...props}
	/>;

};

Languages.propTypes = {
	handleLanguageChange:PropTypes.func
};

export default Languages;