import { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Login from "./Core/Pages/Login/Login";
import SetProviderName from "./Core/Pages/SetProviderName/SetProviderName";
import SetMfaCode from "./Core/Pages/SetMfaCode/SetMfaCode";
import BrowserNotSupportedView from "./Core/Pages/BrowserNotSupported/BrowserNotSupportedView";
import NoMatchView from "./Core/Pages/NoMatch/NoMatchView";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import configureStore from "./Redux/configureStore";
import SendEmail from "./Core/Pages/SendEmail/SendEmail";
import ChangePassword from "./Core/Pages/ChangePassword/ChangePassword";
import ExpiringPassword from "./Core/Pages/ExpiringPassword/ExpiringPassword";
import LoginTitle from "./Core/Components/LoginTitle/LoginTitle";
import LoginIcons from "./Core/Components/LoginIcons/LoginIcons";
import {ApiStore} from "./API/apiStore";
import Helper from "./Core/Components/Helper/Helper";
import NewPassword from "./Core/Pages/NewPassword/NewPassword";
import IntermediateLogin from "./Core/Pages/IntermediateLogin/IntermediateLogin";
import MfaSetup from "./Core/Pages/MfaSetup/MfaSetup";
import MfaAuthentication from "./Core/Components/MfaAuthentication/MfaAuthentication";
import MfaActivation from "./Core/Components/MfaActivation/MfaActivation";
import Portal from "./Core/Components/Portal/Portal";
import SuccessLogoutPopup from "./Core/Components/SuccessLogoutPopup/SuccessLogoutPopup";
import UnsupportedResolutionView from "./Core/Components/UnsupportedResolution/UnsupportedResolutionView";
import {useWindowSize} from "./Core/Hooks/useWindowSize";
import BuildInfo from "./Core/Components/BuildInfo/BuildInfo";

const store = configureStore();

ApiStore.init({language: localStorage.language || "en"});
const isIE = () => {
	/* MSIE used to detect old browsers and Trident used to newer ones*/
	return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
};
let titleAndIconsRoutes = [constants.routes.root, constants.routes.sendEmail, constants.routes.changePassword, constants.routes.setProviderName, constants.routes.newPassword];
const LoginRoutes = () => {

	const [successLogout, setSuccessLogout] = useState(false);
	const [successLogoutResponseCode, setSuccessLogoutResponseCode] = useState(false);
	const windowSize = useWindowSize();

	const showSuccessLogoutPopup = (response) => {
		setSuccessLogout(true);
		setSuccessLogoutResponseCode(response.code);
	};

	// Add an unload listener in order to prevent the bfcache
	useEffect(() => {
		window.addEventListener("unload", () => {/*noop*/});
		return () => window.removeEventListener("unload", () => {/*noop*/});
	}, []);

	return (
		<Provider store={store.store}>
			<PersistGate loading={null} persistor={store.persistor}>
				{
					windowSize.width < constants.minimumSupportedResolution ? <UnsupportedResolutionView extraClasses={"loginPageUnsupportedResolution"} /> :
						<Router basename="/">
							<Helper />
							<Route exact path={titleAndIconsRoutes} render={() => <LoginTitle />} />
							<Switch>
								<Route exact path={constants.routes.root} render={() => isIE() ? <BrowserNotSupportedView /> : <Login />} />
								<Route exact path={constants.routes.sendEmail} render={() => isIE() ? <BrowserNotSupportedView /> : <SendEmail />} />
								<Route exact path={constants.routes.setMfaCode} render={() => isIE() ? <BrowserNotSupportedView /> : <SetMfaCode />} />
								<Route exact path={constants.routes.setProviderName} render={() => isIE() ? <BrowserNotSupportedView /> : <SetProviderName />} />
								<Route exact path={constants.routes.changePassword} render={() => isIE() ? <BrowserNotSupportedView /> : <ChangePassword />} />
								<Route exact path={constants.routes.expiringPassword} render={() => isIE() ? <BrowserNotSupportedView /> : <ExpiringPassword />} />
								<Route exact path={constants.routes.newPassword} render={() => isIE() ? <BrowserNotSupportedView /> : <NewPassword />} />
								<Route exact path={constants.routes.intermediateLogin} render={() => isIE() ? <BrowserNotSupportedView /> : <IntermediateLogin />} />
								<Route exact path={constants.routes.mfaSetup} render={() => isIE() ? <BrowserNotSupportedView /> : <MfaSetup />} />
								<Route exact path={constants.routes.mfaAuthenticate} render={() => isIE() ? <BrowserNotSupportedView /> : <MfaAuthentication isForce={true} targetUser={constants.targetUser.self} />} />
								<Route exact path={constants.routes.mfaActivation} render={() => isIE() ? <BrowserNotSupportedView /> : <MfaActivation isForce={true}
									showSuccessLogoutPopup={showSuccessLogoutPopup}
									successLogout={successLogout}
									successLogoutResponseCode={successLogoutResponseCode} />} />
								<Route render={() => <NoMatchView />} />
							</Switch>
							<Route exact path={titleAndIconsRoutes} render={() => <LoginIcons />} />
							<Route exact path={titleAndIconsRoutes} render={() => <BuildInfo />} />
							<Portal>
								{successLogout && <Fragment>
									<div className={"popupBackground"}></div>
									<SuccessLogoutPopup successLogoutResponseCode={successLogoutResponseCode} />
								</Fragment>}
							</Portal>
						</Router>
				}
			</PersistGate>
		</Provider>
	);
};

LoginRoutes.propTypes = {};
LoginRoutes.defaultProps = {};

ReactDOM.render(<LoginRoutes/>, document.getElementById("loginPage"));