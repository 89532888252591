import ByteBuffer from "bytebuffer";
import { decode, Decoder, encode, Encoder, ExtensionCodec } from "@msgpack/msgpack";
import SetSettingType from "./models/setsettingtype";

class MessageCodec {
    constructor () {
        if (!MessageCodec._instance) {
            this.extensionCodec = null;
            MessageCodec._instance = this;
        }
        return MessageCodec._instance;
    }

    // Private Methods ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    _getExtEncodedArray = (type, array) => {
        this.extensionCodec.register({
            type,
            encode: (array) => {
                if (array instanceof Uint8Array) {
                    return [array[0], array[1]];
                } else {
                    return null;
                }
            }
        });
        const encoder = new Encoder(this.extensionCodec);
        return encoder.encode(array);
    };

    _getExtEncodedObject = (type, object) => {
        this.extensionCodec.register({
            type,
            encode: (object) => {
                if (object instanceof Object && object.type === type) {
                    return encode(object.value);
                } else {
                    return null;
                }
            }
        });

        const encoder = new Encoder(this.extensionCodec);
        return encoder.encode(object);
    };

    _getMessage = (messageType, messageIndex, reply, prePackedData) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array([133]);
        packer.append(result);

        // IncrementElement, { KeyType, KeyValue }
        // ValueType, { ValueType, Value }
        result = this._getExtEncodedObject(0x01, { type: 0x01, value: 0x01 });
        packer.append(result);
        const arrayData = new Uint8Array(new ByteBuffer().order(ByteBuffer.LITTLE_ENDIAN).writeShort(messageType).buffer);
        result = this._getExtEncodedArray(0x02, arrayData);
        packer.append(result);

        result = this._getExtEncodedObject(0x02, { type: 0x01, value: 0x02 });
        packer.append(result);
        result = this._getExtEncodedObject(0x03, { type: 0x03, value: 0x01 });
        packer.append(result);

        result = this._getExtEncodedObject(0x03, { type: 0x01, value: 0x03 });
        packer.append(result);
        result = encode(messageIndex);
        packer.append(result);

        result = this._getExtEncodedObject(0x04, { type: 0x01, value: 0x04 });
        packer.append(result);
        result = encode(reply);
        packer.append(result);

        result = this._getExtEncodedObject(0x05, { type: 0x01, value: 0x05 });
        packer.append(result);
        packer.append(prePackedData);

        return packer;
    };

    _packArrayHeader = (value) => {
        const count = value;
        let prefix = new ByteBuffer();
        if (count <= 0xe) {
            prefix = new ByteBuffer(1);
            prefix.append([0x90 | count]);
        } else if (count <= 0xffff) {
            prefix = new ByteBuffer(3);
            prefix.append([0xdc]);
            prefix.writeShort(count);
        } else {
            prefix = new ByteBuffer(5);
            prefix.append([0xdd]);
            prefix.writeInt(count);
        }
        return prefix.buffer;
    };

    _packMapHeader = (value) => {
        return [0x80 | value];
    };

    _getSOZOProSelfTestBytes = function (timestamp) {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        packer.append(this._packArrayHeader(1));

        const encodedValue = encode(timestamp);
        packer.append(encodedValue);
        return packer;
    };

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    getDecoded = (object) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;

        this.extensionCodec.register({
            type: 0x01,
            decode: (object) => {
                return decode(object);
            }
        });

        this.extensionCodec.register({
            type: 0x08,
            decode: (object) => {
                return decode(object);
            }
        });

        this.extensionCodec.register({
            type: 0x07,
            decode: (object) => {
                return decode(object);
            }
        });

        const decoder = new Decoder(this.extensionCodec);
        return decoder.decode(object);
    };

    getAuthentication = (data, messageIndex, messageType) => {
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        const result = encode(data);
        packer.append(result);

        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getRequest = (messageIndex, messageType, subMessageType) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array([130]);
        packer.append(result);

        result = encode(1);
        packer.append(result);
        const arrayData = new Uint8Array(new ByteBuffer().order(ByteBuffer.LITTLE_ENDIAN).writeShort(messageType).buffer);
        result = this._getExtEncodedArray(0x02, arrayData);
        packer.append(result);

        result = encode(2);
        packer.append(result);
        result = new Uint8Array([192]);
        packer.append(result);

        const message = this._getMessage(subMessageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getDeviceStatus = (messageIndex, statusTypes, messageType, subMessageType) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array([130]);
        packer.append(result);

        result = encode(1);
        packer.append(result);
        const arrayData = new Uint8Array(new ByteBuffer().order(ByteBuffer.LITTLE_ENDIAN).writeShort(messageType).buffer);
        result = this._getExtEncodedArray(0x02, arrayData);
        packer.append(result);

        result = encode(2);
        packer.append(result);
        result = new Uint8Array(this._packArrayHeader(statusTypes.length));
        packer.append(result);

        for (let i = 0; i < statusTypes.length; i++) {
            result = this._getExtEncodedObject(0x08, { type: 0x08, value: statusTypes[i] });
            packer.append(result);
        }

        const message = this._getMessage(subMessageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getDeviceSettings = (messageIndex, settingTypes, messageType, subMessageType) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array([130]);
        packer.append(result);

        result = encode(1);
        packer.append(result);
        const arrayData = new Uint8Array(new ByteBuffer().order(ByteBuffer.LITTLE_ENDIAN).writeShort(messageType).buffer);
        result = this._getExtEncodedArray(0x02, arrayData);
        packer.append(result);

        result = encode(2);
        packer.append(result);
        result = new Uint8Array(this._packArrayHeader(settingTypes.length));
        packer.append(result);

        for (let index = 0; index < settingTypes.length; index++) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: settingTypes[index] });
            packer.append(result);
        }
        const message = this._getMessage(subMessageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getMeasurement = (messageIndex, messageType, driveChannel, senseChannel) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array([133]);
        packer.append(result);

        result = encode(1);
        packer.append(result);
        result = this._getExtEncodedObject(0x05, { type: 0x05, value: driveChannel });
        packer.append(result);

        result = encode(2);
        packer.append(result);
        if (senseChannel.length >= 1) {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: senseChannel[0] });
            packer.append(result);
        } else {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: 0x00 });
            packer.append(result);
        }

        result = encode(3);
        packer.append(result);
        if (senseChannel.length >= 2) {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: senseChannel[1] });
            packer.append(result);
        } else {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: 0x00 });
            packer.append(result);
        }
        this.getMeasurementPacker(result, packer, senseChannel);

        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getMeasurementPacker = (result, packer, senseChannel) => {
        result = encode(4);
        packer.append(result);
        if (senseChannel.length >= 3) {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: senseChannel[2] });
            packer.append(result);
        } else {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: 0x00 });
            packer.append(result);
        }

        result = encode(5);
        packer.append(result);
        if (senseChannel.length >= 4) {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: senseChannel[3] });
            packer.append(result);
        } else {
            result = this._getExtEncodedObject(0x06, { type: 0x06, value: 0x00 });
            packer.append(result);
        }
        return packer;
    };

    getExpectedResponse = (response, messageType) => {
        const reversedCopy = [...response].reverse();
        const type = "0x" + reversedCopy.map((item) => item.toString(16).padStart(2, "0")).join("").toUpperCase();
        return messageType === Number(type);
    };

    getFirmwareUpdate = (messageIndex, messageType, offset, data) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array([146]);
        packer.append(result);

        result = encode(offset);
        packer.append(result);
        const dataArray = [];
        const maxLimit = 1024;
        if (data.length < maxLimit) {
            const sizeOfData = data.length;
            const remainingBytes = maxLimit - sizeOfData;
            for (let index = 0; index < data.length; index++) {
                dataArray.push(data[index]);
            }
            for (let index = sizeOfData; index < remainingBytes + sizeOfData; index++) {
                dataArray.push(0);
            }
        } else {
            for (let index = 0; index < data.length; index++) {
                dataArray.push(data[index]);
            }
        }

        packer.append([197]);
        packer.append([4, 0]);

        packer.append(dataArray);
        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getFirmwareBinaryFinish = (messageIndex, messageType, length, crcValue, isSozoProDevice) => {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);
        let result;
        if (isSozoProDevice) {
            result = new Uint8Array([147]);
            packer.append(result);

            result = encode(Math.round(Date.now() / 1000));
            packer.append(result);
        } else {
            result = new Uint8Array([146]);
            packer.append(result);
        }

        result = encode(length);
        packer.append(result);

        result = encode(crcValue);
        packer.append(result);

        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    // eslint-disable-next-line max-statements
    getSetDeviceSettings = function (messageType, sozoSettings, messageIndex) {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        let result = new Uint8Array(this._packMapHeader(Object.keys(sozoSettings).length));
        packer.append(result);

        if (sozoSettings[SetSettingType.SERIAL_NUMBER] !== undefined) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: 0x01 });
            packer.append(result);
            result = encode(sozoSettings[SetSettingType.SERIAL_NUMBER]);
            packer.append(result);
        }

        if (sozoSettings[SetSettingType.FRIENDLY_NAME] !== undefined) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: 0x02 });
            packer.append(result);
            result = encode(sozoSettings[SetSettingType.FRIENDLY_NAME]);
            packer.append(result);
        }

        if (sozoSettings[SetSettingType.WEIGHT_DISPLAY_DISABLED] !== undefined) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: 0x03 });
            packer.append(result);
            result = encode(sozoSettings[SetSettingType.WEIGHT_DISPLAY_DISABLED]);
            packer.append(result);
        }

        this.getSetDeviceSettingsPacker(packer, result, sozoSettings);
        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getSetDeviceSettingsPacker = (packer, result, sozoSettings) => {
        if (sozoSettings[SetSettingType.DISPLAY_BRIGHTNESS] !== undefined) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: 0x05 });
            packer.append(result);
            result = encode(sozoSettings[SetSettingType.DISPLAY_BRIGHTNESS]);
            packer.append(result);
        }

        if (sozoSettings[SetSettingType.DISPLAY_KILOGRAM] !== undefined) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: 0x06 });
            packer.append(result);
            result = encode(sozoSettings[SetSettingType.DISPLAY_KILOGRAM]);
            packer.append(result);
        }

        if (sozoSettings[SetSettingType.CONTACT_TEST_DISABLED] !== undefined) {
            result = this._getExtEncodedObject(0x07, { type: 0x07, value: 0x04 });
            packer.append(result);
            // The below check ensures that a proper value for contact test disabled is set in the device.
            // When using normal encode function as mentioned above, the encode function doesn't seem to return correct value
            // For example, when 1 is used as a value for contact test disabled, the encode function only gives 1 as the
            // output. But this is not the case for native Android MessagePack it gives us [-54, 63, -128] the same we have
            // written below. The contact test disable can only be 0 or 1/2/3/4/5...
            const weightDisplayValue = sozoSettings[SetSettingType.CONTACT_TEST_DISABLED];
            if (weightDisplayValue === 0) {
                result = [202, 0];
            } else {
                result = [202, 63, 128];
            }
            packer.append(result);
        }
    };

    getRunSelfTestMessage = function (messageType, isSozoProDevice, timestamp, messageIndex) {
        const byteBufferPacker = isSozoProDevice ? this._getSOZOProSelfTestBytes(timestamp) : new ByteBuffer();
        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(byteBufferPacker.buffer));
        return new Uint8Array(message.buffer);
    };

    getSetFrequencyListMessage = function (messageType, messageIndex, data) {
        this.extensionCodec = ExtensionCodec.defaultCodec;
        const packer = new ByteBuffer();
        packer.order(ByteBuffer.LITTLE_ENDIAN);

        packer.append(this._packArrayHeader(data.length));
        for (let index = 0; index < data.length; index++) {
            const frequencyValue = encode(data[index]);
            for (let innerIndex = 0; innerIndex < frequencyValue.length; innerIndex++) {
                packer.writeByte(frequencyValue[innerIndex]);
            }
        }

        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(packer.buffer));
        return new Uint8Array(message.buffer);
    };

    getResetFrequencyListMessage = function (messageType, messageIndex) {
        const message = this._getMessage(messageType, messageIndex, false, new ByteBuffer());
        return new Uint8Array(message.buffer);
    };

    getWeightCalibrationHistoryMessage = function (messageType, messageIndex) {
        const byteBufferPacker = new ByteBuffer();
        const message = this._getMessage(messageType, messageIndex, false, new Uint8Array(byteBufferPacker.buffer));
        return new Uint8Array(message.buffer);
    };

    zeroScaleMessage = function (messageType, messageIndex) {
        const message = this._getMessage(messageType, messageIndex, false, new ByteBuffer());
        return new Uint8Array(message.buffer);
    };

    selfTestGetDetailedResultMessage = function (messageType, messageIndex) {
        const message = this._getMessage(messageType, messageIndex, false, new ByteBuffer());
        return new Uint8Array(message.buffer);
    };
}
export default MessageCodec;
