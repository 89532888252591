import PropTypes from "prop-types";
import {connect} from "react-redux";
import HelperView from "./HelperView";

const Helper = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <HelperView
		loading={props.loading}
	/>;

};

Helper.propTypes = {
	loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
	loading: state.loading
});

export default connect(mapStateToProps, null)(Helper);