import ByteBuffer from "bytebuffer";
import crc32 from "crc-32";

class MessageParser {
    constructor () {
        if (!MessageParser._instance) {
            this.MESSAGE_STATE = {
                DELIMITER: "DELIMITER",
                LENGTH: "LENGTH",
                DATA: "DATA",
                CRC: "CRC"
            };
            this.setDefaultAllValues();
            MessageParser._instance = this;
        }
        return MessageParser._instance;
    }

    // Private Methods ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    _setState = (attribute, rxByte, isCRC) => {
        switch (this.dataIndex++) {
        case 0:
            this[attribute] = parseInt(rxByte) & 0x000000FF;
            break;
        case 1:
            this[attribute] |= rxByte << 8 & 0x0000FF00;
            break;
        case 2:
            this[attribute] |= rxByte << 16 & 0x00FF0000;
            break;
        default:
            this[attribute] |= rxByte << 24 & 0xFF000000;
            this.dataIndex = 0;

            if (isCRC) {
                if (this[attribute] === crc32.buf(this.messagePackBuffer)) {
                    this.responseParsed = true;
                }
            } else {
                this.msgState = this.MESSAGE_STATE.DATA;
                if (this[attribute] >= 0 && this[attribute] < 40000) {
                    this.messagePackBuffer = new Uint8Array(this[attribute]);
                }
            }
            break;
        }
    };

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    setDefaultAllValues = () => {
        this.msgState = this.MESSAGE_STATE.DELIMITER;
        this.delimiter = 0x49D4944D;
        this.delVal = 0;
        this.dataIndex = 0;
        this.length = 0;
        this.crcVal = 0;
        this.messagePackBuffer = null;
        this.responseParsed = false;
    };

    isResponseParsed = () => {
        return this.responseParsed;
    };

    setMessageRxByteBuffer = (rxByte) => {
        this.responseParsed = false;
        switch (this.msgState) {
        case this.MESSAGE_STATE.DELIMITER:
            this.delVal >>>= 8;
            this.delVal |= (rxByte | 0) << 24;
            if (this.delVal === this.delimiter) {
                this.msgState = this.MESSAGE_STATE.LENGTH;
                this.dataIndex = 0;
            }
            break;
        case this.MESSAGE_STATE.LENGTH:
            this._setState("length", rxByte, false);
            break;
        case this.MESSAGE_STATE.DATA:
            this.messagePackBuffer[this.dataIndex++] = rxByte;
            if (this.dataIndex >= this.length) {
                this.msgState = this.MESSAGE_STATE.CRC;
                this.dataIndex = 0;
            }
            break;
        case this.MESSAGE_STATE.CRC:
            this._setState("crcVal", rxByte, true);
            break;
        default: break;
        }
    };

    getMessagePackBuffer = () => {
        return this.messagePackBuffer;
    };

    getMessagePackTxBuffer = (messagePackTxBuffer, totalChunkCount, messageIndex) => {
        let txBuffer = new Uint8Array(0);
        if (messagePackTxBuffer.length >= 0 && messagePackTxBuffer.length < 2500) {
            const buffer = ByteBuffer.allocate(messagePackTxBuffer.length + 12);

            buffer.order(ByteBuffer.LITTLE_ENDIAN);
            buffer.writeInt(this.delimiter);

            const messagePackLength = messagePackTxBuffer.length & 0xFFFFFFFF;
            let totalChunk = totalChunkCount > 1 ? totalChunkCount : 0;
            let msgId = totalChunkCount > 1 ? messageIndex : 0;
            totalChunk &= 0x0F;
            msgId &= 0x0F;

            const centerByte = totalChunk << 4 | msgId;
            buffer.writeInt(messagePackLength | centerByte << 24);

            for (let index = 0; index < messagePackTxBuffer.length; index++) {
                buffer.writeByte(messagePackTxBuffer[index]);
            }

            buffer.writeInt(crc32.buf(messagePackTxBuffer) & 0xFFFFFFFF);
            txBuffer = new Uint8Array(buffer.view);
        }
        return txBuffer;
    };

    getMobileMessagePackBuffer = (data) => {
        const stringArrayOfData = data.replace("[", "").replace("]", "").split(",");
        const bytes = Array(stringArrayOfData.length).fill(null);
        for (let index = 0; index < stringArrayOfData.length; index++) {
            bytes[index] = parseInt(stringArrayOfData[index]) & 0xFF;
        }
        return bytes;
    };
}
export default MessageParser;
