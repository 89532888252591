import {auth, NODE_URL} from "./config";
import {getResponse} from "./utils";
import {ApiStore} from "./apiStore";
import cognitoData from "../../cognitoData.json";
import {version} from "../../package.json";

/**
 * @description POST /auth-customer-api/auth <br> Logs in an active user
 * @async
 * @export const
 */
export const login = (email, password, client, resolve, reject) => {

	let headerFor = "auth";
	let url = NODE_URL + auth + "auth";
	let method = "POST";
	let data = {
		email: email,
		password: password,
		version: version,
		client: client
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description POST /auth-customer-api/auth <br> Logs in an active user
 * @async
 * @static
 */
export const mfaLogin = (email, session, totp, client, resolve, reject) => {
	let headerFor = "auth";
	let url = NODE_URL + auth + "auth/mfa";
	let method = "POST";
	let data = {
		email: email,
		session: session,
		totp: totp,
		version: version,
		client: client
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description POST /auth-customer-api/provider <br> Checks if the given provider name exists
 * @async
 * @export const
 */
export const checkProviderName = (providerName, resolve, reject) => {
	jQuery(".cs-loader").show();
	jQuery.ajax({
		url: NODE_URL + auth + "provider?providerName=" + providerName,
		method: "GET",
		dataType: "json",
		contentType: "application/json",
		success(response) {
			resolve(response);
		},
		error(errorObject) {
			reject(errorObject);
		},
		complete() {
			jQuery(".cs-loader").hide();
		},
		timeout: window.constants.timeoutBig
	});
};

/**
 * @description POST /auth-customer-api/provider <br> Checks if the given provider name exists
 * @async
 * @export const
 */
export const ssoLogin = (client, resolve, reject) => {
	const headerFor = "sso";
	const url = NODE_URL + auth + "auth/sso";
	const method = "POST";
	const data = {
		"version": version,
		"client": client
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description POST auth-customer-api/auth/password <br> Send an email with a temp password
 * @async
 * @export const
 */
export const sendTempPassword = (email, resolve, reject) => {
	let headerFor = "auth";
	let url = NODE_URL + auth + "auth/password";
	let method = "POST";
	let data = {"email":email};

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description PUT auth-customer-api/auth/password <br> Changes the user's password with verification code
 * @async
 * @export const
 */
export const resetPassword = (credentials, resolve, reject) => {
	let headerFor = "auth";
	let url = NODE_URL + auth + "auth/password";
	let method = "PUT";
	let data = {
		"email": credentials.email,
		"newPassword": credentials.password,
		"confirmPassword": credentials.confirmPassword,
		"verificationCode": credentials.verificationCode,
		"newUser": credentials.newUser.toString()
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description PUT auth-customer-api/auth/password <br> Changes the user's password with old password verification
 * @async
 * @export const
 */
export const changePassword = (credentials, resolve, reject) => {
	let headerFor = "auth";
	let url = NODE_URL + auth + "auth/password/expiration";
	let method = "PUT";
	let data = {
		email: credentials.email,
		oldPassword: credentials.oldPassword,
		newPassword: credentials.newPassword,
		confirmPassword: credentials.confirmNewPassword,
		accessToken: credentials.accessToken
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description Method and path provided by the server after successful login <br> Gets the necessary data immediately after login
 * @async
 * @static
 */
export const getInitialData = (resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.initialDataGET.path + "?client=" + "2";
	let method = apiStore.endpointsList.initialDataGET.method;
	let data = undefined;

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description Method and path provided by the server after successful login <br> Logs out current user
 * @async
 * @export const
 */
export const logout = (resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersLogoutPOST.path;
	let method = apiStore.endpointsList.usersLogoutPOST.method;
	let data = {
		"userId": apiStore.userId,
		"email": apiStore.userEmail
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

/**
 * @description Cognito call <br> Fetches token used for SSO login
 * @async
 * @expoort const
 */
export const fetchTokenFromCognito = (code, resolve, reject) => {
	const headerFor = "cognito";
	const url = "https://" + cognitoData.customDomain + "/oauth2/token?"
		+ "grant_type=authorization_code&client_id=" + cognitoData.clientId
		+ "&code=" + code
		+ "&redirect_uri=" + NODE_URL + "IntermediateLogin";
	const method = "POST";
	const data = {};

	getResponse(url, method, headerFor, data, resolve, reject, "application/x-www-form-urlencoded");
};

export const ssoLogout = () => {
	let path = "https://" + cognitoData.customDomain + "/logout?client_id="
		+ cognitoData.clientId + "&logout_uri=" + NODE_URL;
	sessionStorage.clear();
	location.href = path;
};

export const refreshTokenCall=(resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "refreshtoken";
	let url = NODE_URL + apiStore.endpointsList.usersTokensPOST.path;
	let method = apiStore.endpointsList.usersTokensPOST.method;
	let data = {};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const refreshTokenSSOCall=(resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "cognito";
	let url = "https://" + cognitoData.customDomain + "/oauth2/token?"
		+ "grant_type=refresh_token&client_id=" + cognitoData.clientId
		+ "&refresh_token=" + apiStore.tokens.refreshToken;
	let method = "POST";
	let data = {};

	getResponse(url, method, headerFor, data, resolve, reject,"application/x-www-form-urlencoded");

};

export const getXwareUpdate = (deviceUpdate, deviceFirmwareName, swVersion, fileUpdate, resolve, reject) => {
	let headerFor = "";
	let queryParams = "?deviceUpdate=" + encodeURIComponent(deviceUpdate) +
		"&deviceFirmwareName=" + encodeURIComponent(deviceFirmwareName) +
		"&swVersion=" + encodeURIComponent(swVersion) +
		"&fileUpdate=" + encodeURIComponent(fileUpdate);
	let url = NODE_URL + auth + "auth/xware" + queryParams;
	let method = "GET";
	let data = null;

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const postSystemLogs = (logData, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "auth";
	let url = NODE_URL + auth + "auth/systemlogs";
	let method = "POST";
	let data = {
		facilityId: apiStore.facilityId,
		region: apiStore.region,
		userid: apiStore.userId,
		log: logData.log,
		tag: logData.tag,
		level: logData.level,
		type: logData.type
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};