import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./reducers/index";
import {persistStore, persistReducer} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";

const persistConfig = {
	key: "root",
	storage: storageSession,
	stateReconciler: autoMergeLevel1,
	blacklist: [
		"searchValue",
		"loading",
		"nextPath",
		"expandedCollapsable",
		"totalPatients",
		"merge",
		"measurementsFilter",
		"forceUpdateListState",
		"measurementList",
		"selectedTab",
		"selectedMeasurement",
        "selectedTrendings",
        "selectedDevice",
        "logoutPopup",
		"frx"
	]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
let store, persistor;
// -------------------------------
// reduxImmutableStateInvariant
// Development only
// -------------------------------
// const configureStore = (initialState) => {
// 	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //redux devtool
// 	store = createStore(
// 		persistedReducer,
// 		initialState,
// 		composeEnhancers(applyMiddleware(reduxImmutableStateInvariant()))
// 	);
// 	persistor = persistStore(store);
// 	return {store, persistor};
//
// };

const configureStore = (initialState) => {
	store = createStore(
		persistedReducer,
		initialState
	);
	persistor = persistStore(store);
	return {store, persistor};
};

export {store};
export {persistor};
export default configureStore;
