import ToastSuccessView from "./ToastSuccessView";

const ToastSuccess = () => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <ToastSuccessView/>;

};

ToastSuccess.propTypes = {};

export default ToastSuccess;