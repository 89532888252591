export const ApiStore = (() => {
	function ApiStore(apiStore) {
		this.instance = apiStore;
	}
	let instance = null;
	return {
		getInstance: () => {
			return instance;
		},
		init: (apiStore) => {
			instance =  new ApiStore(apiStore).instance;
		}
	};
})();
