import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.htmlChangeLog, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_CHANGELOG :
			delete action.payload.changeLogHtmlAppShort;
			delete action.payload.changeLogHtmlAppLong;
			nextState = action.payload;
			break;
		default:
			nextState = state;
	}
	return nextState;
};