const InstructionsIconView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className={"instructionsIcon"}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.435 28.369">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_18158" data-name="Rectangle 18158" className={"instructionsIcon__a"}/>
                </clipPath>
            </defs>
            <g id="Group_11616" data-name="Group 11616" transform="translate(0 0)">
                <g id="Group_11615" data-name="Group 11615" transform="translate(0 0)" clipPath="url(#clip-path)">
                    <path id="Path_12650" className={"instructionsIcon__b"} data-name="Path 12650" d="M27.433,1.53C22.5-1.444,19.219.865,19.219.865S15.933-1.46,11,1.515,0,2.073,0,2.073V27.429s6.076,2.541,11.593-.805,7.633-.5,7.633-.5,2.1-2.836,7.618.512,11.591.805,11.591.805V2.088s-6.074,2.418-11-.558M18.319,24.094A8.631,8.631,0,0,0,10.694,25.4c-4.121,2.729-9.273.9-9.273.9V4.167S6.509,6,12.46,2.246a6.53,6.53,0,0,1,5.859-.155ZM37.02,26.3s-5.454,1.83-9.578-.9a8.631,8.631,0,0,0-7.625-1.306v-22a6.53,6.53,0,0,1,5.859.155A14.11,14.11,0,0,0,37.02,4.167Z" transform="translate(0 0.001)"/>
                    <path id="Path_12651" className={"instructionsIcon__c"} data-name="Path 12651" d="M19.546,14.243V6.577H12.768V8.146h2.066v6.1H12.768v1.473h8.469V14.243Z" transform="translate(11.719 6.038)"/>
                    <path id="Path_12652" className={"instructionsIcon__c"} data-name="Path 12652" d="M16.157,8.214a2.387,2.387,0,1,0-2.386-2.386,2.386,2.386,0,0,0,2.386,2.386" transform="translate(12.64 3.159)"/>
                </g>
            </g>
        </svg>
    </div>;

};

InstructionsIconView.propTypes = {};

export default InstructionsIconView;