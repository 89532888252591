import { useReducer } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useHistory, useLocation} from "react-router";
import NewPasswordView from "./NewPasswordView";
import Utilities from "../../Libraries/Custom/Utilities";
import {resetPassword} from "../../../API/authApi";

const NewPassword = (props) => {

	//region VARIABLES
	let [inpt, setInpt] = useReducer((state, newState) => ({...state, ...newState}), {
		inputTempPassword: "",
		inputNewPassword: "",
		inputConfirmNewPassword: ""
	});
	let history = useHistory();
	let location = useLocation();
	let urlParams = location.search.slice(1).split("&");
	let email = urlParams[0].split("=")[1];
	let newUser = urlParams[1].split("=")[1] === "true";
	let verificationCode = "";
	if (!newUser) {
		verificationCode = urlParams[2].split("=")[1];
	}
	//endregion

	//region FUNCTIONS
	const validateInput = (data) => {
		let isValid = false;
		let errorType = null;
		if (Utilities.isRequiredFieldsEmpty(data.password, data.confirmPassword, data.verificationCode)) {
			errorType = "emptyFieldsClinic";
		} else if (Utilities.isPassword(data.password) === null) {
			errorType = "invalidPassword_Alert";
		} else if (data.password !== data.confirmPassword) {
			errorType = "invalidPasswordConsistency";
		} else {
			isValid = true;
		}
		return {isValid, errorType};
	};

	const handleResponse = () => {
		sessionStorage.setItem("loginEmail", email);
		Utilities.customSweetAlertOperation({
			title: dictionary[localStorage.language].newPassword,
			text: dictionary[localStorage.language].changePasswordSuccess,
			type: "success"
		}, false, "", dictionary[localStorage.language].ok, () => {
			history.push(constants.routes.root);
		}, true);
	};

	const handleInput = (event) => {
		setInpt({[event.target.id]: event.target.value});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!props.loading) {
			let data = {
				email: email,
				password: inpt.inputNewPassword,
				confirmPassword: inpt.inputConfirmNewPassword,
				verificationCode: newUser ? inpt.inputTempPassword : verificationCode,
				newUser: newUser
			};
			let validation = validateInput(data);
			if (validation.isValid) {
				let response = await Utilities.makeSingleCallAsync(resetPassword, "resetPassword", [data]);
				if (response.status === constants.responseStatus.success) {
					handleResponse(response.data);
				} else {
					Utilities.handleFailResponse(response, [data], "resetPassword");
				}
			} else {
				Utilities.customSweetAlertSimple("", dictionary[localStorage.language][validation.errorType], "error", false, "", dictionary[localStorage.language].ok);
			}
		}

	};

	const handleCancel = () => {
		history.push(constants.routes.root);
	};
	//endregion

	return <NewPasswordView
		email={email}
		newUser={newUser}
		handleInput={handleInput}
		handleSubmit={handleSubmit}
		handleCancel={handleCancel}
		values={inpt}
	/>;

};

NewPassword.propTypes = {
	loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
	loading: state.loading
});

export default connect(mapStateToProps, null)(NewPassword);