import * as actions from "../actionTypes";

export const saveSelectedDevice = (payload) => ({
    type: actions.SAVE_SELECTED_DEVICE,
    payload
});

export const saveDevicePort = (payload) => ({
    type: actions.SAVE_DEVICE_PORT,
    payload
});

export const saveSelfTestStatus = (payload) => ({
    type: actions.SAVE_SELF_TEST_STATUS,
    payload
});

export const saveDeviceStatus = (payload) => ({
    type: actions.SAVE_DEVICE_STATUS,
    payload
});

export const saveDeviceKeys = (payload) => ({
    type: actions.SAVE_DEVICE_KEYS,
    payload
});

export const saveDeviceLicenseStatus = (payload) => ({
    type: actions.SAVE_DEVICE_LICENSE_STATUS,
    payload
});

export const saveDeviceBluetoothName = (payload) => ({
    type: actions.SAVE_DEVICE_BLUETOOTH_NAME,
    payload
});

export const clearSelectedDevice = (payload) => ({
    type: actions.CLEAR_SELECTED_DEVICE,
    payload
});

