import ArrowDownView from "./ArrowDownView";

const ArrowDown = (props) => {
//region VARIABLES
//endregion

//region FUNCTIONS
//endregion

    return <ArrowDownView {...props}/>;
};
ArrowDown.propTypes = {};

export default ArrowDown;