import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import UpdatePopupChainView from "./UpdatePopupChainView";
import {usePasswordProtection} from "../../Hooks/usePasswordProtection";
import Utilities from "../../Libraries/Custom/Utilities";
import {updateVersion} from "../../../API/facilityApi";
import {saveUserPostUpdateHasNotice, saveUserPreUpdateHasNotice} from "../../../Redux/actions/User/userActions";
import {customSweetAlertOperation} from "../../Libraries/Custom/utils/sweetalert";
import {getErrorMessage} from "../../Libraries/Custom/utils/errorHandlers";
import {useDevicePort} from "../../Contexts/DevicePortProvider";
import DeviceApi from "../../Libraries/Custom/DeviceApi/deviceApi";
import {getXwareUpdate, ssoLogout} from "../../../API/authApi";

const UpdatePopupChain = (props) => {

	//region VARIABLES
	let [chainState, setChainState] = useState(null);
	let [show, setShow] = useState(false);
	let [authData, handlePasswordChange, isValidPassword, clearAuthDataFields] = usePasswordProtection();
	const devicePortValue = useDevicePort();
	//endregion

	//region FUNCTIONS
	/**
	 * @description Updates the state for the notice.
	 */
	const changeHasNoticeState = () => {
		props.saveUserPreUpdateHasNotice(0);
		props.saveUserPostUpdateHasNotice(0);
	};

	const handleCancelButton = () => {
		changeHasNoticeState();
		setShow(false);
		clearAuthDataFields();
		if (props.handleClose !== null) {
			props.handleClose();
		}
	};

	const resolveUpdate = (response) => {
		changeHasNoticeState();
		customSweetAlertOperation({
			title: dictionary[localStorage.language].updateStrings.softwareUpdateTitle,
			text: getErrorMessage(response.code),
			type: "info"
		}, false, "", dictionary[localStorage.language].ok, () => {
			Utilities.logoutCall();
		}, false);
	};

	const handleUpdateClick = async () => {
		if (props.facility.isSSO || isValidPassword(false)) {
			let response = await Utilities.makeSingleCallAsync(updateVersion, "updateVersion", [props.facility.facilitysCurrentVersion, props.facility.preUpdateAvailableVersion, authData.password, authData.mfa]);
			if (response.status === constants.responseStatus.success) {
				resolveUpdate(response.data);
			} else {
				Utilities.handleFailResponse(response, [props.facility.facilitysCurrentVersion, props.facility.preUpdateAvailableVersion, authData.password, authData.mfa], "updateVersion");
				changeHasNoticeState();
			}
		}
	};

	const downloadUpdateFile = async (appUpdateUrl) => {
		Utilities.setLoaderState(true);
		let appUpdateFileBytes = null;
		try {
			const downloadedFile = await fetch(appUpdateUrl, {method: "GET"});
			appUpdateFileBytes = new Uint8Array(await downloadedFile.arrayBuffer());
		} catch (error) {
			Utilities.customSweetAlertSimple(dictionary[localStorage.language].warning, dictionary[localStorage.language].noConnectivity, "warning", false, "", dictionary[localStorage.language].ok);
		} finally {
			Utilities.setLoaderState(false);
		}

		if (appUpdateFileBytes !== null) {
			try {
				Utilities.setLoaderState(true);
				const devicePort = devicePortValue || props.devicePort;
				const selectedDevice = {...props.selectedDevice, port: devicePort, currentOS: props.currentOS};

				await new DeviceApi(selectedDevice, (...args) => Utilities.sendLogs(`${selectedDevice.bluetoothName} ApplicationUpdate`, ...args)).setApplicationUpdate(appUpdateFileBytes, false);
			} catch (error) {
				Utilities.handleErrorFromDevice(error, "ApplicationUpdate", props.currentOS);
			} finally {
				Utilities.setLoaderState(false);
			}
		}
	};

	const handleUpdateApplicationClick = async () => {
		const appUpdateResponse = await Utilities.makeSingleCallAsync(getXwareUpdate, "updateApp", ["0", null, props.appVersion, "1"]);

		if (appUpdateResponse.data.code === constants.appUpdateCodes.appUpdateAvailable) {
			downloadUpdateFile(appUpdateResponse.data.data.updateFile);
		} else {
			Utilities.handleFailResponse(appUpdateResponse, ["0", null, props.appVersion, "1"], "updateApp");
		}
	};

	/**
	 * @description Updates the state with the current popup state.
	 */
	const handleTellMeMoreClick = () => {
		setChainState(constants.popupChainStates.adminPreUpdateChanges);
	};

	/**
	 * @description Updates the state with the current popup state.
	 */
	const handleTellMeMoreAppUpdateClick = () => {
		setChainState(constants.popupChainStates.applicationPreUpdateChanges);
	};

	/**
	 * @description Updates the state with the current popup state.
	 */
	const handlePreAcceptUpdateClick = () => {
		setChainState(constants.popupChainStates.adminUpdateAccept);
	};

	/**
	 * @description Updates the state with the current popup state.
	 */
	const handleTellMeMoreNoButtonsClick = () => {
		setChainState(constants.popupChainStates.userFirstLoginChanges);
	};

	const handleCancelAppUpdateClick = () => {
		setShow(false);
		if (props.handleClose !== null) {
			props.handleClose();
		}
		if (props.isSSOLogin) {
			ssoLogout();
		}
	};

	useEffect(() => {
		setChainState(props.chainState);
		setShow(props.showPopup);
	}, [props.chainState, props.showPopup]);
	//endregion

	return <UpdatePopupChainView
		chainState={chainState}
		show={Boolean(show)}
		handleCancelButton={handleCancelButton}
		handleUpdateClick={handleUpdateClick}
		handleTellMeMoreClick={handleTellMeMoreClick}
		handlePreAcceptUpdateClick={handlePreAcceptUpdateClick}
		handleTellMeMoreNoButtonsClick={handleTellMeMoreNoButtonsClick}
		handleUpdateApplicationClick={handleUpdateApplicationClick}
		handleTellMeMoreAppUpdateClick={handleTellMeMoreAppUpdateClick}
		handleCancelAppUpdateClick={handleCancelAppUpdateClick}
		htmlChangeLog={props.htmlChangeLog}
		authData={authData}
		handlePasswordChange={handlePasswordChange}
		passwordProtectionEnabled={!props.facility.isSSO}
		showPasswordPopup={!props.facility.isSSO}
		currentOS={props.currentOS}
	/>;

};

UpdatePopupChain.defaultProps = {
	isSSOLogin: false,
	handleClose: null
};

UpdatePopupChain.propTypes = {
	showPopup: PropTypes.bool,
	chainState: PropTypes.string,
	facility: PropTypes.object,
	htmlChangeLog: PropTypes.object,
	selectedDevice: PropTypes.object,
	currentOS: PropTypes.object,
	devicePort: PropTypes.object,
	appVersion: PropTypes.string,
	isSSOLogin: PropTypes.bool,
	saveUserPreUpdateHasNotice: PropTypes.func,
	saveUserPostUpdateHasNotice: PropTypes.func,
	handleClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
	facility: state.facility,
	htmlChangeLog: state.htmlChangeLog,
	selectedDevice: state.selectedDevice,
	currentOS: state.currentOS
});

const mapDispatchToProps = (dispatch) => ({
	saveUserPreUpdateHasNotice: (data) => dispatch(saveUserPreUpdateHasNotice(data)),
	saveUserPostUpdateHasNotice: (data) => dispatch(saveUserPostUpdateHasNotice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePopupChain);