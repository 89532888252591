import ImpedimedHeaderView from "./ImpedimedHeaderView";

const ImpedimedHeader = (props) => {
    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <ImpedimedHeaderView {...props}/>;
};

ImpedimedHeader.propTypes = {};

export default ImpedimedHeader;