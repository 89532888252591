import PropTypes from "prop-types";
import Label from "../Label/Label";
import OptionListSelections from "../OptionListSelections/OptionListSelections";
import ArrowDown from "../SVGs/ArrowDown/ArrowDown";

const OptionListView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	const getSelected = (options) => {
		let selected = "";
		for (let option of options) {
			if (option.selected) {
				selected = option;
				break;
			}
		}
		if (selected === "") {
			selected = {text: props.placeholder};
		}
		return selected;
	};
	//endregion
	let selectedText = getSelected(props.options).text;
	return <div>
		{props.withLabel && !props.labelInBox && <Label htmlFor={props.id} isRequired={props.isRequired} text={props.labelText} withRequiredTag={props.withRequiredTag}/>}
		<div ref={props.refNode} id={props.id} className={`optionList ${props.classes}`}>
			<button ref={props.blurRef} type={"button"} className={`optionList__box flexColCenterStart ${props.boxClasses}`}
					onClick={() => event.target.type !== "button" && props.handleVisibility(event)}
					onBlur={props.handleClose}
					onFocus={props.handleVisibility}
					disabled={props.disabled}>
				{props.withLabel && props.labelInBox && <Label htmlFor={props.id} isRequired={props.isRequired} text={props.labelText} withRequiredTag={props.withRequiredTag}/>}
				<div className={"optionList__selectedValue flexRowSpaceBetwnCenter"}>
					<span className={`overflowEllipsis optionList__selectedValue--text ${selectedText === props.placeholder && "optionList__selectedValue--placeholder"}`}>{selectedText}</span>
					<ArrowDown classes={props.isVisible ? "rotate180 arrowDown__top" : ""}/>
				</div>
			</button>
			{props.isVisible && <OptionListSelections options={props.options}
													  showSelected={props.showSelected}
													  popupClasses={props.popupClasses}
													  handleVisibility={props.handleVisibility}
													  handleClose={props.handleClose}
													  handleSelect={props.handleSelect}/>}
		</div>
	</div>;

};

OptionListView.propTypes = {
	id: PropTypes.string,
	classes: PropTypes.string,
	boxClasses: PropTypes.string,
	options: PropTypes.array,
	showSelected: PropTypes.bool,
	hasIcon: PropTypes.bool,
	iconPosition: PropTypes.string,
	defaultValue: PropTypes.string,
	defaultSelectable: PropTypes.bool,
	isVisible: PropTypes.bool,
	hasArrowIcon: PropTypes.bool,
	handleVisibility: PropTypes.func,
	handleSelect: PropTypes.func,
	withLabel: PropTypes.bool,
	isRequired: PropTypes.bool,
	labelText: PropTypes.string,
	labelInBox: PropTypes.bool,
	withRequiredTag: PropTypes.bool,
	placeholder: PropTypes.string,
	popupClasses: PropTypes.string,
	handleClose: PropTypes.func,
	disabled: PropTypes.bool,
	refNode: PropTypes.oneOfType([
		// Either a function
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	blurRef: PropTypes.oneOfType([
		// Either a function
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	])
};

export default OptionListView;