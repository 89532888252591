import DeviceConnector from "./deviceConnector.js";

class DeviceiOSConnector extends DeviceConnector {
    constructor (device, sendLogs) {
        if (!DeviceiOSConnector._instance) {
            super(device, sendLogs);
            this.setBluetoothOffListener(device);
            DeviceiOSConnector._instance = this;
        }
        return DeviceiOSConnector._instance;
    }

    setBluetoothOffListener = () => {
        window.setBluetoothOffListener = () => {
             this.rejectCurrentPendingTask("10027");
        };
    };
}

export default DeviceiOSConnector;
