import PropTypes from "prop-types";
import ToastView from "./ToastView";
import ToastSuccess from "../SVGs/ToastSuccess/ToastSuccess";

const Toast = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	const getTitle = (type) => {
		let title;
		switch (type) {
			case "success":
				title = dictionary[localStorage.language].success;
				break;
			default:
				title = "";
				break;
		}
		return title;
	};

	const getIcon = (type) => {
		let icon;
		switch (type) {
			case "success":
				icon = <ToastSuccess/>;
				break;
			default:
				icon = "";
				break;
		}
		return icon;
	};
	//endregion

	return <ToastView
		title={getTitle(props.type)}
		icon={getIcon(props.type)}
		message={props.message}
		closeToast={props.closeToast}
	/>;

};

Toast.propTypes = {
	type: PropTypes.string,
	message: PropTypes.string,
	closeToast: PropTypes.func,
};
Toast.defaultProps = {
	type: "success",
	message: "",
};

export default Toast;