import PropTypes from "prop-types";

const EyeView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className={"eye"}>
        {props.show ?
            <svg xmlns="http://www.w3.org/2000/svg" width="19.505" height="19.501" viewBox="0 0 19.505 13.299">
                <g id="ic_remove_red_eye_24px" transform="translate(-1 -4.5)">
                    <path id="Path_12224" data-name="Path 12224" d="M10.753,4.5A10.486,10.486,0,0,0,1,11.149a10.477,10.477,0,0,0,19.505,0A10.486,10.486,0,0,0,10.753,4.5Zm0,11.082a4.433,4.433,0,1,1,4.433-4.433A4.435,4.435,0,0,1,10.753,15.582Zm0-7.093a2.66,2.66,0,1,0,2.66,2.66A2.656,2.656,0,0,0,10.753,8.49Z" fill="#7d8286"/>
                </g>
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" width="19.505" height="19.501" viewBox="0 0 19.505 19.501">
                <g id="hide" transform="translate(0.002)">
                    <path id="Path_12225" data-name="Path 12225" d="M20.559,9.686a18.637,18.637,0,0,0-2.025-2.4l-3.267,3.267a4.084,4.084,0,0,1,.056.559,4.063,4.063,0,0,1-4.063,4.063,4.084,4.084,0,0,1-.559-.056L8.055,17.771a8.358,8.358,0,0,0,3.206.66c4.638,0,8.048-4.113,9.3-5.883A2.485,2.485,0,0,0,20.559,9.686Z" transform="translate(-1.51 -1.367)" fill="#7d8286"/>
                    <path id="Path_12226" data-name="Path 12226" d="M9.75,3C5.112,3,1.706,7.113.451,8.883a2.485,2.485,0,0,0,0,2.86h0a17.285,17.285,0,0,0,3.887,3.982l2.538-2.538A4.063,4.063,0,0,1,12.622,7.44L6.877,13.186,15.165,4.9A9.331,9.331,0,0,0,9.75,3Z" transform="translate(0 -0.562)" fill="#7d8286"/>
                    <path id="Path_12227" data-name="Path 12227" d="M.813,19.5a.813.813,0,0,1-.574-1.387L18.114.238a.812.812,0,1,1,1.149,1.149L1.387,19.263a.813.813,0,0,1-.574.238Z" transform="translate(0 0)" fill="#7d8286"/>
                </g>
            </svg>
        }
    </div>;

};

EyeView.propTypes = {
    show: PropTypes.bool
};

export default EyeView;