import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.currentOS, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_CURRENT_OS :
            nextState = {...nextState, ...action.payload};
            break;
        default:
            nextState = state;
    }
    return nextState;
};