export default {
	facility: {
		main: {
			facilityId: "",
			name: "",
			alias: "",
			patientList: [],
			userList: [],
			deletedPatientList: [],
			acceptAnyDevice: false,
			licensedDevicesList: [],
			gpId: "",
			preUpdateAvailableVersion: "",
			patientGroupsList: [],
			isSSO: 0,
			facilitysCurrentVersion: ""
		},
		location: {
			address: "",
			city: "",
			stateProvince: "",
			country: "",
			postalCode: "",
			region: "",
		},
		timezone: {
			timezone: "",
			UTCoffset: ""
		},
		settings: {
			resetPasswordTime: "",
			sessionExpirationTime: "",
			mfaForcedTime: null,
			currentLogo: "",
			providerName:null,
			ehrSettings: {
				dataTransfer: {
					sozoToEhr: 0
				},
				units: {
					kg: 0,
					lt: 0,
					cm: 0
				}
			},
			// region The following code is part of the LPP admin settings. This feature is disabled, for now.
			// lppSettings: {
			// 	endDateType: 0,
			// 	endDateValue: 1,
			// 	postTreatment: 0,
			// 	year1: 3,
			// 	year2: 3,
			// 	year3: 3,
			// 	year4: 4,
			// 	year5: 6,
			// 	year6: 12,
			// 	triggerValue: 6.5,
			// 	resolvedValue: 0.0
			// }
			// endregion
		},
		license: {
			lDex: false,
			bilateral: false,
			hfDex: false,
			renal: false,
			bodyCompositionOUS: false,
			bodyCompositionUS: false,
			segmental: false,
			basicReporting: false,
		}
	},
	user: {
		main: {
			user_id: "",
			userEmail: "",
			firstName: "",
			middleName: "",
			lastName: "",
			phoneNumber: "",
			countryPhoneCode: "",
			preUpdateHasNotice: null,
			postUpdateHasNoticePortal: null,
			needsPasswordUpdate: null,
			userRole: "",
		},
		settings: {
			cm: false,
			in: true,
			kg: false,
			lbs: true,
			lt: false,
			pt: true,
		},
		session: {
			token: "",
			refreshToken: "",
			endpointsList: {},
		}

	},
	selectedPatient: {
		user_id: "",
		firstName: "",
		lastName: "",
		middleName: "",
		mrn: "",
		gender: "",
		birthDateTime: "",
		lastAssessmentDateTime: "",
		phoneNumber: "",
		countryPhoneCode: "",
		userEmail: "",
		assessmentTypeSelections:{},
		lymphedemaSelections:{},
		surgeryDate: "",
		lppStartDate: "",
		notes:[]
	},
	selectedGroups: {
		groups: [],
		groupName: ""
	},
	selectedMeasurementId:"",
	selectedMeasurement: null,
	selectedDevice: {
		bluetoothName: "",
		licenseStatus: "ACTIVE",
		port: null,
		keys: {
			// TODO: Need to be fetched from the server in the future
			aesEncryptionKey: "",
			appIV: "",
			sozoIV: ""
		},
		status: {
			deviceName: "",
			macAddress: "",
			serialNumber: "",
			selfTestStatus: "",
			firmwareVersion: "",
			impedanceCalibrationDate: "",
			scaleCalibrationDate: "",
			selfTestDate: "",
			weightUnits: "",
			weightDisplayDisabled: false,
			isSOZOProDevice: false,
			shouldDisplayStatus: false
		}
	},
	pairedDeviceList: [],
	measurementCurrentPosition: 0,
	frx: null,
	measurementList: {
		measurementResultsList: [],
		measurementImportedResultsList: []
	},
	htmlChangeLog: {},
	searchValue: null,
	totalPatients: -1,
	loading: false,
	nextPath:null,
	expandedCollapsable:"",
	permaSearch:{
		activePatientsValue:null
	},
	merge:{
		sourceSearchValue:"",
		destinationSearchValue:"",
		sourcePatient:null,
		destinationPatient:null
	},
	editGroupMembers: {
		patientsInGroupSearchValue: null,
		patientsOutOfGroupSearchValue: null,
		group: {
			id: "",
			name: ""
		},
	},
	measurementsFilter: {
		dateRangeAll: true,
		startDate: "",
		endDate: "",
		tags: []
	},
	selectedTab: "",
	forceUpdateListState: false,
	selectedTrendings: [],
	logoutPopup:{
		showLogoutPopup:false,
		code:"",
		page:"",
	},
	analytics:{
		analyticsDashboardUrlIndex:0,
		analyticsImpacted: false,
	},
	currentOS: {
		isWindows: true,
		isiOS: false,
		isAndroid: false
	},
	sozoDeviceCommEnabled: false,
	sharingListType: "INCOMING",
	sharingCount: 0,
	selectedReceivers: [],
	isSharedPatient: false,
	selectedSharingData: {
		sharingId: "",
		sharingPatientId: "",
		patientFirstName: "",
		patientLastName: "",
		birthDateTime: "",
		creationDateTime: "",
		status: "",
		clinicAlias: "",
		receivers: [],
		statusDateTime: "",
		mrn: "",
		clinician: "",
		senderEmail: "",
		middleName: "",
		countryPhoneCode: "",
		location: null,
		lppStartDate: null,
		surgeryDate: null,
		phoneNumber: ""
	},
	sharingLicense: {
		lDex: false,
		bilateral: false,
		hfDex: false,
		renal: false,
		bodyCompositionOUS: false,
		bodyCompositionUS: false,
		segmental: false,
		basicReporting: false,
	},
	applicationBuild: {
		buildDate: "",
		versionCode: "",
		versionName: "",
	}
};