const UnlockedIconView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className={"unlockedIcon"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="65.357" height="71.997" viewBox="0 0 65.357 71.997">
            <g transform="translate(-1.912 15.204) rotate(-17)">
                <path className="a"
                      d="M49.5,25H17V14.775A9.892,9.892,0,0,1,26.9,5h.327A9.887,9.887,0,0,1,37,14.975l5,.05A14.9,14.9,0,0,0,27.25,0h-.225A14.85,14.85,0,0,0,12,14.75V25H4.5A2.5,2.5,0,0,0,2,27.5v30A2.5,2.5,0,0,0,4.5,60h45A2.5,2.5,0,0,0,52,57.5v-30A2.5,2.5,0,0,0,49.5,25ZM27,47.5a5,5,0,1,1,5-5A5,5,0,0,1,27,47.5Z"
                      transform="translate(0 0)"/>
            </g>
        </svg>
    </div>;

};

UnlockedIconView.propTypes = {};

export default UnlockedIconView;