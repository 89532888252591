import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.measurementCurrentPosition, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_MEASUREMENT_CURRENT_POSITION :
            nextState = (action.payload.pageNum - 1) *  100 + (action.payload.selectedIndex + 1);
            break;
        default:
            nextState = state;
    }
    return nextState;
};