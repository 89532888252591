import GetSettingType from "../Message/models/getsettingtype";
import Utils from "../utils";
import Commands from "./deviceCommands";

class ResultParser {
    constructor (device) {
        if (!ResultParser._instance) {
            ResultParser._instance = this;
            this.utils = new Utils(device.keys);
        }
        return ResultParser._instance;
    }

    _parseSelfTestStatus = (isSOZOProDevice, deviceStatus) => {
        let isSelfTestPassed;
        if (isSOZOProDevice && deviceStatus[3] === 2) {
            isSelfTestPassed = true;
        } else {
            isSelfTestPassed = !isSOZOProDevice && deviceStatus[3] === 1;
        }
        return isSelfTestPassed;
    };

    _convertFirmwareVersionTo10Digits = (firmwareVersion) => {
        let firmwareVersionWith10Digits = "";
        const lengthOfOriginalFirmwareVersion = firmwareVersion.length;
        for (let index = 0; index < 10 - lengthOfOriginalFirmwareVersion; index++) {
            firmwareVersionWith10Digits += "0";
        }
        return (firmwareVersionWith10Digits + firmwareVersion).toString();
    };

    _parseDeviceStatus = (parseResultRequest, isSOZOProDevice) => {
        const deviceStatus = parseResultRequest.deviceStatus;
        const deviceSettings = parseResultRequest.deviceSettings;
        const selfTestStatus = this._parseSelfTestStatus(isSOZOProDevice, deviceStatus);
        const result = {};
        result.serialNumber = deviceSettings[1];
        result.friendlyName = deviceSettings[2];
        result.firmwareVersion = this._convertFirmwareVersionTo10Digits(deviceStatus[1].toString());
        result.macAddress = deviceStatus[2];
        result.selfTestStatus = selfTestStatus === true ? "Passed" : "Failed";
        result.calibrationDate = deviceStatus[4];
        result.isSOZOProDevice = isSOZOProDevice;
        if (isSOZOProDevice) {
            result.calDateForScale = deviceStatus[5];
            result.selfTestDate = deviceStatus[6];
        }
        return result;
    };

    _parseDeviceSetSetting = (parseResultRequest) => {
        return parseResultRequest.setSettings;
    };

    _parseDeviceGetSetting = (parseResultRequest) => {
        const requestedSettings = parseResultRequest.requestedSettings;
        const receivedSettings = parseResultRequest.receivedSettings;
        const result = {};
        for (let index = 0; index < requestedSettings.length; index++) {
            if (requestedSettings[index] === GetSettingType.SERIAL_NUMBER) {
                result.serialNumber = receivedSettings[GetSettingType.SERIAL_NUMBER];
            } else if (requestedSettings[index] === GetSettingType.FRIENDLY_NAME) {
                result.friendlyName = receivedSettings[GetSettingType.FRIENDLY_NAME];
            } else if (requestedSettings[index] === GetSettingType.CONTACT_TEST_DISABLED) {
                result.contactTestDisabled = receivedSettings[GetSettingType.CONTACT_TEST_DISABLED];
            } else if (requestedSettings[index] === GetSettingType.DISPLAY_BRIGHTNESS) {
                result.displayBrightness = receivedSettings[GetSettingType.DISPLAY_BRIGHTNESS];
            } else if (requestedSettings[index] === GetSettingType.DISPLAY_KILOGRAMS) {
                result.displayKilograms = receivedSettings[GetSettingType.DISPLAY_KILOGRAMS];
            } else if (requestedSettings[index] === GetSettingType.WEIGHT_DISPLAY_DISABLED) {
                result.weightDisplayDisabled = receivedSettings[GetSettingType.WEIGHT_DISPLAY_DISABLED];
            }
        }
        return result;
    };

    _parseRunSelfTest = (parseResultRequest, isSOZOProDevice) => {
        const deviceStatus = parseResultRequest.selfTestResult;
        const selfTestStatus = this._parseSelfTestStatus(isSOZOProDevice, deviceStatus);
        return selfTestStatus;
    };

    _parseGetFrequency = (parseResultRequest) => {
        let result = {};
        result = parseResultRequest.frequencyList;
        return result;
    };

    _getBodySegment = (driveChannel, senseChannel) => {
        switch (driveChannel) {
        case 1:
            switch (senseChannel) {
            case 1:
                return "ARM";
            case 2:
                return "TRUNK";
            case 3:
                return "LEG";
            case 4:
                return "WHOLE_BODY";
            default:
                return null;
            }

        case 2:
            switch (senseChannel) {
            case 1:
                return "ARM";
            case 2:
                return "WHOLE_BODY";
            case 3:
                return "LEG";
            case 4:
                return "TRUNK";
            default:
                return null;
            }
        default:
            return null;
        }
    };

    _parseGetMeasurementResult = (parseResultRequest) => {
        const resultMap = {
            R_ARM: [],
            R_TRUNK: [],
            R_LEG: [],
            R_WHOLE_BODY: [],
            L_ARM: [],
            L_LEG: [],
            L_TRUNK: [],
            L_WHOLE_BODY: []
        };

        // Parsing Sense Channel List
        const senseChannelsExt = parseResultRequest.measurementResult[5];
        const senseChannelsList = [];
        for (let index = 0; index < senseChannelsExt.length; index++) {
            senseChannelsList[index] = senseChannelsExt[index].data[0];
        }

        const resultData = parseResultRequest.measurementResult[9];

        const createSozoResult = (frequency, driveChannel, senseChannel, resistance, reactance) => {
            return {
                frequency,
                driveChannel: driveChannel.toString(),
                senseChannel: senseChannel.toString(),
                r: resistance,
                xc: reactance,
                z: Math.sqrt(resistance * resistance + reactance * reactance),
                phi: Math.atan2(reactance, resistance) * 180 / Math.PI
            };
        };

        for (let index = 0; index < resultData.length; index++) {
            const frequency = resultData[index][0];
            const driveChannel = resultData[index][1].data[0];
            const bodySegment = driveChannel === 1 ? "R_" : "L_";
            let senseChannelPosition = 0;

            for (let innerIndex = 2; innerIndex <= 5; innerIndex++) {
                const resistance = resultData[index][innerIndex][0];
                const reactance = resultData[index][innerIndex][1];
                const sozoResult = createSozoResult(frequency, driveChannel, senseChannelsList[senseChannelPosition], resistance, reactance);
                const bodySegmentValue = this._getBodySegment(driveChannel, senseChannelsList[senseChannelPosition]);
                resultMap[bodySegment + bodySegmentValue].push(sozoResult);
                senseChannelPosition += 1;
            }
        }
        return resultMap;
    };

    _parseGetWeightCalibration = (parseResultRequest) => {
        const sozoScaleLogs = parseResultRequest.weightCalibration;

        const mDefaultSozoScaleLog = {
            calibrationDate: -1,
            zeroWeight: -1,
            measuredCalibrationLowPoint: 0,
            measuredCalibrationHighPoint: 591802,
            referenceCalibrationLowPoint: 0,
            referenceCalibrationHighPoint: 100
        };
        const parsedSozoScaleLogs = [];
        sozoScaleLogs.sort((a, b) => new Date(a.calibrationDate) - new Date(b.calibrationDate));

        for (let index = 0; index < sozoScaleLogs.length; index++) {
            let sozoScaleLog;

            if (index === 0) {
                sozoScaleLog = mDefaultSozoScaleLog;
            } else {
                sozoScaleLog = sozoScaleLogs[parsedSozoScaleLogs.length - 1];
            }

            const rawRange =
                sozoScaleLog.measuredCalibrationHighPoint -
                sozoScaleLog.measuredCalibrationLowPoint;
            const refRange =
                sozoScaleLog.referenceCalibrationHighPoint -
                sozoScaleLog.referenceCalibrationLowPoint;

            const measuredLowPoint =
                this.utils.subtract(
                    (sozoScaleLogs[index].measuredCalibrationLowPoint - sozoScaleLog.measuredCalibrationLowPoint) * refRange / rawRange +
                    sozoScaleLog.referenceCalibrationLowPoint,
                    sozoScaleLogs[index].zeroWeight
                );

            const measuredHighPoint =
                this.utils.subtract(
                    (sozoScaleLogs[index].measuredCalibrationHighPoint - sozoScaleLog.measuredCalibrationLowPoint) * refRange / rawRange +
                    sozoScaleLog.referenceCalibrationLowPoint,
                    sozoScaleLogs[index].zeroWeight
                );

            const resultSozoScaleLog = {
                zeroWeight: this.utils.roundDecimals(sozoScaleLogs[index].zeroWeight),
                calibrationDate: sozoScaleLogs[index].calibrationDate * 1000,
                referenceCalibrationHighPoint: this.utils.roundDecimals(
                    sozoScaleLogs[index].referenceCalibrationHighPoint
                ),
                referenceCalibrationLowPoint: this.utils.roundDecimals(
                    sozoScaleLogs[index].referenceCalibrationLowPoint
                ),
                measuredCalibrationLowPoint: measuredLowPoint,
                measuredCalibrationHighPoint: measuredHighPoint
            };

            parsedSozoScaleLogs.push(resultSozoScaleLog);
        }
        return parsedSozoScaleLogs;
    };

    _parseScaleLog = (parseResultRequest) => {
        const scaleLogResponse = parseResultRequest.scaleLogResponse;
        const sozoResult = {
            calibrationDate: scaleLogResponse[5][1],
            zeroWeight: scaleLogResponse[5][2],
            measuredCalibrationLowPoint: scaleLogResponse[5][3],
            measuredCalibrationHighPoint: scaleLogResponse[5][4],
            referenceCalibrationLowPoint: scaleLogResponse[5][5],
            referenceCalibrationHighPoint: scaleLogResponse[5][6]
        };
        return sozoResult;
    };

    _parseScaleWeight = (parseResultRequest) => {
        const scaleWeight = parseResultRequest.scaleWeight;
        const scaledWeightFixed = parseFloat(scaleWeight).toFixed(2);
        return parseFloat(scaledWeightFixed);
    };

    _parseReacquireScaleWeight = (parseResultRequest) => {
        const scaleWeight = parseResultRequest.reacquireScaleWeight;
        const scaledWeightFixed = parseFloat(scaleWeight).toFixed(2);
        return parseFloat(scaledWeightFixed);
    };

    _parseSelfTestGetDetailedResult = (parseResultRequest) => {
        const selfTestDetailedResponse = parseResultRequest.selfTestDetailedResult;
        try {
            const numberOfGeneralErrors = selfTestDetailedResponse[1];
            const generalErrors = this._parseErrors(selfTestDetailedResponse[2]);
            const numberOfSelfTestErrors = selfTestDetailedResponse[3];
            const selfTestErrors = this._parseErrors(selfTestDetailedResponse[4]);

            const currentChannelArray = this._parseCurrentChannelArray(selfTestDetailedResponse[5]);

            const voltageChannelArrays = [];
            const voltageChannelByteArrayLength = selfTestDetailedResponse[6].length;
            for (let index = 0; index < voltageChannelByteArrayLength; index++) {
                const voltageChannelArrayLength = selfTestDetailedResponse[6][index].length;
                const voltageChannelArray = this._parseVoltageArray(selfTestDetailedResponse[6][index], voltageChannelArrayLength);
                voltageChannelArrays.push(voltageChannelArray);
            }
            const selfTestDetailedResult = {
                numberOfGeneralErrors,
                generalErrors,
                numberOfSelfTestErrors,
                selfTestErrors,
                currentChannelArray,
                voltageChannelArrays
            };
            return this.mapAllErrorsToSingleArray(selfTestDetailedResult);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    mapAllErrorsToSingleArray = (selfTestDetailedResult) => {
        if (selfTestDetailedResult) {
            const generalErrors = selfTestDetailedResult.generalErrors || [];
            const selfTestErrors = selfTestDetailedResult.selfTestErrors || [];

            const totalSize = generalErrors.length + selfTestErrors.length;
            const resultArray = new Array(totalSize);

            // Concatenate generalErrors and selfTestErrors arrays
            const allErrors = [...generalErrors, ...selfTestErrors];

            // Fill resultArray with error codes
            this._addErrors(allErrors, resultArray);

            return resultArray;
        } else {
            return [];
        }
    };

    _addErrors = (errors, resultArray) => {
        for (let i = 0; i < errors.length; i++) {
            resultArray[i] = errors[i].errorCode;
        }
    };

    _parseErrors = (selfTestDetailedResponse) => {
        const selfTestDetailedErrors = [];

        try {
            const errorArrayByteCode = selfTestDetailedResponse;
            const errorArrayLength = errorArrayByteCode.length;

            for (let index = 0; index < errorArrayLength; index++) {
                const eachErrorLength = errorArrayByteCode[index];

                if (eachErrorLength.length !== 2) {
                    throw new Error("Invalid arrary");
                }

                const errorCode = errorArrayByteCode[index][0];
                const message = errorArrayByteCode[index][1];

                const selfTestDetailedError = {
                    errorCode,
                    message
                };

                selfTestDetailedErrors.push(selfTestDetailedError);
            }
        } catch (error) {
            throw new Error(error.message);
        }

        return selfTestDetailedErrors;
    };

    _parseCurrentChannelArray = (currentChannelResponse) => {
        const currentChannelArray = [];
        const currentChannelByteArrayLength = currentChannelResponse.length;
        try {
            for (let index = 0; index < currentChannelByteArrayLength; index++) {
                const currentChannelHeaderArrayLength = currentChannelResponse[index].length;
                const currentChannelArrayObject = this._getCurrentChannelArray(
                    currentChannelHeaderArrayLength,
                    currentChannelResponse[index]
                );
                currentChannelArray.push(currentChannelArrayObject);
            }
        } catch (error) {
            throw new Error(error.message);
        }

        return currentChannelArray;
    };

    _getCurrentChannelArray = (currentChannelHeaderArrayLength, currentChannelResponse) => {
        const currentChannelArray = {};

        try {
            for (let innerIndex = 0; innerIndex < currentChannelHeaderArrayLength; innerIndex++) {
                const measurementArrayHeader = currentChannelResponse[innerIndex].length;
                if (measurementArrayHeader !== 4) {
                    throw new Error("Invalid Measurement Array Header");
                }

                const measurementArray = {
                    measurement: currentChannelResponse[innerIndex][0],
                    expectedValue: currentChannelResponse[innerIndex][1],
                    tolerance: currentChannelResponse[innerIndex][2],
                    passed: currentChannelResponse[innerIndex][3]
                };

                switch (innerIndex) {
                case 0:
                    currentChannelArray.frequencyCheck = measurementArray;
                    break;
                case 1:
                    currentChannelArray.internalCircuitMinVoltage = measurementArray;
                    break;
                case 2:
                    currentChannelArray.internalCircuitMaxVoltage = measurementArray;
                    break;
                case 3:
                    currentChannelArray.externalCircuitMinVoltage = measurementArray;
                    break;
                case 4:
                    currentChannelArray.externalCircuitMaxVoltage = measurementArray;
                    break;
                default:
                    break;
                }
            }
        } catch (error) {
            throw new Error(error.message);
        }

        return currentChannelArray;
    };

    _parseVoltageArray = (voltageChannelResponse, voltageChannelArrayLength) => {
        const voltageChannelArray = {};

        try {
            for (let innerIndex = 0; innerIndex < voltageChannelArrayLength; innerIndex++) {
                const measurementArrayHeader = voltageChannelResponse[innerIndex].length;
                if (measurementArrayHeader !== 4) {
                    throw new Error("Invalid Measurement Array Header");
                }

                const measurementArray = {
                    measurement: voltageChannelResponse[innerIndex][0],
                    expectedValue: voltageChannelResponse[innerIndex][1],
                    tolerance: voltageChannelResponse[innerIndex][2],
                    passed: voltageChannelResponse[innerIndex][3]
                };

                switch (innerIndex) {
                case 0:
                    voltageChannelArray.frequencyCheck = measurementArray;
                    break;
                case 1:
                    voltageChannelArray.peakToPeakVoltageMeasurement = measurementArray;
                    break;
                default:
                    break;
                }
            }
        } catch (error) {
            throw new Error(error.message);
        }

        return voltageChannelArray;
    };

    _parseSelfTestResult = (parseResultRequest) => {
        const selfTestResultExceptionArray = parseResultRequest.selfTestResultExceptionArray;
        let result = {};
        if (parseResultRequest.selfTestStatus) {
            result = {
                status: "Passed"
            };
        } else {
            result = {
                status: "Failed",
                codes: selfTestResultExceptionArray
            };
        }
        return result;
    };

    // Public Methods
    parseCommandResult = (parseResultRequest) => {
        switch (parseResultRequest.command) {
        case Commands.GET_DEVICE_STATUS: {
            return this._parseDeviceStatus(parseResultRequest, parseResultRequest.isSOZOProDevice);
        }
        case Commands.GET_DEVICE_SETTING: {
            return this._parseDeviceGetSetting(parseResultRequest);
        }
        case Commands.RUN_SELF_TEST: {
            return this._parseRunSelfTest(parseResultRequest, parseResultRequest.isSOZOProDevice);
        }
        case Commands.GET_FREQUENCY: {
            return this._parseGetFrequency(parseResultRequest);
        }
        case Commands.GET_MEASUREMENT_RESULT: {
            return this._parseGetMeasurementResult(parseResultRequest);
        }
        case Commands.GET_WEIGHT_CALIBRATION_HISTORY: {
            return this._parseGetWeightCalibration(parseResultRequest);
        }
        case Commands.GET_WEIGHT_CALIBRATION_HISTORY_SCALE_LOG: {
            return this._parseScaleLog(parseResultRequest);
        }
        case Commands.GET_SCALE_WEIGHT: {
            return this._parseScaleWeight(parseResultRequest);
        }
        case Commands.REACQUIRE_SCALE_WEIGHT: {
            return this._parseReacquireScaleWeight(parseResultRequest);
        }
        case Commands.SELF_TEST_GET_DETAILED_RESULT: {
            return this._parseSelfTestGetDetailedResult(parseResultRequest);
        }
        case Commands.RUN_SELF_TEST_RESULT: {
            return this._parseSelfTestResult(parseResultRequest);
        }
        default: {
            throw new Error("Result Parse Request doesn't have a command to parse");
        }
        }
    };
}

export default ResultParser;
