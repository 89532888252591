import PropTypes from "prop-types";

const LoginTitleView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <h1 className={"loginTitle"}>{props.mainTitle[0]}<sup>&reg;</sup>{props.mainTitle[1]}</h1>;

};

LoginTitleView.propTypes = {
	mainTitle:PropTypes.array
};

export default LoginTitleView;