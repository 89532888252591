import { Fragment } from "react";
import PropTypes from "prop-types";
import Label from "../Label/Label";
import Eye from "../SVGs/Eye/Eye";

/**
 * Generates a div element which includes a label and an input element.
 * For multiple input elements inside the same div pass as props
 * an inputs array of objects with properties:
 * {id,inputClasses,placeholder,value,onChange}
 * inputClasses must define the width of each input
 */
const InputView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	const getFields = (inputs) => {
		return inputs.map((input, i) =>
			<Fragment key={input.id + i}>
				<input type={props.type}
					id={input.id}
					className={`input__text ${props.withIcon && "input__text--icon"} ${props.inputClasses} ${input.inputClasses}`}
					name={props.name}
					placeholder={input.placeholder}
					disabled={props.disabled}
					value={input.value}
					autoComplete={props.autocomplete}
					onChange={input.onChange}
				/>
				{input.suffix && input.value !== "" && <p>{input.suffix}</p>}
			</Fragment>);
	};

	const getSharingList = () => {
		let sharingList = "";
		if (props.withSearchList && props.searchList.length > 0 && !props.isListHidden) {
			sharingList = <div ref={props.outsideClickSearchBoxRef} className="input__searchList">
				{
					props.searchList.map((item) => {
						return <div className={`input__searchList--list ${item.disabled && "disabled"} `} key={item.key}
									onClick={!item.disabled ? () => props.handleClickedSearchItem(item) : null}>
							{item.value}
						</div>;
					})
				}
			</div>;
		}
		return sharingList;
	};
	//endregion

	return <>
		<div className={`input ${props.withSearchList ? "input--withSearchList" : ""} ${props.containerClasses}`}>
			{props.withLabel && !props.labelInBox &&
				<Label classes={props.labelsSectionClasses} htmlFor={props.id} isRequired={props.isRequired} text={props.labelText} withRequiredTag={props.withRequiredTag} labelClasses={props.labelClasses} />}
			<div className={`input__textField flexColCenterStart ${props.withIcon && "input__textField--icon"} ${props.inputSectionClasses}`}>
				{props.withLabel && props.labelInBox &&
					<Label classes={props.labelsSectionClasses} htmlFor={props.id} isRequired={props.isRequired} text={props.labelText} withRequiredTag={props.withRequiredTag} />}
				<div className={"flexRowSpaceBetwnCenter input__textField--inner"}>
					{props.inputs.length > 0 ? <span className={"flexRowSpaceBetwnCenter input__inputs-wrapper"}>{getFields(props.inputs)}</span> :
						<>
							<input type={props.type === "password" ? props.passwordType : props.type}
								id={props.id}
								className={`input__text ${props.withIcon && "input__text--icon"} ${props.inputClasses}`}
								name={props.name}
								placeholder={props.placeholder}
								disabled={props.disabled}
								value={props.value}
								autoComplete={props.autocomplete}
								onChange={props.onChange}
								onBlur={props.onBlur}
								autoFocus={props.autoFocus}
								onFocus={props.onFocus}
								readOnly={props.readOnly}
								onKeyDown={props.handleOnKeyDown}
							/>
							{props.suffix && props.value !== "" && <p>{props.suffix}</p>}
						</>
					}
					{
						props.type === "password" &&
						<span className={"input__eyeButton"} onClick={(event) => props.togglePassword(event)}>
							<Eye show={props.passwordType === "password"} />
						</span>
					}
				</div>
			</div>
			{getSharingList()}
		</div>
	</>;

};

InputView.propTypes = {
	type: PropTypes.oneOf(["text", "password", "number"]),
	id: PropTypes.string,
	inputClasses: PropTypes.string,
	iconClasses: PropTypes.string,
	containerClasses: PropTypes.string,
	labelClasses: PropTypes.string,
	labelsSectionClasses: PropTypes.string,
	inputSectionClasses: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	labelText: PropTypes.string,
	suffix: PropTypes.string,
	disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	autocomplete: PropTypes.string,
	passwordType: PropTypes.string,
	isRequired: PropTypes.bool,
	withLabel: PropTypes.bool,
	labelInBox: PropTypes.bool,
	withIcon: PropTypes.bool,
	withRequiredTag: PropTypes.bool,
	onChange: PropTypes.func,
	inputs: PropTypes.array,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	togglePassword: PropTypes.func,
	autoFocus: PropTypes.bool,
	readOnly: PropTypes.bool,
	handleOnKeyDown: PropTypes.func,
	withSearchList: PropTypes.bool,
	searchList: PropTypes.array,
	handleClickedSearchItem: PropTypes.func,
	isListHidden: PropTypes.bool,
	outsideClickSearchBoxRef: PropTypes.object
};
InputView.defaultProps = {
	type: "text",
	id: "",
	inputClasses: "",
	iconClasses: "",
	containerClasses: "",
	labelClasses: "",
	labelsSectionClasses: "",
	inputSectionClasses: "",
	name: "",
	placeholder: "",
	labelText: "",
	suffix: "",
	disabled: false,
	value: "",
	autocomplete: "off",
	isRequired: false,
	withLabel: false,
	labelInBox: false,
	withIcon: false,
	withRequiredTag: false,
	withSearchList: false,
	isListHidden: true,
	searchList: [],
	onChange: () => { },
	onBlur: () => { },
	onFocus: () => { },
	handleOnKeyDown: () => { },
	handleClickedSearchItem: () => { },
	inputs: [],
	autoFocus: false,
	readOnly: false
};

export default InputView;