import {useEffect, useReducer} from "react";
import {store} from "../../Redux/configureStore";
import Utilities from "../Libraries/Custom/Utilities";

export const usePasswordProtection = () => {
	let hasMFA = store.getState().user.hasMFA;
	let [authData, setAuthData] = useReducer((state, newState) => ({...state, ...newState}), {
		password: "",
		mfa: ""
	});

	const handlePasswordChange = (event) => {
		if (event.target !== undefined && event.target.id === "password") {
			setAuthData({password: event.target.value});
		} else {
			setAuthData({mfa: event});
		}
	};

	const isValidPassword = (withMFAcheck=true) => {
		let isValid = true;
		if (authData.password === "") {
			Utilities.customSweetAlertSimple(dictionary[localStorage.language].error, dictionary[localStorage.language].emptyFieldsClinic, "error", false, "", dictionary[localStorage.language].ok);
			isValid = false;
		} else if (withMFAcheck && hasMFA && Utilities.isTotpInvalid(authData.mfa)) {
			Utilities.customSweetAlertSimple(dictionary[localStorage.language].error, dictionary[localStorage.language].invalidVerificationCode, "error", false, "", dictionary[localStorage.language].ok);
			isValid = false;
		}
		return isValid;
	};

	const clearAuthDataFields = () => {
		setAuthData({
			password: "",
			mfa: "",
		});
	};
	useEffect(() => {
		return () => {
			clearAuthDataFields();
		};
	}, []);

	return [authData, handlePasswordChange, isValidPassword, clearAuthDataFields];
};