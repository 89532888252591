const InfoIconView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className={"infoIcon"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="info" transform="translate(-440 -286)">
                <rect id="Path" width="24" height="24" transform="translate(440 286)" fill="rgba(0,0,0,0)"/>
                <path id="Icon" d="M452,308a10,10,0,1,1,10-10A10.011,10.011,0,0,1,452,308Zm0-18a8,8,0,1,0,8,8A8.009,8.009,0,0,0,452,290Zm1,13h-2v-6h2v6Zm0-8h-2v-2h2v2Z" fill="#fff"/>
            </g>
        </svg>
    </div>;

};

InfoIconView.propTypes = {};

export default InfoIconView;