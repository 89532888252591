import WarningIconView from "./WarningIconView";

const WarningIcon = (props) => {
    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <WarningIconView {...props}/>;
};

WarningIcon.propTypes = {};

export default WarningIcon;