import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.facility.main, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_FACILITY :
			delete action.payload.location;
			delete action.payload.license;
			delete action.payload.clinicITAdministrator;
			delete action.payload.clinician;
			delete action.payload.facilitySettings;
			nextState = {...nextState, ...action.payload};
			break;
			case actions.SAVE_FACILITY_SSO_STATUS :
			nextState.isSSO = action.payload;
			break;
		default:
			nextState = state;
	}
	return nextState;
};