import IpdIcon from "../SVGs/IpdIcon/IpdIcon";
import InstructionsIcon from "../SVGs/InstructionsIcon/InstructionsIcon";

const LoginIconsView = () => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"loginIcons"}>
		<a href={constants.impedimedLink} target="_blank" rel="noopener noreferrer">
			<IpdIcon/>
		</a>
		<InstructionsIcon/>
	</div>;

};

LoginIconsView.propTypes = {};

export default LoginIconsView;