import PropTypes from "prop-types";
import ThumbsUp from "../SVGs/ThumbsUp/ThumbsUp";

const SuccessLogoutPopupView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"flexColCenterStart successLogoutPopup popupCentered portal"}>
		<ThumbsUp/>
		<h2 className={"successLogoutPopup__title"}>{dictionary[localStorage.language].success}</h2>
		<p className={"successLogoutPopup__message"}>{props.msg}</p>
		<button className={"successLogoutPopup__button"} onClick={props.handleLogout}>{dictionary[localStorage.language].ok}</button>
	</div>;

};

SuccessLogoutPopupView.propTypes = {
	msg:PropTypes.string,
	handleLogout:PropTypes.func,
};

export default SuccessLogoutPopupView;