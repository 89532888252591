import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.user.main, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_USER :
			delete action.payload.settings;
			delete action.payload.session;
			nextState = {...nextState, ...action.payload};
			break;
		case actions.SAVE_USER_PRE_UPDATE_HAS_NOTICE :
			nextState.preUpdateHasNotice = action.payload;
			break;
		case actions.SAVE_USER_POST_UPDATE_HAS_NOTICE :
			nextState.postUpdateHasNoticePortal = action.payload;
			break;
		default:
			nextState = state;
	}
	return nextState;
};