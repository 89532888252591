import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.analytics, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_ANALYTICS:
            nextState.analyticsDashboardUrlIndex = action.payload;
            break;
        case actions.SAVE_ANALYTICS_IMPACTED:
            nextState.analyticsImpacted = action.payload;
            break;
        default:
            nextState = state;
    }
    return nextState;
};
