import PropTypes from "prop-types";
import {connect} from "react-redux";
import PasswordProtectionView from "./PasswordProtectionView";

const PasswordProtection = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <PasswordProtectionView
		authData={props.authData}
		handlePasswordChange={props.handlePasswordChange}
		hasMFA={props.hasMFA}
		passwordProtectionEnabled={props.passwordProtectionEnabled}
		small={props.small}
		withMFAcheck={props.withMFAcheck}
	/>;

};

PasswordProtection.propTypes = {
	handlePasswordChange: PropTypes.func,
	authData: PropTypes.object,
	hasMFA: PropTypes.bool,
	small: PropTypes.bool,
	passwordProtectionEnabled: PropTypes.bool,
	withMFAcheck: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	hasMFA: state.user.hasMFA,
});

export default connect(mapStateToProps, null)(PasswordProtection);