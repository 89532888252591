const GetSettingType = {
    SERIAL_NUMBER: 0x01,
    FRIENDLY_NAME: 0x02,
    WEIGHT_DISPLAY_DISABLED: 0x03,
    CONTACT_TEST_DISABLED: 0x04,
    DISPLAY_BRIGHTNESS: 0x05,
    DISPLAY_KILOGRAMS: 0x06
};

export default GetSettingType;
