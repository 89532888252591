import {useRef, useEffect, useState} from "react";

export const useVisible = (initialIsVisible) => {
	const [isVisible, setIsVisible] = useState(initialIsVisible);
	const ref = useRef(null);

	const handleClickOutside = (event) => {
		let exceptionsClasses = ["swal"];
		let shouldCloseOnClick = true;
		if (event.target.className !== undefined && event.target.className !== null) {
			for (let i = 0; i < exceptionsClasses.length; i++) {
				if (typeof event.target.className === "string" && event.target.className.includes(exceptionsClasses[i])) {
					shouldCloseOnClick = false;
					break;
				}
			}
		}

		if (ref.current && !ref.current.contains(event.target) && shouldCloseOnClick) {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

	return [ref, isVisible, setIsVisible];
};