import {useState, useEffect} from "react";

export const useKeyPress = function(targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = (event) => {
        if (event.key === targetKey) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            setKeyPressed(true);
        }
    };

    const upHandler = (event) => {
        if (event.key === targetKey && event.key !== " ") {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            setKeyPressed(false);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    });


    return keyPressed;
};