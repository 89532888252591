import { useState } from "react";
import PropTypes from "prop-types";
import InputCodeView from "./InputCodeView";

const InputCode = (props) => {
    //region VARIABLES
    let values, setValues;
    //endregion

    //region FUNCTIONS
    [values, setValues] = useState(Array(props.inputLength).fill(""));

    const handleInputCodeChange = (event) => {
        let newValues = [...values];
        if (/\d/.test(event.target.value)) {
            newValues[event.target.getAttribute("data-key")] = event.target.value;

            props.setCode(newValues.join(""));
            setValues(newValues);

            if (event.target.value !== "" && event.target.nextSibling !== null) {
                event.target.nextSibling.focus();
                event.target.nextSibling.select();
            }
        }
    };

    const onBackspace = (event) => {
        if (event.keyCode === 8) {
            let newValues = [...values];
            newValues[event.currentTarget.getAttribute("data-key")] = "";
            setValues(newValues);
            props.setCode(newValues.join(""));

            let previousInput = event.target.previousSibling;
            if (previousInput !== null) {
                previousInput.focus();
                previousInput.select();
            }
        }
    };
    //endregion

    return <InputCodeView handleInputChange={handleInputCodeChange}
                          onBackspace={onBackspace}
                          values={values}
                          {...props}/>;
};

InputCode.propTypes = {
    setCode: PropTypes.func,
    handleInputChange: PropTypes.func,
    inputValues: PropTypes.string,
    inputLength: PropTypes.number
};

export default InputCode;