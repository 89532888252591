import PropTypes from "prop-types";

const PasswordPolicyView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <span className={"link passwordPolicy"}
				 onMouseEnter={() => props.handleTooltipDisplay(false)}
				 onMouseLeave={() => props.handleTooltipDisplay(true)}>
                            {dictionary[localStorage.language].passwordPolicy}
		<p className={"passwordPolicy__tooltip"} hidden={props.tooltipHidden}>
                                <span className={"passwordPolicy__title"}>{dictionary[localStorage.language].invalidPassword.firstLine}</span><br/>
			{dictionary[localStorage.language].invalidPassword.oneNumber}<br/>
			{dictionary[localStorage.language].invalidPassword.oneSpecial}<br/>
			{dictionary[localStorage.language].invalidPassword.oneUpper}<br/>
			{dictionary[localStorage.language].invalidPassword.oneLower}<br/>
			{dictionary[localStorage.language].invalidPassword.history}
                            </p>
                        </span>;

};

PasswordPolicyView.propTypes = {
	handleTooltipDisplay: PropTypes.func,
	tooltipHidden: PropTypes.boool,
};

export default PasswordPolicyView;