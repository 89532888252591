import PropTypes from "prop-types";
import Input from "../Input/Input";
import InputCode from "../InputCode/InputCode";

const PasswordProtectionView = (props) => {

	//region VARIABLES
	let disabledClass = props.passwordProtectionEnabled ? "" : "disabled";
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={`passwordProtection ${props.small && "passwordProtection--small"}`}>
		<Input
			id={"password"}
			type={"password"}
			inputSectionClasses={`passwordProtection__password ${disabledClass}`}
			labelText={dictionary[localStorage.language].password}
			labelsSectionClasses={disabledClass}
			inputClasses={disabledClass}
			isRequired={true}
			withLabel={true}
			withRequiredTag={true}
			value={props.authData.password}
			onChange={props.handlePasswordChange}
			disabled={!props.passwordProtectionEnabled}
		/>

		{props.withMFAcheck && props.hasMFA ? <div className={"passwordProtection__mfaSection"}>
			<label className={"labels__label"}>{dictionary[localStorage.language].enterMfaCode}</label>
			<InputCode inputLength={6} setCode={props.handlePasswordChange} classes={"authentication__mfaCodeInput passwordProtection__mfa"}/>
		</div> : ""}
	</div>;

};

PasswordProtectionView.defaultProps = {
	small: false,
	withMFAcheck: true,
};

PasswordProtectionView.propTypes = {
	handlePasswordChange: PropTypes.func,
	authData: PropTypes.object,
	hasMFA: PropTypes.bool,
	passwordProtectionEnabled: PropTypes.bool,
	small: PropTypes.bool,
	withMFAcheck: PropTypes.bool,
};

export default PasswordProtectionView;