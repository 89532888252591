import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Utilities from "../../Libraries/Custom/Utilities";
import {saveCurrentOS} from "../../../Redux/actions/Generic/currentOSActions";
import DeviceApi from "../../Libraries/Custom/DeviceApi/deviceApi";
import {useHistory} from "react-router";
import {saveSOZODeviceCommEnabled} from "../../../Redux/actions/Generic/sozoDeviceCommEnabledActions";
import BuildInfoView from "./BuildInfoView";
import {saveApplicationBuild} from "../../../Redux/actions/Generic/applicationBuildActions";

const BuildInfo = (props) => {
    //region VARIABLES
    const [devicePort, setDevicePort] = useState(null);
    const history = useHistory();
    //endregion

    //region FUNCTIONS
    const handleMessage = async (event) => {
        const validOrigin = Utilities.validateOrigin(event.target.location.origin);
        const isEventDevicePort = event.data === "DEVICE_PORT";

        if (validOrigin && isEventDevicePort) {
            const _devicePort = event.ports[0];
            const callFunction = {
                name: "setPortReceived",
                data: "Device Port Received"
            };

            if (_devicePort) {
                _devicePort.postMessage(JSON.stringify(callFunction));
                devicePort === null && setDevicePort(_devicePort);
            }
        }
    };

    useEffect(() => {
        // Execute again the application build info retrieval only when it hasn't been retrieved before.
        if (props.applicationBuild.versionName === "") {
            const currentOS = Utilities.getCurrentOS();
            if (currentOS.isAndroid) {
                // Add nativeShell query parameter to the path in order to be able to retrieve the devicePort and get the applicationBuildDate
                history.push({
                    pathname: history.location.pathname,
                    search: history.location.search ? history.location.search + "&nativeShell=true" : "?nativeShell=true"
                });
            }
            props.saveCurrentOS(currentOS);
        }

        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, []);

    useEffect(() => {
        const retrieveApplicationBuildDate = async () => {
            const selectedDevice = {...props.selectedDevice, port: devicePort, currentOS: props.currentOS};
            const applicationBuildDateConfiguration = await new DeviceApi(selectedDevice).getApplicationBuildConfiguration();

            const applicationBuildDateConfigurationObject = JSON.parse(applicationBuildDateConfiguration);

            props.saveApplicationBuild({
                buildDate: applicationBuildDateConfigurationObject.buildDate ? applicationBuildDateConfigurationObject.buildDate / 1000 : "",
                versionCode: applicationBuildDateConfigurationObject.versionCode,
                versionName: applicationBuildDateConfigurationObject.versionName
            });
        };

        // Execute again the application build info retrieval only when it hasn't been retrieved before.
        if (props.applicationBuild.versionName === "") {
            const sozoDeviceCommEnabled = Utilities.isSOZODeviceCommEnabled(devicePort);
            props.saveSOZODeviceCommEnabled(sozoDeviceCommEnabled);

            if (!props.currentOS.isWindows && sozoDeviceCommEnabled) {
                retrieveApplicationBuildDate();
            }
        }
    }, [devicePort, props.currentOS]);
    //endregion

    return <BuildInfoView applicationBuild={props.applicationBuild}
                          currentOS={props.currentOS}
                          sozoDeviceCommEnabled={props.sozoDeviceCommEnabled}/>;
};

BuildInfo.propTypes = {
    currentOS: PropTypes.object,
    selectedDevice: PropTypes.object,
    sozoDeviceCommEnabled: PropTypes.bool,
    applicationBuild: PropTypes.object,
    saveCurrentOS: PropTypes.func,
    saveSOZODeviceCommEnabled: PropTypes.func,
    saveApplicationBuild: PropTypes.func
};

const mapStateToProps = (state) => ({
    currentOS: state.currentOS,
    selectedDevice: state.selectedDevice,
    sozoDeviceCommEnabled: state.sozoDeviceCommEnabled,
    applicationBuild: state.applicationBuild,
});

const mapDispatchToProps = (dispatch) => ({
    saveCurrentOS: (data) => dispatch(saveCurrentOS(data)),
    saveSOZODeviceCommEnabled: (data) => dispatch(saveSOZODeviceCommEnabled(data)),
    saveApplicationBuild: (data) => dispatch(saveApplicationBuild(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildInfo);