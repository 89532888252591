import LoginIconsView from "./LoginIconsView";

const LoginIcons = () => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <LoginIconsView/>;

};

LoginIcons.propTypes = {};

export default LoginIcons;