const UnsupportedResolutionImageView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={"unsupportedResolutionImage"}>
            <svg xmlns="http://www.w3.org/2000/svg" className={"unsupportedResolutionImage__svg"} viewBox="0 0 633.178 439.49">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_18116" data-name="Rectangle 18116" width="633.178" height="439.49" transform="translate(0 0)" fill="none" />
                    </clipPath>
                </defs>
                <g id="Group_11163" data-name="Group 11163" transform="translate(0 0.003)">
                    <g id="Group_11162" data-name="Group 11162" transform="translate(0 -0.003)">
                        <path id="Path_12551" data-name="Path 12551" d="M1304.931,407.64V586.773a14.223,14.223,0,0,0,14.181,14.181h285.03a14.223,14.223,0,0,0,14.181-14.181V407.64a14.223,14.223,0,0,0-14.181-14.181h-285.03a14.223,14.223,0,0,0-14.181,14.181" transform="translate(-987.66 -297.796)" fill="#fff" />
                        <path id="Path_12552" data-name="Path 12552" d="M1302.728,585.1V405.972a14.734,14.734,0,0,1,14.717-14.717h285.03a14.734,14.734,0,0,1,14.717,14.717V585.1a14.734,14.734,0,0,1-14.717,14.717h-285.03a14.734,14.734,0,0,1-14.717-14.717m313.393-179.132a13.661,13.661,0,0,0-13.646-13.646h-285.03a13.661,13.661,0,0,0-13.646,13.646V585.1a13.661,13.661,0,0,0,13.646,13.646h285.03a13.661,13.661,0,0,0,13.646-13.646Z" transform="translate(-985.992 -296.127)" />
                        <path id="Path_12553" data-name="Path 12553" d="M1345.851,438.912V608.038a10.778,10.778,0,0,0,10.746,10.746h272.867a10.778,10.778,0,0,0,10.746-10.746V438.912a10.778,10.778,0,0,0-10.746-10.746H1356.6a10.778,10.778,0,0,0-10.746,10.746" transform="translate(-1018.631 -324.064)" fill="#fff" />
                        <path id="Path_12554" data-name="Path 12554" d="M1343.648,606.37V437.245a11.3,11.3,0,0,1,11.282-11.282H1627.8a11.3,11.3,0,0,1,11.282,11.282V606.37a11.3,11.3,0,0,1-11.282,11.283H1354.93a11.3,11.3,0,0,1-11.282-11.283m294.36-169.125a10.222,10.222,0,0,0-10.211-10.211H1354.93a10.222,10.222,0,0,0-10.211,10.211V606.37a10.222,10.222,0,0,0,10.211,10.211H1627.8a10.223,10.223,0,0,0,10.211-10.211Z" transform="translate(-1016.963 -322.397)" />
                        <path id="Path_12555" data-name="Path 12555" d="M2517.871,777.92V743.734a.536.536,0,0,1,1.071,0V777.92a.536.536,0,1,1-1.071,0" transform="translate(-1905.694 -562.502)" />
                        <rect id="Rectangle_18106" data-name="Rectangle 18106" width="91.647" height="155.226" transform="translate(489.36 121.798)" fill="#e6e6e6" />
                        <path id="Path_12556" data-name="Path 12556" d="M1506.592,656.176h15.443a7.336,7.336,0,0,0,7.336-7.336V508.286a7.336,7.336,0,0,0-7.336-7.336h-15.443a7.336,7.336,0,0,0-7.336,7.336V648.84a7.336,7.336,0,0,0,7.336,7.336" transform="translate(-1134.738 -379.152)" fill="#e6e6e6" />
                        <rect id="Rectangle_18107" data-name="Rectangle 18107" width="57.81" height="155.705" transform="translate(413.522 121.319)" fill="#fff" />
                        <path id="Path_12557" data-name="Path 12557" d="M1698.6,653.017v-155.7a.536.536,0,0,1,.536-.536h57.81a.535.535,0,0,1,.536.536v155.7a.536.536,0,0,1-.536.536h-57.81a.536.536,0,0,1-.536-.536m1.072-.536h56.738V497.848h-56.738Z" transform="translate(-1285.616 -375.994)" fill="#e2eaff" />
                        <path id="Path_12558" data-name="Path 12558" d="M1866.582,848.182V816.912a.536.536,0,1,1,1.072,0v31.271a.536.536,0,1,1-1.072,0" transform="translate(-1412.755 -617.888)" />
                        <path id="Path_12559" data-name="Path 12559" d="M1866.582,735.23V718.989a.536.536,0,0,1,1.072,0V735.23a.536.536,0,1,1-1.072,0" transform="translate(-1412.755 -543.773)" />
                        <path id="Path_12560" data-name="Path 12560" d="M1821.917,916.847v-9.213a.536.536,0,1,1,1.071,0v9.213a.536.536,0,1,1-1.071,0" transform="translate(-1378.949 -686.552)" />
                        <path id="Path_12561" data-name="Path 12561" d="M1821.917,752.985v-34a.536.536,0,1,1,1.071,0v34a.536.536,0,1,1-1.071,0" transform="translate(-1378.949 -543.772)" />
                        <path id="Path_12562" data-name="Path 12562" d="M1777.251,864.953V839.07a.536.536,0,1,1,1.071,0v25.883a.536.536,0,1,1-1.071,0" transform="translate(-1345.143 -634.658)" />
                        <path id="Path_12563" data-name="Path 12563" d="M1777.251,736.461V718.989a.536.536,0,0,1,1.071,0v17.473a.536.536,0,1,1-1.071,0" transform="translate(-1345.143 -543.773)" />
                        <path id="Path_12564" data-name="Path 12564" d="M1773.381,550.5a15.526,15.526,0,1,0,15.526,15.526,15.526,15.526,0,0,0-15.526-15.526" transform="translate(-1330.464 -416.654)" fill="#e2eaff" />
                        <path id="Path_12565" data-name="Path 12565" d="M856.611,860.828H448.5V619.513a13.079,13.079,0,0,1,13.079-13.079H843.533a13.079,13.079,0,0,1,13.079,13.079Z" transform="translate(-339.457 -458.989)" fill="#fff" />
                        <path id="Path_12566" data-name="Path 12566" d="M854.943,859.7H446.833a.536.536,0,0,1-.536-.536V617.844a13.63,13.63,0,0,1,13.615-13.614H841.864a13.63,13.63,0,0,1,13.615,13.614V859.16a.536.536,0,0,1-.536.536m-407.574-1.072H854.407V617.844A12.557,12.557,0,0,0,841.864,605.3H459.912a12.557,12.557,0,0,0-12.543,12.543Z" transform="translate(-337.788 -457.321)" />
                        <path id="Path_12567" data-name="Path 12567" d="M881.6,858.517H498.756a.536.536,0,0,1-.536-.536V650.2a.536.536,0,1,1,1.072,0V857.446H881.6a.536.536,0,1,1,0,1.072" transform="translate(-377.086 -491.707)" />
                        <path id="Path_12568" data-name="Path 12568" d="M882.725,858.572a.536.536,0,0,1-.536-.536V650.824H500.22a.536.536,0,1,1,0-1.071H882.725a.536.536,0,0,1,.536.536V858.036a.536.536,0,0,1-.536.536" transform="translate(-378.194 -491.776)" />
                        <path id="Path_12569" data-name="Path 12569" d="M691.56,1605.64H269.873c-19.3,0-37.037-8.265-46.191-21.519-3.092-4.477-4.293-7.512,1.148-7.512H735.992c5.191,0,6.023.362,3.434,4.862-8.464,14.708-27.2,24.17-47.866,24.17" transform="translate(-167.31 -1193.283)" fill="#fff" />
                        <path id="Path_12570" data-name="Path 12570" d="M689.888,1604.508H268.2c-19.5,0-37.367-8.334-46.632-21.751-2.067-2.993-3.278-5.3-2.469-6.843.532-1.016,1.86-1.509,4.057-1.509H734.321c2.817,0,4.572.077,5.183,1.134.554.958-.052,2.387-1.285,4.531-8.544,14.845-27.515,24.438-48.33,24.438m-466.73-29.031c-1.737,0-2.783.314-3.109.935-.6,1.138.97,3.663,2.4,5.736,9.069,13.131,26.6,21.288,45.751,21.288H689.888c20.439,0,39.045-9.382,47.4-23.9.448-.779,1.64-2.849,1.287-3.46-.32-.553-2.2-.6-4.256-.6Z" transform="translate(-165.638 -1191.615)" />
                        <rect id="Rectangle_18108" data-name="Rectangle 18108" width="333.358" height="69.627" transform="translate(146.964 181.873)" fill="#e6e6e6" />
                        <path id="Path_12571" data-name="Path 12571" d="M939.5,1347.712V1335.98a5.573,5.573,0,0,0-5.574-5.574h-323.9a5.574,5.574,0,0,0-5.574,5.574v11.732a5.574,5.574,0,0,0,5.574,5.574h323.9a5.573,5.573,0,0,0,5.574-5.574" transform="translate(-457.494 -1006.94)" fill="#e6e6e6" />
                        <rect id="Rectangle_18109" data-name="Rectangle 18109" width="332.202" height="43.92" transform="translate(146.964 265.196)" fill="#fff" />
                        <path id="Path_12572" data-name="Path 12572" d="M934.992,1133.532H602.79a.536.536,0,0,1-.536-.536v-43.919a.536.536,0,0,1,.536-.536h332.2a.536.536,0,0,1,.536.536V1133a.536.536,0,0,1-.536.536m-331.666-1.071h331.13v-42.848H603.326Z" transform="translate(-455.826 -823.88)" fill="#e6e6e6" />
                        <path id="Path_12573" data-name="Path 12573" d="M1199.53,1142.634H974.307a.536.536,0,1,1,0-1.072H1199.53a.536.536,0,1,1,0,1.072" transform="translate(-737.015 -864.01)" />
                        <path id="Path_12574" data-name="Path 12574" d="M811.756,1142.634H771.2a.536.536,0,1,1,0-1.072h40.553a.536.536,0,1,1,0,1.072" transform="translate(-583.293 -864.01)" />
                        <path id="Path_12575" data-name="Path 12575" d="M1670.3,1176.568h-74a.536.536,0,1,1,0-1.072h74a.536.536,0,1,1,0,1.072" transform="translate(-1207.786 -889.694)" />
                        <path id="Path_12576" data-name="Path 12576" d="M962.373,1176.568H771.2a.536.536,0,1,1,0-1.072h191.17a.536.536,0,1,1,0,1.072" transform="translate(-583.293 -889.694)" />
                        <path id="Path_12577" data-name="Path 12577" d="M1344.521,1210.5H1165.873a.536.536,0,1,1,0-1.071h178.648a.536.536,0,1,1,0,1.071" transform="translate(-882.005 -915.377)" />
                        <path id="Path_12578" data-name="Path 12578" d="M858.316,1210.5H771.2a.536.536,0,1,1,0-1.071h87.113a.536.536,0,1,1,0,1.071" transform="translate(-583.293 -915.377)" />
                        <path id="Path_12579" data-name="Path 12579" d="M643.6,1142.81a11.8,11.8,0,1,0,11.8-11.8,11.8,11.8,0,0,0-11.8,11.8" transform="translate(-487.118 -856.028)" fill="#e6e6e6" />
                        <path id="Path_12580" data-name="Path 12580" d="M129.384,842.7A129.421,129.421,0,0,1,16.509,650.026a.536.536,0,1,1,.934.525A128.347,128.347,0,0,0,193.758,824.333a.536.536,0,1,1,.538.926A129.321,129.321,0,0,1,129.384,842.7" transform="translate(0 -491.776)" />
                        <path id="Path_12581" data-name="Path 12581" d="M769.722,1372.428a.534.534,0,0,1-.533-.6l.939-7.809-7.809-.939a.536.536,0,0,1,.128-1.064l8.341,1a.536.536,0,0,1,.468.6l-1,8.341a.536.536,0,0,1-.531.472" transform="translate(-576.618 -1030.862)" />
                        <path id="Path_12582" data-name="Path 12582" d="M1991.788,1498.932a97.809,97.809,0,0,1-58.876-19.5.536.536,0,1,1,.641-.859,97.559,97.559,0,0,0,155.787-78.269.536.536,0,0,1,1.072,0,98.734,98.734,0,0,1-98.622,98.623" transform="translate(-1462.795 -1059.443)" />
                        <path id="Path_12583" data-name="Path 12583" d="M2563.466,1406.757a.535.535,0,0,1-.4-.181l-5.2-5.9-5.9,5.2a.536.536,0,1,1-.709-.8l6.3-5.558a.536.536,0,0,1,.756.047l5.559,6.3a.536.536,0,0,1-.4.891" transform="translate(-1930.823 -1059.149)" />
                        <path id="Path_12584" data-name="Path 12584" d="M1856.8,137.987a1.617,1.617,0,0,0-2.013-1c.255-2.166-4.924-.53-4.924-.53,1.324-2.223-1.072-1.309-5.043-.212s-9.468-1.256-13.382,1.468c-1.81,1.259-17.5,22.622-17.5,22.622l-10.055,12.826c6.464,10.9,20.48.032,25.108-2.722,5.1-9.239,9.99-20.394,10.92-22.2,1.431-2.778,2.6-3.846,5.965-4.493s9.23-1.474,9.506-2.711c.207-.926-1.067-.949-1.067-.949s3.013-.641,2.488-2.1" transform="translate(-1365.298 -102.243)" fill="#fff" />
                        <path id="Path_12585" data-name="Path 12585" d="M1898.159,156.1a.536.536,0,0,1-.487-.759c.135-.294,3.307-7.221,4.231-9.016,1.406-2.728,2.655-4.065,6.341-4.774.541-.1,1.146-.212,1.782-.327,2.26-.406,6.961-1.25,7.306-1.987,0-.023.014-.086-.005-.111a.936.936,0,0,0-.554-.172.536.536,0,0,1-.1-1.06,3.767,3.767,0,0,0,2.074-1.054.372.372,0,0,0,.022-.336,1.06,1.06,0,0,0-1.354-.674.536.536,0,0,1-.687-.576c.026-.223-.048-.285-.088-.318-.566-.467-2.807-.061-4.143.361a.536.536,0,0,1-.622-.785,2.645,2.645,0,0,0,.27-.558,16.348,16.348,0,0,0-3.425.776c-.406.115-.835.237-1.284.361a16.92,16.92,0,0,1-5.511.222c-2.715-.173-5.523-.352-7.707,1.169-.427.37-4.6,5.606-6.172,7.577l-.67.84a.536.536,0,0,1-.837-.669l.669-.839c4.466-5.6,6.08-7.568,6.4-7.789,2.491-1.734,5.489-1.545,8.388-1.358a16.243,16.243,0,0,0,5.158-.186c.447-.124.873-.244,1.277-.359,2.707-.768,4.065-1.153,4.627-.422a.938.938,0,0,1,.176.708c1.263-.3,3.214-.6,4.092.125a1.3,1.3,0,0,1,.413.6,2.016,2.016,0,0,1,2.041,1.429,1.439,1.439,0,0,1-.094,1.22,3.076,3.076,0,0,1-1.475,1.154,1.181,1.181,0,0,1,.167.968c-.266,1.194-2.623,1.8-8.159,2.8-.631.114-1.232.221-1.769.325-3.248.624-4.244,1.6-5.59,4.212-.913,1.773-4.177,8.9-4.21,8.972a.536.536,0,0,1-.487.313" transform="translate(-1427.769 -100.578)" />
                        <path id="Path_12586" data-name="Path 12586" d="M1972.059,141.332a.536.536,0,0,1-.151-1.05,19.145,19.145,0,0,0,4.608-2.039.536.536,0,0,1,.559.915,20.243,20.243,0,0,1-4.866,2.153.543.543,0,0,1-.151.021" transform="translate(-1492.181 -104.571)" />
                        <path id="Path_12587" data-name="Path 12587" d="M1980.594,144.617a.536.536,0,0,1-.158-1.048,57.817,57.817,0,0,0,6.669-2.519,1.365,1.365,0,0,0,.42-.245.185.185,0,0,0,.05-.091.523.523,0,0,1,.293-.688.544.544,0,0,1,.7.3,1.146,1.146,0,0,1-.267,1.212,2.268,2.268,0,0,1-.759.486,58.938,58.938,0,0,1-6.792,2.566.534.534,0,0,1-.158.024" transform="translate(-1498.642 -105.951)" />
                        <path id="Path_12588" data-name="Path 12588" d="M1983.636,155.881a.536.536,0,0,1-.087-1.064,38.524,38.524,0,0,0,6.4-1.637.536.536,0,0,1,.354,1.012,39.627,39.627,0,0,1-6.574,1.682.522.522,0,0,1-.088.007" transform="translate(-1500.944 -115.913)" />
                        <path id="Path_12589" data-name="Path 12589" d="M1544.308,75.04c.7,2.463-1.195,4.225-1.4,6.074-.209,1.874,1.3,2.882.422,5.931-.858,2.986-3.17,2.633-4.22,4.182-1.017,1.5-1.395,3.692-2.736,4.968-1.293,1.23-3.371,1.391-4.924,2.36-1.493.931-2.635,2.8-4.332,3.439-1.626.617-3.772-.415-5.553-.1s-2.558,2.162-4.284,2.162c-3.466,0-3.265-1.735-4.958-2.111-1.76-.391-4.332.875-6.546-.1-2.548-1.125-1.766-3.2-3.177-4.273-1.455-1.112-3.514-.258-5.276-2.8-1.642-2.366-.756-4.1-1.511-5.8-.745-1.682-3.043-2.675-3.063-4.6-.031-2.954,1.58-3.714,1.787-5.563.209-1.874-1.056-4.048-.538-5.853.684-2.384,3.206-2.483,4.256-4.032,1.018-1.5.539-3.406,2.01-5.3,1.326-1.706,4.064-1.08,5.617-2.049,1.493-.931,1.3-2.917,3.639-3.751,1.99-.711,3.917.595,5.7.284s2.506-2.131,4.885-2.131c2.667,0,3.205,1.961,4.9,2.337,1.76.391,3.227-.893,5.6.018,2.59.993,2.153,3.229,3.564,4.307,1.455,1.112,4.013.995,5.148,2.409,1.154,1.437.636,3.958,1.494,5.614,1.107,2.137,2.741,1.717,3.5,4.376" transform="translate(-1129.814 -42.368)" />
                        <path id="Path_12590" data-name="Path 12590" d="M1616.187,194.352l5.48,15.947,9.8,19.776.163-24.738-2.163-.027-2.781-9.233Z" transform="translate(-1223.239 -147.098)" fill="#fff" />
                        <path id="Path_12591" data-name="Path 12591" d="M1625.972,143.1a8.378,8.378,0,0,1-.177,6.211c-1.877,3.612-8.737,1.834-9.9.366a2.941,2.941,0,1,1-1.194-5.332c-.865-1.348-1.679-5.454,1.093-6.767,3.09-1.463,2.942-3.571,4.279-3.983.874-.269.838-.226,1.372.476q.611.8,1.164,1.651a27.516,27.516,0,0,1,3.362,7.378" transform="translate(-1219.584 -100.998)" fill="#fff" />
                        <path id="Path_12592" data-name="Path 12592" d="M1619.711,150.318a9.645,9.645,0,0,1-5.62-1.669,3.842,3.842,0,0,1-3.729-.681,3.368,3.368,0,0,1-1.1-3.455,3.483,3.483,0,0,1,2.925-2.32,7.1,7.1,0,0,1-.3-4.317,3.734,3.734,0,0,1,2.011-2.434,6.214,6.214,0,0,0,2.826-2.572,2.93,2.93,0,0,1,1.525-1.439c1.015-.313,1.276-.236,1.867.546l.089.118c.42.553.819,1.119,1.186,1.684a28,28,0,0,1,3.428,7.522c.772,2.679.7,4.84-.217,6.605a4.746,4.746,0,0,1-4.035,2.373c-.282.027-.569.04-.856.04m-5.484-2.829a.536.536,0,0,1,.42.2c.6.759,3.369,1.753,5.817,1.52a3.822,3.822,0,0,0,3.186-1.8c.785-1.511.83-3.413.138-5.816a26.937,26.937,0,0,0-3.3-7.234c-.353-.542-.737-1.087-1.141-1.619l-.091-.121c-.085-.112-.178-.236-.233-.294a4.586,4.586,0,0,0-.463.127c-.352.108-.565.416-.942,1a7.229,7.229,0,0,1-3.267,2.958,2.659,2.659,0,0,0-1.44,1.761,6.015,6.015,0,0,0,.568,4.232.536.536,0,0,1,.007.567.53.53,0,0,1-.506.256,2.585,2.585,0,0,0-2.682,1.549,2.316,2.316,0,0,0,.754,2.378,2.809,2.809,0,0,0,2.958.38.535.535,0,0,1,.212-.044" transform="translate(-1217.916 -99.345)" />
                        <path id="Path_12593" data-name="Path 12593" d="M1619.538,189.573a.535.535,0,0,1-.313-.1,3.375,3.375,0,0,0-1.913-.619.536.536,0,0,1,.008-1.072h.009a4.461,4.461,0,0,1,2.523.822.536.536,0,0,1-.314.97" transform="translate(-1223.692 -142.125)" />
                        <path id="Path_12594" data-name="Path 12594" d="M1604.079,383.718l4.224,13.511,29.5-2.3,1.327-16.125Z" transform="translate(-1214.075 -286.706)" fill="#fff" />
                        <path id="Path_12595" data-name="Path 12595" d="M1606.635,396.1a.536.536,0,0,1-.511-.376L1601.9,382.21a.536.536,0,0,1,.437-.691l35.05-4.912a.536.536,0,0,1,.608.575l-1.327,16.125a.536.536,0,0,1-.492.49l-29.5,2.3-.042,0m-3.524-13.6,3.908,12.5,28.618-2.229,1.234-15Z" transform="translate(-1212.407 -285.037)" />
                        <path id="Path_12596" data-name="Path 12596" d="M1473.8,254.97a8.371,8.371,0,0,0-7.7,6.6c-2.4,9.776-7.414,56.038-9.284,69.926a3.429,3.429,0,0,1-3.229,3.242c-3.977.027-10.214.837-10.025,5.673h25.024a4.42,4.42,0,0,0,4.419-4.42l7.465-56.722Z" transform="translate(-1092.585 -192.978)" fill="#fff" />
                        <path id="Path_12597" data-name="Path 12597" d="M1466.922,339.28H1441.9a.535.535,0,0,1-.535-.515,4.7,4.7,0,0,1,1.3-3.564c2.067-2.164,6.176-2.644,9.259-2.665a2.908,2.908,0,0,0,2.7-2.778c.547-4.063,1.362-10.891,2.306-18.8,2.229-18.671,5.281-44.242,6.989-51.187a8.975,8.975,0,0,1,8.208-7.01.524.524,0,0,1,.526.394l6.673,24.3a.539.539,0,0,1,.015.212l-7.461,56.687a4.961,4.961,0,0,1-4.954,4.921m-24.481-1.072h24.481a3.889,3.889,0,0,0,3.883-3.885.529.529,0,0,1,0-.07l7.451-56.613-6.527-23.771a7.754,7.754,0,0,0-6.778,6.162c-1.692,6.881-4.74,32.415-6.965,51.058-.944,7.91-1.76,14.741-2.308,18.813a3.962,3.962,0,0,1-3.756,3.706c-4.066.028-7.081.857-8.491,2.333a3.521,3.521,0,0,0-.994,2.267" transform="translate(-1090.917 -191.307)" />
                        <path id="Path_12598" data-name="Path 12598" d="M1483.274,589.458a.536.536,0,0,1-.487-.312,2.12,2.12,0,0,1,.725-2.448,5.167,5.167,0,0,1,2.089-.857l3.405-.813c.979-.234,1.924-.548,2.212-1.262a.536.536,0,0,1,.994.4c-.471,1.164-1.7,1.6-2.957,1.9l-3.406.813a4.269,4.269,0,0,0-1.668.65,1.076,1.076,0,0,0-.422,1.164.536.536,0,0,1-.487.76" transform="translate(-1122.155 -441.579)" />
                        <path id="Path_12599" data-name="Path 12599" d="M1548.4,189.474c-5.865-.127-8.54.51-9.377-.674a15.167,15.167,0,0,1-1.139-3.276.652.652,0,0,0-.689-.448l-3.062.318-.732,4.137,0-.013-3.237-3.618-4.58.443a.435.435,0,0,0-.363.563l.889,2.824-12.114,1.4a13.128,13.128,0,0,0-11.38,10.519l-8.526,62.784,20.1.635,7-35.211,17.389-2.007,17.519-1.887.832-13.752a45.574,45.574,0,0,0,25.771-3.835,46.076,46.076,0,0,0,21.684-21.908,23.736,23.736,0,0,0-8.321-10.166,23.421,23.421,0,0,0-9.007-3.691,26.455,26.455,0,0,1-7.585,10.523c-4.19,3.441-7.2,5.9-12.367,6.142-4.28.2-12.836.329-18.7.2" transform="translate(-1130.823 -130.641)" fill="#004b88" />
                        <path id="Path_12600" data-name="Path 12600" d="M1840.093,580.145l-10.326,12.965a7.8,7.8,0,0,0-.693,8.715c4.347,7.634,13.56,19.851,18.282,25.974a2.272,2.272,0,0,0,4.011-.871l2.483-10.6,8.586-14.506Z" transform="translate(-1383.599 -439.092)" fill="#fff" />
                        <path id="Path_12601" data-name="Path 12601" d="M1847.45,627.553a2.779,2.779,0,0,1-2.211-1.095c-4.991-6.471-14.009-18.46-18.324-26.035a8.294,8.294,0,0,1,.739-9.314l10.326-12.965a.536.536,0,0,1,.792-.051l22.343,21.681a.536.536,0,0,1,.088.658l-8.544,14.435-2.464,10.516a2.787,2.787,0,0,1-2.14,2.106,2.868,2.868,0,0,1-.606.065m-9-48.28-9.958,12.5a7.226,7.226,0,0,0-.646,8.116c4.282,7.52,13.267,19.462,18.241,25.912a1.736,1.736,0,0,0,3.065-.667l2.483-10.6a.545.545,0,0,1,.061-.151l8.37-14.142Z" transform="translate(-1381.905 -437.424)" />
                        <path id="Path_12602" data-name="Path 12602" d="M1871.614,641.9a.533.533,0,0,1-.4-.179,90.785,90.785,0,0,1-16.659-26.885.536.536,0,1,1,1-.4,89.736,89.736,0,0,0,16.463,26.569.535.535,0,0,1-.4.893" transform="translate(-1403.623 -464.792)" />
                        <path id="Path_12603" data-name="Path 12603" d="M1984.2,722.7c4.628,8.505,14.013,24.277,17.936,29.1a2.18,2.18,0,0,1-1.973,3.539l-29.939-3.928a15.5,15.5,0,0,1-11.487-7.757l-9.314-16.511Z" transform="translate(-1475.452 -546.987)" fill="#fff" />
                        <path id="Path_12604" data-name="Path 12604" d="M1998.8,754.229a2.825,2.825,0,0,1-.374-.025l-29.939-3.928a16.12,16.12,0,0,1-11.885-8.025l-9.313-16.512a.536.536,0,0,1,.4-.795l34.776-4.442a.542.542,0,0,1,.539.276c4.713,8.662,14.023,24.273,17.882,29.017a2.717,2.717,0,0,1-2.084,4.433m-50.187-28.322,8.922,15.818a15.046,15.046,0,0,0,11.091,7.489l29.939,3.928a1.644,1.644,0,0,0,1.487-2.67c-3.849-4.732-13.031-20.1-17.816-28.859Z" transform="translate(-1473.784 -545.322)" />
                        <path id="Path_12605" data-name="Path 12605" d="M2019.628,793.323a.553.553,0,0,1-.091-.008l-36.116-6.224a.536.536,0,1,1,.182-1.056l36.116,6.224a.536.536,0,0,1-.09,1.064" transform="translate(-1500.849 -594.918)" />
                        <path id="Path_12606" data-name="Path 12606" d="M1778.707,440.076,1768.337,453.1l22.343,21.68,20.366-34.409a17.906,17.906,0,0,0-17.861-26.861l-57.007,6.17-2.678,20.186Z" transform="translate(-1312.029 -312.834)" fill="#004b88" />
                        <path id="Path_12607" data-name="Path 12607" d="M1640.776,441.07l32.284-.079c14.433-.364,25.825,8.992,33.5,21.224,9.04,14.4,10.468,24.639,18.335,39.1l-36.043,4.419-18.073-33.986s-25.388-.155-41.165-.155c-13.893,0-32.209-7.664-21.114-37.028l-.527-1.5,31.6-4Z" transform="translate(-1214.839 -324.74)" fill="#004b88" />
                        <path id="Path_12608" data-name="Path 12608" d="M1887.274,603.776h-10.151a.536.536,0,0,1,0-1.072h10.151a.536.536,0,1,1,0,1.072" transform="translate(-1420.328 -456.166)" />
                        <path id="Path_12609" data-name="Path 12609" d="M1815.279,494.333a.535.535,0,0,1-.454-.25c-9.358-14.866-20.152-21.645-33.99-21.279l-31.353.058h0a.536.536,0,0,1-.526-.432l-.285-1.445a.536.536,0,1,1,1.051-.207l.2,1.011,30.9-.057c14.245-.346,25.332,6.562,34.912,21.779a.536.536,0,0,1-.453.822" transform="translate(-1323.503 -355.989)" />
                        <path id="Path_12610" data-name="Path 12610" d="M295.972,223.157H115.1a4.08,4.08,0,0,0-4.08,4.08v11.238H300.053V227.237a4.08,4.08,0,0,0-4.08-4.08" transform="translate(-84.029 -168.9)" fill="#e6e6e6" />
                        <path id="Path_12611" data-name="Path 12611" d="M148.811,245.08a3.178,3.178,0,1,1-3.178-3.182,3.18,3.18,0,0,1,3.178,3.182" transform="translate(-107.819 -183.084)" fill="#fff" />
                        <path id="Path_12612" data-name="Path 12612" d="M186.962,245.08a3.178,3.178,0,1,1-3.178-3.182,3.18,3.18,0,0,1,3.178,3.182" transform="translate(-136.694 -183.084)" fill="#fff" />
                        <path id="Path_12613" data-name="Path 12613" d="M225.113,245.08a3.178,3.178,0,1,1-3.178-3.182,3.18,3.18,0,0,1,3.178,3.182" transform="translate(-165.569 -183.084)" fill="#fff" />
                        <path id="Path_12614" data-name="Path 12614" d="M456.215,249.136H356.576a2.767,2.767,0,0,1-2.768-2.768v0a2.768,2.768,0,0,1,2.768-2.768h99.639a2.768,2.768,0,0,1,2.768,2.768v0a2.767,2.767,0,0,1-2.768,2.768" transform="translate(-267.786 -184.37)" fill="#fff" />
                        <rect id="Rectangle_18110" data-name="Rectangle 18110" width="188.077" height="123.178" transform="translate(27.451 69.847)" fill="#fff" />
                        <path id="Path_12615" data-name="Path 12615" d="M299.851,409.323H110.7V285.074H299.851Zm-188.077-1.071H298.779V286.146H111.773Z" transform="translate(-83.787 -215.762)" />
                        <rect id="Rectangle_18111" data-name="Rectangle 18111" width="156.742" height="32.203" transform="translate(43.304 83.686)" fill="#e6e6e6" />
                        <path id="Path_12616" data-name="Path 12616" d="M334.852,645.37V634.612a5.111,5.111,0,0,0-5.111-5.111H183.221a5.111,5.111,0,0,0-5.111,5.111V645.37a5.111,5.111,0,0,0,5.111,5.111h146.52a5.111,5.111,0,0,0,5.111-5.111" transform="translate(-134.806 -476.448)" fill="#e6e6e6" />
                        <rect id="Rectangle_18112" data-name="Rectangle 18112" width="157.075" height="27.592" transform="translate(42.971 120.908)" fill="#fff" />
                        <path id="Path_12617" data-name="Path 12617" d="M332.144,523.748H175.069a.536.536,0,0,1-.536-.536V495.62a.536.536,0,0,1,.536-.536H332.144a.536.536,0,0,1,.536.536v27.593a.536.536,0,0,1-.536.536M175.6,522.677h156V496.155h-156Z" transform="translate(-132.098 -374.712)" fill="#e6e6e6" />
                        <path id="Path_12618" data-name="Path 12618" d="M554.972,521.59H487.433a.536.536,0,0,1,0-1.071h67.539a.536.536,0,1,1,0,1.071" transform="translate(-368.516 -393.963)" />
                        <path id="Path_12619" data-name="Path 12619" d="M360.739,521.59H329.5a.536.536,0,1,1,0-1.071h31.238a.536.536,0,1,1,0,1.071" transform="translate(-248.983 -393.963)" />
                        <path id="Path_12620" data-name="Path 12620" d="M662.66,552.708H629.714a.536.536,0,1,1,0-1.071H662.66a.536.536,0,1,1,0,1.071" transform="translate(-476.204 -417.515)" />
                        <path id="Path_12621" data-name="Path 12621" d="M394.89,552.708H329.5a.536.536,0,0,1,0-1.071H394.89a.536.536,0,0,1,0,1.071" transform="translate(-248.984 -417.515)" />
                        <path id="Path_12622" data-name="Path 12622" d="M561.382,583.826H495.9a.536.536,0,1,1,0-1.071h65.48a.536.536,0,1,1,0,1.071" transform="translate(-374.926 -441.067)" />
                        <path id="Path_12623" data-name="Path 12623" d="M363.107,583.826H329.5a.536.536,0,1,1,0-1.071h33.607a.536.536,0,1,1,0,1.071" transform="translate(-248.983 -441.067)" />
                        <path id="Path_12624" data-name="Path 12624" d="M212.628,521.847a10.816,10.816,0,1,0,10.816-10.816,10.816,10.816,0,0,0-10.816,10.816" transform="translate(-160.931 -386.782)" fill="#e6e6e6" />
                        <rect id="Rectangle_18113" data-name="Rectangle 18113" width="30.927" height="30.927" transform="translate(463.223 0)" fill="#ff5c77" />
                        <rect id="Rectangle_18114" data-name="Rectangle 18114" width="0.972" height="24.659" transform="translate(469.641 7.379) rotate(-44.91)" fill="#fff" />
                        <rect id="Rectangle_18115" data-name="Rectangle 18115" width="24.659" height="0.973" transform="matrix(0.708, -0.706, 0.706, 0.708, 469.614, 24.128)" fill="#fff" />
                    </g>
                </g>
            </svg>
        </div>
    );

};

UnsupportedResolutionImageView.propTypes = {};

export default UnsupportedResolutionImageView;