window.dictionary = {
	"trademark": "\xAE",
	"degrees": "\xb0",
	"en": {
		//region LOCAL
		"exception": "Please contact ImpediMed Technical Support.",
		"surgeryDate": "Surgery Date",
		"lppStartDate": "LPP Start Date",
		"selectSurgeryDate": "Select Surgery Date",
		"lppStartDateCheckbox": "LPP Start Date is same as Surgery Date",
		"infoSurgeryDate": "The Surgery Date is used to determine if a patient is included in LPP analytics and to calculate follow up measurement due dates.  The LPP Start Date is used to determine when to start tracking LPP protocol compliance.",
		"noConnectivity": "There is a connectivity problem. Check your internet connection or contact ImpediMed Technical Support.",
		"unauthorized": "There was a connectivity problem. Please logout and login and if the issue persists, contact ImpediMed Technical Support.",
		"timeout": "There is a connectivity problem. Check your internet connection or contact ImpediMed Technical Support.",
		"reportTimeout": "The amount of data required to generate the requested report exceeds the limitations of the system. To resolve, select fewer history graphs per report or update the selected date range to include fewer measurements. Note: Multiple reports may be required to generate all of the desired data output.",
		"username": "Username",
		"password": "Password",
		"yourPassword": "Your Password",
		"lastUpdated": "Last Updated",
		"notesCount": "# NOTES",
		"noPatientsInGroup": "There are no patients in this group. Add the first one.",
		"noNotes": "No Notes",
		"emptyNoteList": "No notes have been written for this patient. Create the first one.",
		"createdBy": "Created by",
		"ldexOutOfRange": "L-Dex score out of chart range",
		"hfdexOutOfRangeOver": "HF-Dex out of chart range",
		"hfdexOutOfRangeUnder": "HF-Dex out of chart range",
		"outOfRangeOver": "out of chart range",
		"outOfRangeUnder": "out of chart range",
		"noRefRangeAge":"No reference range is available for this output. Patient age falls outside reference range data set.",
		"noRefRangeBmi":"No reference range is available for this output. Patient BMI falls outside reference range data set.",
		"segmentalNull": "This segmental result cannot be calculated due to issues found with the measurement data.  Please re-take the measurement to produce a valid result.",
		"title": "Login To Your Account",
		"login": "Login",
		"forgotPassword": "Forgot password?",
		"forgotPasswordExtra": "Forgot email used for MySOZO account? Please contact your MySOZO administrator.",
		"firstname": "First Name",
		"lastname": "Last Name",
		"middlename": "Middle Name",
		"noUserDataSelectedExport": "Please select one user data to export option",
		"noPatientsSelectedExport": "There are no patients selected for export. Please select at least one patient or select all patients.",
		"noGroupsSelectedExport": "There are no groups selected for export. Please select at least one group.",
		"sessionExpirationMsg": "You will be automatically signed out in",
		"emptyFieldsClinic": "Please fill all mandatory fields.",
		"passwordExpirationRange": "Range: 30 - 1000 days",
		"range": "Range",
		"daysWord": "days",
		"minutes": "minutes",
		"sessionExpirationRange": "Range: 5 - 60 minutes",
		"changePasswordSuccess": "Your password has been updated.",
		"middlenameOptional": "Middle Name (Optional)",
		"phoneNumber": "Phone Number",
		"changeEmail": "New Email Address",
		"confirmChangeEmail": "Confirm New Email Address",
		"itemsPerPage": "Items per page",
		"addNewDevice": "+ Add device name",
		"edit": "Edit",
		"phone": "Phone",
		"mrn": "MRN",
		"submit": "Submit",
		"ethnicity": "Ethnicity",
		"myProfile": "My Profile",
		"signOut": "Sign Out",
		"select": "Select",
		"newPasswordTitle": "Set New Password",
		"newPassword": "New Password",
		"oldPassword": "Old Password",
		"sendEmailTitle": "Reset Password",
		"resetPasswordDescription": "Enter the email address used to set up your MySOZO account.",
		"activeDirectoryDescription": "Enter your company domain used for your MySOZO account.",
		"sendTempPasswordSuccess": "An email has been sent with instructions on how to reset your password. If you do not receive an email, check that the email address you have entered is correct, check your spam folder, or contact your MySOZO Administrator.",
		"measurementUnitsTitle": "Measurement Units",
		"timeZoneTitle": "Time Zone",
		"settings": "Settings",
		"createGroup": "Create Group",
		"patientGroups": "Patient Groups",
		"patientMrn": "Patient MRN",
		"noPatientGroups": "No groups have been created yet.",
		"editMembers": "Edit Members",
		"editGroupMembers": "Edit Group Members",
		"editGroupMembersInfo": "Patients may be added to the group by selecting from the list on the left and pressing “add”. Patients may be removed from the group by selecting from the list on the right and then pressing “remove”.",
		"groups": "Groups",
		"groupName": "Group Name",
		"tagName": "Tag Name",
		"tagNew": "New Tag",
		"add": "Add",
		"remove": "Remove",
		"deleteGroupWarning": "Are you sure you want to delete the selected group?",
		"deleteTagWarning": "Are you sure you want to delete the selected tag?",
		"restore": "Restore",
		"shareData": "Share Data",
		"sharedData": "Shared Data",
		"incoming": "Incoming",
		"outgoing": "Outgoing",
		"share": "Share",
		"dataShared": "Data has been Shared",
		"cautionSharingDataMessage": "Patient data will be shared outside your organization. It is your responsibility to ensure that all data transfers adhere to privacy and data protection regulations.",
		"sharingAvailable":"SHARING_AVAILABLE",
		"assignSharedData": "Assign Shared Data",
		"assign": "Assign",
		"assigned": "Assigned",
		"assignNew": "Assign New",
		"recipient": "Recipient",
		"assignedReceiversEmpty": "No receivers have been assigned yet. Assign the first one.",
		"versionNotCompatible": "version not compatible",
		"restorePatient": "Restore Patient",
		"restoreDeletedPatient": "Restore deleted patient",
		"restoreUser": "Restore User",
		"restoreDeletedUser": "Restore deleted user",
		"patients": "Patients",
		"noPatients": "No patients were found. Create the first one.",
		"noDevicesMessage": "No devices have been used yet. Take a measurement to add a device.",
		"noPatientsTitle": "No patients",
		"noDevicesTitle": "No devices found",
		"noPatientsShort": "No Patients found",
		"noUsersTitle": "No users found",
		"noClinicians": "No users were found. Create the first one.",
		"noDeletedUsers": "There are no deleted users.",
		"noSearchResults": "There are no results matching your search criteria. Please try again.",
		"noSearchResultsTitle": "No results found",
		"noSharingsTitle": "No shared data found",
		"noSharings": "No data has been shared yet.",
		"clinicianList": "User List",
		"createClinician": "Create Clinician",
		"clinicianProfile": "Clinician Profile",
		"upload": "Upload",
		"updateStrings": {
			"downloadUpdates": "Download Updates",
			"updateSOZO": "Update MySOZO",
			"update": "Update",
			"updateText": {
				"currentVersion": "MySOZO version",
				"isUpToDate": "Software version is up to date.",
				"hasUpdate": "New software update is available.",
			},
			"updateInformationTitle": "Software Information",
			"softwareUpdateTitle": "Software Update",
			"availableUpdateAdminText": {
				"availableUpdate": "A new software version is available.",
				"wantToUpdate": " Would you like to update?"
			},
			"availableUpdateClinicianText": "A new software version is available. Please consult with your administrator to update.",
			"notAtThisTime": "Not At This Time",
			"tellMeMore": "Tell Me More",
			"summaryChanges": "Summary of Changes",
			"releaseNotes": "Release Notes",
			"acceptParagraph": {
				"part1": "All users will be logged out and the system will be inaccessible to allow for the software update. This process may take up to 10 minutes to complete.",
				"part2": "Upon completion of the update, the SOZOapp on all tablets must be updated and all users will only be able to access the most recent version of SOZO software.",
			},
			"acceptAndUpdate": "Accept and Update",
			"softwareUpdateComplete": "Your software update is complete!",
			"updateSuccessfullClinicianText": {
				"part1": "MySOZO has been updated. Prior to use, please ensure that you understand the SOZO Instructions For Use (available at ",
				"link": "https://impedimed.com/support",
				"part2": ")."
			},
			"appUpdateAvailableAndroid": "The version of SOZOapp that you are using is not compatible with the MySOZO version for your clinic. By following the prompts, the SOZOapp will attempt to automatically update to a version that is compatible with MySOZO. If the SOZOapp is not able to update, this may be resolved by signing into mysozo.com as an administrator user and upgrading MySOZO to the latest version or by manually updating the SOZOapp to a compatible version. For access to other SOZOapp versions and instructions on how to manually update the SOZOapp to a compatible version, contact ImpediMed Technical support.",
			"appUpdateAvailableiOS": "The SOZOapp was unable to automatically update to a compatible version with MySOZO. In some situations, this may be resolved by signing into mysozo.com as an administrator user and upgrading MySOZO to the latest version. For access to other SOZOapp versions and instructions on how to manually update the SOZOapp to a compatible version, contact ImpediMed Technical support.",
		},
		"uploadApk": "Upload Software",
		"uploadFirm": "Upload Firmware",
		"deleteClinician": "Delete Clinician",
		"deleteClinicianConfirm": "Are you sure that you want to delete the selected user?",
		"aboutUs": "About us",
		"logout": "Log Out",
		"remainLogged": "Remain Signed In",
		"createNewClinician": "create New Clinician",
		"createSuccess": "Clinician created successfully!",
		"createFail": "Clinician was not created due to an error.  Re-try your request.",
		"gender": "Gender",
		"sex": "Sex",
		"male": "Male",
		"female": "Female",
		"maleShort": "M",
		"femaleShort": "F",
		"dobOptional": "Date of Birth (Optional)",
		"dateOfBirth": "Date of Birth",
		"dob": "DOB",
		"cPassword": "Confirm New Password",
		"temporaryPassword": "Temporary Password",
		"enterTemporaryPassword": "Enter temporary password",
		"invalidPassword_Alert": "The password does not meet requirements.  The password must be between 8 and 20 characters containing at least:\n1. One number\n2. One special character (!“#$%&‘()+,-./:;<=>?_@[]^`{|}~)*\n3. One upper case letter\n4. One lower case letter \n5. Cannot be one of the last three passwords used.",
		"invalidPassword": {
			"firstLine": "The password must be between 8 and 20 characters containing at least:",
			"oneNumber": "1. One number",
			"oneSpecial": "2. One special character (!“#$%&‘()+,-./:;<=>?_@[]^`{|}~)*",
			"oneUpper": "3. One upper case letter",
			"oneLower": "4. One lower case letter ",
			"history": "5. Cannot be one of the last three passwords used."
		},
		"invalidPasswordConsistency": "The New Password and Confirm New Password\ndo not match.",
		"specialty": "Specialty",
		"setNewPass": "Set New Password",
		"selectFile": "SELECT FILE",
		"validFile": "Please select valid file",
		"resetPass": "Reset Password",
		"resetLater": "Reset Later",
		"resetNow": "Reset Now",
		"setNow": "Set Now",
		"beCareful": "Be Careful",
		"warning": "Warning",
		"caution": "Caution",
		"error": "Error",
		"success": "Success",
		"emptyFiledsBillingReport": "Please enter From and To date.",
		"emptyFieldsCreateClinician": "First Name, Last Name, Email, or User Role cannot be empty. Please fill all mandatory fields.",
		"emptyFieldEditClinician": "Please enter First Name, Last Name and User Role.",
		"emptyFieldsPatient": "Please fill all mandatory fields. First Name, Last Name, Sex, Date of Birth and Height cannot be empty.",
		"emptyFieldsExtraInfo": "Please enter both an Email Address and Password.",
		"emptyFieldsChangeEmail": "Please fill all mandatory fields.",
		"emptyFieldsCreateSharing": "Please enter a Clinic",
		"invalidPhone": "The phone number must be between 4 and 20 digits and contain only numbers.",
		"exit": "Are you sure you want to sign out?",
		"yes": "YES",
		"no": "NO",
		"cancel": "Cancel",
		"done": "Done",
		"invalidDate": "Invalid date",
		"deletePatientConfirm": "Are you sure you want to delete the selected patient?",
		"restorePatientConfirm": "Are you sure you want to restore the selected patient?",
		"deleteUserConfirm": "Are you sure you want to delete the selected user?",
		"restoreUserConfirm": "Are you sure you want to restore the selected user?",
		"ssoEnableConfirm": "Please confirm that you want to enable SSO. All users will be required to login with their organizational account.",
		"ssoDisableConfirm": "Please confirm request to disable SSO. Users will no longer be able to sign in using their organizational account credentials.",
		"deletePatient": "Delete Patient",
		"searchClinician": "Search User...",
		"searchUser": "Search for a user...",
		"placeholderPassword": "PASSWORD",
		"placeholderConfirmPassword": "CONFIRM PASSWORD",
		"save": "Save",
		"invalidFile": "Invalid file",
		"sessionExpiredMessage": "For safety reasons your session has been terminated. Please login again.",
		"kilograms": "kg",
		"pounds": "lbs",
		"centimeters": "cm",
		"feet": "ft",
		"inches": "in",
		"feetLong": "Feet",
		"inchesLong": "Inches",
		"height": "Height",
		"weight": "Weight",
		"currentPreTare": "CURRENT PRE-TARE:",
		"netWeightCaps": "NET WEIGHT",
		"getWeight": "Get Weight",
		"retakeWeight": "Retake Weight",
		"netWeight": "Net Weight",
		"grossWeight": "Gross Weight",
		"preTare": "Pre-Tare",
		"patientDashboard": "Patient\nDashboard",
		"lastSozoAssessment": "Last SOZO Assessment",
		"lastAssessment": "Last Assessment",
		"date": "Date",
		"time": "time",
		"measurementType": "Measurement Type",
		"thrive": "thrive",
		"bodyComposition": "BodyComp",
		"bodyCompositionAnalysis": "BodyComp Analysis",
		"segmentalAnalysis": "Segmental Analysis",
		"segmentalBodyCompAnalysis": "Segmental BodyComp Analysis",
		"segmental": "Segmental",
		"segmentalBodyComp": "Segmental BodyComp",
		"beat": "beat",
		"lDexScore": "L-Dex Score",
		"hfDexScore": "HF-Dex Score",
		"changeFromBaseline": "Change from Baseline",
		"changeFromBaselineNewLine":
			{
				"change": "Change",
				"fromBaseline": "from Baseline"
			},
		"baseline": "Baseline",
		"baselineCaps": "BASELINE",
		"data": "Data",
		"threshold": "51% HF-Dex",
		"ecfScore": "ECF Score",
		"hyDexScore": "Hy-Dex Score",
		"hyDex": "Hy-Dex",
		"Hy-Dex": "Hy-Dex",
		"trending": "Trending",
		"lDex": "L-Dex",
		"bilateralArmReview": "Bilateral Arm Review",
		"delta10": "Delta10",
		"normalRange": "In normal range",
		"notNormalRange": "Out of normal range",
		"hfNormalRange": "In normal range",
		"hfAboveNormalRange": "Above chart range",
		"hfBelowNormalRange": "Below chart range",
		"colePlot": "Cole Plot",
		"riskSide": "Risk limb",
		"noRiskSide": "Unaffected limb",
		"notCollected": "-",
		"saveChanges": "Save Changes",
		"navigateResults": "Navigate Results",
		"demographicDetails": "Demographic Details",
		"SOZOpro": "SOZOpro",
		"SOZOthrive": "SOZOthrive",
		"SOZObeat": "SOZObeat",
		"measurementDate": "Measurement Date",
		"reactance": "Reactance",
		"resistance": "Resistance",
		"xc": "Xc",
		"r": "R",
		"ohms": "Ohms",
		"other": "Other",
		"email": "Email",
		"mfaStatus": "MFA Status",
		"lastSignIn": "Last Sign In",
		"notRetrieved": "Not Retrieved",
		"notProvided": "Not Provided",
		"editPatient": "Edit Patient",
		"continueToEdit": "Continue to Edit",
		"confirmEmail": "Re-enter Email",
		"emailMismatch": "The email addresses entered do not match. Please re-enter them.",
		"getHelp": "Get Help",
		"measurementStatus": "Measurement Status",
		"accepted": "Accepted",
		"rejected": "Rejected",
		"postalCode": "Postal Code",
		"emailOptional": "Email (Optional)",
		"patientList": "Patient List",
		"deletedPatients": "Deleted Patients",
		"deletedUsers": "Deleted Users",
		"users": "Users",
		"searchPatient": "Search for a patient...",
		"searchSourcePatient": "Search source patient",
		"searchDestinationPatient": "Search destination patient",
		"rightBody": "Right Body",
		"leftBody": "Left Body",
		"hyDexAnalysisWithRmark": "Hy-Dex{sup}\xAE{sup} Analysis",
		"hyDexAnalysis": "Hy-Dex Analysis",
		"hyDexChangeAnalysis": "Hy-Dex Change Analysis",
		"changeFromPrevious": "Change from Previous",
		"current": "Current",
		"less": "Less",
		"more": "More",
		"hydrated": "hydrated",
		"bmi": "BMI",
		"smm": "SMM",
		"smmPercentage": "SMM % of Weight",
		"SkeletalMuscleMassSMM": "Skeletal Muscle Mass (SMM)",
		"skeletalMuscleMass": "Skeletal Muscle Mass",
		"leanSoftTissue": "Lean Soft Tissue",
		"triple": "ECF, TBW, Weight",
		"tbw": "TBW",
		"tbwPercentage": "TBW % of Weight",
		"fat": "Fat",
		"fluidStatus": "Fluid status",
		"ecf": "ECF",
		"ecfPercentage": "ECF % of TBW",
		"ECF": "ECF",
		"icf": "ICF",
		"icfPercentage": "ICF % of TBW",
		"distribution": "Distribution",
		"extracellularFluid": "Extracellular Fluid",
		"intracellularFluid": "Intracellular Fluid",
		"fm": "FM",
		"fmPercentage": "FM % of Weight",
		"ffm": "FFM",
		"ffmPercentage": "FFM % of Weight",
		"atm": "ATM",
		"atmPercentage": "ATM % of Weight",
		"ecm": "ECM",
		"ecmPercentage": "ECM % of Weight",
		"bmr": "BMR",
		"phase": "Phase Angle",
		"basalMetabolicRate": "Basal Metabolic Rate",
		"activeTissueMass": "Active Tissue Mass",
		"extracellularMass": "Extracellular Mass",
		"calPerDay": "Cals/Day",
		"metabolicReport": "Metabolic Report",
		"totalBodyComposition": "Total body composition",
		"totalBodyWater": "Total Body Water",
		"fatFreeMassFFM": "Fat Free Mass (FFM)",
		"fatMassFM": "Fat Mass (FM)",
		"bodyMassIndexBMI": "Body Mass Index (BMI)",
		"proteinAndMinerals": "Protein & Minerals",
		"proteinAndMineralsPercentage": "Protein & Minerals % of Weight",
		"phaseAngle": "Phase Angle",
		"lst": "LST",
		"degrees": "Degrees",
		"percentageOfFFM": "% of FFM",
		"percentageOfWeight": "% of Weight",
		"pt": "pt",
		"pints": "pints",
		"lt": "lt",
		"liters": "liters",
		"litersShort": "L",
		"litersShortWithExplanation": "L (liters)",
		"pintsShortWithExplanation": "pt (pints)",
		"auditLogs": "Audit Logs",
		"getPatientsBy": "Get patients by",
		"pseudonymisedId": "Pseudonymised ID",
		"export": "Export",
		"exportAll": "Export All",
		"from": "From",
		"to": "To",
		"heightIsNaN": "Height allows numbers only",
		"invalidHeightValue": "Height must be a number between 38 cm and 251.5 cm",
		"invalidHeightValueImperial": "Height must be a number between 1 ft. 3.0 inches and 8 ft. 3.0 inches",
		"invalidWeightValue": "Invalid weight value",
		"completeChange": "You are about to perform a significant level of changes to this patient’s profile. Consider creating a new patient instead.",
		"continue": "Continue",
		"measurementDatetime": "Measurement Date",
		"mrnOptional": "MRN (Optional)",
		"notSet": "-",
		"missingInformation": "Some graphical information may be outdated or missing.",
		"fitted": "Fitted Curve",
		"tdCorrect": "Td Corrected Data",
		"measured": "Measured Data",
		"arm": "Arm",
		"leg": "Leg",
		"left": "Left",
		"right": "Right",
		"lDexNormalRange": "L-Dex® Normal Range",
		"mySozoVersion": "MySOZO Version: ",
		"days": {
			"monday": "Mon",
			"tuesday": "Tue",
			"wednesday": "Wed",
			"thursday": "Thu",
			"friday": "Fri",
			"saturday": "Sat",
			"sunday": "Sun",
		},
		"months": {
			"january": "Jan",
			"february": "Feb",
			"march": "Mar",
			"april": "Apr",
			"may": "May",
			"june": "Jun",
			"july": "Jul",
			"august": "Aug",
			"september": "Sep",
			"october": "Oct",
			"november": "Nov",
			"december": "Dec",
		},
		"english": "English (US)",
		"french": "French",
		"german": "German",
		"spanish": "Spanish",
		"italian": "Italian",
		"englishUK": "English (UK)",
		"noBasicReportingLicensing": "The licenses granted do not allow for report generation.  Please contact ImpediMed Technical Support for further details.",
		"historyGraphs": "History Graphs",
		"errorCode": "Error Code: ",
		"tissue": "Tissue Analysis",
		"fluid": "Fluid Status",
		"hfDex": "HF-Dex",
		"lDexType": "L-Dex",
		"language": "Language",
		"bodyElement": "Body Element",
		"riskLimb": "Risk Limb",
		"limb": "Limb",
		"limbDominance": "Limb Dominance",
		"both": "Both",
		"ecfAnalysis": "ECF Analysis",
		"additionalAnalysis": "Additional Analysis",
		"bilateral": "Bilateral",
		"unilateral": "Unilateral",
		"rightLimb": "Right Limb",
		"leftLimb": "Left Limb",
		"rightArm": "Right Arm",
		"leftArm": "Left Arm",
		"rightLeg": "Right Leg",
		"leftLeg": "Left Leg",
		"commaHeightInputProblem": "Height entry is invalid and must be a number (##.#). Note: no comma is allowed. If this message is in error, please contact ImpediMed Technical Support.",
		"reviseHeight": "All Measurements Including Historical",
		"allMeasurementsIncluded" : "All measurements included",
		"allMeasurements" : "All Measurements",
		"currentMeasurement" : "Current Measurement",
		"clinic":"Clinic",
		"validator_email_checkEmailRegex": "Invalid email address",
		"validator_email_checkEmailSharingLength": "A maximum of 5 emails may be added. Try again with fewer emails.",
		"oneTimePassword": "One Time Password",
		"otpValidityPeriod": "Verification code resets every 15 mins.",
		"heightAtMeasurement": "Only Future Measurements",
		"recalculateWithHeightMessage": "For the change to height, please select how you would like this entry applied. Be aware that this change is not reversible.",
		"recalculateMessage": "Changing patient profile settings will recalculate all historical results based on the revised settings. Do you want to continue with the setting changes?",
		"measurementPatientMissingField": "The patient's height has not been entered. Height will be required before a measurement can be taken.",
		"sessionExpirationOne": "Your session will expire in",
		"sessionExpirationTwo": "You will be automatically logged out in",
		"generateReport": "Generate Report",
		"ok": "OK",
		"emptyPhoneNumberOrCode": "Both country code and phone number must be entered",
		"noDataTypeSelectedExport": "No data type selected. Please select one data type to continue.",
		"noDataFormatSelectedExport": "Please select a data format to continue.",
		"noAssessmentTypeSelectedExport": "Please select at least one assessment type to continue.",
		"invalidDateRangeMessage": "The From date must be before the To date.",
		"noDateRangeSelected": "Please select a date option.",
		"reportSettings": "Report Settings",
		"exportSettings": "Export Settings",
		"reportSummary": "Report Summary",
		"dataType": "Data Type",
		"resultsSimplifiedData": "Simplified",
		"resultsFullData": "Full",
		"deidentifiedId": "Show De-identified Patient ID",
		"patientIdentifyingDetails": "Show Patient Identifying Details",
		"additionalPatientDetails": "Additional Patient Details",
		"deviceMeasurementInfo": "Device/Measurement Info",
		"groupsInfo": "Group Details",
		"rowPerPatient": "Row per patient",
		"rowPerMeasurement": "Row per measurement",
		"hyDexWithTrademark": "Hy-Dex\xAE",
		"emptyFieldDateRange": "Please select a date range.",
		"noMeasurementTypeSelected": "Please select at least one assessment type and one type of history graph to continue.",
		"noMeasurementsTaken": "A report cannot be generated because no SOZO measurement has been taken for the selected patient. If only imported Impsoft measurements exist, a report can be generated by navigating to any specific measurement result and selecting the report/print option.",
		"clinicSettings": "Clinic Settings",
		"userSettings": "User Settings",
		"deviceSettings": "Device Settings",
		"deviceStatus": "Device Status",
		"scaleSettings": "Scale Settings",
		"displayWeight": "Display Weight",
		"weightMeasurementUnits": "Weight Measurement Units",
		"tareScale": "Tare Scale",
		"tare": "Tare",
		"deviceName": "Device Name",
		"macAddress": "Mac Address",
		"serialNumber": "Serial Number",
		"clinicianName" : "Clinician Name",
		"notAvailableImported" : "NA - Imported",
		"notAvailable" : "NA",
		"lastSelfTestStatus": "Last Self-Test Status",
		"refresh": "Refresh",
		"updateVersion": "Update Version",
		"firmware": "Firmware",
		"version":"Version",
		"calibration": "Calibration",
		"impedanceCalibrationDate": "Impedance Calibration Date",
		"scaleCalibrationDate": "Scale Calibration Date",
		"reportLogoSettings": "Report Logo Settings",
		"maximumDimensions": "Maximum dimensions",
		"maximumSize": "Maximum Size",
		"fileSupportedFormat": "File supported format",
		"pixels": "pixels",
		"revert": "Revert",
		"browse": "Browse",
		"invalidFilePNG": "Invalid file type. Please select a PNG file for upload.",
		"imageLarger1MB": "The image file cannot be larger than 1MB.",
		"imageNotSelected": "Cannot save because no image file was selected.",
		"notSelected": "Not selected",
		"emptyLDexSettings": "You need to set all the Lymphedema settings within the patient's profile in SOZOapp, prior to displaying L-Dex results or trending",
		"patientDetails": "Patient Details",
		"assessmentType": "Assessment Type",
		"assessment": "Assessment",
		"lDexAnalysisForLymphedema": "L-Dex Analysis for Lymphedema",
		"tissueAnalysisForBodyComposition": "Tissue Analysis for Body Composition",
		"fluidAnalysisForFluidStatusMonitoring": "Fluid Analysis for General Fluid Status Monitoring",
		"fluidAnalysisOverviewDescription": "Fluid Analysis for General Fluid Status Monitoring",
		"hfDexAnalysisForHeartFailure": "HF-Dex Analysis for Heart Failure",
		"lymphedemaSettings": "Lymphedema Settings",
		"fullMRN": "Medical Record Number (MRN)",
		"mrnLong": "Medical Record Number",
		"countryCode": "Country Code",
		"address": "Address",
		"city": "City",
		"state": "State",
		"country": "Country",
		"createPatientTwoLines": "Create\nPatient",
		"createPatient": "Create Patient",
		"updatePatient": "Update Patient",
		"importPatient": "Import Patient",
		"updateUser": "Update User",
		"emptyFieldsCreatePatient": "Please fill all mandatory fields.",
		"emptyFields": "Please fill all mandatory fields.",
		"emptyFieldsSecuritySettings": "The security settings must be chosen. Please fill in all fields to continue.",
		"invalidSessionExpirationTime": "The security settings are invalid. Please re-enter them as a number within the ranges specified.",
		"invalidResetPasswordTime": "The security settings are invalid. Please re-enter them as a number within the ranges specified.",
		"mrnErrorMessage": "The Medical Record Number entered must contain letters or numbers with no special characters allowed and may be up to 100 characters long.",
		"postalCodeErrorMessage": "Postal Code allows a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"alphabeticExtendedErrorMessage": "First name, Last name, Middle name, Address, City and State allow a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"alphabeticExtendedErrorMessageNames": "First name, Last name and Middle name allow a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"groupNameAlphabeticExtendedErrorMessage": "An issue was detected with the group name. A maximum of 100 characters are allowed and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"deviceNameAlphabeticExtendedErrorMessage": "An issue was detected with the device name. A maximum of 48 characters are allowed and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"tagNameAlphabeticExtendedErrorMessage": "An issue was detected with the tag name. A maximum of 48 characters are allowed and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"tagColorNotSelected": "Please select a color for this tag.",
		"emailErrorMessage": "The email address is invalid. The email address must be up to 255 characters and may contain the following characters: \"- _ . @\"",
		"emailErrorMessageSameAddress": "The email address was not updated because it is the same as the previous one.",
		"lymphedemaSettingErrorMessage": "Please set your L-Dex assessment settings",
		"surgeryDateBeforeDOB": "The Surgery Date in the L-Dex Settings must be later than the Date of Birth.",
		"lppDateBeforeSurgeryDate": "In the L-Dex settings, the LPP Start Date must be on or later than the Surgery Date.",
		"dataExport": "Data Export",
		"or": "or",
		"required": "Required",
		"optional": "Optional",
		"reportPrint": "Report",
		"licensingStatus": "Licensing Status",
		"dataToExport": "USER DATA TO EXPORT",
		"selectedPatients": "Selected Patients",
		"allPatients": "all patients",
		"selectAll": "Select All",
		"clearAll": "Clear All",
		"history": "History",
		"historyCaps": "HISTORY",
		"results": "Results",
		"result": "Result",
		"profile": "Profile",
		"noAssessmentTypeSelected": "There is no Assessment Type selected in the Patient Profile",
		"tissueAnalysis": "Tissue Analysis",
		"fluidAnalysis": "Fluid Analysis",
		"weightFluidAnalysis": "Weight and Fluid Analysis",
		"hfDexAnalysis": "HF-Dex Analysis",
		"lDexHistory": "L-Dex History",
		"hfDexHistory": "HF-Dex History",
		"setBaseline": "Set as Baseline",
		"setECFBaseline": "Set ECF Baseline",
		"hyDexHistoryWithRmark": "Hy-Dex{sup}\xAE{sup} History",
		"hyDexHistory": "Hy-Dex History",
		"tbwHistory": "TBW History",
		"tbwPercentageHistory": "TBW % of Weight History",
		"ffmHistory": "FFM History",
		"ffmPercentageHistory": "FFM % of Weight History",
		"fmHistory": "FM History",
		"fmPercentageHistory": "FM % of Weight History",
		"ecfHistory": "ECF History",
		"ecfPercentageHistory": "ECF % of TBW History",
		"icfHistory": "ICF History",
		"icfPercentageHistory": "ICF % of TBW History",
		"bmrHistory": "BMR History",
		"smmHistory": "SMM History",
		"smmPercentageHistory": "SMM % of Weight History",
		"atmHistory": "ATM History",
		"atmPercentageHistory": "ATM % of Weight History",
		"bmiHistory": "BMI History",
		"ecmHistory": "ECM History",
		"ecmPercentageHistory": "ECM % of Weight History",
		"proteinAndMineralsHistory": "Protein & Minerals History",
		"proteinAndMineralsPercentageHistory": "Protein & Minerals % of Weight History",
		"weightHistory": "Weight History",
		"phaseAngleHistory": "Phase Angle History",
		"selectMeasurementDate": "Select Measurement Date",
		"colePlots": "Cole Plots",
		"fatMass": "Fat Mass",
		"percOfWeight": "% of{n}Weight",
		"percOfTBW": "% of{n}TBW",
		"note": "Note",
		"fluidDistribution": "Fluid Distribution",
		"mean": "mean",
		"lessHydrated": "Less Hydrated",
		"moreHydrated": "More Hydrated",
		"leftWholeBody": "Left Whole Body",
		"rightWholeBody": "Right Whole Body",
		"atRisk": "At Risk",
		"dominant": "Dominant",
		"measurementDates": "Measurement Dates",
		"help": "Help",
		"technicalSupport": "Technical Support",
		"technicalSupportA": "Americas",
		"technicalSupportAO": "All Others",
		"technicalSupportAP": "Asia Pacific",
		"technicalSupportEMA": "Europe, Middle East, Africa",
		"technicalSupportEmail": "Email:",
		"technicalSupportPhone": "Phone:",
		"productInformation": "More Information",
		"forInstruction": "For Instructions for Use, please visit:",
		"productsLink": "https://www.impedimed.com/support/ifu-documents/",
		"forSupport": "For support and additional documentation, please visit:",
		"linkMain": "https://impedimed.com/support",
		"linkPatent": "https://www.impedimed.com/patents/",
		"emailAssistance": "ts@impedimed.com",
		"emailAssistanceUS": "tsu@impedimed.com",
		"emailAssistanceEMA": "tse@impedimed.com",
		"phoneNA": "USA/Canada (toll free): +1 877-247-0111 option 4",
		"phoneUS": "US: +1 760-585-2125",
		"phoneAP": "+61 7 3860 3700 option 2",
		"phoneEMA": "+30 231-111-6753",
		"privacyPolicy": "Privacy Policy",
		"privacyPolicyVisit": "To learn more, visit:",
		"privacyPolicyLink": "www.impedimed.com/privacy-policy",
		"regulatoryInformation": "Regulatory Information",
		"regInfo": "ImpediMed Regulatory Information",
		"regInfoLimited": "ImpediMed Limited",
		"abn": "ABN 65 089 705 144",
		"australiaLoc": "Unit 1, 50 Parker Court, Pinkenba",
		"australiaLoc2": "Qld. 4008, Australia",
		"europeLoc": "MediMark Europe Sarl 11 rue Emile ZOLA",
		"europeLoc2": "B.P. 2332, 38033 Grenoble Cedex 2, France",
		"regulatoryMDSS": "MDSS GmbH",
		"regulatoryMDSS2": "Schiffgraben 41",
		"regulatoryMDSS3": "30175 Hannover, Germany",
		"regulatoryMDSSUK": "UKRP",
		"regulatoryMDSSUK2": "MDSS-UK RP Ltd.",
		"regulatoryMDSSUK3": "6 Wilmslow Road, Rusholme",
		"regulatoryMDSSUK4": "Manchester M14 5TP",
		"regulatoryMDSSUK5": "United Kingdom",
		"patents": "For patent(s) and/or patent application(s) see: ",
		"trademarks1": "©2024 ImpediMed Limited",
		"trademarks2": "ImpediMed\xAE, SOZO\xAE, L-Dex\xAE and Hy-Dex\xAE are registered",
		"trademarks3": "trademarks of ImpediMed Limited. HF-Dex and BodyComp are trademarks of ",
		"trademarks4": "ImpediMed Limited.",
		"about": "About",
		"getLatestAppFirmware": "Get Latest App/Firmware",
		"verificationCode": "Verification Code",
		"userDetails": "User Details",
		"myAccount": "My Account",
		"assessmentDate": "Assessment Date",
		"dataResults": "Data Results",
		"simplified": "Simplified",
		"full": "Full",
		"createReport": "Create Report",
		"info": "Info",
		"information": "Information",
		"lDexIntendedFor": "L-Dex is intended for use on adult patients at risk of lymphedema.",
		"TissueAnalysisIntendedFor": "Tissue Analysis is intended for use on patients ages 6 to 90.",
		"FluidAnalysisIntendedFor": "Fluid Analysis is intended for use on patients ages 6 to 90.",
		"hfDexIntendedFor": "HF-Dex is intended for use on adult patients living with heart failure to monitor their fluid status.",
		"renalIntendedFor": "Fluid Assessment for ESRD is intended for use on adult patients living with End-stage Renal Disease.",
		"noAssessmentTypeSelectedResults": "Please select an assessment type for this patient based on the current licenses.",
		"noResults": "No measurements have been taken for this patient. Take a new measurement to review results.",
		"licensingStatusTitle": "Licensing Status",
		"licensedSOZODevices": "Licensed SOZO Devices",
		"notActive": "Not active",
		"active": "Active",
		"inactive": "Inactive",
		"lDexLicense": "L-Dex Analysis for Unilateral Lymphedema",
		"lDexBilateralLicense": "L-Dex Analysis for Bilateral Lymphedema",
		"tissueAnalysisLicenseUS": "Tissue Analysis for Body Composition (with Hy-Dex)",
		"tissueAnalysisLicenseOUS": "Tissue Analysis for Body Composition",
		"fluidAnalysisLicenseUS": "Fluid Analysis for General Fluid Status Monitoring",
		"fluidAnalysisLicenseOUS": "Fluid Analysis for General Fluid Status Monitoring",
		"hfDexLicense": "HF-Dex Analysis for Heart Failure",
		"heartFailureGroup": "Heart Failure Group",
		"hfdex": "HF-Dex",
		"hfDexParagraph": "The HF-Dex scale is a tool to assist in monitoring" +
			" fluid status in patients with heart failure who have fluid management " +
			"problems, and should be used in conjunction with other clinical data." +
			" HF-Dex values in the light blue reference range are consistent with normal" +
			" fluid volumes, based on data collected from generally healthy individuals with normal" +
			" fluid levels. Values in the medium blue and dark blue reference range may indicate" +
			" increasing levels of extracellular fluid and may require additional clinical investigation. " +
			"Values in the gray range require additional investigation.",
		"hyDexParagraph": "Hy-Dex is a bi-directional scale that displays a person’s fluid status as compared to a dataset from an average population. " +
			"Hy-Dex is only intended for use with healthy patients and should not be used to diagnose or treat a medical condition.",
		"renalLicense": "Fluid Analysis for ESRD",
		"esrd": "ESRD",
		"ecfTbwWeightHistory": "ECF, TBW, Weight History",
		"tbwIcfEcfHistory": "ECF, ICF, TBW History",
		"basicReportingLicense": "Basic Reporting (Reports/Exports)",
		"noDevices": "There are no licensed devices for this Facility",
		"anyDevice": "Any device",
		"lppProtocolCompliance": "LPP Protocol Compliance",
		"testingFrequency": "Testing Frequency",
		"postTreatment": "First Post-Treatment Measurement",
		"trigger": "Trigger",
		"resolved": "Resolved",
		"endDate": "End Date",
		"increaseFromBaseline": "L-Dex increase from baseline",
		"decreaseFromBaseline": "L-Dex decrease from baseline",
		"triggerInfo": "If no baseline set, trigger will be calculated from first measurement.",
		"resolvedInfo": "If no baseline set, resolved will be calculated from first measurement.",
		"years": "Years",
		"year": "Year",
		"week": "Week",
		"weeks": "Weeks",
		"noEndDate": "No End Date",
		"every": "Every",
		"analytics":"Analytics",
		"protocolCompliance": "Protocol Compliance",
		"programManagement": "Program Management",
		"countries": {
			"unitedStates": "United States",
			"afghanistan": "Afghanistan",
			"albania": "Albania",
			"algeria": "Algeria",
			"americanSamoa": "American Samoa",
			"andorra": "Andorra",
			"angola": "Angola",
			"anguilla": "Anguilla",
			"antiguaAndBarbuda": "Antigua and Barbuda",
			"argentina": "Argentina",
			"armenia": "Armenia",
			"aruba": "Aruba",
			"australia": "Australia",
			"austria": "Austria",
			"azerbaijan": "Azerbaijan",
			"bahamas": "Bahamas",
			"bahrain": "Bahrain",
			"bangladesh": "Bangladesh",
			"barbados": "Barbados",
			"belarus": "Belarus",
			"belgium": "Belgium",
			"belize": "Belize",
			"benin": "Benin",
			"bermuda": "Bermuda",
			"bhutan": "Bhutan",
			"bolivia": "Bolivia",
			"bosniaAndHerzegovina": "Bosnia and Herzegovina",
			"botswana": "Botswana",
			"brazil": "Brazil",
			"britishIndianOceanTerritory": "British Indian Ocean Territory",
			"britishVirginIslands": "British Virgin Islands",
			"brunei": "Brunei",
			"bulgaria": "Bulgaria",
			"burkinaFaso": "Burkina Faso",
			"burundi": "Burundi",
			"cambodia": "Cambodia",
			"cameroon": "Cameroon",
			"canada": "Canada",
			"capeVerde": "Cape Verde",
			"caribbeanNetherlands": "Caribbean Netherlands",
			"caymanIslands": "Cayman Islands",
			"centralAfricanRepublic": "Central African Republic",
			"chad": "Chad",
			"Chile": "Chile",
			"China": "China",
			"christmasIsland": "Christmas Island",
			"cocos": "Cocos",
			"colombia": "Colombia",
			"comoros": "Comoros",
			"congo": "Congo",
			"cookIslands": "Cook Islands",
			"costaRica": "Costa Rica",
			"coteDIvoire": "Côte d’Ivoire",
			"croatia": "Croatia",
			"cuba": "Cuba",
			"curacao": "Curaçao",
			"cyprus": "Cyprus",
			"czechRepublic": "Czech Republic",
			"denmark": "Denmark",
			"djibouti": "Djibouti",
			"dominica": "Dominica",
			"dominicanRepublic": "Dominican Republic",
			"ecuador": "Ecuador",
			"egypt": "Egypt",
			"elSalvador": "El Salvador",
			"equatorialGuinea": "Equatorial Guinea",
			"eritrea": "Eritrea",
			"estonia": "Estonia",
			"ethiopia": "Ethiopia",
			"falklandIslands": "Falkland Islands",
			"faroeIslands": "Faroe Islands",
			"fiji": "Fiji",
			"finland": "Finland",
			"france": "France",
			"frenchGuiana": "French Guiana",
			"frenchPolynesia": "French Polynesia",
			"gabon": "Gabon",
			"gambia": "Gambia",
			"georgia": "Georgia",
			"germany": "Germany",
			"ghana": "Ghana",
			"gibraltar": "Gibraltar",
			"greece": "Greece",
			"greenland": "Greenland",
			"grenada": "Grenada",
			"guadeloupe": "Guadeloupe",
			"guam": "Guam",
			"guatemala": "Guatemala",
			"guernsey": "Guernsey",
			"guinea": "Guinea",
			"guineaBissau": "Guinea-Bissau",
			"guyana": "Guyana",
			"haiti": "Haiti",
			"honduras": "Honduras",
			"hongKong": "Hong Kong",
			"hungary": "Hungary",
			"iceland": "Iceland",
			"india": "India",
			"indonesia": "Indonesia",
			"iran": "Iran",
			"iraq": "Iraq",
			"ireland": "Ireland",
			"isleOfMan": "Isle of Man",
			"israel": "Israel",
			"italy": "Italy",
			"jamaica": "Jamaica",
			"japan": "Japan",
			"jersey": "Jersey",
			"jordan": "Jordan",
			"kazakhstan": "Kazakhstan",
			"kenya": "Kenya",
			"kiribati": "Kiribati",
			"kosovo": "Kosovo",
			"kuwait": "Kuwait",
			"kyrgyzstan": "Kyrgyzstan",
			"laos": "Laos",
			"latvia": "Latvia",
			"lebanon": "Lebanon",
			"lesotho": "Lesotho",
			"liberia": "Liberia",
			"libya": "Libya",
			"liechtenstein": "Liechtenstein",
			"lithuania": "Lithuania",
			"luxembourg": "Luxembourg",
			"macau": "Macau",
			"macedonia": "Macedonia",
			"madagascar": "Madagascar",
			"malawi": "Malawi",
			"malaysia": "Malaysia",
			"maldives": "Maldives",
			"mali": "Mali",
			"malta": "Malta",
			"marshallIslands": "Marshall Islands",
			"Martinique": "Martinique",
			"mauritania": "Mauritania",
			"Mauritius": "Mauritius",
			"mayotte": "Mayotte",
			"mexico": "Mexico",
			"micronesia": "Micronesia",
			"moldova": "Moldova",
			"monaco": "Monaco",
			"mongolia": "Mongolia",
			"montenegro": "Montenegro",
			"montserrat": "Montserrat",
			"morocco": "Morocco",
			"mozambique": "Mozambique",
			"myanmar": "Myanmar",
			"namibia": "Namibia",
			"nauru": "Nauru",
			"nepal": "Nepal",
			"netherlands": "Netherlands",
			"newCaledonia": "New Caledonia",
			"newZealand": "New Zealand",
			"nicaragua": "Nicaragua",
			"niger": "Niger",
			"nigeria": "Nigeria",
			"niue": "Niue",
			"norfolkIsland": "Norfolk Island",
			"northKorea": "North Korea",
			"northernMarianaIslands": "Northern Mariana Islands",
			"norway": "Norway",
			"oman": "Oman",
			"pakistan": "Pakistan",
			"palau": "Palau",
			"palestine": "Palestine",
			"panama": "Panama",
			"papuaNewGuinea": "Papua New Guinea",
			"paraguay": "Paraguay",
			"peru": "Peru",
			"philippines": "Philippines",
			"poland": "Poland",
			"portugal": "Portugal",
			"puertoRico": "Puerto Rico",
			"qatar": "Qatar",
			"reunion": "Réunion",
			"romania": "Romania",
			"russia": "Russia",
			"rwanda": "Rwanda",
			"saintBarthelemy": "Saint Barthélemy",
			"saintHelena": "Saint Helena",
			"saintKittsAndNevis": "Saint Kitts and Nevis",
			"saintLucia": "Saint Lucia",
			"saintMartin": "Saint Martin",
			"saintPierreAndMiquelon": "Saint Pierre and Miquelon",
			"saintVincentAndThe": "Saint Vincent and the Grenadines",
			"samoa": "Samoa",
			"sanMarino": "San Marino",
			"saoTomeAndPrincipe": "São Tomé and Príncipe",
			"saudiArabia": "Saudi Arabia",
			"senegal": "Senegal",
			"serbia": "Serbia",
			"seychelles": "Seychelles",
			"sierraLeone": "Sierra Leone",
			"singapore": "Singapore",
			"sintMaarten": "Sint Maarten",
			"slovakia": "Slovakia",
			"slovenia": "Slovenia",
			"solomonIslands": "Solomon Islands",
			"somalia": "Somalia",
			"southAfrica": "South Africa",
			"southKorea": "South Korea",
			"southSudan": "South Sudan",
			"spain": "Spain",
			"sriLanka": "Sri Lanka",
			"sudan": "Sudan",
			"suriname": "Suriname",
			"svalbardAndJanMayen": "Svalbard and Jan Mayen",
			"swaziland": "Swaziland",
			"sweden": "Sweden",
			"switzerland": "Switzerland",
			"syria": "Syria",
			"taiwan": "Taiwan",
			"tajikistan": "Tajikistan",
			"tanzania": "Tanzania",
			"thailand": "Thailand",
			"timorLeste": "Timor-Leste",
			"togo": "Togo",
			"tokelau": "Tokelau",
			"tonga": "Tonga",
			"trinidadAndTobago": "Trinidad and Tobago",
			"tunisia": "Tunisia",
			"turkey": "Turkey",
			"turkmenistan": "Turkmenistan",
			"turksAndCaicosIslands": "Turks and Caicos Islands",
			"tuvalu": "Tuvalu",
			"uSVirginIslands": "U.S. Virgin Islands",
			"uganda": "Uganda",
			"ukraine": "Ukraine",
			"unitedArabEmirates": "United Arab Emirates",
			"unitedKingdom": "United Kingdom",
			"uruguay": "Uruguay",
			"uzbekistan": "Uzbekistan",
			"vanuatu": "Vanuatu",
			"vaticanCity": "Vatican City",
			"venezuela": "Venezuela",
			"vietnam": "Vietnam",
			"wallisAndFutuna": "Wallis and Futuna",
			"westernSahara": "Western Sahara",
			"yemen": "Yemen",
			"zambia": "Zambia",
			"zimbabwe": "Zimbabwe",
			"alandIslands": "Åland Islands"
		},
		"normalRangeLineInfo": "Normal range",
		"type": "Type",
		"role": "Role",
		"roleAdmin": "Administrator",
		"roleAdminShort": "Admin",
		"roleClinician": "Clinician",
		"changeEmailBtn": "Change",
		"setNewEmail": "Set New Email",
		"changelog": "Changelog",
		"securitySettings": "Security Settings",
		"sessionExpiration": "Session Expiration",
		"passwordExpiration": "Password Expiration",
		"passwordExpirationIn": "Your password will expire in ",
		"passwordExpirationDays": " days.\nDo you want to reset it now?",
		"passwordExpired": "Your password has expired.\nYou must set a new one.",
		"passwordMonths": "Months",
		"sessionExpireAfter": "The session token will expire after minutes",
		"updateAvailable": "There is an update available would you like to proceed?",
		"updateAvailableClinician": "There is an update available",
		"tellMeMore": "Tell me more",
		"createUser": "Create User",
		"createUserOneLine": "Create User",
		"userProfileEdit": "Edit User\nProfile",
		"userProfile": "User Profile",
		"deleteUser": "Delete User",
		"exportAuditLogs": "Export Audit Logs",
		"timezone": "Time Zone",
		"noSAMLFileSelected": "Select a proper SAML file",
		"invalidFileXML": "Invalid file type. Please select an XML file for upload.",
		"sso": "SSO",
		"activeDirectorySettings": "SSO Settings",
		"activeDirectorySelection": "I want to have SSO as an authentication server",
		"importSAMLFile": "Import SAML file",
		"browseFile": "Browse File",
		"fileLoaded": "SAML file is loaded",
		"quality": {
			"high": "HIGH QUALITY",
			"medium": "MEDIUM QUALITY",
			"low": "LOW QUALITY",
			"imported": "IMPORTED QUALITY",
		},
		"invalidSearch": "The search query allows a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"error404": "Error 404",
		"pageNotFound": "Page not found",
		"passwordExpirationPeriod": "Password Expiration Period (in days)",
		"logoutInactivityTime": "Automatic Sign Out Time From Initial Sign In (in minutes)",
		"mfaForUsers": "MFA for Users",
		"enableMFAUntil": "Date that all users must enable MFA by:",
		"noMFAForcedTimeSelected": "Please select a date for MFA activation",
		"multiFactorAuthentication": "Multi-Factor Authentication",
		"mfaStatusON": "Multi-Factor Authentication is turned on.",
		"mfaStatusOFF": "Multi-Factor Authentication is turned off.",
		"mfaTurnON": "Turn On",
		"mfaTurnOFF": "Turn OFF",
		"mfaSecurityInfo": "Multi-Factor Authentication gives you additional security by requiring authentication code whenever you sign in on a new device.",
		"mfaWarningON": "Multi-Factor Authentication provides additional security by requiring an authentication code generated by a device in your possession whenever you sign into the system. Turning this feature on will sign you out of MySOZO.",
		"mfaWarningOFF": "Multi-Factor Authentication provides additional security by requiring an authentication code generated by a device in your possession whenever you sign into the system. Turning this feature off will sign you out of MySOZO.",
		"mfaWarningForce": "Multi-Factor Authentication provides additional security by requiring an authentication code generated by a device in your possession whenever you sign into the system. Turning this feature on will sign you out of MySOZO.",
		"mfaAuthenticationMethod": "Your authentication method is ",
		"authenticatorApp": "Authenticator App",
		"mfaEnterPassword": "For your security, enter your password to make this change.",
		"scanQRCode": "Scan the QR code below.",
		"enterSecretKey": "Or enter this secret key into your Authenticator app:",
		"enterVerificationCode": "Enter the 6-digit verification code generated by your Authenticator app.",
		"invalidVerificationCode": "The Verification Code must be a 6-digit number. Please re-enter the code.",
		"noBaseline": "No Baseline",
		"typeOfDataToExport": "TYPE OF DATA TO EXPORT",
		"allAssessmentTypes": "All Assessment Types",
		"byAssessmentType": "By Assessment Type",
		"byRowperMeasurement": "By Row per Measurement",
		"byRowperPatient": "By Row per Patient",
		"advancedOptions": "ADVANCED OPTIONS",
		"dateRange": "DATE RANGE",
		"dataFormat": "DATA FORMAT",
		"additionalSettings": "ADDITIONAL SETTINGS",
		"all": "All",
		"deidentifiedData": "De-identify Data",
		"timezones": {
			"Etc/GMT+12": "International Date Line West",
			"Pacific/Samoa": "Midway Island",
			"Pacific/Honolulu": "Hawaii",
			"America/Anchorage": "Alaska",
			"America/Tijuana": "Pacific Time (US and Canada);Tijuana",
			"America/Chihuahua": "Chihuahua",
			"America/Denver": "Mountain Time (US & Canada)",
			"America/Phoenix": "Arizona",
			"America/Chicago": "Central Time (US and Canada)",
			"America/Regina": "Saskatchewan",
			"America/Monterrey": "Monterrey",
			"America/Mexico_City": "Guadalajara",
			"America/Costa_Rica": "Central America",
			"America/New_York": "Eastern Time (US and Canada)",
			"America/Indiana/Indianapolis": "Indiana (East)",
			"America/Bogota": "Bogota",
			"America/Caracas": "Caracas",
			"America/Halifax": "Atlantic Time (Canada)",
			"America/La_Paz": "La Paz",
			"America/Manaus": "Manaus",
			"America/Argentina/San_Juan": "San Juan",
			"America/Asuncion": "Asuncion",
			"America/Santiago": "Santiago",
			"America/St_Johns": "Newfoundland",
			"America/Sao_Paulo": "Brasilia",
			"America/Montevideo": "Montevideo",
			"America/Argentina/Buenos_Aires": "Buenos Aires",
			"America/Guyana": "Georgetown",
			"America/Godthab": "Greenland",
			"America/Noronha": "Mid-Atlantic",
			"Atlantic/Azores": "Azores",
			"Atlantic/Cape_Verde": "Cape Verde Islands",
			"Europe/Dublin": "Dublin",
			"Africa/Casablanca": "Casablanca",
			"UTC": "Coordinated Universal Time",
			"Africa/Monrovia": "Monrovia",
			"Europe/Belgrade": "Belgrade",
			"Europe/Sarajevo": "Sarajevo",
			"Europe/Brussels": "Brussels",
			"Europe/Amsterdam": "Amsterdam",
			"Africa/Lagos": "West Central Africa",
			"Europe/Minsk": "Minsk",
			"Africa/Windhoek": "Windhoek",
			"Africa/Cairo": "Cairo",
			"Asia/Beirut": "Beirut",
			"Europe/Helsinki": "Helsinki",
			"Asia/Amman": "Amman",
			"Europe/Athens": "Athens",
			"Asia/Jerusalem": "Jerusalem",
			"Africa/Harare": "Harare",
			"Europe/Moscow": "Moscow",
			"Asia/Tbilisi": "Tbilisi",
			"Asia/Kuwait": "Kuwait",
			"Africa/Nairobi": "Nairobi",
			"Asia/Baghdad": "Baghdad",
			"Asia/Tehran": "Tehran",
			"Asia/Muscat": "Abu Dhabi",
			"Indian/Mauritius": "Port Louis",
			"Asia/Yerevan": "Yerevan",
			"Asia/Baku": "Baku",
			"Asia/Kabul": "Kabul",
			"Asia/Yekaterinburg": "Ekaterinburg",
			"Asia/Karachi": "Islamabad",
			"Asia/Tashkent": "Tashkent",
			"Asia/Kolkata": "Kolkata",
			"Asia/Kathmandu": "Kathmandu",
			"Asia/Dhaka": "Astana, Dhaka",
			"Asia/Colombo": "Sri Jayawardenepura",
			"Asia/Novosibirsk": "Almaty",
			"Asia/Yangon": "Yangon",
			"Asia/Bangkok": "Bangkok",
			"Asia/Krasnoyarsk": "Krasnoyarsk",
			"Asia/Hong_Kong": "Beijing",
			"Asia/Kuala_Lumpur": "Kuala Lumpur",
			"Asia/Taipei": "Taipei",
			"Australia/Perth": "Perth",
			"Asia/Irkutsk": "Irkutsk",
			"Asia/Seoul": "Seoul",
			"Asia/Tokyo": "Tokyo",
			"Asia/Yakutsk": "Yakutsk",
			"Australia/Darwin": "Darwin",
			"Australia/Adelaide": "Adelaide",
			"Australia/Melbourne": "Melbourne",
			"Australia/Brisbane": "Brisbane",
			"Australia/Hobart": "Hobart",
			"Asia/Vladivostok": "Vladivostok",
			"Pacific/Guam": "Guam",
			"Asia/Magadan": "Magadan",
			"Pacific/Fiji": "Fiji",
			"Pacific/Auckland": "Auckland",
			"Asia/Kamchatka": "Petropavlovsk-Kamchatsky",
			"Pacific/Tongatapu": "Nuku'alofa"
		},
		"mergePatients": "Merge Patients",
		"mergeActivePatients": "Merge active patients",
		"mergePatientsSource": "Source Patient",
		"mergePatientsDestination": "Destination Patient",
		"viewMore": "+ View more",
		"viewLess": "- View less",
		"source": "Source",
		"destination": "Destination",
		"mergePatientsInfo": "The source patient’s measurements will be added to the destination patient and the source patient will be deleted. All results will be recalculated based on the destination patient’s profile. This change is permanent and not reversible.",
		"importDataInfo": "The shared patient’s measurements will be added to the destination patient. All results will be recalculated based on the destination patient’s profile.",
		"importAsNew": "Import as New",
		"merge": "Merge",
		"mergeBtn": "Confirm Merge",
		"mergeWarning": "Are you sure that you want to merge the selected patients? This change will be permanent and not reversible.",
		"importWarning": "Confirm the patient selection for shared data importation.",
		"mergePatientsIds": "A patient cannot be merged with themself. Please select two different patients to merge.",
		"importPatientsIds": "A patient cannot be imported with themself. Please select two different patients to import.",
		"preview": "Preview",
		"delete": "Delete",
		"deleteWarning": "By checking this box, you acknowledge that this measurement will be permanently deleted and can not be restored. If the measurement selected for deletion is currently used as a baseline, the impacted baseline will be cleared.",
		"dialysisStatus": "Dialysis Status",
		"dialysisStatusNoSelection": "No Selection",
		"dialysisStatusPre": "Pre-Dialysis",
		"dialysisStatusPost": "Post-Dialysis",
		"dialysisStatusOther": "Other",
		"lowQualityResultsRenal": "This result cannot be provided due to low measurement quality \"red\". For this measurement to be shown, the Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) must be medium quality \"yellow\" or high quality \"green\".",
		"lowQualityResultsHfdex": "This result cannot be provided due to low measurement quality \"red\". For this measurement to be shown, the Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) must be medium quality \"yellow\" or high quality \"green\".",
		"lowQualityHistoryRenal": "A history chart cannot be provided due to low measurement quality. For the measurement history to be shown, at least one measurement must have a Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) of medium quality \"yellow\" or high quality \"green\".",
		"lowQualityHistoryHfdex": "A history chart cannot be provided due to low measurement quality. For the measurement history to be shown, at least one measurement must have a Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) of medium quality \"yellow\" or high quality \"green\".",
		"totalMeasurements": "Total Measurements",
		"of": "of",
		"invalidRenalBaseline": "The ECF baseline value must be equal to or greater than zero and less than the maximum measured TBW value.",
		"activeDirectoryLogin": "SSO Login",
		"domainName": "Domain Name",
		"enterDomainName": "Enter domain name",
		"invalidDomainName": "Domain Name allows a maximum of 32 characters and cannot contain spaces.",
		"secureSignInSSO": "Secure sign in with SSO",
		"signIn": "Sign In",
		"ssoSignIn": "SSO Sign In",
		"ssoSignInDescription": "Enter the Domain Name used for your MySOZO account.",
		"forgotDomainName": "Forgot domain name used for your MySOZO account? Please contact your MySOZO administrator.",
		"sozoDigitalHealthPlatform": "SOZO Digital Health Platform",
		"enterMfaCode": "Enter MFA Code",
		"mfaCode": "MFA Code",
		"enterEmail": "Enter email",
		"enterYourPassword": "Enter your password",
		"newEmail": "New Email",
		"confirm": "Confirm",
		"enterNewEmail": "Enter new email",
		"confirmNewEmail": "Confirm new email",
		"enterPassword": "Enter password",
		"enterOldPassword": "Enter old password",
		"enterNewPassword": "Enter new password",
		"enterFirstName": "Enter first name",
		"enterLastName": "Enter last name",
		"enterMiddleName": "Enter middle name",
		"enterMrn": "Enter medical record number",
		"selectDate": "Select date",
		"selectSex": "Select sex",
		"enterHeightMetric": "Enter height (cm)",
		"selectCountryCode": "Select country code",
		"enterPhoneNumber": "Enter phone number",
		"enterAddress": "Enter address",
		"enterCity": "Enter city",
		"enterState": "Enter state",
		"selectCountry": "Select country",
		"enterPostalCode": "Enter postal code",
		"setNew": "Set New",
		"passwordPolicy": "Password Policy",
		"signOutPolicy": "Sign out Policy",
		"search": "Search",
		"addNew": "Add New",
		"searchTip": "Search Tip",
		"searchPrompt": "Complete a search to find a patient. To view a full patient list, search with an empty field.",
		"demographics": "Demographics",
		"create": "Create",
		"newPatient": "New Patient",
		"sorry": "Sorry!",
		"filter": "Filter",
		"sortBy": "Sort by:",
		"newest": "Newest",
		"oldest": "Oldest",
		"thirty": "30",
		"fifty": "50",
		"hundred": "100",
		"apply": "Apply",
		"clear": "Clear",
		"noGroupsCreatedFilter": "No groups have been created yet.",
		"noReceiverCreatedFilter": "No receivers have been added yet.",
		"noGroupsCreatedList": "No groups have been created yet. Create the first one.",
		"noTagsCreatedList": "No tags have been created yet.\nCreate the first one.",
		"noTagsCreatedListClinician": "No tags have been defined. A tag must be first defined by an Administrator before it can be assigned to a measurement.",
		"noTagsAddedList": "No tags have been added yet.\nAdd the first one.",
		"back": "Back",
		"notes": "Notes",
		"addNewNote": "Add New Note",
		"addTitle": "Add Title",
		"addNote": "Add Note",
		"medicalRecordNumber": "Medical record number",
		"untitled": "Untitled",
		"notePlaceholder": "Start writing your note...",
		"invalidNoteTitle": "Title may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"emptyTitleNotes": "The title must contain at least one character and no more than 255 characters",
		"emptyDescriptionNotes": "The description must contain at least one character and no more than 1000 characters",
		"invalidNoteTitleLength": "Title allows a maximum of 255 characters.",
		"invalidNoteDescription": "Description may contain numbers, spaces, new lines or the following characters: '.()#_-/%&@:;,+",
		"invalidNoteDescriptionLength": "Description allows a maximum of 1000 characters.",
		"newNote": "New Note",
		"deleteNoteConfirm": "Are you sure you want to delete the selected note?",
		"deleteNote": "Delete Note",
		"searchGroup": "Search for a group...",
		"searchTag": "Search for a tag...",
		"group": "Group",
		"member": "Member",
		"members": "Members",
		"view": "View",
		"noGroups": "No groups found",
		"noResultsSearch": "No results found",
		"tags": "Tags",
		"tag": "Tag",
		"devices": "Devices",
		"tagColor": "Tag Color",
		"noMeasurementsFound": "No Measurements found",
		"noTags": "No tags found",
		"noTagsList": "No tags have been created yet.",
		"noMeasurementsFilter": "No results match your filter selections. Please try again.",
		"noDeletedPatients": "There are no deleted patients.",
		"mfaDeactivationWarning": "You are about to deactivate MFA of user:",
		"editGroupName": "Edit Group Name",
		"editTagName": "Edit Tag Name",
		"deleteGroup": "Delete Group",
		"deleteTag": "Delete Tag",
		"newGroup": "New Group",
		"enterGroupName": "Enter group name",
		"enterTagName": "Enter tag name",
		"clinicMfa": "Clinic MFA",
		"noChanges": "No changes detected.",
		"prev": "Prev",
		"next": "Next",
		"deleteMeasurement": "Delete Measurement",
		"unilateralInfo": "The unilateral selection is for use with patients that have only one limb at risk of lymphedema. Both limbs will be measured and used to calculate the L-Dex score.",
		"bilateralInfo": "For use with patients when both legs are at risk of lymphedema.  All limbs will be measured and used to calculate the L-Dex scores.",
		"bilateralInfoTitle": "BILATERAL – ONLY AVAILABLE FOR LEGS",
		"requiredFields": "Required Fields",
		"fittedCurve": "Fitted Curve",
		"measuredData": "Measured Data",
		"tdCorrectedData": "Td Corrected Data",
		"selectGraphs": "Select Graphs",
		"ecfIcfTbw": "ECF/ICF/TBW",
		"ecfTbwWeight": "ECF/TBW/Weight",
		"normal":"Normal",
		"elevated":"Elevated",
		"normalFluid":"Normal Fluid",
		"elevatedFluid":"Elevated Fluid",
		"fluidOverload":"Fluid Overload",
		"veryLean":"Very Lean",
		"lean":"Lean",
		"high":"High",
		"veryHigh":"Very High",
		"excellent":"Excellent",
		"good":"Good",
		"fair":"Fair",
		"poor":"Poor",
		"veryPoor":"Very Poor",
		"underweight":"Underweight",
		"healthy":"Healthy",
		"overweight":"Overweight",
		"obese":"Obese",
		"colePlotWarningNoRed":"None of the Patient’s selected assessment types are currently impacted by the “red” Cole plot(s).",
		"colePlotWarningHasRed":"One or more Cole plots are showing low quality “red”. The impact of the “red” Cole plot(s) on the Patient’s selected assessment types are:",
		"colePlotWarningNegativeFM":"This measurement has resulted in invalid results indicating a probable error in the measurement. Please review the Cole plots above for correctness and verify the height and weight entries. Retry your measurement or contact ImpediMed technical support. Note: once you press Proceed, this information will be lost.",
		"output":"output",
		"impact":"impact",
		"associatedRedColePlot":"associated red cole plot",
		"mayImpactAccuracy":"May impact accuracy",
		"mayImpactTheAccuracyOfLimb":"May impact the accuracy of the associated limb",
		"resultWontShow":"Results will not be shown",
		"graphs": "Graphs",
		"noGraphsSelected": "No graphs have been selected.\nSelect one or more graphs to view historical results.",
		"addNewTag": "Add New Tag",
		"selectTag": "Select Tag",
		"description": "Description",
		"passwordProtectedUserCreate": "For security reasons in order to create a user your password is required to proceed.",
		"invalidTagDescriptionLength": "A maximum of 255 characters are allowed in the Description.",
		"tagNotSelected": "Please select a tag.",
		"wrongEmailFormat": "The email address is invalid. The email address may contain up to 255 characters and the following characters: \"- _ . @\"",
		"show":"show",
		"hide":"hide",
		"ehrSettings": "EHR Settings",
		"dataTransfer": "Data Transfer",
		"dtSozoToEhr": "Data transfer from SOZO to EHR",
		"dtEhrToSozo": "Data transfer from EHR to SOZO",
		"ehrSystem": "EHR System",
		"epic": "Epic",
		"tracker": "Tracker",
		"measure": "Measure",
		"measurement": "Measurement",
		"tutorial": "Tutorial",
		"somethingWentWrong": "Oops! Something went wrong",
		"measurementQuality": "To optimize the quality of the measurement:",
		"measurementTips": "Additional tips to ensure consistent BodyComp measurement:",
		"measurementQualityChecklist": {
			"qualityChecklist-1": "Remove all metal jewelry if possible, including large belt buckles. Items above the neck such as earrings are ok.",
			"qualityChecklist-2": "Dampen hands and feet or the SOZO electrodes.",
			"qualityChecklist-3": "Remove any electronics, coins, keys or other metal objects from pockets.",
			"qualityChecklist-4": "Remain still during the measurement with elbows away from the body.",
			"qualityChecklist-5": "Remove shoes and socks or stockings.",
		},
		"measurementTipsChecklist": {
			"tipChecklist-1": "Ensure an empty bladder prior to measurement.",
			"tipChecklist-2": "Avoid alcohol for 8 hours prior to measurement.",
			"tipChecklist-3": "Avoid exercise for 4 hours prior to measurement.",
			"tipChecklist-4": "Avoid meals for 8 hours prior to measurement.",
			"tipChecklist-5": "Avoid caffeine for 2 hours prior to measurement.",
		},
		"SOZOContraindicationTextPart1": "SOZO is not indicated for patients who have an active implantable device (e.g., ",
		"SOZOContraindicationTextPart2": "permanent pacemaker, defibrillator",
		"SOZOContraindicationTextPart3": ", infusion pumps, neurostimulators, brain stimulators, etc.). Refer to the IFU for additional instructions.",
		"SOZOProContraindicationText": "SOZO Pro is not indicated for patients who have an active implantable device (e.g., infusion pumps, neurostimulators, brain stimulators) with the exceptions of permanent pacemakers (PPMs) or implantable cardioverter-defibrillators (ICDs). Refer to the IFU for additional instructions.",
		"previous": "Previous",
		"placeFeet": "Place feet onto foot unit",
		"placeHand": "Place hands onto hand unit",
		"infoTutorialSOZOBold": "Initiate measurement by pressing start button.",
		"infoTutorial": " This will be followed by a 5 second countdown before the measurement starts. Patient should keep hands and feet on the SOZO device and remain still until the measurement is complete.",
		"tutorialStep1Title": "Have patient stand on foot unit",
		"tutorialStep2Title": "Get weight on SOZOapp",
		"tutorialStep3Title": "Patient should remain standing on foot unit",
		"tutorialStep4Title": "Place hands onto hand unit",
		"tutorialStep1Subtitle": "Patient should not place hands on hand unit until weight has been measured.",
		"tutorialStep2Subtitle": "Retake weight and/or adjust Pre-Tare as needed.",
		"tutorialStep3Subtitle": "Patient’s feet should be touching all 4 electrodes as shown.",
		"tutorialStep4Subtitle": "Patient’s hands should be touching all 4 electrodes as shown.",
		"measurementWillStart": "Measurement will start in",
		"start": "Start",
		"selfTest": "Self-Test",
		"selectDevice": "Select Device",
		"deviceManagement": "Device Management",
		"weightPreTare": "Weight Pre-Tare",
		"selectNewSOZODevice": "Select new SOZO device",
		"pairDeviceAndroid": "If SOZO Device is not listed above,",
		"pairDevice": "Pair Device",
		"pairNewDevice": "Pair New Device",
		"scaleCalibrationHistory": "Scale Calibration History",
		"lowerScaleCalibration": "Lower Scale Calibration",
		"upperScaleCalibration": "Upper Scale Calibration",
		"targetWeight": "Target Weight",
		"actualMeasurement": "Actual Measurement",
		"selfTestDirections": "To ensure consistently accurate readings, we recommend performing a self-test at the start of each day of use to confirm the device is functioning properly. To perform a self-test, ensure that there is nothing in contact with the electrodes on both hand unit and foot unit. A self-test cannot be performed while standing on foot unit.",
		"beginSelfTest": "Begin Self-Test",
		"weightPreTareStatusOn": "Weight Pre-Tare is turned on.",
		"weightPreTareStatusOff": "Weight Pre-Tare is turned off.",
		"weightPreTareSOZOProContent": " Enter weight to subtract from scale for each measurement, e.g clothing offset.",
		"weightPreTareSOZO": "To enable the Weight Pre-Tare, a SOZO Pro device must be selected.",
		"unsupportedOSAndBrowser": "Unsupported Browser or Operating System",
		"unsupportedOSAndBrowserMessage": "Communication with the SOZO device is not supported on this browser or operating system. To use the SOZO device, including taking a measurement – use the SOZOapp on an Android or iOS tablet or a Windows based PC with a Chrome or Edge browser.",
		"weightPromptToSitSOZO": "The patient's weight exceeds the maximum limit of the SOZO device (170 kg or 375 lbs). Please take this patient's measurements in a seated position.",
		"weightPromptToSitSOZOPro": "The patient's weight exceeds the maximum limit of the SOZO Pro device (220 kg or 485 lbs). Please enter the weight into the SOZOapp manually and take this patient's measurements in a seated position.",
		"weightPromptToStand": "The patient's weight must be greater than 22 kg or 48.5 lbs. Re-try the weight measurement while the patient is standing on the SOZO Pro device or manually enter the weight if the patient is in a seated position.",
		"invalidWeightSOZOMetric": "Weight must be a number between 10.0 kg and 340.0 kg",
		"invalidWeightSOZOImperial": "Weight must be a number between 22 lbs and 749.6 lbs",
		"invalidWeightSOZOProMetric": "Weight must be a number between 22.0 kg and 340.0 kg",
		"invalidWeightSOZOProImperial": "Weight must be a number between 48.5 lbs and 749.6 lbs",
		"invalidPreTareMetric": "Pre-Tare Weight must be a number between 0.0 kg and 10.0 kg",
		"invalidPreTareImperial": "Pre-Tare Weight must be a number between 0.0 lbs and 22.0 lbs",
		"weightPreTareSOZOProMetricRange": "Range: 0.0 kg - 10.0 kg",
		"weightPreTareSOZOProImperialRange": "Range: 0.0 lbs - 22.0 lbs",
		"pairingCanceled": "No SOZO device has been selected. Please select a device to initiate a measurement or self-test.",
		"deviceError": "A device error occurred. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		"tareScaleSuccess": "Scale tared",
		"scaleSettingsSuccess": "Scale settings saved",
		"deviceStatusSuccess": "Status retrieved",
		"devicePairedSuccess": "Device Paired",
		"selfTestPassed": "Self-test passed",
		"selfTestFailed": "Self-test failed. Re-try the test and ensure that there is nobody touching or standing on the SOZO device. If the issue persists, contact ImpediMed technical support.",
		"selfTestFailedTakeMeasurement": "Measurement failed because there is no record of a successful self-test. Please complete a successful self-test and if the issue persists, contact ImpediMed Technical Support.",
		"selfTestFailedDeviceStatus": "Note: there are no records of a previously successful self test on the selected SOZO device. A successful self test is required before a measurement may be taken. Select OK to show Device Status.",
		"unsupportedResolutionWarningTitle": "Oops! Unsupported screen resolution",
		"unsupportedResolutionWarningFirst": "This site requires a horizontal screen resolution greater than 1024px.",
		"unsupportedResolutionWarningSecond": "Increase your screen resolution in your browser or operating system to continue.",
		"connectivityLostTitle": "Oops! Connectivity lost",
		"connectivityLostTextFirst": "Check your connectivity and/or internet connection or contact ImpediMed technical support.",
		"connectivityLostTextSecond": "Retry will attempt to re-initiate contact with MySOZO to calculate your measurement.",
		"retry": "Retry",
		"selectDialysisStatus": "Select Dialysis Status",
		"preDialysis": "Pre-dialysis",
		"postDialysis": "Post-dialysis",
		"takeMeasurementHeightEmpty": "The patient's height has not been entered. Enter a height in the patient profile in order to take a measurement.",
		"takeMeasurementAssessmentTypeEmpty": "The patient has no assessment type selected or no valid licenses. Add at least one assessment type to the patient profile or confirm in the information menu that licenses are available.",
		"takeMeasurementNoActiveLicense": "There is no active license for your MySOZO account. Please contact ImpediMed technical support.",
		"takeMeasurementESRDNotSelected": "Dialysis Status must be selected.",
		"takeMeasurementNoSOZODeviceSelect": "A SOZO device must be selected to complete a measurement. Please select a device in the settings menu to continue.",
		"acceptOrRejectColePlots": "Accept or Reject Cole Plots",
		"accept": "Accept",
		"reject": "Reject",
		"proceed": "Proceed",
		"firmwareUptoDate": "The SOZO device firmware is already at the latest version.",
		"firmwareUpdated": "Firmware updated",
		"firmwareUpdate": "Firmware Update",
		"firmwareUpdateConfirmation": "New version may exist. Do you want to update?",
		"notLicensedDevice": "There is no active license for the selected device.",
		"sender": "Sender",
		"receiver": "Receiver",
		"noReceivers": "No Receivers",
		"status": "Status",
		"importData": "Import Data",
		"markAsNew": "Mark as New",
		"shared": "Shared",
		"imported": "Imported",
		"new": "New",
		"recalled": "Recalled",
		"old": "Old",
		"sharingListReceiver": "SharingListReceiver",
		"importDetails": "Import Details",
		"recallData": "Recall Data",
		"recalledDetails": "Recalled Details",
		"recall": "Recall",
		"recallDataConfirmMessage": "By continuing with the recall, the shared clinic will no longer have access to the data. Data that has already been imported will not be affected.",
		"licensedDeviceInactive": "The licences granted do not allow for use of the selected SOZO device. Please contact ImpediMed Technical Support for further details.",
		"noLicense": "No License",
		"windowsPairingWarning": "SOZO device must first be paired with PC through Windows Bluetooth system settings before first use.",
		"import": "Import",
		"sharedDataDuplicateEmails": "Duplicate emails are not allowed. Remove the duplicate email to continue.",
		"buildDate": "Build Date",
		"sozoApp": "SOZOapp",
		"noSOZODevicesPaired": "No SOZO devices have been paired.",
		"noDeviceSelected": "The action could not be completed. Select a SOZO device in the SOZOapp settings menu to proceed.",
		"ssoLogoutWarning": "You are about to be signed out of SOZO. As an SSO user, you will still be signed into your Active Directory. If you are using a browser on a shared device, including Android/iOS, please clear all cookies and cache to ensure sign-out.",
		//endregion
		//region SERVER
		//region COGNITO
		"cognito_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"cognito_InitForgotPassword": "An email has been sent with instructions on how to reset your password. If you do not receive an email, check that the email address you have entered is correct, check your spam folder, or contact your MySOZO Administrator.",
		"cognito_ConfirmForgotPassword": "Your password has been updated.",
		"cognito_ResendConfirmationCode": "An email has been sent with instructions on how to reset your password. If you do not receive an email, check that the email you have entered is correct, check your spam folder, or contact your MySOZO Administrator.",
		"cognito_InitialChangePassword": "Your account has been verified.",
		"cognito_UpdateEmailAuthUser": "Your email has been updated.",
		"cognito_DisableUser": "User has been disabled.",
		"cognito_EnableUser": "User has been enabled.",
		"cognito_SignOutAuthUser": "You have been signed out.",
		"cognito_AuthUser": "You have logged in.",
		"cognito_GetUser": "User has been retrieved.",
		"cognito_RefreshToken": "Users token has been refreshed.",
		"cognito_ResetUserPassword": "Your password has been reset.",
		"cognito_ChangedPassword": "Your password has been reset.",
		"cognito_TerminateUser": "User has been deleted.",
		"cognito_LogoutUser": "You have logged out.",
		"cognito_ListUsers": "User list has been retrieved.",
		"cognito_AdminListEvents": "List of user events has been retreived",
		"cognito_UserPoolClientIdNotFound": "User not found.",
		"cognito_UserPoolNotFound": "User not found.",
		"cognito_InternalErrorException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidLambdaResponseException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidParameterException": "The system cannot handle the provided input most likely due to invalid characters. Please make sure to validate your input. If the issue persists, please contact ImpediMed Technical Support.",
		"cognito_InvalidSmsRoleAccessPolicyException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidSmsRoleTrustRelationshipException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidUserPoolConfigurationException": "Please contact ImpediMed Technical Support.",
		"cognito_MFAMethodNotFoundException": "Please contact ImpediMed Technical Support.",
		"cognito_NotAuthorizedException": {
			"loggedIn": "We do not recognize the password entered. Please enter the password associated with the user who is currently signed into MySOZO.",
			"loggedOut": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password by selecting “Forgot Password?” or contact your MySOZO Administrator.",
		},
		"cognito_AuthUserWithTemp": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
		"cognito_PasswordResetRequiredException": "Please reset your password before continuing.",
		"cognito_ResourceNotFoundException": "Please contact ImpediMed Technical Support.",
		"cognito_TooManyRequestsException": "There was a temporary error.  Please try again and if the issue persists, contact ImpediMed Technical Support.",
		"cognito_UnexpectedLambdaException": "Please contact ImpediMed Technical Support.",
		"cognito_UserLambdaValidationException": "Please contact ImpediMed Technical Support.",
		"cognito_UserNotConfirmedException": "User has not been confirmed.",
		"cognito_UserNotFoundException": {
			"userLoggedIn": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
			"userLoggedOut": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password by selecting “Forgot Password?” or contact your MySOZO Administrator.",
		},
		"cognito_CodeDeliveryFailureException": "The system was unable to generate the verification code email. Try again or contact ImpediMed Technical Support.",
		"cognito_InvalidPasswordException": "Please contact ImpediMed Technical Support.",
		"cognito_PreconditionNotMetException": "Please contact ImpediMed Technical Support.",
		"cognito_UnsupportedUserStateException": "Please contact ImpediMed Technical Support.",
		"cognito_UsernameExistsException": "The email that you entered is already in use in another clinic. Each user must use a unique email address. Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_AliasExistsException": "The email that you entered is already in use in another clinic. Each user must use a unique email address. Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_CodeMismatchException": {
			"changeEmail": "Could not update the email address due to an invalid or expired verification code. Please re-enter the code or request a new code.",
			"login": "The temporary password entered is not valid or has expired. Please re-enter the password or navigate to MySOZO and select “Forgot password?” to obtain a new temporary password.",
			"deleteMFA": "Could not de-activate the Multi-Factor Authentication. The verification code entered is not valid or has expired. Please re-enter the code or request a new code.",
			"updateFacilityMfa": "Could not update Multi-Factor Authentication. The verification code entered is not valid or has expired. Please re-enter the code or request a new code.",
			"resetPassword": "Could not reset the password. The link provided for password reset is invalid or has expired."
		},
		"cognito_ExpiredCodeException": "The verification code has expired. Please request a new code by resetting the password.",
		"cognito_SoftwareTokenMFANotFoundException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidEmailRoleAccessPolicyException": "Please contact ImpediMed Technical Support.",
		"cognito_LimitExceededException": "Please contact ImpediMed Technical Support.",
		"cognito_UserPoolTaggingException": "Please contact ImpediMed Technical Support.",
		"cognito_AccessDeniedException": "You do not have sufficient access to perform this action. Please contact ImpediMed Technical Support.",
		"cognito_IncompleteSignature": "Please contact ImpediMed Technical Support.",
		"cognito_InternalFailure": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidClientTokenId": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidParameterCombination": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidParameterValue": "An invalid value was entered. ",
		"cognito_InvalidQueryParameter": "An invalid query was initiated. Try again with a different query.",
		"cognito_MalformedQueryString": "An invalid query was initiated. Try again with a different query.",
		"cognito_MissingAction": "Please contact ImpediMed Technical Support.",
		"cognito_MissingAuthenticationToken": "Please contact ImpediMed Technical Support.",
		"cognito_MissingParameter": "Please contact ImpediMed Technical Support.",
		"cognito_OptInRequired": "Please contact ImpediMed Technical Support.",
		"cognito_RequestExpired": "The request has expired. Please try again.",
		"cognito_ServiceUnavailable": "There was a temporary error.  Please try again and if the issue persists, contact ImpediMed Technical Support.",
		"cognito_ThrottlingException": "There was a temporary error.  Please try again and if the issue persists, contact ImpediMed Technical Support.",
		"cognito_ValidationError": "Please contact ImpediMed Technical Support.",
		"cognito_MissingRequiredParameter": "Please contact ImpediMed Technical Support.",
		"cognito_ProviderNotFound": "No Domain Name was found with the name entered.",
		"cognito_EmailAlreadyExists": "The email address is already in use in the system.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_EmailAlreadyExists_sso": "The email address is already in use in the system.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_DuplicateProviderException": "The Domain Name already exists and must be unique. Please contact ImpediMed Technical support if you are unable to add your Domain Name.",
		//endregion
		//region S3
		"s3_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"s3_FetchFacilityLogo": "Logo has been uploaded.",
		"s3_UploadFacilityLogo": "Logo has been uploaded.",
		"s3_DeleteFacilityLogo": "Logo has been deleted.",
		"s3_UploadFile": "Upload has completed.",
		"s3_GetUrl": "Please contact ImpediMed Technical Support.",
		"s3_CustomLogoAlreadyExists": "Please contact ImpediMed Technical Support.",
		"s3_NotFound": "Please contact ImpediMed Technical Support.",
		"s3_AccessDenied": "Please contact ImpediMed Technical Support.",
		"s3_AccountProblem": "Please contact ImpediMed Technical Support.",
		"s3_AllAccessDisabled": "Please contact ImpediMed Technical Support.",
		"s3_AmbiguousGrantByEmailAddress": "Please contact ImpediMed Technical Support.",
		"s3_AuthorizationHeaderMalformed": "Please contact ImpediMed Technical Support.",
		"s3_BadDigest": "Please contact ImpediMed Technical Support.",
		"s3_BucketAlreadyExists": "Please contact ImpediMed Technical Support.",
		"s3_BucketAlreadyOwnedByYou": "Please contact ImpediMed Technical Support.",
		"s3_BucketNotEmpty": "Please contact ImpediMed Technical Support.",
		"s3_CredentialsNotSupported": "Please contact ImpediMed Technical Support.",
		"s3_CrossLocationLoggingProhibited": "Please contact ImpediMed Technical Support.",
		"s3_EntityTooSmall": "Please contact ImpediMed Technical Support.",
		"s3_EntityTooLarge": "Please contact ImpediMed Technical Support.",
		"s3_ExpiredToken": "Please contact ImpediMed Technical Support.",
		"s3_IllegalVersioningConfigurationException": "Please contact ImpediMed Technical Support.",
		"s3_IncompleteBody": "Please contact ImpediMed Technical Support.",
		"s3_IncorrectNumberOfFilesInPostRequest": "Please contact ImpediMed Technical Support.",
		"s3_InlineDataTooLarge": "Please contact ImpediMed Technical Support.",
		"s3_InternalError": "Please contact ImpediMed Technical Support.",
		"s3_InvalidAccessKeyId": "Please contact ImpediMed Technical Support.",
		"s3_InvalidAddressingHeader": "Please contact ImpediMed Technical Support.",
		"s3_InvalidArgument": "Please contact ImpediMed Technical Support.",
		"s3_InvalidBucketName": "Please contact ImpediMed Technical Support.",
		"s3_InvalidBucketState": "Please contact ImpediMed Technical Support.",
		"s3_InvalidDigest": "Please contact ImpediMed Technical Support.",
		"s3_InvalidEncryptionAlgorithmError": "Please contact ImpediMed Technical Support.",
		"s3_InvalidLocationConstraint": "Please contact ImpediMed Technical Support.",
		"s3_InvalidObjectState": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPart": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPartOrder": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPayer": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPolicyDocument": "Please contact ImpediMed Technical Support.",
		"s3_InvalidRange": "Please contact ImpediMed Technical Support.",
		"s3_InvalidRequest": "Please contact ImpediMed Technical Support.",
		"s3_InvalidSecurity": "Please contact ImpediMed Technical Support.",
		"s3_InvalidSOAPRequest": "Please contact ImpediMed Technical Support.",
		"s3_InvalidStorageClass": "Please contact ImpediMed Technical Support.",
		"s3_InvalidTargetBucketForLogging": "Please contact ImpediMed Technical Support.",
		"s3_InvalidToken": "Please contact ImpediMed Technical Support.",
		"s3_InvalidURI": "Please contact ImpediMed Technical Support.",
		"s3_KeyTooLongError": "Please contact ImpediMed Technical Support.",
		"s3_MalformedACLError": "Please contact ImpediMed Technical Support.",
		"s3_MalformedPOSTRequest": "Please contact ImpediMed Technical Support.",
		"s3_MalformedXML": "Please contact ImpediMed Technical Support.",
		"s3_MaxMessageLengthExceeded": "Please contact ImpediMed Technical Support.",
		"s3_MaxPostPreDataLengthExceededError": "Please contact ImpediMed Technical Support.",
		"s3_MetadataTooLarge": "Please contact ImpediMed Technical Support.",
		"s3_MethodNotAllowed": "Please contact ImpediMed Technical Support.",
		"s3_MissingAttachment": "Please contact ImpediMed Technical Support.",
		"s3_MissingContentLength": "Please contact ImpediMed Technical Support.",
		"s3_MissingRequestBodyError": "Please contact ImpediMed Technical Support.",
		"s3_MissingSecurityElement": "Please contact ImpediMed Technical Support.",
		"s3_MissingSecurityHeader": "Please contact ImpediMed Technical Support.",
		"s3_NoLoggingStatusForKey": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchBucket": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchBucketPolicy": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchKey": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchLifecycleConfiguration": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchUpload": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchVersion": "Please contact ImpediMed Technical Support.",
		"s3_NotImplemented": "Please contact ImpediMed Technical Support.",
		"s3_NotSignedUp": "Please contact ImpediMed Technical Support.",
		"s3_OperationAborted": "Please contact ImpediMed Technical Support.",
		"s3_PermanentRedirect": "Please contact ImpediMed Technical Support.",
		"s3_PreconditionFailed": "Please contact ImpediMed Technical Support.",
		"s3_Redirect": "Please contact ImpediMed Technical Support.",
		"s3_RestoreAlreadyInProgress": "Please contact ImpediMed Technical Support.",
		"s3_RequestIsNotMultiPartContent": "Please contact ImpediMed Technical Support.",
		"s3_RequestTimeout": "Please contact ImpediMed Technical Support.",
		"s3_RequestTimeTooSkewed": "Please contact ImpediMed Technical Support.",
		"s3_RequestTorrentOfBucketError": "Please contact ImpediMed Technical Support.",
		"s3_SignatureDoesNotMatch": "Please contact ImpediMed Technical Support.",
		"s3_ServiceUnavailable": "Please contact ImpediMed Technical Support.",
		"s3_SlowDown": "Please contact ImpediMed Technical Support.",
		"s3_TemporaryRedirect": "Please contact ImpediMed Technical Support.",
		"s3_TokenRefreshRequired": "Please contact ImpediMed Technical Support.",
		"s3_TooManyBuckets": "Please contact ImpediMed Technical Support.",
		"s3_UnexpectedContent": "Please contact ImpediMed Technical Support.",
		"s3_UnresolvableGrantByEmailAddress": "Please contact ImpediMed Technical Support.",
		"s3_UserKeyMustBeSpecified": "Please contact ImpediMed Technical Support.",
		//endregion
		//region SES
		"ses_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"ses_SuccessMessageFromSES": "Email has been sent.",
		"ses_EmailUpdateSuccessMessageFromSES": "Email has been sent to updated email address.",
		"ses_ExportSuccessMessageFromSES": "Export email has been sent.",
		//endregion
		//region LAMBDAS
		"lambdas_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_ALIAS_EXISTS": "The facility alias already exists and must be unique.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_BANNED": "This account has been locked for 30 minutes because the incorrect password was entered too many times. Reset the password to continue immediately.",
		"lambdas_ER_SIGNAL_EXCEPTION: CLIENT_WRONG_VERSION": "This version of software is not compatible. Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_PASSWORD_IS_EXPIRED": "Your password has expired. Please set a new one.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_USER": "Your account has been deleted. Please contact your MySOZO administrator if this is in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_PATIENT": "The patient is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_MEASUREMENT": "The measurement is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_ADMIN": "Your account has been deleted. Please contact your MySOZO administrator if this is in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_USER_ROLE_AND_CLIENT": "The system does not recognize this user. If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_ACTIVE_VERSION_AVAILABLE": "No active version is available. If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_ACTIVE": "The selected user has already been restored. Refresh the page to update the user list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_INACTIVE": "The selected user has already been deleted. Refresh the page to update the user list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_DELETED": "The selected user account has been deleted. Restore the user in order to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: ADMIN_DELETED": "The selected user has already been deleted. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_LICENSE": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_VALUES": "The entry is not accepted by MySOZO. If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: SAME_PASSWORD": "Your password cannot be one of the last three passwords used. Choose another password to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: UNKNOWN_ERROR": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_NOT_EXISTS": "Facility does not exist in MySOZO. Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: ACTIVE_PATIENT_MRN_EXISTS": "Duplicate MRNs are not allowed. An active patient with the same MRN already exists.",
		"lambdas_ER_SIGNAL_EXCEPTION: INACTIVE_PATIENT_MRN_EXISTS": "Duplicate MRNs are not allowed. A deleted patient with the same MRN already exists. ",
		"lambdas_ER_SIGNAL_EXCEPTION: ACTIVE_PATIENT_DATA_COMBINATION_EXISTS": "An active patient with the same First Name, Last Name and Date of Birth already exists. Are you sure that you are saving to the correct patient?",
		"lambdas_ER_SIGNAL_EXCEPTION: INACTIVE_PATIENT_DATA_COMBINATION_EXISTS": "A deleted patient with the same First Name, Last Name, and Date of Birth already exists. Are you sure you are creating or editing the correct patient?",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS": "The Email Address entered is already in use. Email Addresses must be unique.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_BASIC_REPORTING_LICENSE": "The licenses granted do not allow for report generation. Please contact ImpediMed Technical Support for further details.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_MEASUREMENTS": "An export cannot be generated because no SOZO measurement(s) has been taken for the selected patient(s).",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_ASSESSMENT_TYPE": "The system has detected an error with your license status. Please sign out and sign back in to resolve this issue. If this error continues, please contact ImpediMed technical support.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_DEVICE_IS_IN_USE": "One or more of the SOZO device serial numbers are already in use.  Either choose a different serial number, or delete the identical serial number from the clinic that it has already been assigned to.  If this does not resolve the issue, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: COGNITO_ID_EXISTS": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS_IN_OTHER_FACILITY": "This email is already in use in another clinic. Each user must use a unique email address. Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS_ON_PATIENT": "The email entered already exists for another patient.  The email used must be unique.  ",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXIST_ON_ACTIVE_USER": "The email entered already exists for another clinician or administrator.  The email used must be unique. ",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXIST_ON_INACTIVE_USER": "The email entered already exists for another clinician or administrator that has been deleted. The email used must be unique. To use this email, either restore the deleted user, or restore the deleted user and then modify the email and delete the user again.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_VESRION_UPDATE": "The system has not found an update.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_MEASUREMENT_IN_DATE_RANGE": "Your selected date range must include the date of the measurement that you have selected for printing.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_VERSION": "There is an error with the Software version. Please re-sign in or contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_CALL": "Your role does not allow you to perform this action.",
		"lambdas_ER_SIGNAL_EXCEPTION: OVER_FIFTY_GROUPS": "The maximum number of groups (50) has been reached. Group not created.",
		"lambdas_ER_SIGNAL_EXCEPTION: GROUP_EXISTS": "The group name entered is already in use. Please enter a unique name.",
		"lambdas_ER_SIGNAL_EXCEPTION: DEVICE_NAME_EXISTS": "The device name entered is already in use. Please enter a unique name.",
		"lambdas_ER_SIGNAL_EXCEPTION: GROUP_DELETED": "The selected patient group has already been deleted. Refresh the page to update the patient group list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: LYMPHEDEMA_SETTINGS_NOT_MATCH": "An issue has been detected with this measurement.  Re-load the Patient Dashboard (Results List page) to see results with the most current Lymphedema settings.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_ALREADY_IN_GROUP": "The following patient is already in this group.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_NOT_IN_GROUP": "The following patient has already been deleted from this group.",
		"lambdas_ER_SIGNAL_EXCEPTION: ONE_PATIENT_IS_DELETED": "The following patient has already been deleted. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: NOT_AVAILABLE_PATIENTS": "No active patients found according to your selections. Please, make sure that there is at least one active patient and try again.",
		"lambdas_ER_SIGNAL_EXCEPTION: OVER_EXPORT_SIZE_LIMIT": "The amount of data selected for export exceeds system limits. Please select a smaller number of patients or a reduced date range to export or contact ImpediMed Technical Support to manually export your data.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_RENAL_BASELINE": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_LOGIN_FOR_SSO_FACILITY": "We do not recognize your email and/or password. Confirm that you are a user of Single Sign On and try again. If this does not resolve the issue, contact your MySOZO Administrator.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_FACILITY_USER": "The email address is already in use in the system.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_PROVIDER_NAME_EXISTS": "The domain name already exists and must be unique.",
		"lambdas_ER_SIGNAL_EXCEPTION: TOO_MANY_CLINICS_SELECTED": "The amount of data selected for export exceeds system limits. Please select a smaller number of clinics or contact ImpediMed Technical Support to manually export your data.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_NOT_AVAILABLE": "The MFA is disabled for your clinic. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_TIME_EXPIRED": "The time to enable the MFA has been expired and cannot be disabled. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_ACTIVATED": "SSO cannot be enabled while Multi-Factor Authentication is enabled. Please disable Multi-Factor Authentication before enabling SSO.",
		"lambdas_ER_SIGNAL_EXCEPTION: SSO_ACTIVATED": "Multi-Factor Authentication cannot be enabled while SSO is enabled. Please disable SSO before enabling Multi-Factor Authentication.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_DELETED": "The selected patient has been deleted. Restore the patient in order to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_INACTIVE": "The selected patient has already been deleted. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_ACTIVE": "The selected patient has already been restored. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: NOTE_LIMIT_REACHED": "A maximum of 10 notes is allowed per patient. To add a new note, an existing note must first be deleted.",
		"lambdas_ER_SIGNAL_EXCEPTION: NOTE_INACTIVE": "The selected note has already been deleted. Refresh the page to update the notes list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_NAME_EXISTS": "Tag name already exists. Please choose another one.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_ALREADY_DELETED": "The selected tag has already been deleted. Refresh the page to update the tag list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: POST_TAG_OVER_HUNDRED": "The maximum number of tags (100) has been reached. Tag not created.",
		"lambdas_ER_SIGNAL_EXCEPTION: MERGE_NOTE_LIMIT_EXCEEDED": "The total number of notes between the two patients exceeds the limit of 10 notes.  Delete some notes before re-attempting to merge.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_ASSIGNED_TO_RESULT": "The selected tag has already been assigned to this measurement.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_DELETED_FROM_RESULT": "The selected tag has already been unassigned from this measurement.",
		"lambdas_ER_SIGNAL_EXCEPTION: MAX_TAGS_ASSIGNED_TO_RESULT": "A maximum of 200 tags are allowed per measurement. To assign a new tag, an existing tag must first be removed.",
		"lambdas_ER_SIGNAL_EXCEPTION: SOURCE_PATIENT_INACTIVE": "The source patient has been deleted. Please refresh the screen and try again.",
		"lambdas_ER_SIGNAL_EXCEPTION: DESTINATION_PATIENT_INACTIVE": "The destination patient has been deleted. Please refresh the screen and try again.",
		"lambdas_ER_SIGNAL_EXCEPTION: LACK_OF_DESTINATION_PATIENT_HEIGHT": "Please set the height of the destination patient before attempting to merge.",
		"lambdas_ER_SIGNAL_EXCEPTION: DELETED_MEASUREMENT": "The selected measurement has already been deleted.",
		"lambdas_ER_SIGNAL_EXCEPTION: PENDING_VERSION_UPDATE": "The MySOZO update is in progress and typically takes a few minutes to complete. During this time, the MySOZO system will be unavailable to all users within your clinic. A notification email will be sent when the update has been completed.",
		"lambdas_ER_SIGNAL_EXCEPTION: SSO_INVALID_INPUT": "You have been signed out due to a change in the SSO settings of your clinic. Please re-sign in using SSO.",
		"lambdas_ER_SIGNAL_EXCEPTION: PENDING_SSO_DISABLE": "MySOZO is currently unavailable due to an in process update of the SSO settings. Please sign in again shortly or contact your IT administrator for more details.",
		"lambdas_ER_SIGNAL_EXCEPTION: PENDING_MFA_DISABLE": "MySOZO is currently unavailable due to an in process update of the MFA settings. Please sign in again shortly or contact your IT administrator for more details.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_INVALID_INPUT": "You have been signed out due to a change in the MFA settings of your clinic. Please re-sign in using MFA.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_EXISTS": "This patient has already been shared and cannot be shared again.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_INVALID_USER": "The email entered does not correspond to an active user with Clinician role within the clinic selected. Re-confirm the user does not have only Administrator rights.",
		"lambdas_ER_SIGNAL_EXCEPTION: DELETED_RECEIVER": "The email entered does not correspond to an active clinician user within the clinic selected. Re-confirm the clinic and/or email with the intended recipient.",
		"lambdas_ER_SIGNAL_EXCEPTION: CLINICIAN_NOT_EXIST": "One or more clinician email(s) are not valid MySOZO users. Update the email(s) to only include MySOZO users within the specified clinic.",
		"lambdas_ER_SIGNAL_EXCEPTION: TOO_MANY_MEASUREMENTS": "The patient cannot be shared because the maximum number of results (5000) has been reached. Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_PATIENT_DELETED": "The patient selected is no longer available because their data has been recalled by the sending clinic.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_DELETED": "The selected shared patient has already been recalled. Refresh the page to update the list.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_SHARING": "This action cannot be performed because the shared patient has been recalled or imported.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_NO_MEASUREMENTS": "The selected patient must have a measurement taken before data can be shared.",
		"lambdas_ER_SIGNAL_EXCEPTION: DEVICE_NOT_EXIST": "The selected device has been deleted. Please contact ImpediMed Technical Support to restore the device in order to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: ORIGIN_EXISTS": "You are about to perform a significant level of changes to a patient’s data since you will perform import to the same patient that previously shared.",
		"lambdas_ER_SIGNAL_EXCEPTION: RECALCULATION_ERROR": "An error occurred during profile recalculation. Please check and complete all fields accurately. Try different value combinations if needed. Retry the update and if the issue persists, contact ImpediMed technical support.",
		"lambdas_EC2AccessDeniedException": "Please contact ImpediMed Technical Support.",
		"lambdas_EC2ThrottledException": "Please contact ImpediMed Technical Support.",
		"lambdas_EC2UnexpectedException": "Please contact ImpediMed Technical Support.",
		"lambdas_ENILimitReachedException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidParameterValueException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidRequestContentException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidRuntimeException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidSecurityGroupIDException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidSubnetIDException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidZipFileException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSAccessDeniedException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSDisabledException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSInvalidStateException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSNotFoundException": "Please contact ImpediMed Technical Support.",
		"lambdas_RequestTooLargeException": "Please contact ImpediMed Technical Support.",
		"lambdas_ResourceNotFoundException": "Please contact ImpediMed Technical Support.",
		"lambdas_ServiceException": "Please contact ImpediMed Technical Support.",
		"lambdas_SubnetIPAddressLimitReachedException": "Please contact ImpediMed Technical Support.",
		"lambdas_TooManyRequestsException": "Please contact ImpediMed Technical Support.",
		"lambdas_UnsupportedMediaTypeException": "Please contact ImpediMed Technical Support.",
		"lambdas_v_check_user_banned": "User information retreived.",
		"lambdas_v_post_failed_login": "Failed login posted.",
		"lambdas_v_put_user_banned": "Successful login has been recorded.",
		"lambdas_v_auth_login": "The user has been logged in.",
		"lambdas_v_log_login": "The system has failed to log the user in.",
		"lambdas_v_get_patients_pagination": "Patients have been retreived.",
		"lambdas_v_put_patient_status_inactive": "The patient has been deleted.",
		"lambdas_v_put_patient_status_active": "The patient has been restored.",
		"lambdas_v_put_clinician_status_inactive": "The clinician has been deleted.",
		"lambdas_v_put_clinician_status_active": "The clinician status has been changed to active.",
		"lambdas_v_put_administrator_status_inactive": "The administrator has been deleted.",
		"lambdas_v_put_administrator_status_active": "The administrator status has been changed to active.",
		"lambdas_v_put_administrator_clinician_status_active": "The user has been restored.",
		"lambdas_v_put_administrator_clinician_status_inactive": "The user has been deleted.",
		"lambdas_v_put_clinician_settings": "Measurement units have been updated.",
		"lambdas_v_put_user_profile": {
			"updateCurrentUser": "Account has been updated.",
			"updateSelectedUser": "User profile has been updated.",
		},
		"lambdas_v_check_password": "The password used is valid.",
		"lambdas_v_put_password": "Password has been updated.",
		"lambdas_v_log_logout": "You have been logged out.",
		"lambdas_v_get_patient": "Patient has been retrieved.",
		"lambdas_v_get_measurements": "Measurement data has been retrieved.",
		"lambdas_v_get_measurement": "Measurement data has been retrieved.",
		"lambdas_v_get_frx": "Measurement data has been retrieved.",
		"lambdas_v_get_facility_user": "User has been retrieved.",
		"lambdas_v_put_user_email": "The email address has been updated.",
		"lambdas_v_put_patient_weight": "Weight has been updated.",
		"lambdas_v_put_patient_baseline": "The baseline has been updated.",
		"lambdas_v_put_result_status": "Result has been updated.",
		"lambdas_v_post_measurements": "Result has been stored.",
		"lambdas_v_post_patients": "The patient has been created.",
		"lambdas_v_put_patients": "Patient profile has been updated.",
		"lambdas_v_put_timezones": "Time Zone has been updated.",
		"lambdas_v_put_facility_settings": "Security settings have been updated. You will be signed out for changes to take effect.",
		"lambdas_v_get_facility_users_pagination": "Facility users have been retrieved.",
		"lambdas_v_get_facilities_pagination": "Facilities have been retrieved.",
		"lambdas_v_check_facility_alias": "Facility alias is unique.",
		"lambdas_v_post_facilities": "Facility has been created.",
		"lambdas_v_put_facilities": "Facility has been updated.",
		"lambdas_v_get_facilities_extra": "Facility extra information has been retrieved.",
		"lambdas_v_put_facility_administrators": "Administrator has been updated.",
		"lambdas_v_get_facility_settings": "Facility settings have been retrieved.",
		"lambdas_v_get_facility": "Facility settings have been retrieved.",
		"lambdas_v_post_facility_users": "User has been created and an email has been sent to set the initial password. If the user does not receive an email, check that the email is correct, check the spam folder, or direct the user to use the password reset function on the sign in page of MySOZO.",
		"lambdas_v_refresh_token": "Facility update information retrieved.",
		"lambdas_v_get_app_update": "The facility update my continue.",
		"lambdas_v_check_user_email": "Export has been retrieved.",
		"lambdas_v_post_exports": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_v_get_cognitoids_facility": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_v_get_access_logs": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_v_get_change_logs": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_dateRangeErrorLdex": "The selected date range does not contain trending data for ",
		"lambdas_dateRangeErrorTissueAnalysis": "The selected date range does not contain trending data for ",
		"lambdas_dateRangeErrorFluidStatus": "The selected date range does not contain trending data for ",
		"lambdas_v_post_report_logo_error": "The report logo file type must be a native PNG file. The file that was attached is not a compatible file type or has been corrupted. A MySOZO administrator must replace the report logo picture before continuing.",
		"lambdas_v_put_update_version": "Updated successfully",
		"lambdas_v_put_patients_merges": "The patients have been merged.",
		"lambdas_v_put_patients_results_activation": "The measurement has been deleted.",
		"lambdas_v_post_facilities_groups": "Group has been created.",
		"lambdas_v_delete_facilities_groups": "Group has been deleted.",
		"lambdas_v_put_facility_groups": "Group has been updated.",
		"lambdas_v_facility_device_name_update": "Device name has been updated.",
		"lambdas_v_put_facility_settings_sso": "SSO settings have been updated.",
		"lambdas_v_post_mfa": "Multi-Factor Authentication has been turned on.",
		"lambdas_v_delete_mfa": "Multi-Factor Authentication has been turned off.",
		"lambdas_EnableSoftwareTokenMFAException": "The Verification Code provided is not valid. Please re-enter the code and ensure that it has not expired.",
		"lambdas_v_post_notes": "The note has been posted.",
		"lambdas_v_delete_notes": "The note has been deleted.",
		"lambdas_v_put_notes": "The note has been updated.",
		"lambdas_v_post_group_patients": "The patient(s) have been added to the group.",
		"lambdas_v_delete_group_patients": "The patient(s) have been removed from the group.",
		"lambdas_v_put_mfa": "You have been signed out due to a change in the MFA settings of your clinic. Please re-sign in.",
		"lambdas_v_put_sso": "You have been signed out due to a change in the SSO settings of your clinic. Please re-sign in.",
		"lambdas_v_put_tag": "Tag has been updated.",
		"lambdas_v_post_tag": "Tag has been created.",
		"lambdas_v_delete_tag": "Tag has been deleted.",
		"lambdas_v_post_results_tag": "Tag has been added.",
		"lambdas_v_put_results_tag": "Tag has been updated.",
		"lambdas_v_delete_results_tag": "Tag has been un-assigned.",
		"lambdas_v_post_update_clinic": "A MySOZO update is in progress and typically takes a few minutes to complete. During this time, the MySOZO system will be unavailable to all users within your clinic. A notification email will be sent when the update has been completed.",
		"lambdas_v_put_facilities_settings_ehr": "The EHR settings have been updated.",
		"lambdas_v_put_facilities_settings_lpp": "LPP Protocol Compliance settings have been updated.",
		"lambdas_v_put_patient_invalid_age": "The date of birth entered has caused a measurement to occur when the patient was younger than the minimum age of 6 years. Please confirm the date of birth was entered correctly and/or delete the appropriate measurements to ensure that all measurements are taken after the patient has turned 6 before updating the date of birth.",
		"lambdas_v_put_users_settings_weightpretare": "Weight Pre-Tare has been updated.",
		"lambdas_v_sharing_create": "Patient has been shared.",
		"lambdas_v_sharing_delete": "Shared patient has been recalled.",
		"lambdas_v_sharing_update": "Shared data record updated",
		"lambdas_v_sharing_import_create": "Shared patient has been imported",
		"lambdas_v_sharing_import_patient_create": "New shared patient has been imported",
		"lambdas_NotAvailablePatientsException": "No active patients found according to your selections. Please, make sure that there is at least one active patient and try again.",
		//endregion
		//region VALIDATOR
		"validator_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"validator_email_checkNotEmpty": "Please enter your Email Address.",
		"validator_password_checkNotEmpty": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
		"validator_password_checkPassword": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
		"validator_passwordsValidation_checkEquals": "New Password and Confirm New Password entries do not match.",
		"validator_nameFacilities_checkAlphabeticExtended": "Facility name is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_aliasFacilities_checkAlphabeticExtended": "Facility alis is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_GPIDFacilities_checkAlphabeticExtended": "Facility GPID is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_resetPasswordTimeFacilitySettings_checkNumericRange": "Facility password reset time is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_sessionExpirationTimeFacilitySettings_checkNumericRange": "Facility password reset time is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_addressLocations_checkLength": "Facility address is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_addressLocations_checkAlphabeticExtended": "Facility address is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_cityLocations_checkLength": "Facility location city is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_cityLocations_checkAlphabeticExtended": "Facility location city is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_stateProvinceLocations_checkLength": "Facility state is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_stateProvinceLocations_checkAlphabeticExtended": "Facility state is invalid and must be alpha numeric. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_postalCodeLocations_checkLength": "Facility postal code is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_postalCodeLocations_checkAlphabeticExtended": "Facility postal code is invalid and may on contain alpha-numeric characters. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_deviceSerialNumberLicensedDevices_checkNumericLength": "Device serial number cannot exceed 45 characters.",
		"validator_deviceSerialNumberLicensedDevices_checkAlphabeticExtended": "Device serial number cannot exceed 45 characters.",
		"validator_timezoneTimezones_checkTimezone": "Facility timezone is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_UTCoffsetTimezones_checkUTCoffset": "Facility timezone is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_countryCountries_checkCountry": "Country is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_countryLocations_checkAlphabeticExtended": "Country is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_firstNameUser_checkAlphabeticExtended": "First Name allows a maximum of 45 characters and may contain letters, numbers, spaces, or the following characters ()#_\\-\\/%&@:;+,.",
		"validator_middleNameUser_checkAlphabeticExtended": "Middle name is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_lastNameUser_checkAlphabeticExtended": "Last Name allows a maximum of 45 characters and may contain letters, numbers, spaces, or the following characters ()#_\\-\\/%&@:;+,.",
		"validator_countryPhoneCodeUser_checkPhoneCode": "Country phone code is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_phoneNumberUser_checkPhone": "Phone number is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_mrnUser_checkAlphanumeric": "MRN is invalid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_weightPatients_checkNumericRange": "Weight entry is invalid and must be a number without a comma allowed. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_heightPatients_checkNumericRange": "Height entry is invalid and must be a number (##.#). Note: no comma is allowed. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_firmwareName_checkEquals": "SOZO device firmware is the most current version and does not need to be updated.",
		"validator_logoPNG": "The report logo file type must be a native PNG file. The file that was attached is not a compatible file type or has been corrupted. Please replace the report logo picture before continuing.",
		"validator_invalidSearchKey": "The search query allows a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"validator_nameFacilities_checkNotLinks": "Periods must be the last character in the entry or followed by a space. Please modify your entry in order to successfully save.",
		"validator_aliasFacilities_checkNotLinks": "Periods must be the last character in the entry or followed by a space. Please modify your entry in order to successfully save.",
		"validator_GPIDFacilities_checkNotLinks": "Periods must be the last character in the entry or followed by a space. Please modify your entry in order to successfully save.",
		"validator_resetPasswordTimeFacilitySettings_checkNotLinks": "Periods must be the last character in the entry or followed by a space. Please modify your entry in order to successfully save.",
		"validator_sessionExpirationTimeFacilitySettings_checkNotLinks": "Periods must be the last character in the entry or followed by a space. Please modify your entry in order to successfully save.",
		"validator_deviceSerialNumberLicensedDevices_checkNotLinks": "Periods must be the last character in the entry or followed by a space. Please modify your entry in order to successfully save.",
		"validator_addressLocations_checkNotLinks": "The Address entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_cityLocations_checkNotLinks": "The City entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_stateProvinceLocations_checkNotLinks": "The State entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_postalCodeLocations_checkNotLinks": "The Postal Code entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_timezoneTimezones_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_UTCoffsetTimezones_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_firstNameUser_checkNotLinks": "The First Name entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_middleNameUser_checkNotLinks": "The Middle Name entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_lastNameUser_checkNotLinks": "The Last Name entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_countryPhoneCodeUser_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_phoneNumberUser_checkNotLinks": "The Phone Number entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_mrnUser_checkNotLinks": "The MRN entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_measurementSerialNumberMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_measurementFirmwareVersionMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_measurementCalibrationDateMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_measurementApplicationVersionMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_mergePatientsIds": "Patient ID's are identical. Please contact ImpediMed Technical Support.",
		"validator_firstNameUser_checkNotNullOrUndefined": "The First Name associate with this account is not valid. If you are using SSO, please consult with your MySOZO IT Administrator on the SSO set-up for your account.",
		"validator_firstNameUser_checkNotEmpty": "First Name must be entered.",
		"validator_lastNameUser_checkNotNullOrUndefined": "The Last Name associated with this account in not valid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_lastNameUser_checkNotEmpty": "Last Name must be entered.",
		"validator_email_checkNotNullOrUndefined": "The Email associated with this account is not valid. If you are using SSO, please consult with your MySOZO IT Administrator on the SSO set-up for your account.",
		"validator_email_checkEmail": "Email format is invalid. Please re-enter the email address.",
		"validator_email_checkLength": "Email Address must be less than 60 characters total.",
		"validator_roleUser_checkNotNullOrUndefined": "User role is invalid.",
		"validator_roleUser_checkNotEmpty": "User role must be selected.",
		"validator_roleUser_checkEnum": "The user role is invalid. If using SSO, a user may be a member of only one user/permission group at a time. Please confirm that the user is not entered in multiple user/permission groups and if this does not resolve the issue, contact ImpediMed technical support.",
		"validator_ssoInfo_checkNotLinks": "The Domain Name entry should include only the second level portion of the domain name. For example, if your domain name is www.clinicname.com only enter “clinicname” without the “.com”",
		"validator_tagName_checkNotNullOrUndefined":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkNotEmpty":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkNotLinks":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkAlphabeticExtended":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkLength":"Please contact ImpediMed Technical Support.",
		"validator_colorCode_checkNotNullOrUndefined":"Please contact ImpediMed Technical Support.",
		"validator_colorCode_checkNotEmpty":"Please contact ImpediMed Technical Support.",
		"validator_colorCode_checkColorCode":"Please contact ImpediMed Technical Support.",
		//endregion
		//region CALCULATE
		"calculate_measurement": "MySOZO was unable to complete the measurement calculations for one or more body segments. This may have occurred due to poor electrical contact or loss of contact between the patient and one or more electrodes. Re-dampen the hands and feet or the SOZO electrodes, maintain good contact with the electrodes, and re-try the measurement. If this error persists, please contact ImpediMed Technical Support.",
		//endregion
		//region DEVICE
		"device_commException": "An error occurred during communication with the SOZO device over Bluetooth. Retry and ensure that the SOZO device is powered on and is located near the tablet, and minimize any potential interference from other wireless devices. If the issue persists, try again and contact ImpediMed technical support.",
		"device_bluetoothException": "An error occurred during communication with the SOZO device over Bluetooth. Retry and ensure that the SOZO device is powered on and is located near the PC, and minimize any potential interference from other wireless devices. If the issue persists, try again and contact ImpediMed technical support.",
		"device_measurementInProgress": "Measurement not started. There is a measurement already in progress with the selected SOZO device.",
		"device_measurementFailedSelfTest": "Measurement failed because there is no record of a successful self-test. Please complete a successful self-test and if the issue persists, contact ImpediMed Technical Support.",
		"device_tooManySenseChannels": "Measurement failed due to a device error. Please re-try the measurement and if the issue persists, contact ImpediMed Technical Support.",
		"device_measurementElectrodeCheckFailure": "Measurement was not started because the electrode check failed. Re-try the measurement while ensuring that the patient is standing still and bare hands and feet are covering all of the electrodes.",
		"device_selfTestFailure": "Self-test failed. Re-try the test and ensure that nothing is touching or standing on the SOZO device. If the issue persists, contact ImpediMed technical support.",
		"device_invalidFriendlyName": "The Bluetooth ID of the device is invalid. Please contact ImpediMed technical support.",
		"device_scaleNotCalibrated": "The SOZO device has not been calibrated. If the device has been calibrated and the issue persists, contact ImpediMed technical support.",
		"device_weightNotRetrieved": "The SOZO device was unable to finalize a weight measurement. Please retry and ensure that the patient is standing on the device with as little movement as possible and does not rest on or touch another object (including the SOZO Pro Hand Unit). If the issue persists, contact ImpediMed technical support.",
		"device_scaleNotTared": "The scale did not Tare because the Tare value exceeds the limit (22 kg or 48.5 lbs). Retry with less weight on the SOZO device. If the issue persists, try again and contact ImpediMed technical support.",
		"device_scaleOverweight": "The patient's weight exceeds the maximum limit of the SOZO Pro device (220 kg or 485 lbs). Please enter the weight into the SOZOapp manually and take this patient's measurements in a seated position.",
		"device_scaleUnderweight": "The patient's weight must be greater than 22 kg or 48.5 lbs. Re-try the weight measurement while the patient is standing on the SOZO Pro device or manually enter the weight if the patient is in a seated position.",
		"device_authException": "Bluetooth authentication failed. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		"device_unknownApiException": "An unexpected error occurred. Try again and if the issue persists contact ImpediMed technical support.",
		"device_frequencyListFailure": "A device error occurred. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		"device_bluetoothTurnedOff": "Bluetooth communication could not be established with the SOZO Device. Retry and ensure that Bluetooth is turned on, the SOZO device is powered on and is located near the PC, and minimize any potential interference from other wireless devices. If the issue persists, try again and contact ImpediMed technical support.",
		"device_bluetoothTurnedOffTablet": "There is a Bluetooth connection problem. To resolve, check the tablet settings and ensure that Bluetooth is turned on.",
		"device_pairingMobileException": "An error occurred during communication with the SOZO device over Bluetooth. Pair the SOZO device through the tablet operating system and retry. If the issue persists, try again and contact ImpediMed technical support.",
		"device_pairingPCException": "An error occurred during communication with the SOZO device over Bluetooth. Pair the SOZO device through the PC operating system and retry. If the issue persists, try again and contact ImpediMed technical support.",
		"device_pairingCanceled": "No SOZO device has been selected. Please select a device to initiate a measurement or self-test.",
		"device_softwareUpdateFailed": "The requested software update failed to start or communication was lost during the update process. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		//endregion
		//endregion
	},
	"en-rGB": {
		//region LOCAL
		"exception": "Please contact ImpediMed Technical Support.",
		"surgeryDate": "Surgery Date",
		"lppStartDate": "LPP Start Date",
		"selectSurgeryDate": "Select Surgery Date",
		"lppStartDateCheckbox": "LPP Start Date is same as Surgery Date",
		"infoSurgeryDate": "The Surgery Date is used to determine if a patient is included in LPP analytics and to calculate follow up measurement due dates.  The LPP Start Date is used to determine when to start tracking LPP protocol compliance.",
		"noConnectivity": "There is a connectivity problem. Check your internet connection or contact ImpediMed Technical Support.",
		"unauthorized": "There was a connectivity problem. Please logout and login and if the issue persists, contact ImpediMed Technical Support.",
		"timeout": "There is a connectivity problem. Check your internet connection or contact ImpediMed Technical Support.",
		"reportTimeout": "The amount of data required to generate the requested report exceeds the limitations of the system. To resolve, select fewer history graphs per report or update the selected date range to include fewer measurements. Note: Multiple reports may be required to generate all of the desired data output.",
		"username": "Username",
		"password": "Password",
		"yourPassword": "Your Password",
		"lastUpdated": "Last Updated",
		"notesCount": "# NOTES",
		"noNotes": "No Notes",
		"emptyNoteList": "No notes have been written for this patient. Create the first one.",
		"patientMrn": "Patient MRN",
		"createdBy": "Created by",
		"noPatientsInGroup": "There are no patients in this group. Add the first one.",
		"ldexOutOfRange": "L-Dex score out of chart range",
		"hfdexOutOfRangeOver": "HF-Dex out of chart range",
		"hfdexOutOfRangeUnder": "HF-Dex out of chart range",
		"outOfRangeOver": "out of chart range",
		"outOfRangeUnder": "out of chart range",
		"noRefRangeAge":"No reference range is available for this output. Patient age falls outside reference range data set.",
		"noRefRangeBmi":"No reference range is available for this output. Patient BMI falls outside reference range data set.",
		"segmentalNull": "This segmental result cannot be calculated due to issues found with the measurement data.  Please re-take the measurement to produce a valid result.",
		"emptyFieldsClinic": "Please fill all mandatory fields.",
		"selectedPatients": "Selected Patients",
		"noUserDataSelectedExport": "Please select one user data to export option",
		"allPatients": "all patients",
		"passwordExpirationRange": "Range: 30 - 1000 days",
		"range": "Range",
		"daysWord": "days",
		"minutes": "minutes",
		"sessionExpirationRange": "Range: 5 - 60 minutes",
		"noPatientsSelectedExport": "There are no patients selected for export. Please select at least one patient or select all patients.",
		"noPatientsShort": "No Patients",
		"noGroupsSelectedExport": "There are no groups selected for export. Please select at least one group.",
		"changePasswordSuccess": "Your password has been updated.",
		"sendTempPasswordSuccess": "An email has been sent with instructions on how to reset your password. If you do not receive an email, check that the email you have entered is correct, check your spam folder, or contact your MySOZO Administrator.",
		"title": "Log In To Your Account",
		"login": "Login",
		"newPasswordTitle": "Set New Password",
		"newPassword": "New Password",
		"oldPassword": "Old Password",
		"sendEmailTitle": "Reset Password",
		"dataToExport": "USER DATA TO EXPORT",
		"sessionExpirationMsg": "You will be automatically signed out in",
		"resetPasswordDescription": "Enter the email address used to set up your MySOZO account.",
		"activeDirectoryDescription": "Enter your company domain used for your MySOZO account.",
		"forgotPassword": "Forgot password?",
		"forgotPasswordExtra": "Forgot email used for MySOZO account? Please contact your MySOZO administrator.",
		"firstname": "First Name",
		"lastname": "Last Name",
		"middlename": "Middle Name",
		"middlenameOptional": "Middle Name (Optional)",
		"phoneNumber": "Phone Number",
		"phone": "Phone",
		"mrn": "MRN",
		"myProfile": "My Profile",
		"signOut": "Sign Out",
		"submit": "Submit",
		"changeEmail": "New Email Address",
		"confirmChangeEmail": "Confirm New Email Address",
		"itemsPerPage": "Items per page",
		"addNewDevice": "+ Add device name",
		"edit": "Edit",
		"ethnicity": "Ethnicity",
		"measurementUnitsTitle": "Measurement Units",
		"timeZoneTitle": "Time Zone",
		"settings": "Settings",
		"createGroup": "Create Group",
		"patientGroups": "Patient Groups",
		"noPatientGroups": "No groups have been created yet.",
		"editMembers": "Edit Members",
		"editGroupMembers": "Edit Group Members",
		"editGroupMembersInfo": "Patients may be added to the group by selecting from the list on the left and pressing “add”. Patients may be removed from the group by selecting from the list on the right and then pressing “remove”.",
		"groupName": "Group Name",
		"tagName": "Tag Name",
		"tagNew": "New Tag",
		"groups": "Groups",
		"add": "Add",
		"remove": "Remove",
		"restore": "Restore",
		"shareData": "Share Data",
		"sharedData": "Shared Data",
		"incoming": "Incoming",
		"outgoing": "Outgoing",
		"share": "Share",
		"dataShared": "Data has been Shared",
		"cautionSharingDataMessage": "Patient data will be shared outside your organization. It is your responsibility to ensure that all data transfers adhere to privacy and data protection regulations.",
		"sharingAvailable":"SHARING_AVAILABLE",
		"assignSharedData": "Assign Shared Data",
		"assign": "Assign",
		"assigned": "Assigned",
		"assignNew": "Assign New",
		"recipient": "Recipient",
		"assignedReceiversEmpty": "No receivers have been assigned yet. Assign the first one.",
		"versionNotCompatible": "version not compatible",
		"deleteGroupWarning": "Are you sure you want to delete the selected group?",
		"deleteTagWarning": "Are you sure you want to delete the selected tag?",
		"restorePatient": "Restore Patient",
		"restoreDeletedPatient": "Restore deleted patient",
		"restoreUser": "Restore User",
		"restoreDeletedUser": "Restore deleted user",
		"select": "Select",
		"patients": "Patients",
		"noPatients": "No patients were found. Create the first one.",
		"noDevicesMessage": "No devices have been used yet. Take a measurement to add a device.",
		"noPatientsTitle": "No patients",
		"noDevicesTitle": "No devices found",
		"noUsersTitle": "No users found",
		"noClinicians": "No users were found. Create the first one.",
		"noDeletedUsers": "There are no deleted users.",
		"noSearchResults": "There are no results matching your search criteria. Please try again.",
		"noSearchResultsTitle": "No results found",
		"noSharingsTitle": "No shared patients",
		"noSharings": "No data has been shared yet.",
		"clinicianList": "User List",
		"deletedPatients": "Deleted Patients",
		"deletedUsers": "Deleted Users",
		"users": "Users",
		"createClinician": "Create Clinician",
		"clinicianProfile": "Clinician Profile",
		"upload": "Upload",
		"updateStrings": {
			"downloadUpdates": "Download Updates",
			"updateSOZO": "Update MySOZO",
			"update": "Update",
			"updateText": {
				"currentVersion": "MySOZO version",
				"isUpToDate": "Software version is up to date.",
				"hasUpdate": "New software update is available.",
			},
			"updateInformationTitle": "Software Information",
			"softwareUpdateTitle": "Software Update",
			"availableUpdateAdminText": {
				"availableUpdate": "There is a new software version available.",
				"wantToUpdate": " Would you like to update?"
			},
			"availableUpdateClinicianText": "A new software version is available. Please consult with your administrator to update.",
			"notAtThisTime": "Not At This Time",
			"tellMeMore": "Tell Me More",
			"summaryChanges": "Summary of Changes",
			"releaseNotes": "Release Notes",
			"acceptParagraph": {
				"part1": "All users will be logged out and the system will be inaccessible to allow for the software update. This process may take up to 10 minutes to complete.",
				"part2": "Upon completion of the update, the SOZOapp on all tablets must be updated and all users will only be able to access the most recent version of SOZO software.",
			},
			"acceptAndUpdate": "Accept and Update",
			"softwareUpdateComplete": "Your software update is complete!",
			"updateSuccessfullClinicianText": {
				"part1": "MySOZO has been updated. Prior to use, please ensure that you understand the SOZO Instructions For Use (available at ",
				"link": "https://impedimed.com/support",
				"part2": ")."
			},
			"appUpdateAvailableAndroid": "The version of SOZOapp that you are using is not compatible with the MySOZO version for your clinic. By following the prompts, the SOZOapp will attempt to automatically update to a version that is compatible with MySOZO. If the SOZOapp is not able to update, this may be resolved by signing into mysozo.com as an administrator user and upgrading MySOZO to the latest version or by manually updating the SOZOapp to a compatible version. For access to other SOZOapp versions and instructions on how to manually update the SOZOapp to a compatible version, contact ImpediMed Technical support.",
			"appUpdateAvailableiOS": "The SOZOapp was unable to automatically update to a compatible version with MySOZO. In some situations, this may be resolved by signing into mysozo.com as an administrator user and upgrading MySOZO to the latest version. For access to other SOZOapp versions and instructions on how to manually update the SOZOapp to a compatible version, contact ImpediMed Technical support.",
		},
		"uploadApk": "Upload Software",
		"uploadFirm": "Upload Firmware",
		"deleteClinician": "Delete Clinician",
		"deleteClinicianConfirm": "Are you sure that you want to delete the selected user?",
		"aboutUs": "About us",
		"logout": "Log Out",
		"remainLogged": "Remain Signed In",
		"createNewClinician": "create New Clinician",
		"createSuccess": "Clinician created successfully!",
		"createFail": "Clinician was not created due to an error.  Re-try your request.",
		"gender": "Gender",
		"sex": "Sex",
		"male": "Male",
		"female": "Female",
		"maleShort": "M",
		"femaleShort": "F",
		"dobOptional": "Date of Birth (Optional)",
		"dateOfBirth": "Date of Birth",
		"dob": "DOB",
		"cPassword": "Confirm New Password",
		"temporaryPassword": "Temporary Password",
		"enterTemporaryPassword": "Enter temporary password",
		"invalidPassword_Alert": "The password does not meet requirements.  The password must be between 8 and 20 characters containing at least:\n1. One number\n2. One special character (!“#$%&‘()+,-./:;<=>?_@[]^`{|}~)*\n3. One upper case letter\n4. One lower case letter \n5. Cannot be one of the last three passwords used.",
		"invalidPassword": {
			"firstLine": "The password must be between 8 and 20 characters containing at least:",
			"oneNumber": "1. One number",
			"oneSpecial": "2. One special character (!“#$%&‘()+,-./:;<=>?_@[]^`{|}~)*",
			"oneUpper": "3. One upper case letter",
			"oneLower": "4. One lower case letter ",
			"history": "5. Cannot be one of the last three passwords used."
		},
		"invalidPasswordConsistency": "The New Password and Confirm New Password\ndo not match.",
		"specialty": "Specialty",
		"setNewPass": "Set New Password",
		"selectFile": "SELECT FILE",
		"validFile": "Please select valid file",
		"resetPass": "Reset Password",
		"resetLater": "Reset Later",
		"resetNow": "Reset Now",
		"setNow": "Set Now",
		"beCareful": "Be Careful",
		"warning": "Warning",
		"caution": "Caution",
		"error": "Error",
		"success": "Success",
		"emptyFiledsBillingReport": "Please enter From and To date.",
		"emptyFieldsCreateClinician": "First Name, Last Name, Email, or User Role cannot be empty. Please fill all mandatory fields.",
		"emptyFieldEditClinician": "Please enter First Name, Last Name and User Role.",
		"emptyFieldsPatient": "Please fill all mandatory fields. First Name, Last Name, Sex, Date of Birth and Height cannot be empty.",
		"emptyFieldsExtraInfo": "Please enter both an Email Address and Password.",
		"emptyFieldsChangeEmail": "Please fill all mandatory fields.",
		"emptyFieldsCreateSharing": "Please enter a Clinic",
		"invalidPhone": "The phone number must be between 4 and 20 digits and contain only numbers.",
		"exit": "Are you sure you want to sign out?",
		"yes": "YES",
		"no": "NO",
		"cancel": "Cancel",
		"done": "Done",
		"invalidDate": "Invalid date",
		"deletePatientConfirm": "Are you sure you want to delete the selected patient?",
		"restorePatientConfirm": "Are you sure you want to restore the selected patient?",
		"deleteUserConfirm": "Are you sure you want to delete the selected user?",
		"restoreUserConfirm": "Are you sure you want to restore the selected user?",
		"ssoEnableConfirm": "Please confirm that you want to enable SSO. All users will be required to login with their organisational account.",
		"ssoDisableConfirm": "Please confirm request to disable SSO. Users will no longer be able to sign in using their organisational account credentials.",
		"deletePatient": "Delete Patient",
		"searchClinician": "Search User...",
		"searchUser": "Search for a user...",
		"placeholderPassword": "PASSWORD",
		"placeholderConfirmPassword": "CONFIRM PASSWORD",
		"save": "Save",
		"invalidFile": "Invalid file",
		"sessionExpiredMessage": "For safety reasons your session has been terminated. Please login again.",
		"kilograms": "kg",
		"pounds": "lbs",
		"centimeters": "cm",
		"feet": "ft",
		"inches": "in",
		"feetLong": "Feet",
		"inchesLong": "Inches",
		"height": "Height",
		"weight": "Weight",
		"currentPreTare": "CURRENT PRE-TARE:",
		"netWeightCaps": "NET WEIGHT",
		"getWeight": "Get Weight",
		"retakeWeight": "Retake Weight",
		"netWeight": "Net Weight",
		"grossWeight":"Gross Weight",
		"preTare": "Pre-Tare",
		"patientDashboard": "Patient\nDashboard",
		"lastSozoAssessment": "Last SOZO Assessment",
		"lastAssessment": "Last Assessment",
		"date": "Date",
		"time": "time",
		"measurementType": "Measurement Type",
		"thrive": "thrive",
		"bodyComposition": "BodyComp",
		"bodyCompositionAnalysis": "BodyComp Analysis",
		"segmentalAnalysis": "Segmental Analysis",
		"segmentalBodyCompAnalysis": "Segmental BodyComp Analysis",
		"segmental": "Segmental",
		"segmentalBodyComp": "Segmental BodyComp",
		"beat": "beat",
		"lDexScore": "L-Dex Score",
		"hfDexScore": "HF-Dex Score",
		"changeFromBaseline": "Change from Baseline",
		"changeFromBaselineNewLine":
			{
				"change": "Change",
				"fromBaseline": "from Baseline"
			},
		"baseline": "Baseline",
		"baselineCaps": "BASELINE",
		"data": "Data",
		"threshold": "51% HF-Dex",
		"ecfScore": "ECF Score",
		"hyDexScore": "Hy-Dex Score",
		"hyDex": "Hy-Dex",
		"Hy-Dex": "Hy-Dex",
		"trending": "Trending",
		"lDex": "L-Dex",
		"bilateralArmReview": "Bilateral Arm Review",
		"delta10": "Delta10",
		"normalRange": "In normal range",
		"notNormalRange": "Out of normal range",
		"hfNormalRange": "In normal range",
		"hfAboveNormalRange": "Above chart range",
		"hfBelowNormalRange": "Below chart range",
		"colePlot": "Cole Plot",
		"riskSide": "Risk limb",
		"noRiskSide": "Unaffected limb",
		"notCollected": "-",
		"saveChanges": "Save Changes",
		"navigateResults": "Navigate Results",
		"demographicDetails": "Demographic Details",
		"SOZOpro": "SOZOpro",
		"SOZOthrive": "SOZOthrive",
		"SOZObeat": "SOZObeat",
		"measurementDate": "Measurement Date",
		"reactance": "Reactance",
		"resistance": "Resistance",
		"xc": "Xc",
		"r": "R",
		"ohms": "Ohms",
		"other": "Other",
		"email": "Email",
		"mfaStatus": "MFA Status",
		"lastSignIn": "Last Sign In",
		"notRetrieved": "Not Retrieved",
		"notProvided": "Not Provided",
		"editPatient": "Edit Patient",
		"continueToEdit": "Continue to Edit",
		"confirmEmail": "Re-enter Email",
		"emailMismatch": "The email addresses entered do not match. Please re-enter them.",
		"getHelp": "Get Help",
		"measurementStatus": "Measurement Status",
		"accepted": "Accepted",
		"rejected": "Rejected",
		"postalCode": "Postal Code",
		"emailOptional": "Email (Optional)",
		"patientList": "Patient List",
		"searchPatient": "Search for a patient...",
		"searchSourcePatient": "Search source patient",
		"searchDestinationPatient": "Search destination patient",
		"rightBody": "Right Body",
		"leftBody": "Left Body",
		"hyDexAnalysisWithRmark": "Hy-Dex{sup}\xAE{sup} Analysis",
		"hyDexAnalysis": "Hy-Dex Analysis",
		"hyDexChangeAnalysis": "Hy-Dex Change Analysis",
		"changeFromPrevious": "Change from Previous",
		"current":"Current",
		"less": "Less",
		"more": "More",
		"hydrated": "hydrated",
		"bmi": "BMI",
		"smm": "SMM",
		"smmPercentage": "SMM % of Weight",
		"SkeletalMuscleMassSMM": "Skeletal Muscle Mass (SMM)",
		"skeletalMuscleMass": "Skeletal Muscle Mass",
		"leanSoftTissue": "Lean Soft Tissue",
		"tbw": "TBW",
		"tbwPercentage": "TBW % of Weight",
		"triple": "ECF, TBW, Weight",
		"phase": "Phase Angle",
		"updatePatient": "Update Patient",
		"updateUser": "Update User",
		"fat": "Fat",
		"fluidStatus": "Fluid status",
		"ecf": "ECF",
		"ecfPercentage": "ECF % of TBW",
		"ECF": "ECF",
		"icf": "ICF",
		"icfPercentage": "ICF % of TBW",
		"distribution": "Distribution",
		"extracellularFluid": "Extracellular Fluid",
		"intracellularFluid": "Intracellular Fluid",
		"fm": "FM",
		"fmPercentage": "FM % of Weight",
		"ffm": "FFM",
		"ffmPercentage": "FFM % of Weight",
		"atm": "ATM",
		"atmPercentage": "ATM % of Weight",
		"ecm": "ECM",
		"ecmPercentage": "ECM % of Weight",
		"bmr": "BMR",
		"basalMetabolicRate": "Basal Metabolic Rate",
		"activeTissueMass": "Active Tissue Mass",
		"extracellularMass": "Extracellular Mass",
		"calPerDay": "Cals/Day",
		"metabolicReport": "Metabolic Report",
		"totalBodyComposition": "Total body composition",
		"totalBodyWater": "Total Body Water",
		"fatFreeMassFFM": "Fat Free Mass (FFM)",
		"fatMassFM": "Fat Mass (FM)",
		"bodyMassIndexBMI": "Body Mass Index (BMI)",
		"proteinAndMinerals": "Protein & Minerals",
		"proteinAndMineralsPercentage": "Protein & Minerals % of Weight",
		"phaseAngle": "Phase Angle",
		"lst": "LST",
		"degrees": "Degrees",
		"percentageOfFFM": "% of FFM",
		"percentageOfWeight": "% of Weight",
		"pt": "pt",
		"pints": "pints",
		"lt": "lt",
		"liters": "litres",
		"litersShort": "L",
		"litersShortWithExplanation": "L (litres)",
		"pintsShortWithExplanation": "pt (pints)",
		"auditLogs": "Audit Logs",
		"getPatientsBy": "Get patients by",
		"pseudonymisedId": "Pseudonymised ID",
		"export": "Export",
		"exportAll": "Export All",
		"from": "From",
		"to": "To",
		"heightIsNaN": "Height allows numbers only",
		"invalidHeightValue": "Height must be a number between 38 cm and 251.5 cm",
		"invalidHeightValueImperial": "Height must be a number between 1 ft. 3.0 inches and 8 ft. 3.0 inches",
		"invalidWeightValue": "Invalid weight value",
		"completeChange": "You are about to perform a significant level of changes to this patient’s profile. Consider creating a new patient instead.",
		"continue": "Continue",
		"measurementDatetime": "Measurement Date",
		"mrnOptional": "MRN (Optional)",
		"notSet": "-",
		"missingInformation": "Some graphical information may be outdated or missing.",
		"fitted": "Fitted Curve",
		"tdCorrect": "Td Corrected Data",
		"measured": "Measured Data",
		"arm": "Arm",
		"leg": "Leg",
		"left": "Left",
		"right": "Right",
		"lDexNormalRange": "L-Dex® Normal Range",
		"mySozoVersion": "MySOZO Version: ",
		"days": {
			"monday": "Mon",
			"tuesday": "Tue",
			"wednesday": "Wed",
			"thursday": "Thu",
			"friday": "Fri",
			"saturday": "Sat",
			"sunday": "Sun",
		},
		"months": {
			"january": "Jan",
			"february": "Feb",
			"march": "Mar",
			"april": "Apr",
			"may": "May",
			"june": "Jun",
			"july": "Jul",
			"august": "Aug",
			"september": "Sep",
			"october": "Oct",
			"november": "Nov",
			"december": "Dec",
		},
		"english": "English (US)",
		"french": "French",
		"german": "German",
		"spanish": "Spanish",
		"italian": "Italian",
		"englishUK": "English (UK)",
		"noBasicReportingLicensing": "The licences granted do not allow for report generation.  Please contact ImpediMed Technical Support for further details.",
		"historyGraphs": "History Graphs",
		"errorCode": "Error Code: ",
		"tissue": "Tissue Analysis",
		"fluid": "Fluid Status",
		"hfDex": "HF-Dex",
		"lDexType": "L-Dex",
		"language": "Language",
		"bodyElement": "Body Element",
		"riskLimb": "Risk Limb",
		"limb": "Limb",
		"limbDominance": "Limb Dominance",
		"both": "Both",
		"ecfAnalysis": "ECF Analysis",
		"additionalAnalysis": "Additional Analysis",
		"bilateral": "Bilateral",
		"unilateral": "Unilateral",
		"rightLimb": "Right Limb",
		"leftLimb": "Left Limb",
		"rightArm": "Right Arm",
		"leftArm": "Left Arm",
		"rightLeg": "Right Leg",
		"leftLeg": "Left Leg",
		"commaHeightInputProblem": "Height entry is invalid and must be a number (##.#). Note: no comma is allowed. If this message is in error, please contact ImpediMed Technical Support.\t",
		"reviseHeight": "All Measurements Including Historical",
		"allMeasurementsIncluded" : "All measurements included",
		"allMeasurements" : "All Measurements",
		"currentMeasurement" : "Current Measurement",
		"clinic":"Clinic",
		"validator_email_checkEmailRegex": "Invalid email address",
		"validator_email_checkEmailSharingLength": "A maximum of 5 emails may be added. Try again with fewer emails.",
		"oneTimePassword": "One Time Password",
		"otpValidityPeriod": "Verification code resets every 15 mins.",
		"heightAtMeasurement": "Only Future Measurements",
		"recalculateWithHeightMessage": "For the change to height, please select how you would like this entry applied. Be aware that this change is not reversible.",
		"recalculateMessage": "Changing patient profile settings will recalculate all historical results based on the revised settings. Do you want to continue with the setting changes?",
		"measurementPatientMissingField": "The patient's height has not been entered. Height will be required before a measurement can be taken.",
		"sessionExpirationOne": "Your session will expire in",
		"sessionExpirationTwo": "You will be automatically logged out in",
		"generateReport": "Generate Report",
		"ok": "OK",
		"emptyPhoneNumberOrCode": "Both country code and phone number must be entered",
		"noDataTypeSelectedExport": "No data type selected. Please select one data type to continue.",
		"noDataFormatSelectedExport": "Please select a data format to continue.",
		"noAssessmentTypeSelectedExport": "Please select at least one assessment type to continue.",
		"invalidDateRangeMessage": "The From date must be before the To date.",
		"noDateRangeSelected": "Please select a date option.",
		"reportSettings": "Report Settings",
		"exportSettings": "Export Settings",
		"reportSummary": "Report Summary",
		"dataType": "Data Type",
		"resultsSimplifiedData": "Simplified",
		"resultsFullData": "Full",
		"deidentifiedId": "Show De-identified Patient ID",
		"patientIdentifyingDetails": "Show Patient Identifying Details",
		"additionalPatientDetails": "Additional Patient Details",
		"deviceMeasurementInfo": "Device/Measurement Info",
		"groupsInfo": "Group Details",
		"rowPerPatient": "Row per patient",
		"rowPerMeasurement": "Row per measurement",
		"hyDexWithTrademark": "Hy-Dex\xAE",
		"emptyFieldDateRange": "Please select a date range.",
		"noMeasurementTypeSelected": "Please select at least one assessment type and one type of history graph to continue.",
		"noMeasurementsTaken": "A report cannot be generated because no SOZO measurement has been taken for the selected patient. If only imported Impsoft measurements exist, a report can be generated by navigating to any specific measurement result and selecting the report/print option.",
		"clinicSettings": "Clinic Settings",
		"userSettings": "User Settings",
		"deviceSettings": "Device Settings",
		"deviceStatus": "Device Status",
		"scaleSettings": "Scale Settings",
		"displayWeight": "Display Weight",
		"weightMeasurementUnits": "Weight Measurement Units",
		"tareScale": "Tare Scale",
		"tare": "Tare",
		"deviceName": "Device Name",
		"macAddress": "Mac Address",
		"serialNumber": "Serial Number",
		"clinicianName" : "Clinician Name",
		"notAvailableImported" : "NA - Imported",
		"notAvailable" : "NA",
		"lastSelfTestStatus": "Last Self-Test Status",
		"refresh": "Refresh",
		"updateVersion": "Update Version",
		"firmware": "Firmware",
		"version":"Version",
		"calibration": "Calibration",
		"impedanceCalibrationDate": "Impedance Calibration Date",
		"scaleCalibrationDate": "Scale Calibration Date",
		"reportLogoSettings": "Report Logo Settings",
		"maximumDimensions": "Maximum dimensions",
		"maximumSize": "Maximum Size",
		"fileSupportedFormat": "File supported format",
		"pixels": "pixels",
		"revert": "Revert",
		"browse": "Browse",
		"invalidFilePNG": "Invalid file type. Please select a PNG file for upload.",
		"imageLarger1MB": "The image file cannot be larger than 1MB.",
		"imageNotSelected": "Cannot save because no image file was selected.",
		"notSelected": "Not selected",
		"emptyLDexSettings": "You need to set all the Lymphoedema settings within the patient's profile in SOZOapp, prior to displaying L-Dex results or trending",
		"patientDetails": "Patient Details",
		"assessmentType": "Assessment Type",
		"assessment": "Assessment",
		"lDexAnalysisForLymphedema": "L-Dex Analysis for Lymphoedema",
		"tissueAnalysisForBodyComposition": "Tissue Analysis for Body Composition",
		"fluidAnalysisForFluidStatusMonitoring": "Fluid Analysis for General Fluid Status Monitoring",
		"fluidAnalysisOverviewDescription": "Fluid Analysis for General Fluid Status Monitoring",
		"hfDexAnalysisForHeartFailure": "HF-Dex Analysis for Heart Failure",
		"lymphedemaSettings": "Lymphoedema Settings",
		"fullMRN": "Medical Record Number (MRN)",
		"mrnLong": "Medical Record Number",
		"countryCode": "Country Code",
		"address": "Address",
		"city": "City",
		"state": "State",
		"country": "Country",
		"createPatientTwoLines": "Create\nPatient",
		"createPatient": "Create Patient",
		"importPatient": "Import Patient",
		"emptyFieldsCreatePatient": "Please fill all mandatory fields.",
		"emptyFields": "Please fill all mandatory fields.",
		"emptyFieldsSecuritySettings": "The security settings must be chosen. Please fill in all fields to continue.",
		"invalidSessionExpirationTime": "The security settings are invalid. Please re-enter them as a number within the ranges specified.",
		"invalidResetPasswordTime": "The security settings are invalid. Please re-enter them as a number within the ranges specified.",
		"mrnErrorMessage": "The Medical Record Number entered must contain letters or numbers with no special characters allowed and may be up to 100 characters long.",
		"postalCodeErrorMessage": "Postal Code allows a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"alphabeticExtendedErrorMessage": "First name, Last name, Middle name, Address, City and State allow a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"alphabeticExtendedErrorMessageNames": "First name, Last name and Middle name allow a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"groupNameAlphabeticExtendedErrorMessage": "An issue was detected with the group name. A maximum of 100 characters are allowed and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"deviceNameAlphabeticExtendedErrorMessage": "An issue was detected with the device name. A maximum of 48 characters are allowed and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"tagNameAlphabeticExtendedErrorMessage": "An issue was detected with the tag name. A maximum of 48 characters are allowed and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"tagColorNotSelected": "Please select a color for this tag.",
		"emailErrorMessage": "The email address is invalid. The email address must be up to 255 characters and may contain the following characters: \"- _ . @\"",
		"emailErrorMessageSameAddress": "The email address was not updated because it is the same as the previous one.",
		"lymphedemaSettingErrorMessage": "Please set your L-Dex assessment settings",
		"surgeryDateBeforeDOB": "The Surgery Date in the L-Dex Settings must be later than the Date of Birth.",
		"lppDateBeforeSurgeryDate": "In the L-Dex settings, the LPP Start Date must be on or later than the Surgery Date.",
		"dataExport": "Data Export",
		"or": "or",
		"required": "Required",
		"optional": "Optional",
		"reportPrint": "Report",
		"licensingStatus": "Licencing Status",
		"selectAll": "Select All",
		"clearAll": "Clear All",
		"history": "History",
		"historyCaps": "HISTORY",
		"results": "Results",
		"result": "Result",
		"profile": "Profile",
		"noAssessmentTypeSelected": "There is no Assessment Type selected in the Patient Profile",
		"tissueAnalysis": "Tissue Analysis",
		"fluidAnalysis": "Fluid Analysis",
		"weightFluidAnalysis": "Weight and Fluid Analysis",
		"hfDexAnalysis": "HF-Dex Analysis",
		"lDexHistory": "L-Dex History",
		"hfDexHistory": "HF-Dex History",
		"setBaseline": "Set as Baseline",
		"setECFBaseline": "Set ECF Baseline",
		"hyDexHistoryWithRmark": "Hy-Dex{sup}\xAE{sup} History",
		"hyDexHistory": "Hy-Dex History",
		"tbwHistory": "TBW History",
		"tbwPercentageHistory": "TBW % of Weight History",
		"ffmHistory": "FFM History",
		"ffmPercentageHistory": "FFM % of Weight History",
		"fmHistory": "FM History",
		"fmPercentageHistory": "FM % of Weight History",
		"ecfHistory": "ECF History",
		"ecfPercentageHistory": "ECF % of TBW History",
		"icfHistory": "ICF History",
		"icfPercentageHistory": "ICF % of TBW History",
		"bmrHistory": "BMR History",
		"smmHistory": "SMM History",
		"smmPercentageHistory": "SMM % of Weight History",
		"atmHistory": "ATM History",
		"atmPercentageHistory": "ATM % of Weight History",
		"bmiHistory": "BMI History",
		"ecmHistory": "ECM History",
		"ecmPercentageHistory": "ECM % of Weight History",
		"proteinAndMineralsHistory": "Protein & Minerals History",
		"proteinAndMineralsPercentageHistory": "Protein & Minerals % of Weight History",
		"weightHistory": "Weight History",
		"phaseAngleHistory": "Phase Angle History",
		"selectMeasurementDate": "Select Measurement Date",
		"colePlots": "Cole Plots",
		"fatMass": "Fat Mass",
		"percOfWeight": "% of{n}Weight",
		"percOfTBW": "% of{n}TBW",
		"note": "Note",
		"fluidDistribution": "Fluid Distribution",
		"mean": "mean",
		"lessHydrated": "Less Hydrated",
		"moreHydrated": "More Hydrated",
		"leftWholeBody": "Left Whole Body",
		"rightWholeBody": "Right Whole Body",
		"atRisk": "At Risk",
		"dominant": "Dominant",
		"measurementDates": "Measurement Dates",
		"help": "Help",
		"technicalSupport": "Technical Support",
		"technicalSupportA": "Americas",
		"technicalSupportAO": "All Others",
		"technicalSupportAP": "Asia Pacific",
		"technicalSupportEMA": "Europe, Middle East, Africa",
		"technicalSupportEmail": "Email:",
		"technicalSupportPhone": "Phone:",
		"productInformation": "More Information",
		"forInstruction": "For Instructions for Use, please visit:",
		"productsLink": "https://www.impedimed.com/products/sozo/",
		"forSupport": "For support and additional documentation, please visit:",
		"linkMain": "https://impedimed.com/support",
		"linkPatent": "https://www.impedimed.com/patents/",
		"emailAssistance": "ts@impedimed.com",
		"emailAssistanceUS": "tsu@impedimed.com",
		"emailAssistanceEMA": "tse@impedimed.com",
		"phoneNA": "USA/Canada (toll free): +1 877-247-0111 option 4",
		"phoneUS": "US: +1 760-585-2125",
		"phoneAP": "+61 7 3860 3700 option 2",
		"phoneEMA": "+30 231-111-6753",
		"privacyPolicy": "Privacy Policy",
		"privacyPolicyVisit": "To learn more, visit:",
		"privacyPolicyLink": "www.impedimed.com/privacy-policy",
		"regulatoryInformation": "Regulatory Information",
		"regInfo": "ImpediMed Regulatory Information",
		"regInfoLimited": "ImpediMed Limited",
		"abn": "ABN 65 089 705 144",
		"australiaLoc": "Unit 1, 50 Parker Court, Pinkenba",
		"australiaLoc2": "Qld. 4008, Australia",
		"europeLoc": "MediMark Europe Sarl 11 rue Emile ZOLA",
		"europeLoc2": "B.P. 2332, 38033 Grenoble Cedex 2, France",
		"regulatoryMDSS": "MDSS GmbH",
		"regulatoryMDSS2": "Schiffgraben 41",
		"regulatoryMDSS3": "30175 Hannover, Germany",
		"regulatoryMDSSUK": "UKRP",
		"regulatoryMDSSUK2": "MDSS-UK RP Ltd.",
		"regulatoryMDSSUK3": "6 Wilmslow Road, Rusholme",
		"regulatoryMDSSUK4": "Manchester M14 5TP",
		"regulatoryMDSSUK5": "United Kingdom",
		"patents": "For patent(s) and/or patent application(s) see: ",
		"trademarks1": "©2024 ImpediMed Limited",
		"trademarks2": "ImpediMed\xAE, SOZO\xAE, L-Dex\xAE and Hy-Dex\xAE are registered",
		"trademarks3": "trademarks of ImpediMed Limited. HF-Dex and BodyComp are trademarks of ",
		"trademarks4": "ImpediMed Limited.",
		"about": "About",
		"getLatestAppFirmware": "Get Latest App/Firmware",
		"verificationCode": "Verification Code",
		"userDetails": "User Details",
		"myAccount": "My Account",
		"assessmentDate": "Assessment Date",
		"dataResults": "Data Results",
		"simplified": "Simplified",
		"full": "Full",
		"createReport": "Create Report",
		"info": "Info",
		"information": "Information",
		"lDexIntendedFor": "L-Dex is intended for use on adult patients at risk of lymphoedema.",
		"TissueAnalysisIntendedFor": "Tissue Analysis is intended for use on patients ages 6 to 90.",
		"FluidAnalysisIntendedFor": "Fluid Analysis is intended for use on patients ages 6 to 90.",
		"hfDexIntendedFor": "HF-Dex is intended for use on adult patients living with heart failure to monitor their fluid status.",
		"renalIntendedFor": "Fluid Assessment for ESRD is intended for use on adult patients living with End-stage Renal Disease.",
		"noAssessmentTypeSelectedResults": "Please select an assessment type for this patient based on the current licences.",
		"noResults": "No measurements have been taken for this patient. Take a new measurement to review results.",
		"licensingStatusTitle": "Licencing Status",
		"licensedSOZODevices": "Licenced SOZO Devices",
		"notActive": "Not active",
		"active": "Active",
		"inactive": "Inactive",
		"lDexLicense": "L-Dex Analysis for Unilateral lymphoedema",
		"lDexBilateralLicense": "L-Dex Analysis for Bilateral lymphoedema",
		"tissueAnalysisLicenseUS": "Tissue Analysis for Body Composition (with Hy-Dex)",
		"tissueAnalysisLicenseOUS": "Tissue Analysis for Body Composition",
		"fluidAnalysisLicenseUS": "Fluid Analysis for General Fluid Status Monitoring",
		"fluidAnalysisLicenseOUS": "Fluid Analysis for General Fluid Status Monitoring",
		"hfDexLicense": "HF-Dex Analysis for Heart Failure",
		"heartFailureGroup": "Heart Failure Group",
		"hfdex": "HF-Dex",
		"hfDexParagraph": "The HF-Dex scale is a tool to assist in monitoring" +
			" fluid status in patients with heart failure who have fluid management " +
			"problems, and should be used in conjunction with other clinical data." +
			" HF-Dex values in the light blue reference range are consistent with normal" +
			" fluid volumes, based on data collected from generally healthy individuals with normal" +
			" fluid levels. Values in the medium blue and dark blue reference range may indicate" +
			" increasing levels of extracellular fluid and may require additional clinical investigation. " +
			"Values in the gray range require additional investigation.",
		"hyDexParagraph": "Hy-Dex is a bi-directional scale that displays a person’s fluid status as compared to a dataset from an average population. " +
			"Hy-Dex is only intended for use with healthy patients and should not be used to diagnose or treat a medical condition.",
		"renalLicense": "Fluid Analysis for ESRD",
		"esrd": "ESRD",
		"ecfTbwWeightHistory": "ECF, TBW, Weight History",
		"tbwIcfEcfHistory": "ECF, ICF, TBW History",
		"basicReportingLicense": "Basic Reporting (Reports/Exports)",
		"noDevices": "There are no licenced devices for this Facility",
		"anyDevice": "Any device",
		"lppProtocolCompliance": "LPP Protocol Compliance",
		"testingFrequency": "Testing Frequency",
		"postTreatment": "First Post-Treatment Measurement",
		"trigger": "Trigger",
		"resolved": "Resolved",
		"endDate": "End Date",
		"increaseFromBaseline": "L-Dex increase from baseline",
		"decreaseFromBaseline": "L-Dex decrease from baseline",
		"triggerInfo": "If no baseline set, trigger will be calculated from first measurement.",
		"resolvedInfo": "If no baseline set, resolved will be calculated from first measurement.",
		"years": "Years",
		"year": "Year",
		"week": "Week",
		"weeks": "Weeks",
		"noEndDate": "No End Date",
		"every": "Every",
		"analytics":"Analytics",
		"protocolCompliance": "Protocol Compliance",
		"programManagement": "Program Management",
		"countries": {
			"unitedStates": "United States",
			"afghanistan": "Afghanistan",
			"albania": "Albania",
			"algeria": "Algeria",
			"americanSamoa": "American Samoa",
			"andorra": "Andorra",
			"angola": "Angola",
			"anguilla": "Anguilla",
			"antiguaAndBarbuda": "Antigua and Barbuda",
			"argentina": "Argentina",
			"armenia": "Armenia",
			"aruba": "Aruba",
			"australia": "Australia",
			"austria": "Austria",
			"azerbaijan": "Azerbaijan",
			"bahamas": "Bahamas",
			"bahrain": "Bahrain",
			"bangladesh": "Bangladesh",
			"barbados": "Barbados",
			"belarus": "Belarus",
			"belgium": "Belgium",
			"belize": "Belize",
			"benin": "Benin",
			"bermuda": "Bermuda",
			"bhutan": "Bhutan",
			"bolivia": "Bolivia",
			"bosniaAndHerzegovina": "Bosnia and Herzegovina",
			"botswana": "Botswana",
			"brazil": "Brazil",
			"britishIndianOceanTerritory": "British Indian Ocean Territory",
			"britishVirginIslands": "British Virgin Islands",
			"brunei": "Brunei",
			"bulgaria": "Bulgaria",
			"burkinaFaso": "Burkina Faso",
			"burundi": "Burundi",
			"cambodia": "Cambodia",
			"cameroon": "Cameroon",
			"canada": "Canada",
			"capeVerde": "Cape Verde",
			"caribbeanNetherlands": "Caribbean Netherlands",
			"caymanIslands": "Cayman Islands",
			"centralAfricanRepublic": "Central African Republic",
			"chad": "Chad",
			"Chile": "Chile",
			"China": "China",
			"christmasIsland": "Christmas Island",
			"cocos": "Cocos",
			"colombia": "Colombia",
			"comoros": "Comoros",
			"congo": "Congo",
			"cookIslands": "Cook Islands",
			"costaRica": "Costa Rica",
			"coteDIvoire": "Côte d’Ivoire",
			"croatia": "Croatia",
			"cuba": "Cuba",
			"curacao": "Curaçao",
			"cyprus": "Cyprus",
			"czechRepublic": "Czech Republic",
			"denmark": "Denmark",
			"djibouti": "Djibouti",
			"dominica": "Dominica",
			"dominicanRepublic": "Dominican Republic",
			"ecuador": "Ecuador",
			"egypt": "Egypt",
			"elSalvador": "El Salvador",
			"equatorialGuinea": "Equatorial Guinea",
			"eritrea": "Eritrea",
			"estonia": "Estonia",
			"ethiopia": "Ethiopia",
			"falklandIslands": "Falkland Islands",
			"faroeIslands": "Faroe Islands",
			"fiji": "Fiji",
			"finland": "Finland",
			"france": "France",
			"frenchGuiana": "French Guiana",
			"frenchPolynesia": "French Polynesia",
			"gabon": "Gabon",
			"gambia": "Gambia",
			"georgia": "Georgia",
			"germany": "Germany",
			"ghana": "Ghana",
			"gibraltar": "Gibraltar",
			"greece": "Greece",
			"greenland": "Greenland",
			"grenada": "Grenada",
			"guadeloupe": "Guadeloupe",
			"guam": "Guam",
			"guatemala": "Guatemala",
			"guernsey": "Guernsey",
			"guinea": "Guinea",
			"guineaBissau": "Guinea-Bissau",
			"guyana": "Guyana",
			"haiti": "Haiti",
			"honduras": "Honduras",
			"hongKong": "Hong Kong",
			"hungary": "Hungary",
			"iceland": "Iceland",
			"india": "India",
			"indonesia": "Indonesia",
			"iran": "Iran",
			"iraq": "Iraq",
			"ireland": "Ireland",
			"isleOfMan": "Isle of Man",
			"israel": "Israel",
			"italy": "Italy",
			"jamaica": "Jamaica",
			"japan": "Japan",
			"jersey": "Jersey",
			"jordan": "Jordan",
			"kazakhstan": "Kazakhstan",
			"kenya": "Kenya",
			"kiribati": "Kiribati",
			"kosovo": "Kosovo",
			"kuwait": "Kuwait",
			"kyrgyzstan": "Kyrgyzstan",
			"laos": "Laos",
			"latvia": "Latvia",
			"lebanon": "Lebanon",
			"lesotho": "Lesotho",
			"liberia": "Liberia",
			"libya": "Libya",
			"liechtenstein": "Liechtenstein",
			"lithuania": "Lithuania",
			"luxembourg": "Luxembourg",
			"macau": "Macau",
			"macedonia": "Macedonia",
			"madagascar": "Madagascar",
			"malawi": "Malawi",
			"malaysia": "Malaysia",
			"maldives": "Maldives",
			"mali": "Mali",
			"malta": "Malta",
			"marshallIslands": "Marshall Islands",
			"Martinique": "Martinique",
			"mauritania": "Mauritania",
			"Mauritius": "Mauritius",
			"mayotte": "Mayotte",
			"mexico": "Mexico",
			"micronesia": "Micronesia",
			"moldova": "Moldova",
			"monaco": "Monaco",
			"mongolia": "Mongolia",
			"montenegro": "Montenegro",
			"montserrat": "Montserrat",
			"morocco": "Morocco",
			"mozambique": "Mozambique",
			"myanmar": "Myanmar",
			"namibia": "Namibia",
			"nauru": "Nauru",
			"nepal": "Nepal",
			"netherlands": "Netherlands",
			"newCaledonia": "New Caledonia",
			"newZealand": "New Zealand",
			"nicaragua": "Nicaragua",
			"niger": "Niger",
			"nigeria": "Nigeria",
			"niue": "Niue",
			"norfolkIsland": "Norfolk Island",
			"northKorea": "North Korea",
			"northernMarianaIslands": "Northern Mariana Islands",
			"norway": "Norway",
			"oman": "Oman",
			"pakistan": "Pakistan",
			"palau": "Palau",
			"palestine": "Palestine",
			"panama": "Panama",
			"papuaNewGuinea": "Papua New Guinea",
			"paraguay": "Paraguay",
			"peru": "Peru",
			"philippines": "Philippines",
			"poland": "Poland",
			"portugal": "Portugal",
			"puertoRico": "Puerto Rico",
			"qatar": "Qatar",
			"reunion": "Réunion",
			"romania": "Romania",
			"russia": "Russia",
			"rwanda": "Rwanda",
			"saintBarthelemy": "Saint Barthélemy",
			"saintHelena": "Saint Helena",
			"saintKittsAndNevis": "Saint Kitts and Nevis",
			"saintLucia": "Saint Lucia",
			"saintMartin": "Saint Martin",
			"saintPierreAndMiquelon": "Saint Pierre and Miquelon",
			"saintVincentAndThe": "Saint Vincent and the Grenadines",
			"samoa": "Samoa",
			"sanMarino": "San Marino",
			"saoTomeAndPrincipe": "São Tomé and Príncipe",
			"saudiArabia": "Saudi Arabia",
			"senegal": "Senegal",
			"serbia": "Serbia",
			"seychelles": "Seychelles",
			"sierraLeone": "Sierra Leone",
			"singapore": "Singapore",
			"sintMaarten": "Sint Maarten",
			"slovakia": "Slovakia",
			"slovenia": "Slovenia",
			"solomonIslands": "Solomon Islands",
			"somalia": "Somalia",
			"southAfrica": "South Africa",
			"southKorea": "South Korea",
			"southSudan": "South Sudan",
			"spain": "Spain",
			"sriLanka": "Sri Lanka",
			"sudan": "Sudan",
			"suriname": "Suriname",
			"svalbardAndJanMayen": "Svalbard and Jan Mayen",
			"swaziland": "Swaziland",
			"sweden": "Sweden",
			"switzerland": "Switzerland",
			"syria": "Syria",
			"taiwan": "Taiwan",
			"tajikistan": "Tajikistan",
			"tanzania": "Tanzania",
			"thailand": "Thailand",
			"timorLeste": "Timor-Leste",
			"togo": "Togo",
			"tokelau": "Tokelau",
			"tonga": "Tonga",
			"trinidadAndTobago": "Trinidad and Tobago",
			"tunisia": "Tunisia",
			"turkey": "Turkey",
			"turkmenistan": "Turkmenistan",
			"turksAndCaicosIslands": "Turks and Caicos Islands",
			"tuvalu": "Tuvalu",
			"uSVirginIslands": "U.S. Virgin Islands",
			"uganda": "Uganda",
			"ukraine": "Ukraine",
			"unitedArabEmirates": "United Arab Emirates",
			"unitedKingdom": "United Kingdom",
			"uruguay": "Uruguay",
			"uzbekistan": "Uzbekistan",
			"vanuatu": "Vanuatu",
			"vaticanCity": "Vatican City",
			"venezuela": "Venezuela",
			"vietnam": "Vietnam",
			"wallisAndFutuna": "Wallis and Futuna",
			"westernSahara": "Western Sahara",
			"yemen": "Yemen",
			"zambia": "Zambia",
			"zimbabwe": "Zimbabwe",
			"alandIslands": "Åland Islands"
		},
		"normalRangeLineInfo": "Normal range",
		"type": "Type",
		"role": "Role",
		"roleAdmin": "Administrator",
		"roleAdminShort": "Admin",
		"roleClinician": "Clinician",
		"changeEmailBtn": "Change",
		"setNewEmail": "Set New Email",
		"changelog": "Changelog",
		"securitySettings": "Security Settings",
		"sessionExpiration": "Session Expiration",
		"passwordExpiration": "Password Expiration",
		"passwordExpirationIn": "Your password will expire in ",
		"passwordExpirationDays": " days.\nDo you want to reset it now?",
		"passwordExpired": "Your password has expired.\nYou must set a new one.",
		"passwordMonths": "Months",
		"sessionExpireAfter": "The session token will expire after minutes",
		"updateAvailable": "There is an update available would you like to proceed?",
		"updateAvailableClinician": "There is an update available",
		"tellMeMore": "Tell me more",
		"createUser": "Create User",
		"createUserOneLine": "Create User",
		"userProfileEdit": "Edit User\nProfile",
		"userProfile": "User Profile",
		"deleteUser": "Delete User",
		"exportAuditLogs": "Export Audit Logs",
		"timezone": "Time Zone",
		"noSAMLFileSelected": "Select a proper SAML file",
		"invalidFileXML": "Invalid file type. Please select an XML file for upload.",
		"sso": "SSO",
		"activeDirectorySettings": "SSO Settings",
		"activeDirectorySelection": "I want to have SSO as an authentication server",
		"importSAMLFile": "Import SAML file",
		"browseFile": "Browse File",
		"fileLoaded": "SAML file is loaded",
		"quality": {
			"high": "HIGH QUALITY",
			"medium": "MEDIUM QUALITY",
			"low": "LOW QUALITY",
			"imported": "IMPORTED QUALITY",
		},
		"invalidSearch": "The search query allows a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"error404": "Error 404",
		"pageNotFound": "Page not found",
		"passwordExpirationPeriod": "Password Expiration Period (in days)",
		"logoutInactivityTime": "Automatic Sign Out Time From Initial Sign In (in minutes)",
		"mfaForUsers": "MFA for Users",
		"enableMFAUntil": "Date that all users must enable MFA by:",
		"noMFAForcedTimeSelected": "Please select a date for MFA activation",
		"multiFactorAuthentication": "Multi-Factor Authentication",
		"mfaStatusON": "Multi-Factor Authentication is turned on.",
		"mfaStatusOFF": "Multi-Factor Authentication is turned off.",
		"mfaTurnON": "Turn On",
		"mfaTurnOFF": "Turn OFF",
		"mfaSecurityInfo": "Multi-Factor Authentication gives you additional security by requiring authentication code whenever you sign in on a new device.",
		"mfaWarningON": "Multi-Factor Authentication provides additional security by requiring an authentication code generated by a device in your possession whenever you sign into the system. Turning this feature on will sign you out of MySOZO.",
		"mfaWarningOFF": "Multi-Factor Authentication provides additional security by requiring an authentication code generated by a device in your possession whenever you sign into the system. Turning this feature off will sign you out of MySOZO.",
		"mfaWarningForce": "Multi-Factor Authentication provides additional security by requiring an authentication code generated by a device in your possession whenever you sign into the system. Turning this feature on will sign you out of MySOZO.",
		"mfaAuthenticationMethod": "Your authentication method is ",
		"authenticatorApp": "Authenticator App",
		"mfaEnterPassword": "For your security, enter your password to make this change.",
		"scanQRCode": "Scan the QR code below.",
		"enterSecretKey": "Or enter this secret key into your Authenticator app:",
		"enterVerificationCode": "Enter the 6-digit verification code generated by your Authenticator app.",
		"invalidVerificationCode": "The Verification Code must be a 6-digit number. Please re-enter the code.",
		"noBaseline": "No Baseline",
		"typeOfDataToExport": "TYPE OF DATA TO EXPORT",
		"allAssessmentTypes": "All Assessment Types",
		"byAssessmentType": "By Assessment Type",
		"byRowperMeasurement": "By Row per Measurement",
		"byRowperPatient": "By Row per Patient",
		"advancedOptions": "ADVANCED OPTIONS",
		"dateRange": "DATE RANGE",
		"dataFormat": "DATA FORMAT",
		"additionalSettings": "ADDITIONAL SETTINGS",
		"all": "All",
		"deidentifiedData": "De-identify Data",
		"timezones": {
			"Etc/GMT+12": "International Date Line West",
			"Pacific/Samoa": "Midway Island",
			"Pacific/Honolulu": "Hawaii",
			"America/Anchorage": "Alaska",
			"America/Tijuana": "Pacific Time (US and Canada);Tijuana",
			"America/Chihuahua": "Chihuahua",
			"America/Denver": "Mountain Time (US & Canada)",
			"America/Phoenix": "Arizona",
			"America/Chicago": "Central Time (US and Canada)",
			"America/Regina": "Saskatchewan",
			"America/Monterrey": "Monterrey",
			"America/Mexico_City": "Guadalajara",
			"America/Costa_Rica": "Central America",
			"America/New_York": "Eastern Time (US and Canada)",
			"America/Indiana/Indianapolis": "Indiana (East)",
			"America/Bogota": "Bogota",
			"America/Caracas": "Caracas",
			"America/Halifax": "Atlantic Time (Canada)",
			"America/La_Paz": "La Paz",
			"America/Manaus": "Manaus",
			"America/Argentina/San_Juan": "San Juan",
			"America/Asuncion": "Asuncion",
			"America/Santiago": "Santiago",
			"America/St_Johns": "Newfoundland",
			"America/Sao_Paulo": "Brasilia",
			"America/Montevideo": "Montevideo",
			"America/Argentina/Buenos_Aires": "Buenos Aires",
			"America/Guyana": "Georgetown",
			"America/Godthab": "Greenland",
			"America/Noronha": "Mid-Atlantic",
			"Atlantic/Azores": "Azores",
			"Atlantic/Cape_Verde": "Cape Verde Islands",
			"Europe/Dublin": "Dublin",
			"Africa/Casablanca": "Casablanca",
			"UTC": "Coordinated Universal Time",
			"Africa/Monrovia": "Monrovia",
			"Europe/Belgrade": "Belgrade",
			"Europe/Sarajevo": "Sarajevo",
			"Europe/Brussels": "Brussels",
			"Europe/Amsterdam": "Amsterdam",
			"Africa/Lagos": "West Central Africa",
			"Europe/Minsk": "Minsk",
			"Africa/Windhoek": "Windhoek",
			"Africa/Cairo": "Cairo",
			"Asia/Beirut": "Beirut",
			"Europe/Helsinki": "Helsinki",
			"Asia/Amman": "Amman",
			"Europe/Athens": "Athens",
			"Asia/Jerusalem": "Jerusalem",
			"Africa/Harare": "Harare",
			"Europe/Moscow": "Moscow",
			"Asia/Tbilisi": "Tbilisi",
			"Asia/Kuwait": "Kuwait",
			"Africa/Nairobi": "Nairobi",
			"Asia/Baghdad": "Baghdad",
			"Asia/Tehran": "Tehran",
			"Asia/Muscat": "Abu Dhabi",
			"Indian/Mauritius": "Port Louis",
			"Asia/Yerevan": "Yerevan",
			"Asia/Baku": "Baku",
			"Asia/Kabul": "Kabul",
			"Asia/Yekaterinburg": "Ekaterinburg",
			"Asia/Karachi": "Islamabad",
			"Asia/Tashkent": "Tashkent",
			"Asia/Kolkata": "Kolkata",
			"Asia/Kathmandu": "Kathmandu",
			"Asia/Dhaka": "Astana, Dhaka",
			"Asia/Colombo": "Sri Jayawardenepura",
			"Asia/Novosibirsk": "Almaty",
			"Asia/Yangon": "Yangon",
			"Asia/Bangkok": "Bangkok",
			"Asia/Krasnoyarsk": "Krasnoyarsk",
			"Asia/Hong_Kong": "Beijing",
			"Asia/Kuala_Lumpur": "Kuala Lumpur",
			"Asia/Taipei": "Taipei",
			"Australia/Perth": "Perth",
			"Asia/Irkutsk": "Irkutsk",
			"Asia/Seoul": "Seoul",
			"Asia/Tokyo": "Tokyo",
			"Asia/Yakutsk": "Yakutsk",
			"Australia/Darwin": "Darwin",
			"Australia/Adelaide": "Adelaide",
			"Australia/Melbourne": "Melbourne",
			"Australia/Brisbane": "Brisbane",
			"Australia/Hobart": "Hobart",
			"Asia/Vladivostok": "Vladivostok",
			"Pacific/Guam": "Guam",
			"Asia/Magadan": "Magadan",
			"Pacific/Fiji": "Fiji",
			"Pacific/Auckland": "Auckland",
			"Asia/Kamchatka": "Petropavlovsk-Kamchatsky",
			"Pacific/Tongatapu": "Nuku'alofa"
		},
		"mergePatients": "Merge Patients",
		"mergeActivePatients": "Merge active patients",
		"mergePatientsSource": "Source Patient",
		"mergePatientsDestination": "Destination Patient",
		"viewMore": "+ View more",
		"viewLess": "- View less",
		"source": "Source",
		"destination": "Destination",
		"mergePatientsInfo": "The source patient’s measurements will be added to the destination patient and the source patient will be deleted. All results will be recalculated based on the destination patient’s profile. This change is permanent and not reversible.",
		"importDataInfo": "The shared patient’s measurements will be added to the destination patient. All results will be recalculated based on the destination patient’s profile.",
		"importAsNew": "Import as New",
		"import": "Import",
		"mergeBtn": "Confirm Merge",
		"merge": "Merge",
		"mergeWarning": "Are you sure that you want to merge the selected patients? This change will be permanent and not reversible.",
		"importWarning": "Confirm the patient selection for shared data importation.",
		"mergePatientsIds": "A patient cannot be merged with themself. Please select two different patients to merge.",
		"importPatientsIds": "A patient cannot be imported with themself. Please select two different patients to import.",
		"preview": "Preview",
		"delete": "Delete",
		"deleteWarning": "By checking this box, you acknowledge that this measurement will be permanently deleted and can not be restored. If the measurement selected for deletion is currently used as a baseline, the impacted baseline will be cleared.",
		"dialysisStatus": "Dialysis Status",
		"dialysisStatusNoSelection": "No Selection",
		"dialysisStatusPre": "Pre-Dialysis",
		"dialysisStatusPost": "Post-Dialysis",
		"dialysisStatusOther": "Other",
		"lowQualityResultsRenal": "This result cannot be provided due to low measurement quality \"red\".  For this measurement to be shown, the Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) must be medium quality \"yellow\" or high quality \"green\".",
		"lowQualityResultsHfdex": "This result cannot be provided due to low measurement quality \"red\".  For this measurement to be shown, the Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) must be medium quality \"yellow\" or high quality \"green\".",
		"lowQualityHistoryRenal": "A history chart cannot be provided due to low measurement quality. For the measurement history to be shown, at least one measurement must have a Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) of medium quality \"yellow\" or high quality \"green\".",
		"lowQualityHistoryHfdex": "A history chart cannot be provided due to low measurement quality. For the measurement history to be shown, at least one measurement must have a Right Whole Body Cole Plot (found in the Cole plot review screen after the measurement) of medium quality \"yellow\" or high quality \"green\".",
		"totalMeasurements": "Total Measurements",
		"of": "of",
		"invalidRenalBaseline": "The ECF baseline value must be equal to or greater than zero and less than the maximum measured TBW value.",
		"activeDirectoryLogin": "SSO Login",
		"domainName": "Domain Name",
		"enterDomainName": "Enter domain name",
		"invalidDomainName": "Domain Name allows a maximum of 32 characters and cannot contain spaces.",
		"secureSignInSSO": "Secure sign in with SSO",
		"signIn": "Sign In",
		"ssoSignIn": "SSO Sign In",
		"ssoSignInDescription": "Enter the Domain Name used for your MySOZO account.",
		"forgotDomainName": "Forgot domain name used for your MySOZO account? Please contact your MySOZO administrator.",
		"sozoDigitalHealthPlatform": "SOZO Digital Health Platform",
		"enterMfaCode": "Enter MFA Code",
		"mfaCode": "MFA Code",
		"enterEmail": "Enter email",
		"enterYourPassword": "Enter your password",
		"newEmail": "New Email",
		"confirm": "Confirm",
		"enterNewEmail": "Enter new email",
		"confirmNewEmail": "Confirm new email",
		"enterPassword": "Enter password",
		"enterOldPassword": "Enter old password",
		"enterNewPassword": "Enter new password",
		"enterFirstName": "Enter first name",
		"enterLastName": "Enter last name",
		"enterMiddleName": "Enter middle name",
		"enterMrn": "Enter medical record number",
		"selectDate": "Select date",
		"selectSex": "Select sex",
		"enterHeightMetric": "Enter height (cm)",
		"selectCountryCode": "Select country code",
		"enterPhoneNumber": "Enter phone number",
		"enterAddress": "Enter address",
		"enterCity": "Enter city",
		"enterState": "Enter state",
		"selectCountry": "Select country",
		"enterPostalCode": "Enter postal code",
		"setNew": "Set New",
		"passwordPolicy": "Password Policy",
		"signOutPolicy": "Sign out Policy",
		"search": "Search",
		"addNew": "Add New",
		"searchTip": "Search Tip",
		"searchPrompt": "Complete a search to find a patient. To view a full patient list, search with an empty field.",
		"demographics": "Demographics",
		"create": "Create",
		"newPatient": "New Patient",
		"sorry": "Sorry!",
		"filter": "Filter",
		"sortBy": "Sort by:",
		"newest": "Newest",
		"oldest": "Oldest",
		"thirty": "30",
		"fifty": "50",
		"hundred": "100",
		"apply": "Apply",
		"clear": "Clear",
		"noGroupsCreatedFilter": "No groups have been created yet.",
		"noReceiverCreatedFilter": "No receivers have been added yet.",
		"noGroupsCreatedList": "No groups have been created yet. Create the first one.",
		"noTagsCreatedList": "No tags have been created yet.\nCreate the first one.",
		"noTagsCreatedListClinician": "No tags have been defined. A tag must be first defined by an Administrator before it can be assigned to a measurement.",
		"noTagsAddedList": "No tags have been added yet.\nAdd the first one.",
		"back": "Back",
		"notes": "Notes",
		"addNewNote": "Add New Note",
		"addTitle": "Add Title",
		"addNote": "Add Note",
		"medicalRecordNumber": "Medical record number",
		"untitled": "Untitled",
		"notePlaceholder": "Start writing your note...",
		"invalidNoteTitle": "Title may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"emptyTitleNotes": "The title must contain at least one character and no more than 255 characters",
		"emptyDescriptionNotes": "The description must contain at least one character and no more than 1000 characters",
		"invalidNoteTitleLength": "Title allows a maximum of 255 characters.",
		"invalidNoteDescription": "Description may contain numbers, spaces, new lines or the following characters: '.()#_-/%&@:;,+",
		"invalidNoteDescriptionLength": "Description allows a maximum of 1000 characters.",
		"newNote": "New Note",
		"deleteNoteConfirm": "Are you sure you want to delete the selected note?",
		"deleteNote": "Delete Note",
		"searchGroup": "Search for a group...",
		"searchTag": "Search for a tag...",
		"group": "Group",
		"member": "Member",
		"members": "Members",
		"view": "View",
		"noGroups": "No groups found",
		"noResultsSearch": "No results found",
		"tags": "Tags",
		"tag": "Tag",
		"devices": "Devices",
		"tagColor": "Tag Color",
		"noMeasurementsFound": "No Measurements found",
		"noTags": "No tags found",
		"noTagsList": "No tags have been created yet.",
		"noMeasurementsFilter": "No results match your filter selections. Please try again.",
		"noDeletedPatients": "There are no deleted patients.",
		"mfaDeactivationWarning": "You are about to deactivate MFA of user:",
		"editGroupName": "Edit Group Name",
		"editTagName": "Edit Tag Name",
		"deleteGroup": "Delete Group",
		"deleteTag": "Delete Tag",
		"newGroup": "New Group",
		"enterGroupName": "Enter group name",
		"enterTagName": "Enter tag name",
		"clinicMfa": "Clinic MFA",
		"noChanges": "No changes detected.",
		"prev": "Prev",
		"next": "Next",
		"deleteMeasurement": "Delete Measurement",
		"unilateralInfo": "The unilateral selection is for use with patients that have only one limb at risk of lymphoedema. Both limbs will be measured and used to calculate the L-Dex score.",
		"bilateralInfo": "For use with patients when both legs are at risk of lymphoedema.  All limbs will be measured and used to calculate the L-Dex scores.",
		"bilateralInfoTitle": "BILATERAL – ONLY AVAILABLE FOR LEGS",
		"requiredFields": "Required Fields",
		"fittedCurve": "Fitted Curve",
		"measuredData": "Measured Data",
		"tdCorrectedData": "Td Corrected Data",
		"selectGraphs": "Select Graphs",
		"ecfIcfTbw": "ECF/ICF/TBW",
		"ecfTbwWeight": "ECF/TBW/Weight",
		"normal":"Normal",
		"elevated":"Elevated",
		"normalFluid":"Normal Fluid",
		"elevatedFluid":"Elevated Fluid",
		"fluidOverload":"Fluid Overload",
		"veryLean":"Very Lean",
		"lean":"Lean",
		"high":"High",
		"veryHigh":"Very High",
		"excellent":"Excellent",
		"good":"Good",
		"fair":"Fair",
		"poor":"Poor",
		"veryPoor":"Very Poor",
		"underweight":"Underweight",
		"healthy":"Healthy",
		"overweight":"Overweight",
		"obese":"Obese",
		"colePlotWarningNoRed":"None of the Patient’s selected assessment types are currently impacted by the “red” Cole plot(s).",
		"colePlotWarningHasRed":"One or more Cole plots are showing low quality “red”. The impact of the “red” Cole plot(s) on the Patient’s selected assessment types are:",
		"colePlotWarningNegativeFM":"This measurement has resulted in invalid results indicating a probable error in the measurement. Please review the Cole plots above for correctness and verify the height and weight entries. Retry your measurement or contact ImpediMed technical support. Note: once you press Proceed, this information will be lost.",
		"output":"output",
		"impact":"impact",
		"associatedRedColePlot":"associated red cole plot",
		"mayImpactAccuracy":"May impact accuracy",
		"mayImpactTheAccuracyOfLimb":"May impact the accuracy of the associated limb",
		"resultWontShow":"Results will not be shown",
		"graphs": "Graphs",
		"noGraphsSelected": "No graphs have been selected.\nSelect one or more graphs to view historical results.",
		"addNewTag": "Add New Tag",
		"selectTag": "Select Tag",
		"description": "Description",
		"passwordProtectedUserCreate": "For security reasons in order to create a user your password is required to proceed.",
		"invalidTagDescriptionLength": "A maximum of 255 characters are allowed in the Description.",
		"tagNotSelected": "Please select a tag.",
		"wrongEmailFormat": "The email address is invalid. The email address may contain up to 255 characters and the following characters: \"- _ . @\"",
		"show":"show",
		"hide":"hide",
		"ehrSettings": "EHR Settings",
		"dataTransfer": "Data Transfer",
		"dtSozoToEhr": "Data transfer from SOZO to EHR",
		"dtEhrToSozo": "Data transfer from EHR to SOZO",
		"ehrSystem": "EHR System",
		"epic": "Epic",
		"tracker": "Tracker",
		"measure": "Measure",
		"measurement": "Measurement",
		"tutorial": "Tutorial",
		"somethingWentWrong": "Oops! Something went wrong",
		"measurementQuality": "To optimize the quality of the measurement:",
		"measurementTips": "Additional tips to ensure consistent BodyComp measurement:",
		"measurementQualityChecklist": {
			"qualityChecklist-1": "Remove all metal jewellery if possible, including large belt buckles. Items above the neck such as earrings are ok.",
			"qualityChecklist-2": "Dampen hands and feet or the SOZO electrodes.",
			"qualityChecklist-3": "Remove any electronics, coins, keys or other metal objects from pockets.",
			"qualityChecklist-4": "Remain still during the measurement with elbows away from the body.",
			"qualityChecklist-5": "Remove shoes and socks or stockings.",
		},
		"measurementTipsChecklist": {
			"tipChecklist-1": "Ensure an empty bladder prior to measurement.",
			"tipChecklist-2": "Avoid alcohol for 8 hours prior to measurement.",
			"tipChecklist-3": "Avoid exercise for 4 hours prior to measurement.",
			"tipChecklist-4": "Avoid meals for 8 hours prior to measurement.",
			"tipChecklist-5": "Avoid caffeine for 2 hours prior to measurement.",
		},
		"SOZOContraindicationTextPart1": "SOZO is not indicated for patients who have an active implantable device (e.g., ",
		"SOZOContraindicationTextPart2": "permanent pacemaker, defibrillator",
		"SOZOContraindicationTextPart3": ", infusion pumps, neurostimulators, brain stimulators, etc.). Refer to the IFU for additional instructions.",
		"SOZOProContraindicationText": "SOZO Pro is not indicated for patients who have an active implantable device (e.g., infusion pumps, neurostimulators, brain stimulators) with the exceptions of permanent pacemakers (PPMs) or implantable cardioverter-defibrillators (ICDs). Refer to the IFU for additional instructions.",
		"previous": "Previous",
		"placeFeet": "Place feet onto foot unit",
		"placeHand": "Place hands onto hand unit",
		"infoTutorialSOZOBold": "Initiate measurement by pressing start button.",
		"infoTutorial": " This will be followed by a 5 second countdown before the measurement starts. Patient should keep hands and feet on the SOZO device and remain still until the measurement is complete.",		"tutorialStep1Title": "Have patient stand on foot unit",
		"tutorialStep2Title": "Get weight on SOZOapp",
		"tutorialStep3Title": "Patient should remain standing on foot unit",
		"tutorialStep4Title": "Place hands onto hand unit",
		"tutorialStep1Subtitle": "Patient should not place hands on hand unit until weight has been measured.",
		"tutorialStep2Subtitle": "Retake weight and/or adjust Pre-Tare as needed.",
		"tutorialStep3Subtitle": "Patient’s feet should be touching all 4 electrodes as shown.",
		"tutorialStep4Subtitle": "Patient’s hands should be touching all 4 electrodes as shown.",
		"measurementWillStart": "Measurement will start in",
		"start": "Start",
		"selfTest": "Self-Test",
		"selectDevice": "Select Device",
		"deviceManagement": "Device Management",
		"weightPreTare": "Weight Pre-Tare",
		"selectNewSOZODevice": "Select new SOZO device",
		"pairDeviceAndroid": "If SOZO Device is not listed above,",
		"pairDevice": "Pair Device",
		"pairNewDevice": "Pair New Device",
		"scaleCalibrationHistory": "Scale Calibration History",
		"lowerScaleCalibration": "Lower Scale Calibration",
		"upperScaleCalibration": "Upper Scale Calibration",
		"targetWeight": "Target Weight",
		"actualMeasurement": "Actual Measurement",
		"selfTestDirections": "To ensure consistently accurate readings, we recommend performing a self-test at the start of each day of use to confirm the device is functioning properly. To perform a self-test, ensure that there is nothing in contact with the electrodes on both hand unit and foot unit. A self-test cannot be performed while standing on foot unit.",
		"beginSelfTest": "Begin Self-Test",
		"weightPreTareStatusOn": "Weight Pre-Tare is turned on.",
		"weightPreTareStatusOff": "Weight Pre-Tare is turned off.",
		"weightPreTareSOZOProContent": " Enter weight to subtract from scale for each measurement, e.g clothing offset.",
		"weightPreTareSOZO": "To enable the Weight Pre-Tare, a SOZO Pro device must be selected.",
		"unsupportedOSAndBrowser": "Unsupported Browser or Operating System",
		"unsupportedOSAndBrowserMessage": "Communication with the SOZO device is not supported on this browser or operating system. To use the SOZO device, including taking a measurement – use the SOZOapp on an Android or iOS tablet or a Windows based PC with a Chrome or Edge browser.",
		"weightPromptToSitSOZO": "The patient's weight exceeds the maximum limit of the SOZO device (170 kg or 375 lbs). Please take this patient's measurements in a seated position.",
		"weightPromptToSitSOZOPro": "The patient's weight exceeds the maximum limit of the SOZO Pro device (220 kg or 485 lbs). Please enter the weight into the SOZOapp manually and take this patient's measurements in a seated position.",
		"weightPromptToStand": "The patient's weight must be greater than 22 kg or 48.5 lbs. Re-try the weight measurement while the patient is standing on the SOZO Pro device or manually enter the weight if the patient is in a seated position.",
		"invalidWeightSOZOMetric": "Weight must be a number between 10.0 kg and 340.0 kg",
		"invalidWeightSOZOImperial": "Weight must be a number between 22 lbs and 749.6 lbs",
		"invalidWeightSOZOProMetric": "Weight must be a number between 22.0 kg and 340.0 kg",
		"invalidWeightSOZOProImperial": "Weight must be a number between 48.5 lbs and 749.6 lbs",
		"invalidPreTareMetric": "Pre-Tare Weight must be a number between 0.0 kg and 10.0 kg",
		"invalidPreTareImperial": "Pre-Tare Weight must be a number between 0.0 lbs and 22.0 lbs",
		"weightPreTareSOZOProMetricRange": "Range: 0.0 kg - 10.0 kg",
		"weightPreTareSOZOProImperialRange": "Range: 0.0 lbs - 22.0 lbs",
		"pairingCanceled": "No SOZO device has been selected. Please select a device to initiate a measurement or self-test.",
		"deviceError": "A device error occurred. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		"tareScaleSuccess": "Scale tared",
		"scaleSettingsSuccess": "Scale settings saved",
		"deviceStatusSuccess": "Status retrieved",
		"devicePairedSuccess": "Device Paired",
		"selfTestPassed": "Self-test passed",
		"selfTestFailed": "Self-test failed. Re-try the test and ensure that there is nobody touching or standing on the SOZO device. If the issue persists, contact ImpediMed technical support.",
		"selfTestFailedTakeMeasurement": "Measurement failed because there is no record of a successful self-test. Please complete a successful self-test and if the issue persists, contact ImpediMed Technical Support.",
		"selfTestFailedDeviceStatus": "Note: there are no records of a previously successful self test on the selected SOZO device. A successful self test is required before a measurement may be taken. Select OK to show Device Status.",
		"unsupportedResolutionWarningTitle": "Oops! Unsupported screen resolution",
		"unsupportedResolutionWarningFirst": "This site requires a horizontal screen resolution greater than 1024px.",
		"unsupportedResolutionWarningSecond": "Increase your screen resolution in your browser or operating system to continue.",
		"connectivityLostTitle": "Oops! Connectivity lost",
		"connectivityLostTextFirst": "Check your connectivity and/or internet connection or contact ImpediMed technical support.",
		"connectivityLostTextSecond": "Retry will attempt to re-initiate contact with MySOZO to calculate your measurement.",
		"retry": "Retry",
		"selectDialysisStatus": "Select Dialysis Status",
		"preDialysis": "Pre-dialysis",
		"postDialysis": "Post-dialysis",
		"takeMeasurementHeightEmpty": "The patient's height has not been entered. Enter a height in the patient profile in order to take a measurement.",
		"takeMeasurementAssessmentTypeEmpty": "The patient has no assessment type selected or no valid licenses. Add at least one assessment type to the patient profile or confirm in the information menu that licenses are available.",
		"takeMeasurementNoActiveLicense": "There is no active license for your MySOZO account. Please contact ImpediMed technical support.",
		"takeMeasurementESRDNotSelected": "Dialysis Status must be selected.",
		"takeMeasurementNoSOZODeviceSelect": "A SOZO device must be selected to complete a measurement. Please select a device in the settings menu to continue.",
		"acceptOrRejectColePlots": "Accept or Reject Cole Plots",
		"accept": "Accept",
		"reject": "Reject",
		"proceed": "Proceed",
		"firmwareUptoDate": "The SOZO device firmware is already at the latest version.",
		"firmwareUpdated": "Firmware updated",
		"firmwareUpdate": "Firmware Update",
		"firmwareUpdateConfirmation": "New version may exist. Do you want to update?",
		"notLicensedDevice": "There is no active license for the selected device.",
		"sender": "Sender",
		"receiver": "Receiver",
		"noReceivers": "No Receivers",
		"status": "Status",
		"importData": "Import Data",
		"markAsNew": "Mark as New",
		"shared": "Shared",
		"imported": "Imported",
		"new": "New",
		"recalled": "Recalled",
		"old": "Old",
		"sharingListReceiver": "SharingListReceiver",
		"importDetails": "Import Details",
		"recallData": "Recall Data",
		"recalledDetails": "Recalled Details",
		"recall": "Recall",
		"recallDataConfirmMessage": "By continuing with the recall, the shared clinic will no longer have access to the data. Data that has already been imported will not be affected.",
		"licensedDeviceInactive": "The licences granted do not allow for use of the selected SOZO device. Please contact ImpediMed Technical Support for further details.",
		"noLicense": "No Licence",
		"windowsPairingWarning": "SOZO device must first be paired with PC through Windows Bluetooth system settings before first use.",
		"sharedDataDuplicateEmails": "Duplicate emails are not allowed. Remove the duplicate email to continue.",
		"buildDate": "Build Date",
		"sozoApp": "SOZOapp",
		"noSOZODevicesPaired": "No SOZO devices have been paired.",
		"noDeviceSelected": "The action could not be completed. Select a SOZO device in the SOZOapp settings menu to proceed.",
		"ssoLogoutWarning": "You are about to be signed out of SOZO. As an SSO user, you will still be signed into your Active Directory. If you are using a browser on a shared device, including Android/iOS, please clear all cookies and cache to ensure sign-out.",
		//endregion
		//region SERVER
		//region COGNITO
		"cognito_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"cognito_InitForgotPassword": "An email has been sent with instructions on how to reset your password. If you do not receive an email, check that the email address you have entered is correct, check your spam folder, or contact your MySOZO Administrator.",
		"cognito_ConfirmForgotPassword": "Your password has been updated.",
		"cognito_ResendConfirmationCode": "An email has been sent with instructions on how to reset your password. If you do not receive an email, check that the email you have entered is correct, check your spam folder, or contact your MySOZO Administrator.",
		"cognito_InitialChangePassword": "Your account has been verified.",
		"cognito_UpdateEmailAuthUser": "Your email has been updated.",
		"cognito_DisableUser": "User has been disabled.",
		"cognito_EnableUser": "User has been enabled.",
		"cognito_SignOutAuthUser": "You have been signed out.",
		"cognito_AuthUser": "You have logged in.",
		"cognito_GetUser": "User has been retrieved.",
		"cognito_RefreshToken": "Users token has been refreshed.",
		"cognito_ResetUserPassword": "Your password has been reset.",
		"cognito_ChangedPassword": "Your password has been reset.",
		"cognito_TerminateUser": "User has been deleted.",
		"cognito_LogoutUser": "You have logged out.",
		"cognito_ListUsers": "User list has been retrieved.",
		"cognito_AdminListEvents": "List of user events has been retreived",
		"cognito_UserPoolClientIdNotFound": "User not found.",
		"cognito_UserPoolNotFound": "User not found.",
		"cognito_InternalErrorException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidLambdaResponseException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidParameterException": "The system cannot handle the provided input most likely due to invalid characters. Please make sure to validate your input. If the issue persists, please contact ImpediMed Technical Support.",
		"cognito_InvalidSmsRoleAccessPolicyException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidSmsRoleTrustRelationshipException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidUserPoolConfigurationException": "Please contact ImpediMed Technical Support.",
		"cognito_MFAMethodNotFoundException": "Please contact ImpediMed Technical Support.",
		"cognito_NotAuthorizedException": {
			"loggedIn": "We do not recognize the password entered. Please enter the password associated with the user who is currently signed into MySOZO.",
			"loggedOut": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password by selecting “Forgot Password?” or contact your MySOZO Administrator.",
		},
		"cognito_AuthUserWithTemp": "We do not recognize your email and/or password.  Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
		"cognito_PasswordResetRequiredException": "Please reset your password before continuing.",
		"cognito_ResourceNotFoundException": "Please contact ImpediMed Technical Support.",
		"cognito_TooManyRequestsException": "There was a temporary error.  Please try again and if the issue persists, contact ImpediMed Technical Support.",
		"cognito_UnexpectedLambdaException": "Please contact ImpediMed Technical Support.",
		"cognito_UserLambdaValidationException": "Please contact ImpediMed Technical Support.",
		"cognito_UserNotConfirmedException": "User has not been confirmed.",
		"cognito_UserNotFoundException": {
			"userLoggedIn": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
			"userLoggedOut": "We do not recognize your email and/or password. Try again and if this does not resolve the issue, reset your password by selecting “Forgot Password?” or contact your MySOZO Administrator.",
		},
		"cognito_CodeDeliveryFailureException": "The system was unable to generate the verification code email.  Try again or contact ImpediMed Technical Support.",
		"cognito_InvalidPasswordException": "Please contact ImpediMed Technical Support.",
		"cognito_PreconditionNotMetException": "Please contact ImpediMed Technical Support.",
		"cognito_UnsupportedUserStateException": "Please contact ImpediMed Technical Support.",
		"cognito_UsernameExistsException": "The email that you entered is already in use in another clinic.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_AliasExistsException": "The email that you entered is already in use in another clinic.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_CodeMismatchException": {
			"changeEmail": "Could not update the email address due to an invalid or expired verification code. Please re-enter the code or request a new code.",
			"login": "The temporary password entered is not valid or has expired. Please re-enter the password or navigate to MySOZO and select “Forgot password?” to obtain a new temporary password.",
			"deleteMFA": "Could not de-activate the Multi-Factor Authentication. The verification code entered is not valid or has expired. Please re-enter the code or request a new code.",
			"updateFacilityMfa": "Could not update Multi-Factor Authentication. The verification code entered is not valid or has expired. Please re-enter the code or request a new code.",
			"resetPassword": "Could not reset the password. The link provided for password reset is invalid or has expired."
		},
		"cognito_ExpiredCodeException": "The verification code has expired.  Please request a new code by resetting the password.",
		"cognito_SoftwareTokenMFANotFoundException": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidEmailRoleAccessPolicyException": "Please contact ImpediMed Technical Support.",
		"cognito_LimitExceededException": "Please contact ImpediMed Technical Support.",
		"cognito_UserPoolTaggingException": "Please contact ImpediMed Technical Support.",
		"cognito_AccessDeniedException": "You do not have sufficient access to perform this action.  Please contact ImpediMed Technical Support.",
		"cognito_IncompleteSignature": "Please contact ImpediMed Technical Support.",
		"cognito_InternalFailure": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidClientTokenId": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidParameterCombination": "Please contact ImpediMed Technical Support.",
		"cognito_InvalidParameterValue": "An invalid value was entered. ",
		"cognito_InvalidQueryParameter": "An invalid query was initiated.  Try again with a different query.",
		"cognito_MalformedQueryString": "An invalid query was initiated.  Try again with a different query.",
		"cognito_MissingAction": "Please contact ImpediMed Technical Support.",
		"cognito_MissingAuthenticationToken": "Please contact ImpediMed Technical Support.",
		"cognito_MissingParameter": "Please contact ImpediMed Technical Support.",
		"cognito_OptInRequired": "Please contact ImpediMed Technical Support.",
		"cognito_RequestExpired": "The request has expired.  Please try again.",
		"cognito_ServiceUnavailable": "There was a temporary error.  Please try again and if the issue persists, contact ImpediMed Technical Support.",
		"cognito_ThrottlingException": "There was a temporary error.  Please try again and if the issue persists, contact ImpediMed Technical Support.",
		"cognito_ValidationError": "Please contact ImpediMed Technical Support.",
		"cognito_MissingRequiredParameter": "Please contact ImpediMed Technical Support.",
		"cognito_ProviderNotFound": "No Domain Name was found with the name entered.",
		"cognito_EmailAlreadyExists": "The email address is already in use in the system.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_EmailAlreadyExists_sso": "The email address is already in use in the system.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"cognito_DuplicateProviderException": "The Domain Name already exists and must be unique. Please contact ImpediMed Technical support if you are unable to add your Domain Name.",
		//endregion
		//region S3
		"s3_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"s3_FetchFacilityLogo": "Logo has been uploaded.",
		"s3_UploadFacilityLogo": "Logo has been uploaded.",
		"s3_DeleteFacilityLogo": "Logo has been deleted.",
		"s3_UploadFile": "Upload has completed.",
		"s3_GetUrl": "Please contact ImpediMed Technical Support.",
		"s3_CustomLogoAlreadyExists": "Please contact ImpediMed Technical Support.",
		"s3_NotFound": "Please contact ImpediMed Technical Support.",
		"s3_AccessDenied": "Please contact ImpediMed Technical Support.",
		"s3_AccountProblem": "Please contact ImpediMed Technical Support.",
		"s3_AllAccessDisabled": "Please contact ImpediMed Technical Support.",
		"s3_AmbiguousGrantByEmailAddress": "Please contact ImpediMed Technical Support.",
		"s3_AuthorizationHeaderMalformed": "Please contact ImpediMed Technical Support.",
		"s3_BadDigest": "Please contact ImpediMed Technical Support.",
		"s3_BucketAlreadyExists": "Please contact ImpediMed Technical Support.",
		"s3_BucketAlreadyOwnedByYou": "Please contact ImpediMed Technical Support.",
		"s3_BucketNotEmpty": "Please contact ImpediMed Technical Support.",
		"s3_CredentialsNotSupported": "Please contact ImpediMed Technical Support.",
		"s3_CrossLocationLoggingProhibited": "Please contact ImpediMed Technical Support.",
		"s3_EntityTooSmall": "Please contact ImpediMed Technical Support.",
		"s3_EntityTooLarge": "Please contact ImpediMed Technical Support.",
		"s3_ExpiredToken": "Please contact ImpediMed Technical Support.",
		"s3_IllegalVersioningConfigurationException": "Please contact ImpediMed Technical Support.",
		"s3_IncompleteBody": "Please contact ImpediMed Technical Support.",
		"s3_IncorrectNumberOfFilesInPostRequest": "Please contact ImpediMed Technical Support.",
		"s3_InlineDataTooLarge": "Please contact ImpediMed Technical Support.",
		"s3_InternalError": "Please contact ImpediMed Technical Support.",
		"s3_InvalidAccessKeyId": "Please contact ImpediMed Technical Support.",
		"s3_InvalidAddressingHeader": "Please contact ImpediMed Technical Support.",
		"s3_InvalidArgument": "Please contact ImpediMed Technical Support.",
		"s3_InvalidBucketName": "Please contact ImpediMed Technical Support.",
		"s3_InvalidBucketState": "Please contact ImpediMed Technical Support.",
		"s3_InvalidDigest": "Please contact ImpediMed Technical Support.",
		"s3_InvalidEncryptionAlgorithmError": "Please contact ImpediMed Technical Support.",
		"s3_InvalidLocationConstraint": "Please contact ImpediMed Technical Support.",
		"s3_InvalidObjectState": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPart": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPartOrder": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPayer": "Please contact ImpediMed Technical Support.",
		"s3_InvalidPolicyDocument": "Please contact ImpediMed Technical Support.",
		"s3_InvalidRange": "Please contact ImpediMed Technical Support.",
		"s3_InvalidRequest": "Please contact ImpediMed Technical Support.",
		"s3_InvalidSecurity": "Please contact ImpediMed Technical Support.",
		"s3_InvalidSOAPRequest": "Please contact ImpediMed Technical Support.",
		"s3_InvalidStorageClass": "Please contact ImpediMed Technical Support.",
		"s3_InvalidTargetBucketForLogging": "Please contact ImpediMed Technical Support.",
		"s3_InvalidToken": "Please contact ImpediMed Technical Support.",
		"s3_InvalidURI": "Please contact ImpediMed Technical Support.",
		"s3_KeyTooLongError": "Please contact ImpediMed Technical Support.",
		"s3_MalformedACLError": "Please contact ImpediMed Technical Support.",
		"s3_MalformedPOSTRequest": "Please contact ImpediMed Technical Support.",
		"s3_MalformedXML": "Please contact ImpediMed Technical Support.",
		"s3_MaxMessageLengthExceeded": "Please contact ImpediMed Technical Support.",
		"s3_MaxPostPreDataLengthExceededError": "Please contact ImpediMed Technical Support.",
		"s3_MetadataTooLarge": "Please contact ImpediMed Technical Support.",
		"s3_MethodNotAllowed": "Please contact ImpediMed Technical Support.",
		"s3_MissingAttachment": "Please contact ImpediMed Technical Support.",
		"s3_MissingContentLength": "Please contact ImpediMed Technical Support.",
		"s3_MissingRequestBodyError": "Please contact ImpediMed Technical Support.",
		"s3_MissingSecurityElement": "Please contact ImpediMed Technical Support.",
		"s3_MissingSecurityHeader": "Please contact ImpediMed Technical Support.",
		"s3_NoLoggingStatusForKey": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchBucket": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchBucketPolicy": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchKey": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchLifecycleConfiguration": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchUpload": "Please contact ImpediMed Technical Support.",
		"s3_NoSuchVersion": "Please contact ImpediMed Technical Support.",
		"s3_NotImplemented": "Please contact ImpediMed Technical Support.",
		"s3_NotSignedUp": "Please contact ImpediMed Technical Support.",
		"s3_OperationAborted": "Please contact ImpediMed Technical Support.",
		"s3_PermanentRedirect": "Please contact ImpediMed Technical Support.",
		"s3_PreconditionFailed": "Please contact ImpediMed Technical Support.",
		"s3_Redirect": "Please contact ImpediMed Technical Support.",
		"s3_RestoreAlreadyInProgress": "Please contact ImpediMed Technical Support.",
		"s3_RequestIsNotMultiPartContent": "Please contact ImpediMed Technical Support.",
		"s3_RequestTimeout": "Please contact ImpediMed Technical Support.",
		"s3_RequestTimeTooSkewed": "Please contact ImpediMed Technical Support.",
		"s3_RequestTorrentOfBucketError": "Please contact ImpediMed Technical Support.",
		"s3_SignatureDoesNotMatch": "Please contact ImpediMed Technical Support.",
		"s3_ServiceUnavailable": "Please contact ImpediMed Technical Support.",
		"s3_SlowDown": "Please contact ImpediMed Technical Support.",
		"s3_TemporaryRedirect": "Please contact ImpediMed Technical Support.",
		"s3_TokenRefreshRequired": "Please contact ImpediMed Technical Support.",
		"s3_TooManyBuckets": "Please contact ImpediMed Technical Support.",
		"s3_UnexpectedContent": "Please contact ImpediMed Technical Support.",
		"s3_UnresolvableGrantByEmailAddress": "Please contact ImpediMed Technical Support.",
		"s3_UserKeyMustBeSpecified": "Please contact ImpediMed Technical Support.",
		//endregion
		//region SES
		"ses_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"ses_SuccessMessageFromSES": "Email has been sent.",
		"ses_EmailUpdateSuccessMessageFromSES": "Email has been sent to updated email address.",
		"ses_ExportSuccessMessageFromSES": "Export email has been sent.",
		//endregion
		//region LAMBDAS
		"lambdas_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_ALIAS_EXISTS": "The facility alias already exists and must be unique.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_BANNED": "This account has been locked for 30 minutes because the incorrect password was entered too many times.  Reset the password to continue immediately.",
		"lambdas_ER_SIGNAL_EXCEPTION: CLIENT_WRONG_VERSION": "This version of software is not compatible.  Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_PASSWORD_IS_EXPIRED": "Your password has expired.  Please set a new one.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_USER": "Your account has been deleted. Please contact your MySOZO administrator if this is in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_PATIENT": "The patient is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_MEASUREMENT": "The measurement is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_ADMIN": "Your account has been deleted. Please contact your MySOZO administrator if this is in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_USER_ROLE_AND_CLIENT": "The system does not recognize this user.  If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_ACTIVE_VERSION_AVAILABLE": "No active version is available.   If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_ACTIVE": "The selected user has already been restored. Refresh the page to update the user list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_INACTIVE": "The selected user has already been deleted.  Refresh the page to update the user list.  If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: USER_DELETED": "The selected user account has been deleted. Restore the user in order to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: ADMIN_DELETED": "The selected user has already been deleted.  Refresh the page to update the patient list.  If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_LICENSE": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_VALUES": "The entry is not accepted by MySOZO.     If this message is in error, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: SAME_PASSWORD": "Your password cannot be one of the last three passwords used. Choose another password to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: UNKNOWN_ERROR": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_NOT_EXISTS": "Facility does not exist in MySOZO.  Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: ACTIVE_PATIENT_MRN_EXISTS": "Duplicate MRNs are not allowed. An active patient with the same MRN already exists.",
		"lambdas_ER_SIGNAL_EXCEPTION: INACTIVE_PATIENT_MRN_EXISTS": "Duplicate MRNs are not allowed. A deleted patient with the same MRN already exists. ",
		"lambdas_ER_SIGNAL_EXCEPTION: ACTIVE_PATIENT_DATA_COMBINATION_EXISTS": "An active patient with the same First Name, Last Name and Date of Birth already exists. Are you sure that you are saving to the correct patient?",
		"lambdas_ER_SIGNAL_EXCEPTION: INACTIVE_PATIENT_DATA_COMBINATION_EXISTS": "A deleted patient with the same First Name, Last Name, and Date of Birth already exists. Are you sure you are creating or editing the correct patient?",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS": "The Email Address entered is already in use. Email Addresses must be unique.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_BASIC_REPORTING_LICENSE": "The licences granted do not allow for report generation.  Please contact ImpediMed Technical Support for further details.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_MEASUREMENTS": "An export cannot be generated because no SOZO measurement(s) has been taken for the selected patient(s).",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_ASSESSMENT_TYPE": "The system has detected an error with your license status. Please sign out and sign back in to resolve this issue. If this error continues, please contact ImpediMed technical support.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_DEVICE_IS_IN_USE": "One or more of the SOZO device serial numbers are already in use.  Either choose a different serial number, or delete the identical serial number from the clinic that it has already been assigned to.  If this does not resolve the issue, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: COGNITO_ID_EXISTS": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS_IN_OTHER_FACILITY": "This email is already in use in another clinic. Each user must use a unique email address. Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS_ON_PATIENT": "The email entered already exists for another patient.  The email used must be unique.  ",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXIST_ON_ACTIVE_USER": "The email entered already exists for another clinician or administrator.  The email used must be unique. ",
		"lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXIST_ON_INACTIVE_USER": "The email entered already exists for another clinician or administrator that has been deleted. The email used must be unique. To use this email, either restore the deleted user, or restore the deleted user and then modify the email and delete the user again.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_VESRION_UPDATE": "The system has not found an update.",
		"lambdas_ER_SIGNAL_EXCEPTION: NO_MEASUREMENT_IN_DATE_RANGE": "Your selected date range must include the date of the measurement that you have selected for printing.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_VERSION": "There is an error with the Software version. Please re-sign in or contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_CALL": "Your role does not allow you to perform this action.",
		"lambdas_ER_SIGNAL_EXCEPTION: OVER_FIFTY_GROUPS": "The maximum number of groups (50) has been reached. Group not created.",
		"lambdas_ER_SIGNAL_EXCEPTION: GROUP_EXISTS": "The group name entered is already in use. Please enter a unique name.",
		"lambdas_ER_SIGNAL_EXCEPTION: DEVICE_NAME_EXISTS": "The device name entered is already in use. Please enter a unique name.",
		"lambdas_ER_SIGNAL_EXCEPTION: GROUP_DELETED": "The selected patient group has already been deleted. Refresh the page to update the patient group list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: LYMPHEDEMA_SETTINGS_NOT_MATCH": "An issue has been detected with this measurement.  Re-load the Patient Dashboard (Results List page) to see results with the most current Lymphoedema settings.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_ALREADY_IN_GROUP": "The following patient is already in this group.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_NOT_IN_GROUP": "The following patient has already been deleted from this group.",
		"lambdas_ER_SIGNAL_EXCEPTION: ONE_PATIENT_IS_DELETED": "The following patient has already been deleted. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: NOT_AVAILABLE_PATIENTS": "No active patients found according to your selections. Please, make sure that there is at least one active patient and try again.",
		"lambdas_ER_SIGNAL_EXCEPTION: OVER_EXPORT_SIZE_LIMIT": "The amount of data selected for export exceeds system limits. Please select a smaller number of patients or a reduced date range to export or contact ImpediMed Technical Support to manually export your data.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_RENAL_BASELINE": "Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_LOGIN_FOR_SSO_FACILITY": "We do not recognize your email and/or password. Confirm that you are a user of Single Sign On and try again. If this does not resolve the issue, contact your MySOZO Administrator.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_FACILITY_USER": "The email address is already in use in the system.  Each user must use a unique email address.  Please contact ImpediMed Technical Support if you think that this message was generated in error.",
		"lambdas_ER_SIGNAL_EXCEPTION: FACILITY_PROVIDER_NAME_EXISTS": "The domain name already exists and must be unique.",
		"lambdas_ER_SIGNAL_EXCEPTION: TOO_MANY_CLINICS_SELECTED": "The amount of data selected for export exceeds system limits. Please select a smaller number of clinics or contact ImpediMed Technical Support to manually export your data.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_NOT_AVAILABLE": "The MFA is disabled for your clinic. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_TIME_EXPIRED": "The time to enable the MFA has been expired and cannot be disabled. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_ACTIVATED": "SSO cannot be enabled while Multi-Factor Authentication is enabled. Please disable Multi-Factor Authentication before enabling SSO.",
		"lambdas_ER_SIGNAL_EXCEPTION: SSO_ACTIVATED": "Multi-Factor Authentication cannot be enabled while SSO is enabled. Please disable SSO before enabling Multi-Factor Authentication.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_DELETED": "The selected patient has been deleted. Restore the patient in order to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_INACTIVE": "The selected patient has already been deleted. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: PATIENT_ACTIVE": "The selected patient has already been restored. Refresh the page to update the patient list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: NOTE_LIMIT_REACHED": "A maximum of 10 notes is allowed per patient. To add a new note, an existing note must first be deleted.",
		"lambdas_ER_SIGNAL_EXCEPTION: NOTE_INACTIVE": "The selected note has already been deleted. Refresh the page to update the notes list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_NAME_EXISTS": "Tag name already exists. Please choose another one.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_ALREADY_DELETED": "The selected tag has already been deleted. Refresh the page to update the tag list. If this error continues, please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: POST_TAG_OVER_HUNDRED": "The maximum number of tags (100) has been reached. Tag not created.",
		"lambdas_ER_SIGNAL_EXCEPTION: MERGE_NOTE_LIMIT_EXCEEDED": "The total number of notes between the two patients exceeds the limit of 10 notes.  Delete some notes before re-attempting to merge.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_ASSIGNED_TO_RESULT": "The selected tag has already been assigned to this measurement.",
		"lambdas_ER_SIGNAL_EXCEPTION: TAG_DELETED_FROM_RESULT": "The selected tag has already been unassigned from this measurement.",
		"lambdas_ER_SIGNAL_EXCEPTION: MAX_TAGS_ASSIGNED_TO_RESULT": "A maximum of 200 tags are allowed per measurement. To assign a new tag, an existing tag must first be removed.",
		"lambdas_ER_SIGNAL_EXCEPTION: SOURCE_PATIENT_INACTIVE": "The source patient has been deleted. Please refresh the screen and try again.",
		"lambdas_ER_SIGNAL_EXCEPTION: DESTINATION_PATIENT_INACTIVE": "The destination patient has been deleted. Please refresh the screen and try again.",
		"lambdas_ER_SIGNAL_EXCEPTION: LACK_OF_DESTINATION_PATIENT_HEIGHT": "Please set the height of the destination patient before attempting to merge.",
		"lambdas_ER_SIGNAL_EXCEPTION: DELETED_MEASUREMENT": "The selected measurement has already been deleted.",
		"lambdas_ER_SIGNAL_EXCEPTION: PENDING_VERSION_UPDATE": "The MySOZO update is in progress and typically takes a few minutes to complete. During this time, the MySOZO system will be unavailable to all users within your clinic. A notification email will be sent when the update has been completed.",
		"lambdas_ER_SIGNAL_EXCEPTION: SSO_INVALID_INPUT": "You have been signed out due to a change in the SSO settings of your clinic. Please re-sign in using SSO.",
		"lambdas_ER_SIGNAL_EXCEPTION: PENDING_SSO_DISABLE": "MySOZO is currently unavailable due to an in process update of the SSO settings. Please sign in again shortly or contact your IT administrator for more details.",
		"lambdas_ER_SIGNAL_EXCEPTION: PENDING_MFA_DISABLE": "MySOZO is currently unavailable due to an in process update of the MFA settings. Please sign in again shortly or contact your IT administrator for more details.",
		"lambdas_ER_SIGNAL_EXCEPTION: MFA_INVALID_INPUT": "You have been signed out due to a change in the MFA settings of your clinic. Please re-sign in using MFA.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_EXISTS": "This patient has already been shared and cannot be shared again.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_INVALID_USER": "The email entered does not correspond to an active user with Clinician role within the clinic selected. Re-confirm the user does not have only Administrator rights.",
		"lambdas_ER_SIGNAL_EXCEPTION: DELETED_RECEIVER": "The email entered does not correspond to an active clinician user within the clinic selected. Re-confirm the clinic and/or email with the intended recipient.",
		"lambdas_ER_SIGNAL_EXCEPTION: CLINICIAN_NOT_EXIST": "One or more clinician email(s) are not valid MySOZO users. Update the email(s) to only include MySOZO users within the specified clinic.",
		"lambdas_ER_SIGNAL_EXCEPTION: TOO_MANY_MEASUREMENTS": "The patient cannot be shared because the maximum number of results (5000) has been reached. Please contact ImpediMed Technical Support.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_PATIENT_DELETED": "The patient selected is no longer available because their data has been recalled by the sending clinic.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_DELETED": "The selected shared patient has already been recalled. Refresh the page to update the list.",
		"lambdas_ER_SIGNAL_EXCEPTION: INVALID_SHARING": "This action cannot be performed because the shared patient has been recalled or imported.",
		"lambdas_ER_SIGNAL_EXCEPTION: SHARING_NO_MEASUREMENTS": "The selected patient must have a measurement taken before data can be shared.",
		"lambdas_ER_SIGNAL_EXCEPTION: DEVICE_NOT_EXIST": "The selected device has been deleted. Please contact ImpediMed Technical Support to restore the device in order to continue.",
		"lambdas_ER_SIGNAL_EXCEPTION: ORIGIN_EXISTS": "You are about to perform a significant level of changes to a patient’s data since you will perform import to the same patient that previously shared.",
		"lambdas_ER_SIGNAL_EXCEPTION: RECALCULATION_ERROR": "An error occurred during profile recalculation. Please check and complete all fields accurately. Try different value combinations if needed. Retry the update and if the issue persists, contact ImpediMed technical support.",
		"lambdas_EC2AccessDeniedException": "Please contact ImpediMed Technical Support.",
		"lambdas_EC2ThrottledException": "Please contact ImpediMed Technical Support.",
		"lambdas_EC2UnexpectedException": "Please contact ImpediMed Technical Support.",
		"lambdas_ENILimitReachedException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidParameterValueException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidRequestContentException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidRuntimeException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidSecurityGroupIDException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidSubnetIDException": "Please contact ImpediMed Technical Support.",
		"lambdas_InvalidZipFileException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSAccessDeniedException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSDisabledException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSInvalidStateException": "Please contact ImpediMed Technical Support.",
		"lambdas_KMSNotFoundException": "Please contact ImpediMed Technical Support.",
		"lambdas_RequestTooLargeException": "Please contact ImpediMed Technical Support.",
		"lambdas_ResourceNotFoundException": "Please contact ImpediMed Technical Support.",
		"lambdas_ServiceException": "Please contact ImpediMed Technical Support.",
		"lambdas_SubnetIPAddressLimitReachedException": "Please contact ImpediMed Technical Support.",
		"lambdas_TooManyRequestsException": "Please contact ImpediMed Technical Support.",
		"lambdas_UnsupportedMediaTypeException": "Please contact ImpediMed Technical Support.",
		"lambdas_v_check_user_banned": "User information retreived.",
		"lambdas_v_post_failed_login": "Failed login posted.",
		"lambdas_v_put_user_banned": "Successful login has been recorded.",
		"lambdas_v_auth_login": "The user has been logged in.",
		"lambdas_v_log_login": "The system has failed to log the user in.",
		"lambdas_v_get_patients_pagination": "Patients have been retreived.",
		"lambdas_v_put_patient_status_inactive": "The patient has been deleted.",
		"lambdas_v_put_patient_status_active": "The patient has been restored.",
		"lambdas_v_put_clinician_status_inactive": "The clinician has been deleted.",
		"lambdas_v_put_clinician_status_active": "The clinician status has been changed to active.",
		"lambdas_v_put_administrator_status_inactive": "The administrator has been deleted.",
		"lambdas_v_put_administrator_status_active": "The administrator status has been changed to active.",
		"lambdas_v_put_administrator_clinician_status_active": "The user has been restored.",
		"lambdas_v_put_administrator_clinician_status_inactive": "The user has been deleted.",
		"lambdas_v_put_clinician_settings": "Measurement units have been updated.",
		"lambdas_v_put_user_profile": {
			"updateCurrentUser": "Account has been updated.",
			"updateSelectedUser": "User profile has been updated.",
		},
		"lambdas_v_check_password": "The password used is valid.",
		"lambdas_v_put_password": "Password has been updated.",
		"lambdas_v_log_logout": "You have been logged out.",
		"lambdas_v_get_patient": "Patient has been retrieved.",
		"lambdas_v_get_measurements": "Measurement data has been retrieved.",
		"lambdas_v_get_measurement": "Measurement data has been retrieved.",
		"lambdas_v_get_frx": "Measurement data has been retrieved.",
		"lambdas_v_get_facility_user": "User has been retrieved.",
		"lambdas_v_put_user_email": "The email address has been updated.",
		"lambdas_v_put_patient_weight": "Weight has been updated.",
		"lambdas_v_put_patient_baseline": "The baseline has been updated.",
		"lambdas_v_put_result_status": "Result has been updated.",
		"lambdas_v_post_measurements": "Result has been stored.",
		"lambdas_v_post_patients": "The patient has been created.",
		"lambdas_v_put_patients": "Patient profile has been updated.",
		"lambdas_v_put_timezones": "Time Zone has been updated.",
		"lambdas_v_put_facility_settings": "Security settings have been updated. You will be signed out for changes to take effect.",
		"lambdas_v_get_facility_users_pagination": "Facility users have been retrieved.",
		"lambdas_v_get_facilities_pagination": "Facilities have been retrieved.",
		"lambdas_v_check_facility_alias": "Facility alias is unique.",
		"lambdas_v_post_facilities": "Facility has been created.",
		"lambdas_v_put_facilities": "Facility has been updated.",
		"lambdas_v_get_facilities_extra": "Facility extra information has been retrieved.",
		"lambdas_v_put_facility_administrators": "Administrator has been updated.",
		"lambdas_v_get_facility_settings": "Facility settings have been retrieved.",
		"lambdas_v_get_facility": "Facility settings have been retrieved.",
		"lambdas_v_post_facility_users": "User has been created and an email has been sent to set the initial password. If the user does not receive an email, check that the email is correct, check the spam folder, or direct the user to use the password reset function on the sign in page of MySOZO.",
		"lambdas_v_refresh_token": "Facility update information retrieved.",
		"lambdas_v_get_app_update": "The facility update my continue.",
		"lambdas_v_check_user_email": "Export has been retrieved.",
		"lambdas_v_post_exports": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_v_get_cognitoids_facility": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_v_get_access_logs": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_v_get_change_logs": "Your data export request is in progress and may take some time to process, depending on the amount of data requested. When the data file is ready, you will receive an email from MySOZO containing a secure link that enables you to download it. The link remains valid for 48 hours. If no email is received, contact ImpediMed technical support.",
		"lambdas_dateRangeErrorLdex": "The selected date range does not contain trending data for ",
		"lambdas_dateRangeErrorTissueAnalysis": "The selected date range does not contain trending data for ",
		"lambdas_dateRangeErrorFluidStatus": "The selected date range does not contain trending data for ",
		"lambdas_v_post_report_logo_error": "The report logo file type must be a native PNG file. The file that was attached is not a compatible file type or has been corrupted. A MySOZO administrator must replace the report logo picture before continuing.",
		"lambdas_v_put_update_version": "Updated successfully",
		"lambdas_v_put_patients_merges": "The patients have been merged.",
		"lambdas_v_put_patients_results_activation": "The measurement has been deleted.",
		"lambdas_v_post_facilities_groups": "Group has been created.",
		"lambdas_v_delete_facilities_groups": "Group has been deleted.",
		"lambdas_v_put_facility_groups": "Group has been updated.",
		"lambdas_v_facility_device_name_update": "Device name has been updated.",
		"lambdas_v_put_facility_settings_sso": "SSO settings have been updated.",
		"lambdas_v_post_mfa": "Multi-Factor Authentication has been turned on.",
		"lambdas_v_delete_mfa": "Multi-Factor Authentication has been turned off.",
		"lambdas_EnableSoftwareTokenMFAException": "The Verification Code provided is not valid. Please re-enter the code and ensure that it has not expired.",
		"lambdas_v_post_notes": "The note has been posted.",
		"lambdas_v_delete_notes": "The note has been deleted.",
		"lambdas_v_put_notes": "The note has been updated.",
		"lambdas_v_post_group_patients": "The patient(s) have been added to the group.",
		"lambdas_v_delete_group_patients": "The patient(s) have been removed from the group.",
		"lambdas_v_put_mfa": "You have been signed out due to a change in the MFA settings of your clinic. Please re-sign in.",
		"lambdas_v_put_sso": "You have been signed out due to a change in the SSO settings of your clinic. Please re-sign in.",
		"lambdas_v_put_tag": "Tag has been updated.",
		"lambdas_v_post_tag": "Tag has been created.",
		"lambdas_v_delete_tag": "Tag has been deleted.",
		"lambdas_v_post_results_tag": "Tag has been added.",
		"lambdas_v_put_results_tag": "Tag has been updated.",
		"lambdas_v_delete_results_tag": "Tag has been un-assigned.",
		"lambdas_v_post_update_clinic": "A MySOZO update is in progress and typically takes a few minutes to complete. During this time, the MySOZO system will be unavailable to all users within your clinic. A notification email will be sent when the update has been completed.",
		"lambdas_v_put_facilities_settings_ehr": "The EHR settings have been updated.",
		"lambdas_v_put_facilities_settings_lpp": "LPP Protocol Compliance settings have been updated.",
		"lambdas_v_put_patient_invalid_age": "The date of birth entered has caused a measurement to occur when the patient was younger than the minimum age of 6 years. Please confirm the date of birth was entered correctly and/or delete the appropriate measurements to ensure that all measurements are taken after the patient has turned 6 before updating the date of birth.",
		"lambdas_v_put_users_settings_weightpretare": "Weight Pre-Tare has been updated.",
		"lambdas_v_sharing_create": "Patient has been shared.",
		"lambdas_v_sharing_delete": "Shared patient has been recalled.",
		"lambdas_v_sharing_update": "Shared data record updated",
		"lambdas_v_sharing_import_create": "Shared patient has been imported",
		"lambdas_v_sharing_import_patient_create": "New shared patient has been imported",
		"lambdas_NotAvailablePatientsException": "No active patients found according to your selections. Please, make sure that there is at least one active patient and try again.",
		//endregion
		//region VALIDATOR
		"validator_EXCEPTION": "Please contact ImpediMed Technical Support.",
		"validator_email_checkNotEmpty": "Please enter your Email Address.",
		"validator_password_checkNotEmpty": "We do not recognize your email and/or password.  Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
		"validator_password_checkPassword": "We do not recognize your email and/or password.  Try again and if this does not resolve the issue, reset your password or contact your MySOZO Administrator.",
		"validator_passwordsValidation_checkEquals": "New Password and Confirm New Password entries do not match.",
		"validator_nameFacilities_checkAlphabeticExtended": "Facility name is invalid.   If this message is in error, please contact ImpediMed Technical Support.",
		"validator_aliasFacilities_checkAlphabeticExtended": "Facility alis is invalid.   If this message is in error, please contact ImpediMed Technical Support.",
		"validator_GPIDFacilities_checkAlphabeticExtended": "Facility GPID is invalid.   If this message is in error, please contact ImpediMed Technical Support.",
		"validator_resetPasswordTimeFacilitySettings_checkNumericRange": "Facility password reset time is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_sessionExpirationTimeFacilitySettings_checkNumericRange": "Facility password reset time is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_addressLocations_checkLength": "Facility address is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_addressLocations_checkAlphabeticExtended": "Facility address is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_cityLocations_checkLength": "Facility location city is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_cityLocations_checkAlphabeticExtended": "Facility location city is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_stateProvinceLocations_checkLength": "Facility state is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_stateProvinceLocations_checkAlphabeticExtended": "Facility state is invalid and must be alpha numeric.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_postalCodeLocations_checkLength": "Facility postal code is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_postalCodeLocations_checkAlphabeticExtended": "Facility postal code is invalid and may on contain alpha-numeric characters.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_deviceSerialNumberLicensedDevices_checkNumericLength": "Device serial number cannot exceed 45 characters.",
		"validator_deviceSerialNumberLicensedDevices_checkAlphabeticExtended": "Device serial number cannot exceed 45 characters.",
		"validator_timezoneTimezones_checkTimezone": "Facility timezone is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_UTCoffsetTimezones_checkUTCoffset": "Facility timezone is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_countryCountries_checkCountry": "Country is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_countryLocations_checkAlphabeticExtended": "Country is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_firstNameUser_checkAlphabeticExtended": "First Name allows a maximum of 45 characters and may contain letters, numbers, spaces, or the following characters ()#_\\-\\/%&@:;+,.",
		"validator_middleNameUser_checkAlphabeticExtended": "Middle name is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_lastNameUser_checkAlphabeticExtended": "Last Name allows a maximum of 45 characters and may contain letters, numbers, spaces, or the following characters ()#_\\-\\/%&@:;+,.",
		"validator_countryPhoneCodeUser_checkPhoneCode": "Country phone code is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_phoneNumberUser_checkPhone": "Phone number is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_mrnUser_checkAlphanumeric": "MRN is invalid.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_weightPatients_checkNumericRange": "Weight entry is invalid and must be a number without a comma allowed.  If this message is in error, please contact ImpediMed Technical Support.",
		"validator_heightPatients_checkNumericRange": "Height entry is invalid and must be a number (##.#). Note: no comma is allowed. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_firmwareName_checkEquals": "SOZO device firmware is the most current version and does not need to be updated.",
		"validator_logoPNG": "The report logo file type must be a native PNG file.  The file that was attached is not a compatible file type or has been corrupted. Please replace the report logo picture before continuing.",
		"validator_invalidSearchKey": "The search query allows a maximum of 45 characters and may contain numbers, spaces or the following characters: '.()#_-/%&@:;,+",
		"validator_nameFacilities_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_aliasFacilities_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_GPIDFacilities_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_resetPasswordTimeFacilitySettings_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_sessionExpirationTimeFacilitySettings_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_deviceSerialNumberLicensedDevices_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_addressLocations_checkNotLinks": "The Address entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_cityLocations_checkNotLinks": "The City entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_stateProvinceLocations_checkNotLinks": "The State entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_postalCodeLocations_checkNotLinks": "The Postal Code entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_timezoneTimezones_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_UTCoffsetTimezones_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_firstNameUser_checkNotLinks": "The First Name entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_middleNameUser_checkNotLinks": "The Middle Name entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_lastNameUser_checkNotLinks": "The Last Name entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_countryPhoneCodeUser_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_phoneNumberUser_checkNotLinks": "The Phone Number entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_mrnUser_checkNotLinks": "The MRN entered contains a link (typically ending with .com, .net, .edu, etc.). Please modify the entry.",
		"validator_measurementSerialNumberMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_measurementFirmwareVersionMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_measurementCalibrationDateMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_measurementApplicationVersionMeasurementInformation_checkNotLinks": "Periods must be the last character in the entry or followed by a space.  Please modify your entry in order to successfully save.",
		"validator_mergePatientsIds": "Patient ID's are identical. Please contact ImpediMed Technical Support.",
		"validator_firstNameUser_checkNotNullOrUndefined": "The First Name associate with this account is not valid. If you are using SSO, please consult with your MySOZO IT Administrator on the SSO set-up for your account.",
		"validator_firstNameUser_checkNotEmpty": "First Name must be entered.",
		"validator_lastNameUser_checkNotNullOrUndefined": "The Last Name associated with this account in not valid. If this message is in error, please contact ImpediMed Technical Support.",
		"validator_lastNameUser_checkNotEmpty": "Last Name must be entered.",
		"validator_email_checkNotNullOrUndefined": "The Email associated with this account is not valid. If you are using SSO, please consult with your MySOZO IT Administrator on the SSO set-up for your account.",
		"validator_email_checkEmail": "Email format is invalid. Please re-enter the email address.",
		"validator_email_checkLength": "Email Address must be less than 60 characters total.",
		"validator_roleUser_checkNotNullOrUndefined": "User role is invalid.",
		"validator_roleUser_checkNotEmpty": "User role must be selected.",
		"validator_roleUser_checkEnum": "The user role is invalid. If using SSO, a user may be a member of only one user/permission group at a time. Please confirm that the user is not entered in multiple user/permission groups and if this does not resolve the issue, contact ImpediMed technical support.",
		"validator_ssoInfo_checkNotLinks": "The Domain Name entry should include only the second level portion of the domain name. For example, if your domain name is www.clinicname.com only enter “clinicname” without the “.com”",
		"validator_tagName_checkNotNullOrUndefined":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkNotEmpty":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkNotLinks":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkAlphabeticExtended":"Please contact ImpediMed Technical Support.",
		"validator_tagName_checkLength":"Please contact ImpediMed Technical Support.",
		"validator_colorCode_checkNotNullOrUndefined":"Please contact ImpediMed Technical Support.",
		"validator_colorCode_checkNotEmpty":"Please contact ImpediMed Technical Support.",
		"validator_colorCode_checkColorCode":"Please contact ImpediMed Technical Support.",
		//endregion
		//region CALCULATE
		"calculate_measurement": "MySOZO was unable to complete the measurement calculations for one or more body segments. This may have occurred due to poor electrical contact or loss of contact between the patient and one or more electrodes. Re-dampen the hands and feet or the SOZO electrodes, maintain good contact with the electrodes, and re-try the measurement. If this error persists, please contact ImpediMed Technical Support.",
		//endregion
		//region DEVICE
		"device_commException": "An error occurred during communication with the SOZO device over Bluetooth. Retry and ensure that the SOZO device is powered on and is located near the tablet, and minimize any potential interference from other wireless devices. If the issue persists, try again and contact ImpediMed technical support.",
		"device_bluetoothException": "An error occurred during communication with the SOZO device over Bluetooth. Retry and ensure that the SOZO device is powered on and is located near the PC, and minimize any potential interference from other wireless devices. If the issue persists, try again and contact ImpediMed technical support.",
		"device_measurementInProgress": "Measurement not started. There is a measurement already in progress with the selected SOZO device.",
		"device_measurementFailedSelfTest": "Measurement failed because there is no record of a successful self-test. Please complete a successful self-test and if the issue persists, contact ImpediMed Technical Support.",
		"device_tooManySenseChannels": "Measurement failed due to a device error. Please re-try the measurement and if the issue persists, contact ImpediMed Technical Support.",
		"device_measurementElectrodeCheckFailure": "Measurement was not started because the electrode check failed. Re-try the measurement while ensuring that the patient is standing still and bare hands and feet are covering all of the electrodes.",
		"device_selfTestFailure": "Self-test failed. Re-try the test and ensure that nothing is touching or standing on the SOZO device. If the issue persists, contact ImpediMed technical support.",
		"device_invalidFriendlyName": "The Bluetooth ID of the device is invalid. Please contact ImpediMed technical support.",
		"device_scaleNotCalibrated": "The SOZO device has not been calibrated. If the device has been calibrated and the issue persists, contact ImpediMed technical support.",
		"device_weightNotRetrieved": "The SOZO device was unable to finalize a weight measurement. Please retry and ensure that the patient is standing on the device with as little movement as possible and does not rest on or touch another object (including the SOZO Pro Hand Unit). If the issue persists, contact ImpediMed technical support.",
		"device_scaleNotTared": "The scale did not Tare because the Tare value exceeds the limit (22 kg or 48.5 lbs). Retry with less weight on the SOZO device. If the issue persists, try again and contact ImpediMed technical support.",
		"device_scaleOverweight": "The patient's weight exceeds the maximum limit of the SOZO Pro device (220 kg or 485 lbs). Please enter the weight into the SOZOapp manually and take this patient's measurements in a seated position.",
		"device_scaleUnderweight": "The patient's weight must be greater than 22 kg or 48.5 lbs. Re-try the weight measurement while the patient is standing on the SOZO Pro device or manually enter the weight if the patient is in a seated position.",
		"device_authException": "Bluetooth authentication failed. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		"device_unknownApiException": "An unexpected error occurred. Try again and if the issue persists contact ImpediMed technical support.",
		"device_frequencyListFailure": "A device error occurred. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		"device_bluetoothTurnedOff": "Bluetooth communication could not be established with the SOZO Device. Retry and ensure that Bluetooth is turned on, the SOZO device is powered on and is located near the PC, and minimize any potential interference from other wireless devices. If the issue persists, try again and contact ImpediMed technical support.",
		"device_bluetoothTurnedOffTablet": "There is a Bluetooth connection problem. To resolve, check the tablet settings and ensure that Bluetooth is turned on.",
		"device_pairingMobileException": "An error occurred during communication with the SOZO device over Bluetooth. Pair the SOZO device through the tablet operating system and retry. If the issue persists, try again and contact ImpediMed technical support.",
		"device_pairingPCException": "An error occurred during communication with the SOZO device over Bluetooth. Pair the SOZO device through the PC operating system and retry. If the issue persists, try again and contact ImpediMed technical support.",
		"device_pairingCanceled": "No SOZO device has been selected. Please select a device to initiate a measurement or self-test.",
		"device_softwareUpdateFailed": "The requested software update failed to start or communication was lost during the update process. Please re-try and if the issue persists, contact ImpediMed Technical Support.",
		//endregion
		//endregion
	}
};