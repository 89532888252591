import InstructionsIconView from "./InstructionsIconView";

const InstructionsIcon = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <InstructionsIconView/>;

};

InstructionsIcon.propTypes = {};

export default InstructionsIcon;