import PropTypes from "prop-types";
import UnsupportedResolutionImage from "../SVGs/UnsupportedResolutionImage/UnsupportedResolutionImage";

const UnsupportedResolutionView = (props) => {

    //region VARIABLES

    const defaultDictionaryLanguage = dictionary[localStorage.language] === undefined ? dictionary.en : dictionary[localStorage.language];

    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={`flexColSpaceBetwnCenter unsupportedResolution ${props.extraClasses}`}>
            <UnsupportedResolutionImage/>
            <h1 className="unsupportedResolution__title">{defaultDictionaryLanguage.unsupportedResolutionWarningTitle}</h1>
            <p className="unsupportedResolution__text">{defaultDictionaryLanguage.unsupportedResolutionWarningFirst}
                <br/> {defaultDictionaryLanguage.unsupportedResolutionWarningSecond}</p>
        </div>
    );
};

UnsupportedResolutionView.propTypes = {
    extraClasses: PropTypes.string
};

export default UnsupportedResolutionView;