const SetSettingType = {
    SERIAL_NUMBER: "serial_number",
    FRIENDLY_NAME: "friendly_name",
    WEIGHT_DISPLAY_DISABLED: "weight_display_disabled",
    CONTACT_TEST_DISABLED: "contact_test_disabled",
    DISPLAY_BRIGHTNESS: "display_brightness",
    DISPLAY_KILOGRAM: "display_kilogram"
};

export default SetSettingType;
