const conversionHeight = 2.54;
const conversionWeight = 2.2046;
const conversionWater = 2.11338;

/**
 * @static
 * @description Rounds a number to specified decimal places
 */
export const roundDecimals = (number, decimals) => {
	return Number(Math.round(number + "e" + decimals) + "e-" + decimals);
};

/**
 * @static
 * @description Rounds a number to specified decimal places
 */
export const roundEvenDecimals = (number) => {
	return Number(Math.round(number * 5.0) / 5.0);
};

/**
 * @static
 * @deprecated
 * @description Converts cm to inches
 */
export const imperialHeight = (heightValue) => {
	return roundDecimals(parseFloat(heightValue) / conversionHeight, 1);
};

/**
 * @static
 * @description Gets feet part of height
 */
export const imperialHeightFeetShow = (heightValue) => {
	let toReturnFeet = heightValue / window.constants.oneFootInInches;
	return parseInt(toReturnFeet);
};

/**
 * @static
 * @description Gets inches part of height
 */
export const imperialHeightInchShow = (heightValue) => {
	let mod = heightValue % window.constants.oneFootInInches;
	return roundDecimals(mod, 1);
};

/**
 * @static
 * @description Converts kg to lbs
 */
export const imperialWeight = (weightValue) => {
	return roundDecimals(parseFloat(weightValue) * conversionWeight, 1);
};

/**
 * @static
 * @description Converts kg to lbs
 */
export const imperialEvenWeight = (weightValue) => {
	return roundEvenDecimals(parseFloat(weightValue) * conversionWeight);
};

/**
 * @static
 * @deprecated
 * @description Converts liters to pints
 */
export const imperialWater = (waterValue) => {
	return parseFloat(waterValue) * conversionWater;
};

/**
 * @static
 * @deprecated
 * @description Converts lbs to kg
 */
export const toMetricWeight = (value) => {
	return value / conversionWeight;
};