import MfaSetupView from "./MfaSetupView";
import {useHistory} from "react-router";

const MfaSetup = () => {
    //region VARIABLES
    const history = useHistory();
    //endregion

    //region FUNCTIONS
    const handleTurnOnClick = () => {
        history.push(constants.routes.mfaAuthenticate);
    };

    const handleCancelClick = () => {
        history.push(constants.routes.root);
    };
    //endregion

    return <MfaSetupView handleTurnOnClick={handleTurnOnClick}
                         handleCancelClick={handleCancelClick}/>;
};

export default MfaSetup;