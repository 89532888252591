import XbuttonView from "./XbuttonView";

const Xbutton = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <XbuttonView {...props}/>;

};

Xbutton.propTypes = {};

export default Xbutton;