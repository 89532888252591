import PrivacyIconView from "./PrivacyIconView";

const PrivacyIcon = () => {
    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <PrivacyIconView/>;
};

PrivacyIcon.propTypes = {};

export default PrivacyIcon;