import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.expandedCollapsable, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_COLLAPSIBLE_NAME :
			nextState = action.payload;
			break;
		case actions.CLEAR_COLLAPSIBLE_NAME :
			nextState = "";
			break;
		default:
			nextState = state;
	}
	return nextState;
};