import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.editGroupMembers, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_PATIENT_OUT_OF_GROUP_SEARCH :
            nextState.patientsOutOfGroupSearchValue = action.payload;
            break;
        case actions.SAVE_PATIENT_IN_GROUP_SEARCH:
            nextState.patientsInGroupSearchValue = action.payload;
            break;
        case actions.SAVE_SELECTED_GROUP_EDIT_MEMBERS:
            nextState.group = action.payload;
            break;
        default:
            nextState = state;
    }
    return nextState;
};