import {useReducer, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import SetMfaCodeView from "./SetMfaCodeView";
import Utilities from "../../Libraries/Custom/Utilities";
import {persistor, store} from "../../../Redux/configureStore";
import {getInitialData} from "../../../API/authApi";
import {mfaLogin} from "../../../API/authApi";
import {ApiStore} from "../../../API/apiStore";
import {useHistory} from "react-router";
import {saveDeviceKeys} from "../../../Redux/actions/Device/deviceActions";
import { version } from "../../../../package.json";

const SetMfaCode = (props) => {
	//region VARIABLES
	let inpt, setInpt;
	const [showUpdatePopup, setShowUpdatePopup] = useState(false);

	let history = useHistory();
	//endregion

	//region FUNCTIONS
	[inpt, setInpt] = useReducer((state, newState) => ({...state, ...newState}), {
		inputMfaCode: ""
	});

	const changePassword = () => {
		history.push(constants.routes.changePassword + Utilities.getQueryParams());
	};

	const promiseResolveGetInitialData = (response) => {
		try {
			Utilities.setInitialData(response);

			let storeState = store.getState();
			sessionStorage.loginTime = new Date().getTime();

			persistor.flush().then(() => {
				if (response.data.facility.clinician !== undefined) {
					sessionStorage.setItem("sozoCurentView", constants.activeView.clinician);
					const sharedDataNavigation = sessionStorage.getItem("sharedDataNavigation");
					const sharedDataPath = sharedDataNavigation !== null ? constants.routes.sharedData : "";

					location.href = Utilities.getLocationHref(constants.activeView.clinician, storeState.facility, storeState.user.user_id, storeState.facilityLocation.region, sharedDataPath);
					sessionStorage.removeItem("sharedDataNavigation");
				} else if (response.data.facility.clinicITAdministrator !== undefined) {
					sessionStorage.setItem("sozoCurentView", constants.activeView.admin);
					location.href = Utilities.getLocationHref(constants.activeView.admin, storeState.facility, storeState.user.user_id, storeState.facilityLocation.region);
				}
			});
		} catch (errorObject) {
			Utilities.handleError(errorObject, "promiseResolveGetInitialData");
		}
	};

	const getInitialDataCall = async () => {
		let response = await Utilities.makeSingleCallAsync(getInitialData, "getInitialData", []);
		if (response.status === constants.responseStatus.success) {
			promiseResolveGetInitialData(response.data);
		} else {
			Utilities.handleFailResponse(response, [], "getInitialData");
		}
	};

	const promiseResolveMfaLogin = async (responseAuth) => {
		try {
			Utilities.setLoginData(responseAuth, sessionStorage.getItem("userEmail"));
			props.saveDeviceKeys(responseAuth.data.deviceEncryptionKeys);
			getInitialDataCall();
		} catch (errorObject) {
			Utilities.handleError(errorObject, "promiseResolveMfaLogin");
		}
	};

	const resolveMfaLoginResponse = (response) => {
		try {
			if (response.response === constants.response.success) {
				let user = response.data.facility.clinician === undefined ? response.data.facility.clinicITAdministrator : response.data.facility.clinician;
				if (user.needsPasswordUpdate <= constants.resetPasswordTime_UpperLimit && user.needsPasswordUpdate > constants.resetPasswordTime_LowerLimit) {
					Utilities.customSweetAlertSelectionOperation({
							title: "",
							text: dictionary[localStorage.language].passwordExpirationIn + user.needsPasswordUpdate + dictionary[localStorage.language].passwordExpirationDays,
							type: "info"
						}, true, dictionary[localStorage.language].resetLater, dictionary[localStorage.language].setNow,
						() => {
							sessionStorage.setItem("accessToken", response.data.accessToken);
							changePassword();
						},
						() => {
							promiseResolveMfaLogin(response);
						}, true);
				} else {
					promiseResolveMfaLogin(response);
				}
			} else if (response.code === constants.passwordExpiredCode) {
				sessionStorage.setItem("accessToken", response.data.accessToken);
				Utilities.customSweetAlertSelectionOperation({
					title: dictionary[localStorage.language].passwordExpiration,
					text: Utilities.getErrorMessage(response.code),
					type: "info"
				}, true, dictionary[localStorage.language].cancel, dictionary[localStorage.language].setNew, () => changePassword(), () => {
				}, true);
			} else {
				Utilities.handleFailFromServer(response, "mfaLogin");
			}
		} catch (errorObject) {
			Utilities.handleError(errorObject, "resolveMfaLoginResponse");
		}
	};

	const handleInputChange = (value) => {
		setInpt({inputMfaCode: value});
	};

	const handleSetMfaCodeClick = async (event) => {
		event.preventDefault();

		if (!props.loading) {
			let mfaCode = Utilities.removeWhitespacesToLowercase(inpt.inputMfaCode);
			if (mfaCode === "" || mfaCode.length !== 6) {
				Utilities.customSweetAlertSimple("", dictionary[localStorage.language].invalidVerificationCode, "error", false, "", dictionary[localStorage.language].ok);
			} else {
				const client = props.sozoDeviceCommEnabled ? "1" : "2";
				let response = await Utilities.makeSingleCallAsync(mfaLogin, "mfaLogin", [sessionStorage.getItem("userEmail"), sessionStorage.getItem("session"), mfaCode, client]);
				if (response.status === constants.responseStatus.success) {
					resolveMfaLoginResponse(response.data);
				} else {
					if (response.data.code === constants.clientWrongVersionCode) {
						Utilities.setLoginData(response.data, sessionStorage.getItem("userEmail"));
						setShowUpdatePopup(true);
					} else {
						Utilities.handleFailResponse(response, [sessionStorage.getItem("userEmail"), sessionStorage.getItem("session"), mfaCode], "mfaLogin");
					}
				}
			}
		}
	};

	const handleCancelClick = () => {
		history.push(constants.routes.root);
	};

	useEffect(() => {
		ApiStore.init({
			language: localStorage.language
		});
	}, []);
	//endregion

	return <SetMfaCodeView showUpdatePopup={showUpdatePopup}
						   appVersion={version}
						   handleInputChange={handleInputChange}
						   handleSetMfaCodeClick={handleSetMfaCodeClick}
						   handleCancelClick={handleCancelClick}
						   handleCloseUpdatePopup={setShowUpdatePopup}/>;
};

SetMfaCode.propTypes = {
	loading: PropTypes.bool,
	currentOS: PropTypes.object,
	applicationBuild: PropTypes.object,
	sozoDeviceCommEnabled: PropTypes.bool,
	saveDeviceKeys: PropTypes.func
};

const mapStateToProps = (state) => ({
	loading: state.loading,
	currentOS: state.currentOS,
	applicationBuild: state.applicationBuild,
	sozoDeviceCommEnabled: state.sozoDeviceCommEnabled
});

const mapDispatchToProps = (dispatch) => ({
	saveDeviceKeys: (data) => dispatch(saveDeviceKeys(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetMfaCode);