import { useState } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import MfaAuthenticationView from "./MfaAuthenticationView";
import Utilities from "../../Libraries/Custom/Utilities";
import {deleteMFA, associateTotpToken} from "../../../API/userApi";
import {toast} from "react-toastify";
import Toast from "../Toast/Toast";
import {saveForceUpdateListState} from "../../../Redux/actions/Generic/forceUpdateListActions";
import {useHistory} from "react-router";

const MfaAuthentication = (props) => {

	//region VARIABLES
	let [password, setPassword] = useState("");
	let [exitClass,setExitClass] = useState("");
	let [mfaCode,setMfaCode] = useState("");
	const history = useHistory();
	//endregion

	//region FUNCTIONS
	const handleInput = (event) => {
		setPassword(event.target.value);
	};

	const handleMfaCodeChange = (value) => {
		setMfaCode(value);
	};

	const promiseResolveDisableMFA = (response) => {
		if (props.targetUser === constants.targetUser.self) {
			props.showSuccessLogoutPopup(response);
		} else {
			props.handleCancel();
			props.saveForceUpdateListState(true);
			toast(<Toast message={Utilities.getErrorMessage(response.code)}/>, constants.toastSettings.success);
		}
	};

	const promiseResolveAssociateTotpToken = (response) => {
		sessionStorage.accessToken = response.data.accessToken;
		sessionStorage.secretKey = response.data.secretCode;
		if (props.isForce) {
			history.push(constants.routes.mfaActivation);
		} else {
			setExitClass("flip-exit");
		}

	};

	const handleAnimationEnd = () => {
		props.handleMfaActivation();
	};

	const handleCancel = (event) => {
		event.stopPropagation();
		if (props.isForce) {
			history.push(constants.routes.root);
		} else {
			props.handleCancel();
		}
	};

	const handleAuthentication = async () => {
		if (password === "" || (props.user.hasMFA && mfaCode === "")) {
			Utilities.customSweetAlertSimple("", dictionary[localStorage.language].emptyFields, "error", false, "", dictionary[localStorage.language].ok);
		} else if (props.user.hasMFA && Utilities.isTotpInvalid(mfaCode)) {
			Utilities.customSweetAlertSimple(dictionary[localStorage.language].error, dictionary[localStorage.language].invalidVerificationCode, "error", false, "", dictionary[localStorage.language].ok);
		} else {
			if (props.targetUser === constants.targetUser.self) {
				if (props.user.hasMFA) {
					let response = await Utilities.makeSingleCallAsync(deleteMFA, "deleteMFA", [props.user.userEmail, password, mfaCode]);
					if (response.status === constants.responseStatus.success) {
						promiseResolveDisableMFA(response.data);
					} else {
						Utilities.handleFailResponse(response, [props.user.userEmail, password, mfaCode], "deleteMFA");
					}
				} else {
					let response = await Utilities.makeSingleCallAsync(associateTotpToken, "associateTotpToken", [props.user.userEmail, password]);
					if (response.status === constants.responseStatus.success) {
						promiseResolveAssociateTotpToken(response.data);
					} else {
						Utilities.handleFailResponse(response, [props.user.userEmail, password], "associateTotpToken");
					}
				}
			} else {
				let response = await Utilities.makeSingleCallAsync(deleteMFA, "deleteMFA", [props.userEmail, password, mfaCode]);
				if (response.status === constants.responseStatus.success) {
					promiseResolveDisableMFA(response.data);
				} else {
					Utilities.handleFailResponse(response, [props.userEmail, password, mfaCode], "deleteMFA");
				}
			}
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!props.loading) {
			handleAuthentication();
		}
	};
	//endregion

	return <MfaAuthenticationView
		password={password}
		mfaCode={mfaCode}
		exitClass={exitClass}
		hasMFA={props.user.hasMFA}
		handleInput={handleInput}
		handleSubmit={handleSubmit}
		handleCancel={handleCancel}
		handleAnimationEnd={handleAnimationEnd}
		handleMfaCodeChange={handleMfaCodeChange}
		{...props}
	/>;

};

MfaAuthentication.propTypes = {
	user:PropTypes.object,
	userEmail: PropTypes.string,
	targetUser: PropTypes.string,
	isForce: PropTypes.bool,
	loading: PropTypes.bool,
	handleMfaActivation:PropTypes.func,
	handleCancel:PropTypes.func,
	showSuccessLogoutPopup:PropTypes.func,
	saveForceUpdateListState: PropTypes.func
};

MfaAuthentication.defaultProps = {
	isForce: false
};

const mapStateToProps = (state) => ({
	user: state.user,
	loading: state.loading
});

const mapDispatchToProps = (dispatch) => ({
	saveForceUpdateListState: (data) => dispatch(saveForceUpdateListState(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MfaAuthentication);