import EyeView from "./EyeView";

const Eye = (props) => {
    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <EyeView {...props}/>;
};

Eye.propTypes = {};

export default Eye;