import PropTypes from "prop-types";
import Utilities from "../../Libraries/Custom/Utilities";

const BuildInfoView = (props) => {

    //region VARIABLES
    const envPrefix = window.location.hostname.split(".")[0];
    const displayInfo = !props.currentOS.isWindows && props.sozoDeviceCommEnabled;
    const buildDate = envPrefix === "testsprint" && props.applicationBuild ? ` - ${dictionary[localStorage.language].buildDate}: ${props.applicationBuild.buildDate}` : "";
    const buildPrefix = Utilities.getBuildPrefix(envPrefix, props.currentOS);
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={"buildInfo"}>
            <label className={"buildInfo__label"}>
                {displayInfo ? `${buildPrefix} - ${dictionary[localStorage.language].sozoApp}${envPrefix === "demo" ? " Demo" : ""} ${props.applicationBuild.versionName}${buildDate}` : ""}
            </label>
        </div>
    );

};

BuildInfoView.propTypes = {
    applicationBuild: PropTypes.object,
    currentOS: PropTypes.object,
    sozoDeviceCommEnabled: PropTypes.bool
};

export default BuildInfoView;