window.errorCodes = {
	//region COGNITO
	"10101": "cognito_EXCEPTION",
	"10165": "cognito_AuthUserWithTemp",
	"10102": "cognito_InitForgotPassword",
	"10103": "cognito_ConfirmForgotPassword",
	"10104": "cognito_ResendConfirmationCode",
	"10105": "cognito_InitialChangePassword",
	"10106": "cognito_UpdateEmailAuthUser",
	"10153": "cognito_DisableUser",
	"10161": "cognito_EnableUser",
	"10154": "cognito_SignOutAuthUser",
	"10164": "cognito_AuthUser",
	"10155": "cognito_GetUser",
	"10162": "cognito_RefreshToken",
	"10156": "cognito_ResetUserPassword",
	"10157": "cognito_ChangedPassword",
	"10158": "cognito_TerminateUser",
	"10159": "cognito_LogoutUser",
	"10160": "cognito_ListUsers",
	"10163": "cognito_AdminListEvents",
	"10107": "cognito_UserPoolClientIdNotFound",
	"10108": "cognito_UserPoolNotFound",
	"10109": "cognito_InternalErrorException",
	"10110": "cognito_InvalidLambdaResponseException",
	"10111": "cognito_InvalidParameterException",
	"10112": "cognito_InvalidSmsRoleAccessPolicyException",
	"10113": "cognito_InvalidSmsRoleTrustRelationshipException",
	"10114": "cognito_InvalidUserPoolConfigurationException",
	"10115": "cognito_MFAMethodNotFoundException",
	"10116": "cognito_NotAuthorizedException",
	"10117": "cognito_PasswordResetRequiredException",
	"10118": "cognito_ResourceNotFoundException",
	"10119": "cognito_TooManyRequestsException",
	"10120": "cognito_UnexpectedLambdaException",
	"10121": "cognito_UserLambdaValidationException",
	"10122": "cognito_UserNotConfirmedException",
	"10123": "cognito_UserNotFoundException",
	"10124": "cognito_CodeDeliveryFailureException",
	"10125": "cognito_InvalidPasswordException",
	"10126": "cognito_PreconditionNotMetException",
	"10127": "cognito_UnsupportedUserStateException",
	"10128": "cognito_UsernameExistsException",
	"10129": "cognito_AliasExistsException",
	"10130": "cognito_CodeMismatchException",
	"10131": "cognito_ExpiredCodeException",
	"10132": "cognito_SoftwareTokenMFANotFoundException",
	"10133": "cognito_InvalidEmailRoleAccessPolicyException",
	"10134": "cognito_LimitExceededException",
	"10135": "cognito_UserPoolTaggingException",
	"10136": "cognito_AccessDeniedException",
	"10137": "cognito_IncompleteSignature",
	"10138": "cognito_InternalFailure",
	"10139": "cognito_InvalidClientTokenId",
	"10140": "cognito_InvalidParameterCombination",
	"10141": "cognito_InvalidParameterValue",
	"10142": "cognito_InvalidQueryParameter",
	"10143": "cognito_MalformedQueryString",
	"10144": "cognito_MissingAction",
	"10145": "cognito_MissingAuthenticationToken",
	"10146": "cognito_MissingParameter",
	"10147": "cognito_OptInRequired",
	"10148": "cognito_RequestExpired",
	"10149": "cognito_ServiceUnavailable",
	"10150": "cognito_ThrottlingException",
	"10151": "cognito_ValidationError",
	"10152": "cognito_MissingRequiredParameter",
	"10168": "cognito_ProviderNotFound",
	"10175": "cognito_EmailAlreadyExists",
    "10169": "cognito_DuplicateProviderException",
	//endregion
	//region S3
	"60101": "s3_EXCEPTION",
	"60102": "s3_FetchFacilityLogo",
	"60103": "s3_UploadFacilityLogo",
	"60104": "s3_DeleteFacilityLogo",
	"60108": "s3_UploadFile",
	"60107": "s3_GetUrl",
	"60105": "s3_CustomLogoAlreadyExists",
	"60106": "s3_NotFound",
	"60120": "s3_AccessDenied",
	"60121": "s3_AccountProblem",
	"60122": "s3_AllAccessDisabled",
	"60123": "s3_AmbiguousGrantByEmailAddress",
	"60124": "s3_AuthorizationHeaderMalformed",
	"60125": "s3_BadDigest",
	"60126": "s3_BucketAlreadyExists",
	"60127": "s3_BucketAlreadyOwnedByYou",
	"60128": "s3_BucketNotEmpty",
	"60129": "s3_CredentialsNotSupported",
	"60130": "s3_CrossLocationLoggingProhibited",
	"60131": "s3_EntityTooSmall",
	"60132": "s3_EntityTooLarge",
	"60133": "s3_ExpiredToken",
	"60134": "s3_IllegalVersioningConfigurationException",
	"60135": "s3_IncompleteBody",
	"60136": "s3_IncorrectNumberOfFilesInPostRequest",
	"60137": "s3_InlineDataTooLarge",
	"60138": "s3_InternalError",
	"60139": "s3_InvalidAccessKeyId",
	"60140": "s3_InvalidAddressingHeader",
	"60141": "s3_InvalidArgument",
	"60142": "s3_InvalidBucketName",
	"60143": "s3_InvalidBucketState",
	"60144": "s3_InvalidDigest",
	"60145": "s3_InvalidEncryptionAlgorithmError",
	"60146": "s3_InvalidLocationConstraint",
	"60147": "s3_InvalidObjectState",
	"60148": "s3_InvalidPart",
	"60149": "s3_InvalidPartOrder",
	"60150": "s3_InvalidPayer",
	"60151": "s3_InvalidPolicyDocument",
	"60152": "s3_InvalidRange",
	"60153": "s3_InvalidRequest",
	"60154": "s3_InvalidRequest",
	"60155": "s3_InvalidRequest",
	"60156": "s3_InvalidRequest",
	"60157": "s3_InvalidRequest",
	"60158": "s3_InvalidRequest",
	"60159": "s3_InvalidRequest",
	"60160": "s3_InvalidRequest",
	"60161": "s3_InvalidRequest",
	"60162": "s3_InvalidSecurity",
	"60163": "s3_InvalidSOAPRequest",
	"60164": "s3_InvalidStorageClass",
	"60165": "s3_InvalidTargetBucketForLogging",
	"60166": "s3_InvalidToken",
	"60167": "s3_InvalidURI",
	"60168": "s3_KeyTooLongError",
	"60169": "s3_MalformedACLError",
	"60170": "s3_MalformedPOSTRequest",
	"60171": "s3_MalformedXML",
	"60172": "s3_MaxMessageLengthExceeded",
	"60173": "s3_MaxPostPreDataLengthExceededError",
	"60174": "s3_MetadataTooLarge",
	"60175": "s3_MethodNotAllowed",
	"60176": "s3_MissingAttachment",
	"60177": "s3_MissingContentLength",
	"60178": "s3_MissingRequestBodyError",
	"60179": "s3_MissingSecurityElement",
	"60180": "s3_MissingSecurityHeader",
	"60181": "s3_NoLoggingStatusForKey",
	"60182": "s3_NoSuchBucket",
	"60183": "s3_NoSuchBucketPolicy",
	"60184": "s3_NoSuchKey",
	"60185": "s3_NoSuchLifecycleConfiguration",
	"60186": "s3_NoSuchUpload",
	"60187": "s3_NoSuchVersion",
	"60188": "s3_NotImplemented",
	"60189": "s3_NotSignedUp",
	"60190": "s3_OperationAborted",
	"60191": "s3_PermanentRedirect",
	"60192": "s3_PreconditionFailed",
	"60193": "s3_Redirect",
	"60194": "s3_RestoreAlreadyInProgress",
	"60195": "s3_RequestIsNotMultiPartContent",
	"60196": "s3_RequestTimeout",
	"60197": "s3_RequestTimeTooSkewed",
	"60198": "s3_RequestTorrentOfBucketError",
	"60199": "s3_SignatureDoesNotMatch",
	"60200": "s3_ServiceUnavailable",
	"60201": "s3_SlowDown",
	"60202": "s3_TemporaryRedirect",
	"60203": "s3_TokenRefreshRequired",
	"60204": "s3_TooManyBuckets",
	"60205": "s3_UnexpectedContent",
	"60206": "s3_UnresolvableGrantByEmailAddress",
	"60207": "s3_UserKeyMustBeSpecified",
	//endregion
	//region SES
	"80101": "ses_EXCEPTION",
	"80120": "ses_SuccessMessageFromSES",
	"80122": "ses_EmailUpdateSuccessMessageFromSES",
	"80121": "ses_ExportSuccessMessageFromSES",
	//endregion
	//region LAMBDAS
	"50101": "lambdas_EXCEPTION",
	"50102": "lambdas_ER_SIGNAL_EXCEPTION: FACILITY_ALIAS_EXISTS",
	"50103": "lambdas_ER_SIGNAL_EXCEPTION: USER_BANNED",
	"50134": "lambdas_ER_SIGNAL_EXCEPTION: CLIENT_WRONG_VERSION",
	"50136": "lambdas_ER_SIGNAL_EXCEPTION: USER_PASSWORD_IS_EXPIRED",
	"50137": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_USER",
	"50159": "lambdas_ER_SIGNAL_EXCEPTION: PATIENT_DELETED",
	"50160": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_MEASUREMENT",
	"50151": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_ADMIN",
	"50138": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_USER_ROLE_AND_CLIENT",
	"50139": "lambdas_ER_SIGNAL_EXCEPTION: NO_ACTIVE_VERSION_AVAILABLE",
	"50141": "lambdas_ER_SIGNAL_EXCEPTION: USER_ACTIVE",
	"50169": "lambdas_ER_SIGNAL_EXCEPTION: USER_INACTIVE",
	"50143": "lambdas_ER_SIGNAL_EXCEPTION: USER_DELETED",
	"50170": "lambdas_ER_SIGNAL_EXCEPTION: ADMIN_DELETED",
	"50171": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_LICENSE",
	"50146": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_VALUES",
	"50148": "lambdas_ER_SIGNAL_EXCEPTION: SAME_PASSWORD",
	"50140": "lambdas_ER_SIGNAL_EXCEPTION: UNKNOWN_ERROR",
	"50207": "lambdas_ER_SIGNAL_EXCEPTION: FACILITY_NOT_EXISTS",
	"50201": "lambdas_ER_SIGNAL_EXCEPTION: ACTIVE_PATIENT_MRN_EXISTS",
	"50202": "lambdas_ER_SIGNAL_EXCEPTION: INACTIVE_PATIENT_MRN_EXISTS",
	"50203": "lambdas_ER_SIGNAL_EXCEPTION: ACTIVE_PATIENT_DATA_COMBINATION_EXISTS",
	"50204": "lambdas_ER_SIGNAL_EXCEPTION: INACTIVE_PATIENT_DATA_COMBINATION_EXISTS",
	"50205": "lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS",
	"50189": "lambdas_ER_SIGNAL_EXCEPTION: NO_BASIC_REPORTING_LICENSE",
	"50188": "lambdas_ER_SIGNAL_EXCEPTION: NO_MEASUREMENTS",
	"50206": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_ASSESSMENT_TYPE",
	"50174": "lambdas_ER_SIGNAL_EXCEPTION: FACILITY_DEVICE_IS_IN_USE",
	"50175": "lambdas_ER_SIGNAL_EXCEPTION: COGNITO_ID_EXISTS",
	"50192": "lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS_IN_OTHER_FACILITY",
	"50193": "lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXISTS_ON_PATIENT",
	"50194": "lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXIST_ON_ACTIVE_USER",
	"50195": "lambdas_ER_SIGNAL_EXCEPTION: EMAIL_EXIST_ON_INACTIVE_USER",
	"50213": "lambdas_ER_SIGNAL_EXCEPTION: NO_VERSION_UPDATE",
	"50402": "lambdas_ER_SIGNAL_EXCEPTION: NO_MEASUREMENT_IN_DATE_RANGE",
	"50220": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_VERSION",
	"50401": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_CALL",
    "50303": "lambdas_ER_SIGNAL_EXCEPTION: OVER_FIFTY_GROUPS",
    "50304": "lambdas_ER_SIGNAL_EXCEPTION: GROUP_EXISTS",
	"50305": "lambdas_ER_SIGNAL_EXCEPTION: GROUP_DELETED",
	"50306": "lambdas_ER_SIGNAL_EXCEPTION: LYMPHEDEMA_SETTINGS_NOT_MATCH",
	"50307": "lambdas_ER_SIGNAL_EXCEPTION: PATIENT_ALREADY_IN_GROUP",
	"50308": "lambdas_ER_SIGNAL_EXCEPTION: PATIENT_NOT_IN_GROUP",
	"50309": "lambdas_ER_SIGNAL_EXCEPTION: ONE_PATIENT_IS_DELETED",
	"50310": "lambdas_ER_SIGNAL_EXCEPTION: NOT_AVAILABLE_PATIENTS",
	"50237": "lambdas_ER_SIGNAL_EXCEPTION: OVER_EXPORT_SIZE_LIMIT",
	"50239": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_RENAL_BASELINE",
	"50246": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_LOGIN_FOR_SSO_FACILITY",
	"50250": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_FACILITY_USER",
    "50241": "lambdas_ER_SIGNAL_EXCEPTION: FACILITY_PROVIDER_NAME_EXISTS",
    "50299": "lambdas_ER_SIGNAL_EXCEPTION: TOO_MANY_CLINICS_SELECTED",
    "50261": "lambdas_ER_SIGNAL_EXCEPTION: MFA_NOT_AVAILABLE",
    "50262": "lambdas_ER_SIGNAL_EXCEPTION: MFA_TIME_EXPIRED",
    "50263": "lambdas_ER_SIGNAL_EXCEPTION: MFA_ACTIVATED",
    "50264": "lambdas_ER_SIGNAL_EXCEPTION: SSO_ACTIVATED",
    "50268": "lambdas_ER_SIGNAL_EXCEPTION: PATIENT_INACTIVE",
    "50269": "lambdas_ER_SIGNAL_EXCEPTION: PATIENT_ACTIVE",
    "50271": "lambdas_ER_SIGNAL_EXCEPTION: NOTE_LIMIT_REACHED",
    "50273": "lambdas_ER_SIGNAL_EXCEPTION: NOTE_INACTIVE",
    "50294": "lambdas_ER_SIGNAL_EXCEPTION: TAG_ASSIGNED_TO_RESULT",
	"50300": "lambdas_ER_SIGNAL_EXCEPTION: SOURCE_PATIENT_INACTIVE",
	"50301": "lambdas_ER_SIGNAL_EXCEPTION: DESTINATION_PATIENT_INACTIVE",
	"50302": "lambdas_ER_SIGNAL_EXCEPTION: LACK_OF_DESTINATION_PATIENT_HEIGHT",
	"50173": "lambdas_ER_SIGNAL_EXCEPTION: DELETED_MEASUREMENT",
	"50267": "lambdas_ER_SIGNAL_EXCEPTION: PENDING_VERSION_UPDATE",
	"50542": "lambdas_ER_SIGNAL_EXCEPTION: SSO_INVALID_INPUT",
	"50265": "lambdas_ER_SIGNAL_EXCEPTION: PENDING_SSO_DISABLE",
	"50279": "lambdas_ER_SIGNAL_EXCEPTION: MFA_INVALID_INPUT",
	"50196": "lambdas_ER_SIGNAL_EXCEPTION: MERGE_NOTE_LIMIT_EXCEEDED",
	"50288": "lambdas_ER_SIGNAL_EXCEPTION: TAG_NAME_EXISTS",
	"50289": "lambdas_ER_SIGNAL_EXCEPTION: POST_TAG_OVER_HUNDRED",
	"50290": "lambdas_ER_SIGNAL_EXCEPTION: TAG_ALREADY_DELETED",
	"50266": "lambdas_ER_SIGNAL_EXCEPTION: PENDING_MFA_DISABLE",
	"50292": "lambdas_ER_SIGNAL_EXCEPTION: TAG_DELETED_FROM_RESULT",
	"50295": "lambdas_ER_SIGNAL_EXCEPTION: MAX_TAGS_ASSIGNED_TO_RESULT",
	"50504": "lambdas_ER_SIGNAL_EXCEPTION: DEVICE_NAME_EXISTS",
	"50319": "lambdas_ER_SIGNAL_EXCEPTION: SURGERY_DATE_BEFORE_BIRTH_DATE",
	"50527": "lambdas_ER_SIGNAL_EXCEPTION: SHARING_EXISTS",
	"50326": "lambdas_ER_SIGNAL_EXCEPTION: SHARING_INVALID_USER",
	"50324": "lambdas_ER_SIGNAL_EXCEPTION: DELETED_RECEIVER",
	"50532": "lambdas_ER_SIGNAL_EXCEPTION: CLINICIAN_NOT_EXIST",
	"50327": "lambdas_ER_SIGNAL_EXCEPTION: TOO_MANY_MEASUREMENTS",
	"50531": "lambdas_ER_SIGNAL_EXCEPTION: SHARING_PATIENT_DELETED",
	"50368": "lambdas_ER_SIGNAL_EXCEPTION: SHARING_DELETED",
	"50534": "lambdas_ER_SIGNAL_EXCEPTION: INVALID_SHARING",
	"50538": "lambdas_ER_SIGNAL_EXCEPTION: SHARING_NO_MEASUREMENTS",
	"50199": "lambdas_ER_SIGNAL_EXCEPTION: DEVICE_NOT_EXIST",
	"50328": "lambdas_ER_SIGNAL_EXCEPTION: ORIGIN_EXISTS",
	"50330": "lambdas_ER_SIGNAL_EXCEPTION: RECALCULATION_ERROR",
	"50110": "lambdas_EC2AccessDeniedException",
	"50111": "lambdas_EC2ThrottledException",
	"50112": "lambdas_EC2UnexpectedException",
	"50113": "lambdas_ENILimitReachedException",
	"50114": "lambdas_InvalidParameterValueException",
	"50115": "lambdas_InvalidRequestContentException",
	"50116": "lambdas_InvalidRuntimeException",
	"50117": "lambdas_InvalidSecurityGroupIDException",
	"50118": "lambdas_InvalidSubnetIDException",
	"50119": "lambdas_InvalidZipFileException",
	"50120": "lambdas_KMSAccessDeniedException",
	"50121": "lambdas_KMSDisabledException",
	"50122": "lambdas_KMSInvalidStateException",
	"50123": "lambdas_KMSNotFoundException",
	"50124": "lambdas_RequestTooLargeException",
	"50125": "lambdas_ResourceNotFoundException",
	"50126": "lambdas_ServiceException",
	"50127": "lambdas_SubnetIPAddressLimitReachedException",
	"50128": "lambdas_TooManyRequestsException",
	"50129": "lambdas_UnsupportedMediaTypeException",
	"50130": "lambdas_v_check_user_banned",
	"50131": "lambdas_v_post_failed_login",
	"50190": "lambdas_v_put_user_banned",
	"50132": "lambdas_v_auth_login",
	"50133": "lambdas_v_log_login",
	"50142": "lambdas_v_get_patients_pagination",
	"50144": "lambdas_v_put_patient_status_inactive",
	"50208": "lambdas_v_put_patient_status_active",
	"50209": "lambdas_v_put_clinician_status_inactive",
	"50210": "lambdas_v_put_clinician_status_active",
	"50211": "lambdas_v_put_administrator_status_inactive",
	"50212": "lambdas_v_put_administrator_status_active",
	"50145": "lambdas_v_put_clinician_settings",
	"50147": "lambdas_v_put_user_profile",
	"50149": "lambdas_v_check_password",
	"50150": "lambdas_v_put_password",
	"50155": "lambdas_v_log_logout",
	"50172": "lambdas_v_get_patient",
	"50157": "lambdas_v_get_measurements",
	"50158": "lambdas_v_get_measurement",
	"50162": "lambdas_v_get_frx",
	"50163": "lambdas_v_get_facility_user",
	"50164": "lambdas_v_put_user_email",
	"50165": "lambdas_v_put_patient_weight",
	"50166": "lambdas_v_put_patient_baseline",
	"50167": "lambdas_v_put_result_status",
	"50168": "lambdas_v_post_measurements",
	"50152": "lambdas_v_post_patients",
	"50153": "lambdas_v_put_patients",
	"50154": "lambdas_v_put_timezones",
	"50156": "lambdas_v_put_facility_settings",
	"50176": "lambdas_v_get_facility_users_pagination",
	"50177": "lambdas_v_get_facilities_pagination",
	"50178": "lambdas_v_check_facility_alias",
	"50179": "lambdas_v_post_facilities",
	"50180": "lambdas_v_put_facilities",
	"50181": "lambdas_v_get_facilities_extra",
	"50182": "lambdas_v_put_facility_administrators",
	"50183": "lambdas_v_get_facility_settings",
	"50184": "lambdas_v_get_facility",
	"50185": "lambdas_v_post_facility_users",
	"50186": "lambdas_v_refresh_token",
	"50187": "lambdas_v_get_app_update",
	"50191": "lambdas_v_check_user_email",
	"50197": "lambdas_v_get_cognitoids_facility",
	"50198": "lambdas_v_get_access_logs",
	"50217": "lambdas_dateRangeErrorLdex",
	"50218": "lambdas_dateRangeErrorTissueAnalysis",
	"50219": "lambdas_v_put_update_version",
	"50135": "lambdas_v_put_patients_merges",
	"50223": "lambdas_v_put_administrator_clinician_status_active",
	"50224": "lambdas_v_put_administrator_clinician_status_inactive",
	"50161": "lambdas_v_put_patients_results_activation",
    "50226": "lambdas_v_post_facilities_groups",
    "50227": "lambdas_v_delete_facilities_groups",
	"50228": "lambdas_v_put_facility_groups",
	"50229": "lambdas_v_post_group_patients",
	"50230": "lambdas_v_delete_group_patients",
	"50526": "lambdas_v_facility_device_name_update",
	"50256": "lambdas_v_put_facility_settings_sso",
	"10180": "lambdas_v_post_mfa",
	"10182": "lambdas_v_delete_mfa",
	"10181": "lambdas_EnableSoftwareTokenMFAException",
	"50270": "lambdas_v_post_notes",
	"50272": "lambdas_v_delete_notes",
	"50276": "lambdas_v_put_notes",
	"50281": "lambdas_v_put_mfa",
	"50282": "lambdas_v_put_sso",
	"50284": "lambdas_v_post_tag",
	"50286": "lambdas_v_put_tag",
	"50287": "lambdas_v_delete_tag",
	"50291": "lambdas_v_delete_results_tag",
	"50293": "lambdas_v_post_results_tag",
	"50296": "lambdas_v_put_results_tag",
	"50316": "lambdas_v_put_facilities_settings_ehr",
	"50318": "lambdas_v_put_facilities_settings_lpp",
	"50507": "lambdas_v_put_patient_invalid_age",
	"50322": "lambdas_v_put_users_settings_weightpretare",
	"50525": "lambdas_v_sharing_create",
	"50523": "lambdas_v_sharing_delete",
	"50533": "lambdas_v_sharing_update",
	"50529": "lambdas_v_sharing_import_create",
	"50530": "lambdas_v_sharing_import_patient_create",
	//endregion
	//region VALIDATOR
	"40101": "validator_EXCEPTION",
	"40342": "validator_email_checkNotNullOrUndefined",
	"40109": "validator_email_checkNotEmpty",
	"40344": "validator_email_checkEmail",
	"40401": "validator_email_checkLength",
	"40108": "validator_password_checkNotEmpty",
	"40385": "validator_password_checkPassword",
	"40110": "validator_passwordsValidation_checkEquals",
	"40113": "validator_nameFacilities_checkAlphabeticExtended",
	"40117": "validator_aliasFacilities_checkAlphabeticExtended",
	"40370": "validator_GPIDFacilities_checkAlphabeticExtended",
	"40125": "validator_resetPasswordTimeFacilitySettings_checkNumericRange",
	"40128": "validator_sessionExpirationTimeFacilitySettings_checkNumericRange",
	"40159": "validator_addressLocations_checkLength",
	"40372": "validator_addressLocations_checkAlphabeticExtended",
	"40162": "validator_cityLocations_checkLength",
	"40373": "validator_cityLocations_checkAlphabeticExtended",
	"40165": "validator_stateProvinceLocations_checkLength",
	"40374": "validator_stateProvinceLocations_checkAlphabeticExtended",
	"40168": "validator_postalCodeLocations_checkLength",
	"40375": "validator_postalCodeLocations_checkAlphabeticExtended",
	"40153": "validator_deviceSerialNumberLicensedDevices_checkNumericLength",
	"40371": "validator_deviceSerialNumberLicensedDevices_checkAlphabeticExtended",
	"40171": "validator_timezoneTimezones_checkTimezone",
	"40174": "validator_UTCoffsetTimezones_checkUTCoffset",
	"40176": "validator_countryCountries_checkCountry",
	"40376": "validator_countryLocations_checkAlphabeticExtended",
	"40178": "validator_roleUser_checkNotNullOrUndefined",
	"40179": "validator_roleUser_checkNotEmpty",
	"40180": "validator_roleUser_checkEnum",
	"40181": "validator_firstNameUser_checkNotNullOrUndefined",
	"40182": "validator_firstNameUser_checkNotEmpty",
	"40183": "validator_firstNameUser_checkNotLinks",
	"40184": "validator_firstNameUser_checkAlphabeticExtended",
	"40187": "validator_middleNameUser_checkAlphabeticExtended",
	"40188": "validator_lastNameUser_checkNotNullOrUndefined",
	"40189": "validator_lastNameUser_checkNotEmpty",
	"40190": "validator_lastNameUser_checkNotLinks",
	"40191": "validator_lastNameUser_checkAlphabeticExtended",
	"40194": "validator_countryPhoneCodeUser_checkPhoneCode",
	"40197": "validator_phoneNumberUser_checkPhone",
	"40200": "validator_mrnUser_checkAlphanumeric",
	"40227": "validator_weightPatients_checkNumericRange",
	"40230": "validator_heightPatients_checkNumericRange",
	"40325": "validator_firmwareName_checkEquals",
	"40360": "validator_logoPNG",
	"40318": "validator_invalidSearchKey",
	"40122": "validator_nameFacilities_checkNotLinks",
	"40116": "validator_aliasFacilities_checkNotLinks",
	"40369": "validator_GPIDFacilities_checkNotLinks",
	"40124": "validator_resetPasswordTimeFacilitySettings_checkNotLinks",
	"40127": "validator_sessionExpirationTimeFacilitySettings_checkNotLinks",
	"40152": "validator_deviceSerialNumberLicensedDevices_checkNotLinks",
	"40158": "validator_addressLocations_checkNotLinks",
	"40161": "validator_cityLocations_checkNotLinks",
	"40164": "validator_stateProvinceLocations_checkNotLinks",
	"40167": "validator_postalCodeLocations_checkNotLinks",
	"40170": "validator_timezoneTimezones_checkNotLinks",
	"40173": "validator_UTCoffsetTimezones_checkNotLinks",
	"40186": "validator_middleNameUser_checkNotLinks",
	"40193": "validator_countryPhoneCodeUser_checkNotLinks",
	"40196": "validator_phoneNumberUser_checkNotLinks",
	"40199": "validator_mrnUser_checkNotLinks",
	"40266": "validator_measurementSerialNumberMeasurementInformation_checkNotLinks",
	"40270": "validator_measurementFirmwareVersionMeasurementInformation_checkNotLinks",
	"40274": "validator_measurementCalibrationDateMeasurementInformation_checkNotLinks",
	"40278": "validator_measurementApplicationVersionMeasurementInformation_checkNotLinks",
	"40249": "validator_mergePatientsIds",
	"40413": "validator_ssoInfo_checkNotLinks",
	"40428": "validator_tagName_checkNotNullOrUndefined",
	"40429": "validator_tagName_checkNotEmpty",
	"40430": "validator_tagName_checkNotLinks",
	"40431": "validator_tagName_checkAlphabeticExtended",
	"40432": "validator_tagName_checkLength",
	"40433": "validator_colorCode_checkNotNullOrUndefined",
	"40434": "validator_colorCode_checkNotEmpty",
	"40435": "validator_colorCode_checkColorCode",
	//endregion
	//region BEANSTALK
	"98489":"lambdas_v_get_change_logs",
	"98488":"lambdas_v_post_exports",
	"97088":"lambdas_v_post_report_logo_error",
	"90002":"lambdas_v_post_update_clinic",
	"99998":"cognito_EmailAlreadyExists_sso",
	"98487":"lambdas_NotAvailablePatientsException",
	//endregion
	//region CALCULATE
	"20102": "calculate_measurement",
	"20103": "calculate_measurement",
	"20104": "calculate_measurement",
	"20105": "calculate_measurement",
	"20106": "calculate_measurement",
	"20107": "calculate_measurement",
	"20108": "calculate_measurement",
	//endregion
	//region DEVICE
	"00024": "device_commException",
	"00422": "device_bluetoothException",
	"00047": "device_measurementInProgress",
	"00029": "device_measurementFailedSelfTest",
	"00049": "device_tooManySenseChannels",
	"00048": "device_measurementElectrodeCheckFailure",
	"00052": "device_selfTestFailure",
	"00326": "device_invalidFriendlyName",
	"00323": "device_scaleNotCalibrated",
	"00324": "device_weightNotRetrieved",
	"00231": "device_scaleNotTared",
	"00321": "device_scaleOverweight",
	"00322": "device_scaleUnderweight",
	"00035": "device_authException",
	"00215": "device_unknownApiException",
	"00199": "device_frequencyListFailure",
	"00421": "device_bluetoothTurnedOff",
	"00021": "device_bluetoothTurnedOffTablet",
	"00056": "device_pairingMobileException",
	"00426": "device_pairingPCException",
	"00425": "device_pairingCanceled",
	"00263": "device_softwareUpdateFailed",
	//endregion
};
