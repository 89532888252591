import { useReducer } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Utilities from "../../Libraries/Custom/Utilities";
import ChangePasswordView from "./ChangePasswordView";
import {useHistory} from "react-router";
import {changePassword} from "../../../API/authApi";

const ChangePassword = (props) => {
    //region VARIABLES
    let [inpt, setInpt] = useReducer((state, newState) => ({...state, ...newState}), {
        inputOldPassword: "",
        inputNewPassword: "",
        inputConfirmNewPassword: ""
    });
    let history = useHistory();
    let email = sessionStorage.getItem("userEmail");
    let accessToken = sessionStorage.getItem("accessToken");
    //endregion

    //region FUNCTIONS

    /**
     * @description handles the server response.
     */
    const promiseResolveChangePassword = () => {
        sessionStorage.setItem("loginEmail", email);
        sessionStorage.removeItem("userEmail");
        Utilities.customSweetAlertOperation({
            title: dictionary[localStorage.language].newPassword,
            text: dictionary[localStorage.language].changePasswordSuccess,
            type: "success"
        }, false, "", dictionary[localStorage.language].ok, () => {
            history.push(constants.routes.root + Utilities.getQueryParams());
        }, true);
    };

    const handleInputChange = (event) => {
        setInpt({[event.target.id]: event.target.value});
    };

    const handleSetNewPasswordClick = async () => {
        event.preventDefault();

        if (!props.loading) {
            let changePasswordData = {
                email: email,
                oldPassword: inpt.inputOldPassword,
                newPassword: inpt.inputNewPassword,
                confirmNewPassword: inpt.inputConfirmNewPassword,
                accessToken: accessToken ? accessToken : null
            };

            if (Utilities.isRequiredFieldsEmpty(changePasswordData.newPassword, changePasswordData.confirmNewPassword, changePasswordData.oldPassword)) {
                Utilities.customSweetAlertSimple("", dictionary[localStorage.language].emptyFieldsClinic, "error", false, "", dictionary[localStorage.language].ok);
            } else if (Utilities.isPassword(changePasswordData.newPassword) === null) {
                Utilities.customSweetAlertSimple("", dictionary[localStorage.language].invalidPassword_Alert, "error", false, "", dictionary[localStorage.language].ok);
            } else if (changePasswordData.newPassword !== changePasswordData.confirmNewPassword) {
                Utilities.customSweetAlertSimple("", dictionary[localStorage.language].invalidPasswordConsistency, "error", false, "", dictionary[localStorage.language].ok);
            } else {
                let response = await Utilities.makeSingleCallAsync(changePassword, "changePassword", [changePasswordData]);
                if (response.status === constants.responseStatus.success) {
                    promiseResolveChangePassword(response.data);
                } else {
                    Utilities.handleFailResponse(response, [changePasswordData], "changePassword");
                }
            }
        }
    };

    const handleCancelClick = (event) => {
        event.preventDefault();
        history.goBack();
    };

    //endregion

    return <ChangePasswordView email={email}
                               oldPassword={inpt.inputOldPassword}
                               newPassword={inpt.inputNewPassword}
                               confirmNewPassword={inpt.inputConfirmNewPassword}
                               tooltipHidden={inpt.tooltipHidden}
                               handleInputChange={handleInputChange}
                               handleSetNewPasswordClick={handleSetNewPasswordClick}
                               handleCancelClick={handleCancelClick}/>;
};

ChangePassword.propTypes = {
    loading: PropTypes.bool
};

const mapStateToProps = (state) => ({
    loading: state.loading
});

export default connect(mapStateToProps, null)(ChangePassword);