import { Fragment } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

const LoaderView = () => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return (
        <Fragment>
            <div className={"popupBackground popupBackground--loader"}/>
            <LinearProgress className={"loader"}/>
        </Fragment>
    );

};

LoaderView.propTypes = {
	loading: PropTypes.bool,
	compact: PropTypes.bool,
};

export default LoaderView;