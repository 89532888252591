import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import OptionListSelectionsView from "./OptionListSelectionsView";
import {useKeyPress} from "../../Hooks/useKeyPress";

const OptionListSelections = (props) => {
    //region VARIABLES
    const indexOfSelected = props.options.map((option) => option.selected).indexOf(true);
    const initCursor = indexOfSelected !== -1 ? indexOfSelected : 0;

    const downPress = useKeyPress("ArrowDown");
    const upPress = useKeyPress("ArrowUp");
    const spacePress = useKeyPress(" ");
    const [cursor, setCursor] = useState(initCursor);
    //endregion

    //region FUNCTIONS
    const handleHover = (selection) => {
        if (selection.value === "" || selection.value !== undefined) {
            setCursor(props.options.map((option) => option.value).indexOf(selection.value));
        }
    };

    useEffect(() => {
        if (props.options.length && downPress) {
            setCursor((prevState) =>
                prevState < props.options.length - 1 ? prevState + 1 : 0
            );
        }
    }, [downPress]);

    useEffect(() => {
        if (props.options.length && upPress) {
            setCursor((prevState) =>
                prevState > 0 ? prevState - 1 : props.options.length - 1
            );
        }
    }, [upPress]);

    useEffect(() => {
        if (props.options.length && spacePress) {
            props.handleClose();
            props.handleSelect({stopPropagation: () => {}}, props.options[cursor]);
        }
    }, [spacePress]);

    useEffect(() => {
        const hoveredOption = jQuery(".optionList__text--hovered");
        const optionList = jQuery(".optionList__options");
        if (hoveredOption.length) {
            const optionHeight = hoveredOption.outerHeight() + 10.25; // Plus 10 due to padding
            const optionPosition = hoveredOption.position().top;
            if (optionPosition + optionList.scrollTop() - optionList.height() > optionList.scrollTop()) {
                optionList.scrollTop(optionPosition - optionList.height() + optionList.scrollTop());
            } else if (optionPosition < 0) {
                optionList.scrollTop(optionList.scrollTop() - Math.abs(optionPosition) - optionHeight);
            }
        }
    }, [cursor]);
    //endregion

    return <OptionListSelectionsView cursor={cursor} handleHover={handleHover}{...props}/>;
};

OptionListSelections.propTypes = {
    options: PropTypes.array,
    handleClose: PropTypes.func,
    handleSelect: PropTypes.func
};

export default OptionListSelections;