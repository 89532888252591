import { Fragment } from "react";
import PropTypes from "prop-types";
import Input from "../../Components/Input/Input";
import PasswordPolicy from "../../Components/PasswordPolicy/PasswordPolicy";

const ChangePasswordView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <Fragment>
            <div className={"changePassword"}>
                <div className={"changePassword__box"}>
                    <h2 className="sectionTitle changePassword__title">{dictionary[localStorage.language].setNewPass}</h2>
                    <label className={"changePassword__emailLabel"}>{dictionary[localStorage.language].email}</label>
                    <p className={"changePassword__email"}>{props.email}</p>

                    <div className="changePassword__form">
                        <form onSubmit={(event) => props.handleSetNewPasswordClick(event)}>
                            <Input
                                id={"inputOldPassword"}
                                type={"password"}
                                inputClasses={""}
                                withLabel={true}
                                labelInBox={true}
                                labelText={dictionary[localStorage.language].oldPassword}
                                value={props.oldPassword}
                                placeholder={dictionary[localStorage.language].enterOldPassword}
                                onChange={props.handleInputChange}
                            />

                            <Input
                                id={"inputNewPassword"}
                                type={"password"}
                                inputClasses={""}
                                withLabel={true}
                                labelInBox={true}
                                labelText={dictionary[localStorage.language].newPassword}
                                value={props.newPassword}
                                placeholder={dictionary[localStorage.language].enterNewPassword}
                                onChange={props.handleInputChange}
                            />

                            <Input
                                id={"inputConfirmNewPassword"}
                                type={"password"}
                                inputClasses={""}
                                withLabel={true}
                                labelInBox={true}
                                labelText={dictionary[localStorage.language].cPassword}
                                value={props.confirmNewPassword}
                                placeholder={dictionary[localStorage.language].enterNewPassword}
                                onChange={props.handleInputChange}
                            />

                            <input className={"button-blue changePassword__button"}
                                   value={dictionary[localStorage.language].submit} type="submit"/>

                            <button className={"button-white changePassword__button"} onClick={props.handleCancelClick}>
                                {dictionary[localStorage.language].cancel}
                            </button>

                            <PasswordPolicy/>
                        </form>
                    </div>

                </div>
            </div>
        </Fragment>
    );

};

ChangePasswordView.propTypes = {
    email: PropTypes.string,
    oldPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmNewPassword: PropTypes.string,
    tooltipHidden: PropTypes.bool,
    handleInputChange: PropTypes.func,
    handleSetNewPasswordClick: PropTypes.func,
    handleCancelClick: PropTypes.func,
    handleTooltipDisplay: PropTypes.func
};

export default ChangePasswordView;