import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.merge, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_MERGE_SOURCE_SEARCH :
			nextState.sourceSearchValue = action.payload;
			break;
		case actions.SAVE_MERGE_DESTINATION_SEARCH :
			nextState.destinationSearchValue = action.payload;
			break;
		case actions.SAVE_MERGE_SOURCE_PATIENT :
			nextState.sourcePatient = action.payload;
			break;
		case actions.SAVE_MERGE_DESTINATION_PATIENT :
			nextState.destinationPatient = action.payload;
			break;
		case actions.CLEAR_MERGE_SOURCE_PATIENT :
			nextState.sourcePatient = null;
			break;
		case actions.CLEAR_MERGE_DESTINATION_PATIENT :
			nextState.destinationPatient = null;
			break;
		case actions.CLEAR_MERGE_SOURCE_SEARCH :
			nextState.sourceSearchValue = "";
			break;
		case actions.CLEAR_MERGE_DESTINATION_SEARCH :
			nextState.destinationSearchValue = "";
			break;
		default:
			nextState = state;
	}
	return nextState;
};
