import PropTypes from "prop-types";
import CheckIcon from "../SVGs/CheckIcon/CheckIcon";

const OptionListSelectionsView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    const getOptions = (options, showSelected) => {
        return options.map((option, i) => {
            return <div key={i} className={"optionList__item flexRowStartCenter"}
                        onMouseDown={() => {
                            event.preventDefault();
                            props.handleClose(event);
                            props.handleSelect(event, option);
                        }}
                        onMouseMove={() => props.handleHover(option)}
                        onMouseLeave={() => props.handleHover("")}>
                <span className={"optionList__icon"}>{showSelected && option.selected && <CheckIcon/>}</span>
                <span className={`optionList__text ${option.selected && "optionList__text--selected"} ${(i === props.cursor) && "optionList__text--hovered"}`}>{option.text}</span>
            </div>;
        });
    };
    //endregion

    return (
        <div className={`optionList__options ${props.popupClasses}`}>
            {getOptions(props.options, props.showSelected)}
        </div>
    );

};

OptionListSelectionsView.propTypes = {
    options: PropTypes.array,
    cursor: PropTypes.number,
    showSelected: PropTypes.bool,
    popupClasses: PropTypes.string,
    handleClose: PropTypes.func,
    handleSelect: PropTypes.func,
    handleHover: PropTypes.func
};

export default OptionListSelectionsView;