import {useState} from "react";
import {useHistory} from "react-router";
import {store} from "../../Redux/configureStore";
import {savePath} from "../../Redux/actions/Generic/pathActions";

export const useFlip = (currentPage) => {
	let [flipClass, setFlipClass] = useState(store.getState().nextPath === currentPage ? "flip-enter" : "");
	let history = useHistory();

	const setFlipClasses = (nextPage) => {
		store.dispatch(savePath(nextPage));
		setFlipClass("flip-exit");
	};
	const handleAnimationEnd = () => {
		let nextPath = store.getState().nextPath;
		if (nextPath !== currentPage) {
			history.push(nextPath);
		}
	};
	return [flipClass, setFlipClasses, handleAnimationEnd];
};