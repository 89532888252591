import {NODE_URL} from "./config";
import {getRegionForGraphQLURL, getResponse, getResponseGraphQL} from "./utils";
import {ApiStore} from "./apiStore";
import { readSharingFacilitiesQuery } from "./graphqlQueries";

export const exportAuditLogs = (exportSettings, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesLogsPOST.path;
	let method = apiStore.endpointsList.facilitiesLogsPOST.method;
	let data = {
		"startDateTime": exportSettings.startDate.toString(),
		"endDateTime": exportSettings.endDate.toString(),
		"email": apiStore.userEmail
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const getFacilitySettings = (resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSettingsGET.path;
	let method = apiStore.endpointsList.facilitiesSettingsGET.method;
	let data = null;

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const saveTimezone = (timezone, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSettingsTimezonesPUT.path;
	let method = apiStore.endpointsList.facilitiesSettingsTimezonesPUT.method;
	let data = {
		"UTCoffset": timezone.UTCoffset,
		"timezone": timezone.timezone
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const saveLogo = (logo, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSettingsLogosPUT.path;
	let method = apiStore.endpointsList.facilitiesSettingsLogosPUT.method;
	let data = {
		"logo": logo
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateFacilityMfa = (mfaForcedTime, password, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesMfaPUT.path;
	let method = apiStore.endpointsList.facilitiesMfaPUT.method;
	let data = {
		"mfaForcedTime": mfaForcedTime,
		"password": password
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateFacilitySso = (settings, password, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSsoPUT.path;
	let method = apiStore.endpointsList.facilitiesSsoPUT.method;
	let data = {
		"providerName": settings.providerName,
		"saml": settings.saml,
		"password": password
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updatePasswordPolicy = (settings, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSettingsSecuritiesPUT.path;
	let method = apiStore.endpointsList.facilitiesSettingsSecuritiesPUT.method;
	let data = {
		"sessionExpirationTime": settings.sessionExpirationTime,
		"resetPasswordTime": settings.resetPasswordTime,
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateVersion = (facilitysCurrentVersion, preUpdateAvailableVersion, password, mfa, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesUpdatePUT.path;
	let method = apiStore.endpointsList.facilitiesUpdatePUT.method;
	let data = {
		"softwareCurrentVersion": facilitysCurrentVersion,
		"softwareNewVersion": preUpdateAvailableVersion,
		"password": password,
		"totp": mfa,
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateEhrSettings = (ehrSettings, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSettingsEhrPUT.path;
	let method = apiStore.endpointsList.facilitiesSettingsEhrPUT.method;
	let data = {...ehrSettings};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateLppSettings = (lppSettings, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSettingsLppPUT.path;
	let method = apiStore.endpointsList.facilitiesSettingsLppPUT.method;
	let data = {...lppSettings};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const getQuicksightDashboard = (facilityId, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let queryParams = "?facilityId=" + encodeURIComponent(facilityId);
	let url = NODE_URL + apiStore.endpointsList.facilitiesDashboardsGET.path + queryParams;
	let method = apiStore.endpointsList.facilitiesDashboardsGET.method;
	let data = null;

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateFacilityDevice = (deviceData, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesDevicesPUT.path;
	let method = apiStore.endpointsList.facilitiesDevicesPUT.method;
	let data = {
		serialNumber: deviceData.serialNumber,
		deviceName: deviceData.deviceName,
		lastIpdCalibrationDate: deviceData.calibrationDate,
		macAddress: deviceData.macAddress,
		firmwareVersion: deviceData.firmwareVersion,
		lastSelfTestDate: deviceData.selfTestDate,
		lastSelfTestStatus: deviceData.selfTestStatus,
		weightDisplay: deviceData.weightDisplay,
		weightMetric: deviceData.weightMetric
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const getSharingFacilitiesREAD = (params, query, resolve, reject) => {
    let apiStore = ApiStore.getInstance();
    let headerFor = "graphql";
    let url = NODE_URL + getRegionForGraphQLURL(apiStore.region);
    let method = "POST";
    let data = readSharingFacilitiesQuery(params, query);

    getResponseGraphQL(url, method, headerFor, data, "sharingFacilitiesREAD", resolve, reject);
};

export const postSystemLogs = (logData, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.facilitiesSystemlogsPOST.path;
	let method = apiStore.endpointsList.facilitiesSystemlogsPOST.method;
	let data = {
		facilityId: apiStore.facilityId,
		log: logData.log,
		tag: logData.tag,
		level: logData.level,
		type: logData.type
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};
