import {combineReducers} from "redux";
import facilityReducer from "./Facility/facilityReducer";
import facilityLicenseReducer from "./Facility/facilityLicenseReducer";
import facilityLocationReducer from "./Facility/facilityLocationReducer";
import facilitySettingsReducer from "./Facility/facilitySettingsReducer";
import facilityTimezoneReducer from "./Facility/facilityTimezoneReducer";
import userReducer from "./User/userReducer";
import userSessionReducer from "./User/userSessionReducer";
import userSettingsReducer from "./User/userSettingsReducer";
import changelogReducer from "./Generic/changelogReducer";
import searchReducer from "./Generic/searchReducer";
import patientReducer from "./Patient/patientReducer";
import loaderReducer from "./Generic/loaderReducer";
import pathReducer from "./Generic/pathReducer";
import collapsibleReducer from "./Generic/collapsibleReducer";
import totalPatientsReducer from "./Generic/totalPatientsReducer";
import groupsReducer from "./Groups/groupsReducer";
import mergeReducer from "./Patient/mergeReducer";
import editGroupMembersReducer from "./Groups/editGroupMembersReducer";
import measurementsFilterReducer from "./Generic/measurementsFilterReducer";
import forceUpdateListReducer from "./Generic/forceUpdateListReducer";
import measurementReducer from "./Measurement/measurementReducer";
import measurementListReducer from "./Measurement/measurementListReducer";
import selectedTabReducer from "./Generic/selectedTabReducer";
import measurementIdReducer from "./Measurement/measurementIdReducer";
import selectedTrendingsReducer from "./Generic/selectedTrendingsReducer";
import logoutPopupReducer from "./Generic/logoutPopupReducer";
import permaSearchReducer from "./Generic/permaSearchReducer";
import frxReducer from "./Measurement/frxReducer";
import analyticsReducer from "./Analytics/analyticsReducer";
import measurementCurrentPositionReducer from "./Measurement/measurementCurrentPositionReducer";
import deviceReducer from "./Device/deviceReducer";
import currentOSReducer from "./Generic/currentOSReducer";
import sozoDeviceCommEnabledReducer from "./Generic/sozoDeviceCommEnabledReducer";
import sharingListReducer from "./Generic/sharingListReducer";
import pairedDeviceListReducer from "./Device/pairedDeviceListReducer";
import selectedReceiversReducer from "./Generic/selectedReceiversReducer";
import {persistReducer} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import sharingCountReducer from "./Generic/sharingCountReducer";
import sharingLicenseReducer from "./Generic/sharingLicenseReducer";
import isSharedPatientReducer from "./Generic/isSharedPatientReducer";
import sharingDataReducer from "./Sharing/sharingDataReducer";
import applicationBuildReducer from "./Generic/applicationBuildReducer";

const rootReducer = combineReducers({
	loading: loaderReducer,
	facility: facilityReducer,
	user: userReducer,
	userSession: userSessionReducer,
	userSettings: userSettingsReducer,
	htmlChangeLog: changelogReducer,
	facilityLicense: facilityLicenseReducer,
	facilityLocation: facilityLocationReducer,
	facilitySettings: facilitySettingsReducer,
	facilityTimezone: facilityTimezoneReducer,
	searchValue: searchReducer,
	selectedGroups: groupsReducer,
	selectedPatient: patientReducer,
	selectedMeasurement: measurementReducer,
	measurementList: measurementListReducer,
	nextPath: pathReducer,
	expandedCollapsable: collapsibleReducer,
	totalPatients: totalPatientsReducer,
	merge: mergeReducer,
	editGroupMembers: editGroupMembersReducer,
	measurementsFilter: measurementsFilterReducer,
	selectedTab: selectedTabReducer,
	forceUpdateListState: forceUpdateListReducer,
	selectedMeasurementId: measurementIdReducer,
	selectedTrendings: selectedTrendingsReducer,
	logoutPopup: logoutPopupReducer,
	permaSearch: permaSearchReducer,
	frx: frxReducer,
	analytics: analyticsReducer,
	measurementCurrentPosition: measurementCurrentPositionReducer,
	selectedDevice: persistReducer({key: "selectedDevice", storage: storageSession, whitelist: ["keys"]}, deviceReducer),
	currentOS: currentOSReducer,
	sozoDeviceCommEnabled: sozoDeviceCommEnabledReducer,
	sharingListType: sharingListReducer,
	pairedDeviceList: pairedDeviceListReducer,
	sharingCount: sharingCountReducer,
	selectedReceivers: selectedReceiversReducer,
	isSharedPatient: isSharedPatientReducer,
	selectedSharingData: sharingDataReducer,
	sharingLicense: sharingLicenseReducer,
	applicationBuild: applicationBuildReducer,
});

export default rootReducer;