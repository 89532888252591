import {version, description} from "../../../../../package.json";
import Utilities from "../Utilities";
import {isNullOrUndefined} from "./validation";
import {ApiStore} from "../../../../API/apiStore";
import {store} from "../../../../Redux/configureStore";
import {saveFacility} from "../../../../Redux/actions/Facility/facilityActions";
import {saveFacilityLicense} from "../../../../Redux/actions/Facility/facilityLicenseActions";
import {saveFacilityLocation} from "../../../../Redux/actions/Facility/facilityLocationActions";
import {saveFacilitySettings} from "../../../../Redux/actions/Facility/facilitySettingsActions";
import {saveFacilityTimezone} from "../../../../Redux/actions/Facility/facilityTimezoneActions";
import {saveUser, saveUserSession, saveUserSettings} from "../../../../Redux/actions/User/userActions";
import {saveChangelog} from "../../../../Redux/actions/Generic/changelogActions";
import {saveDevicePort} from "../../../../Redux/actions/Device/deviceActions";
import {NODE_URL} from "../../../../API/config";

export const getSozoEnvironmentAndBuildDate = () => {
    let environment = "production";
    let returnee;

    switch (environment) {
        case "production":
            returnee = " " + description;
            break;
        case "test":
            returnee = " " + description + " - Test";
            break;
        default:
            returnee = " " + description;
            break;
    }
    return returnee;
};

export const getBuildPrefix = (envPrefix, currentOS) => {
    let prefix = "";

    if (envPrefix === "testsprint") {
        prefix = "Test";
    } else {
        if (currentOS.isAndroid) {
            prefix = envPrefix === "demo" ? "SFT-027" : "SFT-013";
        } else if (currentOS.isiOS) {
            prefix = envPrefix === "demo" ? "SFT-034" : "SFT-033";
        } else {
            prefix = "SFT-025";
        }
    }

    return prefix;
};

export const getSoftwareVersion = (envPrefix, currentOS, facilitysCurrentVersion) => {
    const testEnvironment = envPrefix === "testsprint" ? " Test" : "";
    const buildPrefix = getBuildPrefix(envPrefix === "testsprint" ? "" : envPrefix, currentOS);

    return `${buildPrefix}${testEnvironment}${getSozoEnvironmentAndBuildDate()} v${facilitysCurrentVersion}`;
};

export const getCurrentView = () => window.location.pathname.includes(constants.activeView.clinician) ?
    constants.activeView.clinician : constants.activeView.admin;

/**
 * @static
 * @description Gets at risk body element according to patient selections
 */
export const riskElementSelection = (riskSide, bodyElement) => {

    let bodyElementText = "";
    switch (riskSide) {
        case 1:
            bodyElementText = bodyElement === 1 ? dictionary[localStorage.language].rightArm : dictionary[localStorage.language].rightLeg;
            break;
        case 2:
            bodyElementText = bodyElement === 1 ? dictionary[localStorage.language].leftArm : dictionary[localStorage.language].leftLeg;
            break;
        case 3:
            bodyElementText = dictionary[localStorage.language].notSet;
            break;
        default:
            break;
    }
    return bodyElementText;
};

/**
 * @static
 * @description Returns true if the L-Dex is visible based on license and assessment type selection
 */
export const canShowLdex = (selectedPatient, facilityLicense) => {
    return selectedPatient.assessmentTypeSelections.assessmentTypeSelectionLD &&
        (facilityLicense.lDex && selectedPatient.lymphedemaSelections.riskSideSelection !== 3) ||
        (facilityLicense.bilateral && selectedPatient.lymphedemaSelections.riskSideSelection === 3);
};

/**
 * @static
 * @description Returns true if the Body Composition is visible based on license and assessment type selection
 */
export const canShowBodyComposition = (selectedPatient, facilityLicense) => {
    return selectedPatient.assessmentTypeSelections.assessmentTypeSelectionBC && (facilityLicense.bodyCompositionOUS || facilityLicense.bodyCompositionUS);
};

/**
 * @static
 * @description Returns true if the Fluid Analysis is visible based on license and assessment type selection
 */
export const canShowSegmental = (selectedPatient, facilityLicense) => {
    return selectedPatient.assessmentTypeSelections.assessmentTypeSelectionSM && facilityLicense.segmental;
};

/**
 * @static
 * @description Returns true if HF-Dex is visible based on license and assessment type selection
 */
export const canShowHfdex = (selectedPatient, facilityLicense) => {
    return selectedPatient.assessmentTypeSelections.assessmentTypeSelectionHF && facilityLicense.hfDex;
};

/**
 * @static
 * @description Returns true if Renal is visible based on license and assessment type selection
 */
export const canShowRenal = (selectedPatient, facilityLicense) => {
    return selectedPatient.assessmentTypeSelections.assessmentTypeSelectionRN && facilityLicense.renal;
};

/**
 * @static
 * @description Returns the query params
 */
export const getQueryParams = (userId, region, currentView = null) => {
    const activeView = currentView ? currentView : getCurrentView();
    const nativeShellQueryParam = activeView === constants.activeView.clinician ? "&nativeShell=true" : "";

    return "?qp=" + btoa("userid=" + userId + "&region=" + region) + nativeShellQueryParam;
};

/**
 * @static
 * @description Returns the facility's version to be used in the url
 */
export const getVersionForUrl = (facility) => {
    let facilityVersion = version;
    if (facility !== null && facility !== undefined && facility.facilitysCurrentVersion !== "") {
        facilityVersion = facility.facilitysCurrentVersion;
    }
    return facilityVersion.replace(/\./g, "");
};

export const getUserRole = (isAdmin, isClinician) => {
    let role = "";
    if (isAdmin && isClinician) {
        role = "1@2";
    } else if (isAdmin) {
        role = "1";
    } else if (isClinician) {
        role = "2";
    }
    return role;
};

export const getUserRoleArray = (userRole) => {
    let rolesArray = [];
    switch (userRole) {
        case "1":
            rolesArray.push(dictionary[localStorage.language].roleAdminShort);
            break;
        case "2":
            rolesArray.push(dictionary[localStorage.language].roleClinician);
            break;
        case "1@2":
            rolesArray.push(dictionary[localStorage.language].roleAdminShort);
            rolesArray.push(dictionary[localStorage.language].roleClinician);
            break;
        default:
            rolesArray = [];
    }
    return rolesArray;
};

export const getKeyFromId = (id, wordToReplace) => {
    const cleanId = id.replace(wordToReplace, "");
    return cleanId.charAt(0).toLowerCase() + cleanId.slice(1);
};

export const getGroupDetails = (groupList, groupId) => {
    return groupList.find((group) => group.group_id === groupId);
};

export const filterLowQualityMeasurements = (measurements) => {
    return measurements.filter((measurement) => measurement.wholeRightFlag < constants.resultQuality.low);
};

export const splitMeasurementsList = (measurements) => {
    return {
        measurementResultsList: measurements.filter((measurement) => measurement.id.split("-")[0] !== "U400"),
        measurementImportedResultsList: measurements.filter((measurement) => measurement.id.split("-")[0] === "U400")
    };
};

export const getStatusText = (dialysisStatus) => {
    let text;
    switch (dialysisStatus) {
        case constants.dialysisStatus.pre:
            text = dictionary[localStorage.language].dialysisStatusPre;
            break;
        case constants.dialysisStatus.post:
            text = dictionary[localStorage.language].dialysisStatusPost;
            break;
        case constants.dialysisStatus.other:
            text = dictionary[localStorage.language].dialysisStatusOther;
            break;
        default:
            text = dictionary[localStorage.language].dialysisStatusNoSelection;
            break;
    }
    return text;
};

export const getLocationHref = (location, facility, userId, region, specificPath = "") => {
    let url = `https://${window.location.host}`;
    let href;
    switch (location) {
        case constants.activeView.clinician:
            href = `${url}/v${getVersionForUrl(facility)}/Clinician${specificPath}${getQueryParams(userId, region, constants.activeView.clinician)}`;
            break;
        case constants.activeView.admin:
            href = `${url}/v${getVersionForUrl(facility)}/ClinicITAdministrator${specificPath}${getQueryParams(userId, region, constants.activeView.admin)}`;
            break;
        default:
            href = url;
    }
    return href;
};

// eslint-disable-next-line complexity
export const callBasedResponse = (logsName, response) => {
    const validResponses = {
        login: response.code === constants.passwordExpiredCode || response.code === constants.banned || response.code === constants.mfaForce,
        mfaLogin: response.code === constants.passwordExpiredCode,
        sendEmail: response.code === 10123 || response.code === 10116,
        createPatient: response.code === constants.activePatientMrnExists || response.code === constants.activePatientDataCombinationExists ||
            response.code === constants.inactivePatientMrnExists || response.code === constants.inactivePatientDataCombinationExists,
        importPatient: Number(response.code) === constants.activePatientMrnExists || Number(response.code) === constants.activePatientDataCombinationExists ||
            Number(response.code) === constants.inactivePatientMrnExists || Number(response.code) === constants.inactivePatientDataCombinationExists,
        updatePatient: response.code === constants.activePatientMrnExists || response.code === constants.activePatientDataCombinationExists ||
            response.code === constants.inactivePatientMrnExists || response.code === constants.inactivePatientDataCombinationExists,
        createUser: response.code === constants.emaiExistOnInactiveUser || response.code === constants.emailExistInOtherFacility ||
            response.code === constants.emailExistOnPatient || response.code === constants.emailExistOnActiveUser || response.code === constants.cognitoUsernameExistsException,
        changeEmail: response.code === constants.emaiExistOnInactiveUser || response.code === constants.emailExistInOtherFacility ||
            response.code === constants.emailExistOnPatient || response.code === constants.emailExistOnActiveUser ||
            response.code === constants.lambdaCheckUserEmail,
        createNotes: response.code === constants.noteLimitsReached,
        fetchTokenFromCognito: true,
        refreshTokenSSO: true,
    };

    return response.response === constants.response.success || validResponses[logsName];
};

export const getExtraMessageData = (logsName, response, ...data) => {
    let extraMessageData = "";
    let specialCases = {
        createPatient: response.code === constants.groupIsDeleted,
        updatePatient: response.code === constants.groupIsDeleted,
        removePatientsFromGroup: response.code === constants.patientNotInCurrentGroup || response.code === constants.patientIsDeleted,
        addPatientsToGroup: response.code === constants.patientAlreadyInCurrentGroup || response.code === constants.patientIsDeleted,
    };
    if (specialCases[logsName]) {
        if (logsName === "createPatient") {
            let details = Utilities.getGroupDetails(data[0].patientGroupsList, response.data.group_id[1]);
            extraMessageData = "\n\nGroup name: " + details.name;
        } else if (logsName === "updatePatient") {
            let details = Utilities.getGroupDetails(data[0].patientGroupsList, response.data[1]);
            extraMessageData = "\n\nGroup name: " + details.name;
        } else if (logsName === "addPatientsToGroup" || logsName === "removePatientsFromGroup") {
            let patients = data[2];
            let patient = patients.filter((patient) => {
                return patient.user_id === response.data[1];
            });
            extraMessageData = "\n\n" + dictionary[localStorage.language].patientMrn + ": " + patient[0].mrn;
        }

    }
    return extraMessageData;
};

export const getPageForMessage = (code, logsName) => {
    let page = "";
    let specialCodes = {
        "10130": {
            "changeEmail": "changeEmail",
            "mfaLogin": "login",
            "deleteMFA": "deleteMFA",
            "updateFacilityMfa": "updateFacilityMfa",
            "resetPassword": "resetPassword"
        },
        "50147": {
            "updateCurrentUser": "updateCurrentUser",
            "updateSelectedUser": "updateSelectedUser",
        },
        "10116": {
            "login": "loggedOut",
            "changePassword": "loggedOut",
            "createUser": "loggedIn",
            "changeEmail": "loggedIn",
            "updateFacilityMfa": "loggedIn",
            "associateTotpToken": "loggedIn",
            "updateFacilitySso": "loggedIn",
            "updateVersion": "loggedIn",
            "deleteMFA": "loggedIn",
            "resetPassword": "loggedOut",
            "refreshToken": "loggedIn",
            "mfaLogin": "loggedOut",
        },
        "10123": {
            "changeEmail": "userLoggedIn",
            "toggleUserState": "userLoggedIn",
            "login": "userLoggedOut",
            "refreshToken": "loggedIn",
        }
    };

    if (specialCodes[code]) {
        page = specialCodes[code][logsName];
    }
    return page;
};

export const getErrorCodeFromUrl = (errorDescription) => {
    let results = new RegExp(/[0-9]{5}\./g).exec(errorDescription);
    let errorCode;
    if (!results) {
        errorCode = null;
    } else {
        errorCode = results[0].substring(0, results[0].length - 1);
    }
    return errorCode;
};

export const getGender = (gender) => {
    return gender === "M" ? dictionary[localStorage.language].male : dictionary[localStorage.language].female;
};

export const getFactorForFluids = (userSettings) => {
    let factor;
    if (userSettings.lt && userSettings.kg) {
        factor = 1;
    } else if (!userSettings.lt && !userSettings.kg) {
        factor = 1.04;
    } else if (userSettings.lt) {
        factor = 2.2046;
    } else if (userSettings.kg) {
        factor = 1 / 2.11338;
    }
    return factor;
};

export const getTickPositions = (min, step, stepCount = 4) => {
    let ticks = [];
    for (let i = 0; i <= stepCount; i++) {
        ticks.push(min + step * i);
    }
    return ticks;
};

export const getColePlotQuality = (qualityNumber) => {
    let quality;
    switch (qualityNumber) {
        case constants.resultQuality.high:
            quality = "high";
            break;
        case constants.resultQuality.medium:
            quality = "medium";
            break;
        case constants.resultQuality.low:
            quality = "low";
            break;
        default:
            quality = "imported";
            break;
    }
    return quality;
};

export const getTextFromLymphSelections = (lymphedemaSelections) => {
    return {
        assessmentType: lymphedemaSelections.riskSideSelection === 3 ? dictionary[localStorage.language].bilateral : dictionary[localStorage.language].unilateral,
        bodyElementSelection: lymphedemaSelections.bodyElementSelection === 1 ? dictionary[localStorage.language].arm : dictionary[localStorage.language].leg,
        riskSideSelection: lymphedemaSelections.riskSideSelection === 1 ? dictionary[localStorage.language].right :
            lymphedemaSelections.riskSideSelection === 2 ? dictionary[localStorage.language].left : dictionary[localStorage.language].both,
        limbDominanceSelection: lymphedemaSelections.limbDominanceSelection === 1 ? dictionary[localStorage.language].right : dictionary[localStorage.language].left,
    };
};

export const getPlotBands = (measurementType, baseline, areaMin, areaMax) => {
    let plotBands = [];
    if (measurementType === constants.assessmentTypes.ldex) {
        plotBands = baseline === null || baseline === undefined ? [{
            color: "#EDEFF0",
            from: areaMin,
            to: -10
        }, {
            color: "#82D1BC",
            from: -10,
            to: 0
        }, {
            color: "#82D1BC",
            from: 0,
            to: 6.5
        }, {
            color: "#F0E7B3",
            from: 6.5,
            to: 10
        }, {
            color: "#F5B3C0",
            from: 10,
            to: areaMax
        }] : [{
            color: "#EDEFF0",
            from: areaMin,
            to: parseFloat(baseline) - 10
        }, {
            color: "#82D1BC",
            from: parseFloat(baseline) - 10,
            to: parseFloat(baseline)
        }, {
            color: "#82D1BC",
            from: parseFloat(baseline),
            to: parseFloat(baseline) + 6.5
        }, {
            color: "#F0E7B3",
            from: parseFloat(baseline) + 6.5,
            to: parseFloat(baseline) + 10
        }, {
            color: "#F5B3C0",
            from: parseFloat(baseline) + 10,
            to: areaMax
        }];
    } else if (measurementType === constants.assessmentTypes.hfdex) {
        plotBands = [{
            color: "#EDEFF0",
            from: areaMin,
            to: 41.5
        }, {
            color: "#DDEBFF",
            from: 41.5,
            to: 46.4
        }, {
            color: "#B2D0F8",
            from: 46.4,
            to: 51.0
        }, {
            color: "#8DBCFD",
            from: 51.0,
            to: areaMax
        }];
    } else if (measurementType === constants.assessmentTypes.hydex) {
        plotBands = [{
            color: "#C9CBCE",
            from: areaMin,
            to: -50.0
        }, {
            color: "#E0E1E2",
            from: -50.0,
            to: -25.0
        }, {
            color: "#EDEFF0",
            from: -25.0,
            to: 0.0
        }, {
            color: "#DDEBFF",
            from: 0.0,
            to: 25.0
        }, {
            color: "#B2D0F8",
            from: 25.0,
            to: 50.0
        }, {
            color: "#8DBCFD",
            from: 50.0,
            to: areaMax
        }];
    }
    return plotBands;
};

export const getLegendExtraValues = (measurementType) => {
    let legendValues = [];
    if (measurementType === constants.assessmentTypes.hfdex) {
        legendValues = [{text: dictionary[localStorage.language].normalFluid, color: "#DDEBFF"},
            {text: dictionary[localStorage.language].elevatedFluid, color: "#B2D0F8"},
            {text: dictionary[localStorage.language].fluidOverload, color: "#8DBCFD"}];
    } else if (measurementType === constants.bodyComposition.hydex) {
        legendValues = [{text: dictionary[localStorage.language].lessHydrated, color: "#BCBFC2"},
            {text: "", color: "#D9DADB"},
            {text: "", color: "#EDEFF0"},
            {text: "", color: "#DDEBFF"},
            {text: "", color: "#B2D0F8"},
            {text: dictionary[localStorage.language].moreHydrated, color: "#8DBCFD"}];
    } else if (measurementType === constants.bodyComposition.bmi) {
        legendValues = [{text: dictionary[localStorage.language].underweight, color: "#ECEFF1"},
            {text: dictionary[localStorage.language].healthy, color: "#82D1BC"},
            {text: dictionary[localStorage.language].overweight, color: "#F0E7B3"},
            {text: dictionary[localStorage.language].obese, color: "#F5B3C0"}];
    } else if (measurementType === constants.bodyComposition.fm) {
        legendValues = [{text: dictionary[localStorage.language].veryLean, color: "#DDEBFF"},
            {text: dictionary[localStorage.language].lean, color: "#B2D0F8"},
            {text: dictionary[localStorage.language].good, color: "#82D1BC"},
            {text: dictionary[localStorage.language].fair, color: "#F0E7B3"},
            {text: dictionary[localStorage.language].high, color: "#F4CCAA"},
            {text: dictionary[localStorage.language].veryHigh, color: "#F5B3C0"}];
    } else {
        legendValues = [];
    }
    return legendValues;
};

export const formatResultData = (value, replaceWith = "-", decimals = 1, extraText = "") => {
    return isNullOrUndefined(value) ? replaceWith : value.toFixed(decimals) + extraText;
};

export const hasLastAssessment = (patientId, patientList) => {
    let selectedPatient = patientList.find((patientObj) => {
        return patientObj.user_id === patientId;
    });

    return selectedPatient.lastAssessmentDateTime !== null;
};

export const setLoginData = (response, userEmail) => {
    let user, session, endPoints;

    if (response.data.facility.clinician === undefined) {
        user = {...response.data.facility.clinicITAdministrator};
        endPoints = {...response.data.facility.clinicITAdministrator.session.endpointsList};
        session = {...response.data.facility.clinicITAdministrator.session};
    } else if (response.data.facility.clinicITAdministrator === undefined) {
        user = {...response.data.facility.clinician};
        endPoints = {...response.data.facility.clinician.session.endpointsList};
        session = {...response.data.facility.clinician.session};
    } else {
        user = {...response.data.facility.clinician, ...response.data.facility.clinicITAdministrator};
        endPoints = {...response.data.facility.clinician.session.endpointsList, ...response.data.facility.clinicITAdministrator.session.endpointsList};
        session = {...response.data.facility.clinician.session};
    }

    user.userEmail = userEmail;
    session.endpointsList = endPoints;

    store.dispatch(saveUser({...user}));
    store.dispatch(saveUserSession({...session}));
    store.dispatch(saveFacility({...response.data.facility}));
    store.dispatch(saveFacilityLocation({...response.data.facility.location}));
    store.dispatch(saveChangelog({...response.data.htmlChangeLog}));

    ApiStore.init({
        language: localStorage.language,
        userId: user.user_id,
        region: response.data.facility.location.region,
        tokens: {
            token: session.token
        },
        endpointsList: endPoints,
        version: response.data.facility.facilitysCurrentVersion
    });
};

export const setInitialData = (response, devicePort) => {
    let user, settings;

    if (response.data.facility.clinician === undefined) {
        user = {...response.data.facility.clinicITAdministrator};
    } else if (response.data.facility.clinicITAdministrator === undefined) {
        user = {...response.data.facility.clinician};
        settings = {...response.data.facility.clinician.settings};
    } else {
        user = {...response.data.facility.clinician, ...response.data.facility.clinicITAdministrator};
        settings = {...response.data.facility.clinician.settings};
    }

    store.dispatch(saveUser({...user}));
    store.dispatch(saveFacility({...response.data.facility}));
    store.dispatch(saveFacilityLocation({...response.data.facility.location}));

    store.dispatch(saveFacilityLicense({...response.data.facility.license}));
    store.dispatch(saveFacilitySettings({...response.data.facility.facilitySettings}));
    store.dispatch(saveFacilityTimezone({...response.data.facility.location.timezone}));
    store.dispatch(saveUserSettings({...settings}));
    store.dispatch(saveDevicePort(devicePort));
};

export const prepareBaselineResults = (baselines) => {
    let prepBaselines = baselines;
    for (const baselineKey in baselines) {
        if (baselines[baselineKey] === null && baselineKey !== "baselineRenalMetricECF" && baselineKey !== "baselineRenalImperialECF") {
            prepBaselines[baselineKey] = constants.resultNull;
        }
    }
    return prepBaselines;
};

export const getCurrentOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platformRegex = /\(([^\s;]+)[^)]*\)/i;
    const match = platformRegex.exec(userAgent);
    const platformName = match[0].toLowerCase();
    const currentOS = {
        isWindows: platformName.includes("windows"),
        isiOS: (/iPad|iPhone|iPod/.test(userAgent)) || platformName.includes("mac") && navigator.maxTouchPoints > 2,
        isAndroid: platformName.includes("android")
    };

    return currentOS;
};

/*export const isSOZODeviceCommEnabled = () => {
    let bluetoothSupported = false;

    const userAgent = window.navigator.userAgent.toLowerCase();

    const currentOS = getCurrentOS();

    if (currentOS.isWindows) {
        const chromeRegex = /chrome\/[0-9]{3}/g;
        const chromeMatch = chromeRegex.exec(userAgent);
        if (chromeMatch) {
            const chromeVersionMatch = /[0-9]{3}/.exec(chromeMatch[0]);
            const chromeVersion = chromeVersionMatch && parseInt(chromeVersionMatch[0]);

            bluetoothSupported = !userAgent.includes("opr/") && !userAgent.includes("opera/") && !userAgent.includes("chromium/") && chromeVersion >= 120;
        }
    } else if (currentOS.isiOS) {
        bluetoothSupported = userAgent.includes("sozoapphwa"); //!userAgent.includes("safari") && !navigator.standalone;
    } else if (currentOS.isAndroid) {
        bluetoothSupported = userAgent.includes("webview");
    }

    return bluetoothSupported;
};*/

export const isSOZODeviceCommEnabled = (devicePort = null) => {
    const currentOS = getCurrentOS();
    let bluetoothSupported;

    if (currentOS.isAndroid) {
        bluetoothSupported = devicePort !== null;
    } else if (currentOS.isiOS) {
        try {
            bluetoothSupported = window.webkit.messageHandlers.devicePort !== undefined;
        } catch (error) {
            bluetoothSupported = false;
        }
    } else {
        bluetoothSupported = false;
    }

    return bluetoothSupported;
};

export const validateOrigin = (origin) => {
    return origin === NODE_URL.replace(/\/$/, "");
};

export const getDeviceLicenseStatus = (deviceName, licensedDevicesList, acceptAnyDevice) => {
    let licenseStatus;
    let licensedDeviceExists = false;
    const deviceNameNoIOS = deviceName.replace("IOS_", "");

    if (acceptAnyDevice) {
        licenseStatus = constants.deviceLicenseStatus.active;
    } else {
        licensedDevicesList.forEach((licensedDevice) => {
            if (deviceNameNoIOS === licensedDevice.serialNumber) {
                licensedDeviceExists = true;
                licenseStatus = licensedDevice.isEnabled ? constants.deviceLicenseStatus.active : constants.deviceLicenseStatus.inactive;
            }
        });
        if (!licensedDeviceExists) {
            licenseStatus = constants.deviceLicenseStatus.noLicense;
        }
    }

    return licenseStatus;
};

export const validateDeviceLicense = (selectedDevice) => {
    let isDeviceActive;
    let errorMessage;

    switch (selectedDevice.licenseStatus) {
        case constants.deviceLicenseStatus.inactive:
            isDeviceActive = false;
            errorMessage = dictionary[localStorage.language].licensedDeviceInactive;
            break;
        case constants.deviceLicenseStatus.noLicense:
            isDeviceActive = false;
            errorMessage = dictionary[localStorage.language].notLicensedDevice;
            break;
        default:
            isDeviceActive = true;
            errorMessage = "";
    }

    return {
        isDeviceActive,
        errorMessage
    };
};
