let phaseAngleMeanStdValues = {
    male: {
        childhood:
            [{mean: 4.79, std: 0.6},
            {mean: 5.06, std: 0.68},
            {mean: 5.28, std: 1.58},
            {mean: 5.48, std: 0.86}],
        puberty1:
            [{mean: 5.25, std: 0.5},
            {mean: 5.5, std: 0.73},
            {mean: 5.65, std: 0.77},
            {mean: 5.77, std: 0.64},
            {mean: 5.78, std: 0.7}],
        puberty2:
            [{mean: 5.73, std: 0.62},
            {mean: 6.38, std: 0.79},
            {mean: 6.23, std: 0.84},
            {mean: 6.26, std: 0.8},
            {mean: 6.35, std: 0.95}],
        adolescence:
            [{mean: 6.82, std: 0.77},
            {mean: 7.07, std: 0.7},
            {mean: 6.92, std: 0.89},
            {mean: 6.81, std: 0.67},
            {mean: 6.56, std: 0.6}],
        earlyAdulthood:
            [{mean: 6.89, std: 0.72},
            {mean: 7.0, std: 0.72},
            {mean: 7.02, std: 0.71},
            {mean: 6.9, std: 0.74},
            {mean: 6.73, std: 0.69}],
        middleAdulthood1:
            [{mean: 6.66, std: 0.7},
            {mean: 6.92, std: 0.69},
            {mean: 6.94, std: 0.72},
            {mean: 6.88, std: 0.69},
            {mean: 6.68, std: 0.76}],
        middleAdulthood2:
            [{mean: 6.46, std: 0.7},
            {mean: 6.7, std: 0.7},
            {mean: 6.75, std: 0.68},
            {mean: 6.64, std: 0.74},
            {mean: 6.43, std: 0.77}],
        lateAdulthood2:
            [{mean: 6.24, std: 0.66},
            {mean: 6.41, std: 0.72},
            {mean: 6.43, std: 0.7},
            {mean: 6.36, std: 0.76},
            {mean: 6.16, std: 0.77}],
        earlyOld:
            [{mean: 5.77, std: 0.82},
            {mean: 6.01, std: 0.75},
            {mean: 6.03, std: 0.76},
            {mean: 5.96, std: 0.85},
            {mean: 5.75, std: 0.86}],
        old:
            [{mean: 5.11, std: 0.86},
            {mean: 5.43, std: 0.77},
            {mean: 5.5, std: 0.76},
            {mean: 5.42, std: 0.73},
            {mean: 5.03, std: 0.87}]
    },
    female: {
        childhood:
            [{mean: 5.14, std: 1.21},
            {mean: 5.15, std: 1.51},
            {mean: 5.33, std: 1.08},
            {mean: 5.46, std: 0.89}],
        puberty1:
            [{mean: 5.22, std: 0.58},
            {mean: 5.48, std: 0.9},
            {mean: 5.6, std: 0.65},
            {mean: 5.74, std: 0.65},
            {mean: 5.86, std: 0.72}],
        puberty2:
            [{mean: 5.61, std: 0.67},
            {mean: 5.93, std: 0.66},
            {mean: 6.07, std: 0.69},
            {mean: 6.03, std: 0.66},
            {mean: 6.09, std: 0.71}],
        adolescence:
            [{mean: 5.93, std: 0.69},
            {mean: 6.08, std: 0.69},
            {mean: 6.1, std: 0.65},
            {mean: 6.13, std: 0.77},
            {mean: 6.06, std: 0.64}],
        earlyAdulthood:
            [{mean: 5.98, std: 0.68},
            {mean: 6.1, std: 0.68},
            {mean: 6.18, std: 0.68},
            {mean: 6.21, std: 0.68},
            {mean: 6.15, std: 0.66}],
        middleAdulthood1:
            [{mean: 6.03, std: 0.67},
            {mean: 6.17, std: 0.67},
            {mean: 6.25, std: 0.67},
            {mean: 6.24, std: 0.66},
            {mean: 6.19, std: 0.71}],
        middleAdulthood2:
            [{mean: 5.96, std: 0.68},
            {mean: 6.09, std: 0.67},
            {mean: 6.17, std: 0.69},
            {mean: 6.16, std: 0.7},
            {mean: 6.08, std: 0.72}],
        lateAdulthood2:
            [{mean: 5.73, std: 0.68},
            {mean: 5.87, std: 0.7},
            {mean: 5.9, std: 0.7},
            {mean: 5.89, std: 0.72},
            {mean: 5.81, std: 0.7}],
        earlyOld:
            [{mean: 5.51, std: 0.78},
            {mean: 5.59, std: 0.72},
            {mean: 5.62, std: 0.73},
            {mean: 5.56, std: 0.75},
            {mean: 5.49, std: 0.77}],
        old:
            [{mean: 5.12, std: 0.84},
            {mean: 5.26, std: 0.78},
            {mean: 5.27, std: 0.75},
            {mean: 5.26, std: 0.84},
            {mean: 5.07, std: 0.72}]
    }
};
let ffmRanges = {
    male: {
        youngAdult: [0, 75.1, 80.3, 84.2, 88.5, 92.1, 1000],
        middleAdult1: [0, 73.6, 77.6, 80.8, 84.1, 87.6, 1000],
        middleAdult2: [0, 72.2, 75.8, 78.6, 81.5, 85.0, 1000],
        lateAdult: [0, 70.8, 74.4, 77.0, 79.8, 83.0, 1000],
        earlyOld: [0, 70.2, 73.6, 76.4, 79.0, 81.9, 1000],
        old: [0, 70.6, 74.2, 76.3, 79.0, 82.5, 1000]
    },
    female: {
        youngAdult: [0, 69.5, 75.8, 79.4, 82.4, 84.9, 1000],
        middleAdult1: [0, 68.5, 74.2, 78.0, 81.7, 84.5, 1000],
        middleAdult2: [0, 66.6, 71.6, 75.4, 79.4, 83.2, 1000],
        lateAdult: [0, 65.0, 69.2, 72.4, 76.4, 80.9, 1000],
        earlyOld: [0, 64.4, 68.5, 71.7, 75.4, 79.8, 1000],
        old: [0, 64.7, 79.0, 72.4, 76.3, 81.7, 1000]
    }
};

let fmRanges = {
    male: {
        youngAdult: [0, 7.9, 11.5, 15.8, 19.7, 24.9, 1000],
        middleAdult1: [0, 12.4, 15.9, 19.2, 22.4, 26.4, 1000],
        middleAdult2: [0, 15.0, 18.5, 21.4, 24.2, 27.8, 1000],
        lateAdult: [0, 17.0, 20.2, 23.0, 25.6, 29.2, 1000],
        earlyOld: [0, 18.1, 21.0, 23.6, 26.4, 29.8, 1000],
        old: [0, 17.5, 21.0, 23.7, 25.8, 29.4, 1000]
    },
    female: {
        youngAdult: [0, 15.1, 17.6, 20.6, 24.2, 30.5, 1000],
        middleAdult1: [0, 15.5, 18.3, 22.0, 25.8, 31.5, 1000],
        middleAdult2: [0, 16.8, 20.6, 24.6, 28.4, 33.4, 1000],
        lateAdult: [0, 19.1, 23.6, 27.6, 30.8, 35.0, 1000],
        earlyOld: [0, 20.2, 24.6, 28.3, 31.5, 35.6, 1000],
        old: [0, 18.3, 23.7, 27.6, 31.0, 35.3, 1000]
    }
};

const filterTicks = (ticks, minValue, maxValue, hasStdTicks = false) => {
    const stdTicks = ["-3SD", "-2SD", "-1SD", dictionary[localStorage.language].mean.toUpperCase(), "1SD", "2SD", "3SD"];
    const filteredTicks = [];
    const filteredStdTicks = [];
    for (let i = 0; i < ticks.length; i++) {
        if (ticks[i] > minValue && ticks[i] < maxValue) {
            filteredTicks.push(ticks[i]);
            hasStdTicks && filteredStdTicks.push(stdTicks[i]);
        }
    }
    return {ticks: filteredTicks, stdTicks: filteredStdTicks};
};

const createPlotBandsAndTicks = (defaultTicks, colors, hasStdTicks = false, minValue = null, maxValue = null) => {
    let ticks;
    if (minValue === null || minValue === undefined || maxValue === null || maxValue === undefined) {
        ticks = {
            ticks: defaultTicks,
            stdTicks: ["-3SD", "-2SD", "-1SD", dictionary[localStorage.language].mean.toUpperCase(), "1SD", "2SD", "3SD"]
        };
    } else {
        ticks = filterTicks(defaultTicks, minValue, maxValue, hasStdTicks);
    }

    let plotBands = [];
    for (let i = 0; i < defaultTicks.length - 1; i++) {
        plotBands.push({
            from: defaultTicks[i],
            to: defaultTicks[i + 1],
            color: colors[i]
        });
    }
    plotBands.unshift({
        from: -1000,
        to: defaultTicks[0],
        color: colors[0]
    });
    plotBands.push({
        from: defaultTicks[defaultTicks.length - 1],
        to: 1000,
        color: colors[colors.length - 1]
    });

    return {plotBands: plotBands, tickPositions: ticks.ticks, stdTickPositions: ticks.stdTicks};
};

const getRangesFromMeanAndStd = (meanValue, stdValue, minValue, maxValue) => {
    let ticks = [];
    for (let i = -3; i <= 3; i++) {
        ticks.push(parseFloat((stdValue * i + meanValue).toFixed(2)));
    }

    let plotBandsAndTicks = createPlotBandsAndTicks(ticks, ["#C9CBCE", "#E0E1E2", "#EDEFF0", "#EDEFF0", "#E0E1E2", "#C9CBCE"], true, minValue, maxValue);

    return {
        plotBands: plotBandsAndTicks.plotBands,
        tickPositions: plotBandsAndTicks.tickPositions,
        stdTickPositions: plotBandsAndTicks.stdTickPositions,
        meanValue: meanValue
    };
};

const getMeanStdPair = (gender, age, bmi, bmiRanges) => {
    let meanStdPair = null;
    if (bmi >= bmiRanges[0] && bmi <= bmiRanges[1]) {
        meanStdPair = phaseAngleMeanStdValues[gender][age][0];
    }

    for (let i = 1; i < bmiRanges.length - 1; i++) {
        if (bmi > bmiRanges[i] && bmi <= bmiRanges[i + 1]) {
            meanStdPair = phaseAngleMeanStdValues[gender][age][i];
        }
    }
    return meanStdPair;
};

export const getRefRangesTBW = (patientGender, patientAge, min = null, max = null) => {
    let refRanges = null;

    if (patientGender === "M") {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(58.7, 3.8, min, max);
        } else if (patientAge >= 30 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(57.1, 3.9, min, max);
        } else if (patientAge >= 50) {
            refRanges = getRangesFromMeanAndStd(53.8, 3.9, min, max);
        }
    } else {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(51.7, 4.1, min, max);
        } else if (patientAge >= 30 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(50.8, 5.6, min, max);
        } else if (patientAge >= 50) {
            refRanges = getRangesFromMeanAndStd(48.6, 5.9, min, max);
        }
    }

    return refRanges;
};

export const getRefRangesECF = (patientGender, patientAge, min = null, max = null) => {
    let refRanges = null;

    if (patientGender === "M") {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(44.0, 1.3, min, max);
        } else if (patientAge >= 30 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(44.9, 2.0, min, max);
        } else if (patientAge >= 50) {
            refRanges = getRangesFromMeanAndStd(46.7, 1.6, min, max);
        }
    } else {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(43.1, 1.4, min, max);
        } else if (patientAge >= 30 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(43.6, 1.4, min, max);
        } else if (patientAge >= 50) {
            refRanges = getRangesFromMeanAndStd(44.9, 1.7, min, max);
        }
    }

    return refRanges;
};

export const getRefRangesICF = (patientGender, patientAge, min = null, max = null) => {
    let refRanges = null;

    if (patientGender === "M") {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(56.0, 1.3, min, max);
        } else if (patientAge >= 30 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(55.1, 2.0, min, max);
        } else if (patientAge >= 50) {
            refRanges = getRangesFromMeanAndStd(53.3, 1.6, min, max);
        }
    } else {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(56.9, 1.4, min, max);
        } else if (patientAge >= 30 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(56.4, 1.4, min, max);
        } else if (patientAge >= 50) {
            refRanges = getRangesFromMeanAndStd(55.1, 1.7, min, max);
        }
    }

    return refRanges;
};

// eslint-disable-next-line complexity
export const getRefRangesSMM = (patientGender, patientAge, min = null, max = null) => {
    let refRanges = null;

    if (patientGender === "M") {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(42.3, 4.4, min, max);
        } else if (patientAge >= 30 && patientAge <= 39) {
            refRanges = getRangesFromMeanAndStd(39.1, 5.0, min, max);
        } else if (patientAge >= 40 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(37.1, 4.0, min, max);
        } else if (patientAge >= 50 && patientAge <= 59) {
            refRanges = getRangesFromMeanAndStd(35.1, 3.4, min, max);
        } else if (patientAge >= 60) {
            refRanges = getRangesFromMeanAndStd(34.8, 5.4, min, max);
        }
    } else {
        if (patientAge >= 18 && patientAge <= 29) {
            refRanges = getRangesFromMeanAndStd(34.1, 5.7, min, max);
        } else if (patientAge >= 30 && patientAge <= 39) {
            refRanges = getRangesFromMeanAndStd(30.6, 5.6, min, max);
        } else if (patientAge >= 40 && patientAge <= 49) {
            refRanges = getRangesFromMeanAndStd(29.2, 5.0, min, max);
        } else if (patientAge >= 50 && patientAge <= 59) {
            refRanges = getRangesFromMeanAndStd(29.1, 4.4, min, max);
        } else if (patientAge >= 60) {
            refRanges = getRangesFromMeanAndStd(29.1, 4.7, min, max);
        }
    }

    return refRanges;
};

// eslint-disable-next-line complexity
export const getRefRangesPhaseAngle = (patientGender, patientAge, bmi, min, max) => {
    let refRanges = null;
    let meanStdPair = [];
    let gender = patientGender === "M" ? "male" : "female";
    let age = "";
    let bmiRanges = [];

    if (patientAge >= 6 && patientAge <= 9) {
        age = "childhood";
        bmiRanges = [9, 13, 15, 17, 25];
    } else if (patientAge >= 10 && patientAge <= 13) {
        age = "puberty1";
        bmiRanges = [11, 15, 20, 25, 30, 35];
    } else if (patientAge >= 14 && patientAge <= 17) {
        age = "puberty2";
        bmiRanges = [14, 19, 25, 30, 35, 40];
    } else if (patientAge === 18 || patientAge === 19) {
        age = "adolescence";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    } else if (patientAge >= 20 && patientAge <= 29) {
        age = "earlyAdulthood";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    } else if (patientAge >= 30 && patientAge <= 39) {
        age = "middleAdulthood1";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    } else if (patientAge >= 40 && patientAge <= 49) {
        age = "middleAdulthood2";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    } else if (patientAge >= 50 && patientAge <= 59) {
        age = "lateAdulthood2";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    } else if (patientAge >= 60 && patientAge <= 69) {
        age = "earlyOld";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    } else if (patientAge >= 70) {
        age = "old";
        bmiRanges = [18.5, 25, 30, 35, 40, 50];
    }

    meanStdPair = getMeanStdPair(gender, age, bmi, bmiRanges);
    if (meanStdPair) {
        refRanges = getRangesFromMeanAndStd(meanStdPair.mean, meanStdPair.std, min, max);
    } else {
        let messageKey = "";
        if (age === "") {
            messageKey = "noRefRangeAge";
        } else if (bmi < bmiRanges[0] || bmi > bmiRanges[bmiRanges.length - 1]) {
            messageKey = "noRefRangeBmi";
        }
        refRanges = [null, messageKey];
    }

    return refRanges;
};

export const getRefRangesBMI = (min = null, max = null) => {
    return createPlotBandsAndTicks([0, 18.5, 25, 30], ["#ECEFF1", "#82D1BC", "#F0E7B3", "#F5B3C0"], false, min, max);
};

// eslint-disable-next-line complexity
export const getRefRangesFFM = (patientGender, patientAge, min, max) => {
    let refRanges = null;
    const gender = patientGender === "M" ? "male" : "female";
    let ticks = [];

    if (patientAge >= 20 && patientAge <= 29) {
        ticks = ffmRanges[gender].youngAdult;
    } else if (patientAge >= 30 && patientAge <= 39) {
        ticks = ffmRanges[gender].middleAdult1;
    } else if (patientAge >= 40 && patientAge <= 49) {
        ticks = ffmRanges[gender].middleAdult2;
    } else if (patientAge >= 50 && patientAge <= 59) {
        ticks = ffmRanges[gender].lateAdult;
    } else if (patientAge >= 60 && patientAge <= 69) {
        ticks = ffmRanges[gender].earlyOld;
    } else if (patientAge >= 70 && patientAge <= 79) {
        ticks = ffmRanges[gender].old;
    }

    if (min === null || min === undefined || max === null || max === undefined) {
        refRanges = ticks;
    } else {
        let filteredTicks = filterTicks(ticks, min, max);
        refRanges = filteredTicks.ticks;
    }

    return refRanges;
};

export const getRefRangesFM = (patientGender, patientAge, min, max) => {
    let refRanges = null;
    const colors = ["#DDEBFF", "#B2D0F8", "#82D1BC", "#F0E7B3", "#F4CCAA", "#F5B3C0"];
    const gender = patientGender === "M" ? "male" : "female";

    if (patientAge >= 20 && patientAge <= 29) {
        refRanges = createPlotBandsAndTicks(fmRanges[gender].youngAdult, colors, false, min, max);
    } else if (patientAge >= 30 && patientAge <= 39) {
        refRanges = createPlotBandsAndTicks(fmRanges[gender].middleAdult1, colors, false, min, max);
    } else if (patientAge >= 40 && patientAge <= 49) {
        refRanges = createPlotBandsAndTicks(fmRanges[gender].middleAdult2, colors, false, min, max);
    } else if (patientAge >= 50 && patientAge <= 59) {
        refRanges = createPlotBandsAndTicks(fmRanges[gender].lateAdult, colors, false, min, max);
    } else if (patientAge >= 60 && patientAge <= 69) {
        refRanges = createPlotBandsAndTicks(fmRanges[gender].earlyOld, colors, false, min, max);
    } else if (patientAge >= 70 && patientAge <= 79) {
        refRanges = createPlotBandsAndTicks(fmRanges[gender].old, colors, false, min, max);
    }

    return refRanges;
};

export const getRefRangesHyDex = (min = null, max = null) => {
    return createPlotBandsAndTicks([-75.0, -50.0, -25.0, 0, 25.0, 50.0, 75.0], ["#C9CBCE", "#E0E1E2", "#EDEFF0", "#DDEBFF", "#B2D0F8", "#8DBCFD"], true, min, max);
};