import UnsupportedResolutionImageView from "./UnsupportedResolutionImageView";

const UnsupportedResolutionImage = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <UnsupportedResolutionImageView {...props}/>;

};

UnsupportedResolutionImage.propTypes = {};

export default UnsupportedResolutionImage;