import PropTypes from "prop-types";
import CustomPopupView from "./CustomPopupView";

const CustomPopup = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <CustomPopupView {...props}/>;

};

CustomPopup.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	defaultButtons: PropTypes.bool,
	withCancel: PropTypes.bool,
	popupover: PropTypes.bool,
	show: PropTypes.bool,
	cancelText: PropTypes.string,
	submitText: PropTypes.string,
	title: PropTypes.string,
	containerClasses: PropTypes.string,
	titleClasses: PropTypes.string,
	bodyClasses: PropTypes.string,
	defaultButtonsWrapperClasses: PropTypes.string,
	defaultCancelClasses: PropTypes.string,
	defaultSubmitClasses: PropTypes.string,
	handleSubmit: PropTypes.func,
	handleCancel: PropTypes.func,
};

export default CustomPopup;