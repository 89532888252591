import LoginTitleView from "./LoginTitleView";
import Utilities from "../../Libraries/Custom/Utilities";

const LoginTitle = () => {

	//region VARIABLES
	localStorage.language = localStorage.language || "en";
	let mainTitle = Utilities.breakStringInTwoParts(dictionary[localStorage.language].sozoDigitalHealthPlatform, "SOZO");
	//endregion

	//region FUNCTIONS
	//endregion

	return <LoginTitleView
		mainTitle={mainTitle}
	/>;

};

LoginTitle.propTypes = {};

export default LoginTitle;