import { NODE_URL } from "./config";
import { getRegionForGraphQLURL, getResponse, getResponseGraphQL } from "./utils";
import { updateFacilityDeviceNameQuery, readFacilityDevicesQuery } from "./graphqlQueries";
import { ApiStore } from "./apiStore";

/**
 * @description Method and path provided by the server after successful login <br> Returns a list with the users.
 * @async
 * @export const
 */
export const getUserList = (listParams, isActiveUser, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let params = "?searchKey=" + encodeURIComponent(listParams.searchKey) +
		"&orderKey=" + encodeURIComponent(listParams.orderKey) +
		"&orderStyle=" + encodeURIComponent(listParams.orderStyle) +
		"&pageNum=" + encodeURIComponent(listParams.pageNum) +
		"&isActiveUser=" + encodeURIComponent(isActiveUser) +
		"&level=" + "0";
	let url = NODE_URL + apiStore.endpointsList.usersGET.path + params;
	let method = apiStore.endpointsList.usersGET.method;
	let data = null;

	getResponse(url, method, headerFor, data, resolve, reject);

};

export const toggleUserState = (userDetails, newState, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersActivationsPUT.path;
	let method = apiStore.endpointsList.usersActivationsPUT.method;
	let data = {
		"activation": newState,
		"userSlaveId": userDetails.userId,
		"email": userDetails.userEmail,
		"isPatient": "0"
	};

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const deleteMFA = (email, password, totp, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.mfaDELETE.path;
	let method = apiStore.endpointsList.mfaDELETE.method;
	let data = {
		email,
		password,
		totp
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const associateTotpToken = (email, password, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.associateTotpTokenPOST.path;
	let method = apiStore.endpointsList.associateTotpTokenPOST.method;
	let data = {
		email,
		password
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const enableMFA = (email, totp, accessToken, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.mfaPOST.path;
	let method = apiStore.endpointsList.mfaPOST.method;
	let data = {
		email,
		totp,
		accessToken,
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const getUserLevels = (userSlaveId, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let queryParams = "?level=1&userSlaveId=" + userSlaveId;
	let url = NODE_URL + apiStore.endpointsList.usersGET.path + queryParams;
	let method = apiStore.endpointsList.usersGET.method;
	let data = undefined;

	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateUser = (user, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersPUT.path;
	let method = apiStore.endpointsList.usersPUT.method;
	let data = {
		"userSlaveId": user.userSlaveId,
		"firstName": user.firstName,
		"lastName": user.lastName,
		"middleName": user.middleName,
		"countryPhoneCode": user.countryPhoneCode,
		"phoneNumber": user.phoneNumber,
		"userRole": user.role,
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateUserSettings = (settings, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersSettingsPUT.path;
	let method = apiStore.endpointsList.usersSettingsPUT.method;
	let data = {
		settings: {
			cm: Number(settings.cm).toString(),
			in: Number(!settings.cm).toString(),
			kg: Number(settings.kg).toString(),
			lbs: Number(!settings.kg).toString(),
			lt: Number(settings.lt).toString(),
			pt: Number(!settings.lt).toString()
		}
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const createUser = (user, password, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersPOST.path;
	let method = apiStore.endpointsList.usersPOST.method;
	let data = {
		"email": user.email,
		"firstName": user.firstName,
		"lastName": user.lastName,
		"middleName": user.middleName,
		"countryPhoneCode": user.countryPhoneCode,
		"phoneNumber": user.phoneNumber,
		"userRole": user.role,
		"password": password
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const changeEmail = (inputData, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersEmailsPUT.path;
	let method = apiStore.endpointsList.usersEmailsPUT.method;
	let data = {
		"password": inputData.password,
		"userSlaveId": inputData.userSlaveId,
		"newEmail": inputData.newEmail,
		"oldEmail": inputData.oldEmail,
		"email": apiStore.userEmail,
		"totp": inputData.totp,
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const updateWeightPreTare = (weightPreTare, weightStyle, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "default";
	let url = NODE_URL + apiStore.endpointsList.usersSettingsWeightPreTarePUT.path;
	let method = apiStore.endpointsList.usersSettingsWeightPreTarePUT.method;
	let data = {
		"weightPreTare": weightPreTare,
		"weightStyle": weightStyle,
	};
	getResponse(url, method, headerFor, data, resolve, reject);
};

export const getFacilityDevicesGraphQL = (params, query, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "graphql";
	let url = NODE_URL + getRegionForGraphQLURL(apiStore.region);
	let method = "POST";
	let data = readFacilityDevicesQuery(params, query);

	getResponseGraphQL(url, method, headerFor, data, "facilityDevicesREAD", resolve, reject);
};

export const facilityDeviceNameUPDATEGraphQL = (params, resolve, reject) => {
	let apiStore = ApiStore.getInstance();
	let headerFor = "graphql";
	let url = NODE_URL + getRegionForGraphQLURL(apiStore.region);
	let method = "POST";
	let data = updateFacilityDeviceNameQuery(params);

	getResponseGraphQL(url, method, headerFor, data, "facilityDeviceNameUPDATE", resolve, reject);
};
