class ErrorToUiCodeMapper {
    constructor () {
        // [tablet, pc]
        this.uiCodes = {
            0: ["00024", ""],
            2: ["00024", ""],
            3: ["00024", ""],
            4: ["00024", ""],
            5: ["00047", ""],
            6: ["00029", ""],
            7: ["00029", ""],
            8: ["00029", ""],
            10: ["00029", ""],
            11: ["00049", ""],
            12: ["00048", ""],
            50: ["00024", ""],
            51: ["00024", ""],
            52: ["00047", ""],
            53: ["00326", ""],
            101: ["00029", ""],
            102: ["00029", ""],
            103: ["00029", ""],
            104: ["00029", ""],
            105: ["00029", ""],
            106: ["00029", ""],
            350: ["00049", ""],
            351: ["00048", ""],
            352: ["00029", ""],
            401: ["00323", ""],
            404: ["00231", ""],
            405: ["00321", ""],
            406: ["00323", ""],
            410: ["00322", ""],
            10001: ["00035", ""],
            10002: ["00024", ""],
            10004: ["00199", ""],
            10005: ["00199", ""],
            10006: ["00199", ""],
            10009: ["00049", ""],
            10011: ["00049", ""],
            10012: ["00215", ""],
            10027: ["", ""],
            10028: ["00056", ""],
            10029: ["", ""],
            10030: ["00024", ""],
            10031: ["", ""],
            10032: ["00263", ""],
            10033: ["00324", ""]
        };
    }

    map = (error) => {
        const uiCodes = Object.prototype.hasOwnProperty.call(this.uiCodes, error.message) ? this.uiCodes[error.message] : undefined;
        let mappedResult;
        if (uiCodes) {
            mappedResult = {
                uiCodes: {
                    tablet: uiCodes[0],
                    pc: uiCodes[1]
                },
                stack: error.stack
            };
        }
        return mappedResult;
    };
}
export default ErrorToUiCodeMapper;
