import { useState, useEffect } from "react";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        prevWidth: window.innerWidth,
        prevHeight: window.innerHeight
    });

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize((prevWindowSize) => ({
                width: window.innerWidth,
                height: window.innerHeight,
                prevWidth: prevWindowSize.width,
                prevHeight: prevWindowSize.height
            }));
        };

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return windowSize;
};