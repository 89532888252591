import {createPortal} from "react-dom";

const Portal = (props) => {

	//region VARIABLES
	const mount = document.getElementById("portal-root");

	//endregion

	//region FUNCTIONS

	//endregion

	return createPortal(props.children, mount);

};

Portal.propTypes = {};

export default Portal;