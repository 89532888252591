import PropTypes from "prop-types";
import UnlockedIcon from "../../Components/SVGs/UnlockedIcon/UnlockedIcon";

const ExpiringPasswordView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"loginBox expiringPassword"}>
		<span className={"expiringPassword__icon"}><UnlockedIcon/></span>
		<h2 className={"sectionTitle expiringPassword__title"}>{dictionary[localStorage.language].passwordExpiration}</h2>
		<p className={"expiringPassword__description"}>{dictionary[localStorage.language].passwordExpirationIn+props.days+dictionary[localStorage.language].passwordExpirationDays}</p>
		<button className={"button-blue expiringPassword__resetNow"} onClick={props.resetNow}>{dictionary[localStorage.language].resetNow}</button>
		<button className={"button-white expiringPassword__resetLater"} onClick={props.resetLater}>{dictionary[localStorage.language].resetLater}</button>
	</div>;

};

ExpiringPasswordView.propTypes = {
	days:PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
	resetNow:PropTypes.func,
	resetLater:PropTypes.func,
};

export default ExpiringPasswordView;