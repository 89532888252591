import * as actions from "../actionTypes";

export const showLogoutPopup = (payload) => ({
	type: actions.SHOW_LOGOUT_POPUP,
	payload
});

export const saveLogoutPopupCode = (payload) => ({
	type: actions.SAVE_LOGOUT_POPUP_CODE,
	payload
});

export const saveLogoutPopupPage = (payload) => ({
	type: actions.SAVE_LOGOUT_POPUP_PAGE,
	payload
});
