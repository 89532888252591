import ArrowBigView from "./ArrowBigView";

const ArrowBig = () => {
    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <ArrowBigView/>;
};

ArrowBig.propTypes = {};

export default ArrowBig;