import PropTypes from "prop-types";

const XbuttonView = (props) => {

	//region VARIABLES
	const containerClassName = props.withBackground ? "xButton" : "xButton--no-background";
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={containerClassName}>
		{props.xButtonLocation === "list" ?
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<g id="Group_10823" data-name="Group 10823" transform="translate(-462 -382)">
					<rect id="Path" width="24" height="24" transform="translate(462 382)" fill="rgba(0,0,0,0)" />
					<path id="Icon" d="M624.723,1488.637l-3.533,3.533,3.533,3.533a.72.72,0,1,1-1.019,1.019l-3.533-3.533-3.533,3.533a.721.721,0,0,1-1.019-1.019l3.534-3.533-3.534-3.533a.72.72,0,0,1,1.019-1.019l3.533,3.534,3.533-3.526a.718.718,0,0,1,1.019,1.011Z" transform="translate(-145.907 -1097.907)" fill="#fff" stroke="#fff" />
				</g>
			</svg>
			:
			<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
				<g transform="translate(0.33)">
					{props.withBackground ? <circle className="xButton__circle" cx="14" cy="14" r="14" transform="translate(-0.33)" /> : ""}
					<path className={`${containerClassName}__x`}
						d="M624.723,1488.637l-3.533,3.533,3.533,3.533a.72.72,0,1,1-1.019,1.019l-3.533-3.533-3.533,3.533a.721.721,0,0,1-1.019-1.019l3.534-3.533-3.534-3.533a.72.72,0,0,1,1.019-1.019l3.533,3.534,3.533-3.526a.718.718,0,0,1,1.019,1.011Z"
						transform="translate(-606.066 -1478.064)" />
				</g>
			</svg>
		}
	</div>;

};

XbuttonView.propTypes = {
	withBackground: PropTypes.bool,
	xButtonLocation: PropTypes.string
};

XbuttonView.defaultProps = {
	withBackground: true,
	xButtonLocation: "popup"
};

export default XbuttonView;