import PropTypes from "prop-types";
import Portal from "../Portal/Portal";
import LoaderView from "../Loader/LoaderView";

const HelperView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <Portal>
		{props.loading && <LoaderView loading={props.loading}/>}
	</Portal>;

};

HelperView.propTypes = {
	loading:PropTypes.bool
};

export default HelperView;