import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.selectedSharingData, action) => {
    let nextState = { ...state };
    switch (action.type) {
        case actions.SAVE_SHARING_DATA:
            nextState = {...nextState, ...action.payload};
            break;
        case actions.CLEAR_SHARING_DATA:
            nextState = {
                sharingId: "",
                sharingPatientId: ""
            };
            break;
        default:
            nextState = state;
    }
    return nextState;
};