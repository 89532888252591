import { useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ExpiringPasswordView from "./ExpiringPasswordView";
import {ApiStore} from "../../../API/apiStore";
import Utilities from "../../Libraries/Custom/Utilities";
import {persistor} from "../../../Redux/configureStore";
import {useHistory} from "react-router";

const ExpiringPassword = (props) => {

	//region VARIABLES
	let history = useHistory();
	//endregion

	//region FUNCTIONS
	const resetLater = () => {
		let activeView = props.user.type===constants.userTypes.admin?constants.activeView.admin : constants.activeView.clinician;
		persistor.flush().then(() => {
			sessionStorage.setItem("sozoCurentView", activeView);
			location.href = Utilities.getLocationHref(activeView, props.facility, props.user.user_id, props.facilityLocation.region);
		});
	};

	const changePassword = () => {
		sessionStorage.setItem("userEmail", props.user.userEmail);
		history.push(constants.routes.changePassword + Utilities.getQueryParams());
	};

	useEffect(() => {
		if (props.user.user_id === "") {
			history.push(constants.routes.root);
		}
		ApiStore.init({
			language: localStorage.language
		});
	}, []);
	//endregion

	return <ExpiringPasswordView
		days={props.user.needsPasswordUpdate}
		resetNow={changePassword}
		resetLater={resetLater}
	/>;

};

ExpiringPassword.propTypes = {
	user: PropTypes.object,
	facility: PropTypes.object,
	facilityLocation: PropTypes.object,
};

const mapStateToProps = (state) => ({
	user: state.user,
	facility: state.facility,
	facilityLocation: state.facilityLocation,
});

export default connect(mapStateToProps, null)(ExpiringPassword);