import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.facility.license, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_FACILITY_LICENSE :
			nextState = {...nextState, ...action.payload};
			break;
		default:
			nextState = state;
	}
	return nextState;
};