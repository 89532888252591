import { Fragment } from "react";

const NoMatchView = () => {
	 let language = localStorage.language === undefined || localStorage.language === null ? "en" : localStorage.language;
	 let hr = localStorage.language === undefined || localStorage.language === null ? <hr id="pageNotFoundLineTop"/> : "";
	return (
        <Fragment>
			{hr}
			<div>
				<div id={"pageNotFoundContainer"}>
					<h1 id={"error404"} className={"pageNotFoundText"}>{dictionary[language].error404}</h1>
					<h2 id={"pageNotFound"} className={"pageNotFoundText"}>{dictionary[language].pageNotFound}</h2>
				</div>
				<hr id={"pageNotFoundLine"}/>
			</div>
		</Fragment>
    );
};

export default NoMatchView;