import PropTypes from "prop-types";
import InputCode from "../InputCode/InputCode";
import PrivacyIcon from "../SVGs/PrivacyIcon/PrivacyIcon";

const MfaActivationView = (props) => {

	//region VARIABLES
	const containerClassName = props.isForce ? "mfaActivation--page" : "flip-enter portal mfaActivation--popup";
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"flexColStartCenter loginBox mfaActivation " + containerClassName}>
		<div className={"flexColCenterStart mfaActivation__header"}>
			<PrivacyIcon/>
			<h2 className={"mfaActivation__title"}>{dictionary[localStorage.language].multiFactorAuthentication}</h2>
		</div>
		<div className={"mfaActivation__qrCodeArea"}>
			<p className={"mfaActivation__qrCodeInfo"}>{dictionary[localStorage.language].scanQRCode}</p>
			<img className={"mfaActivation__qrCodeImage"} alt={"qrCode"} src={props.qrCodeData}/>
			<p className={"mfaActivation__secretKey"}>{dictionary[localStorage.language].enterSecretKey}</p>
			<span className={"mfaActivation__secretKey mfaActivation__secretKey--blue"}>{props.secretKey}</span>
		</div>
		<form className={"mfaActivation__form"} onSubmit={props.handleSubmit}>
			<p className={"mfaActivation__info"}>{dictionary[localStorage.language].enterVerificationCode}</p>
			<InputCode inputLength={6} setCode={props.handleInputChange} classes={"mfaActivation__inputCode"}/>
			<div className={"mfaActivation__buttonContainer"}>
			<input className={"button-blue mfaActivation__submit"} type={"submit"} value={dictionary[localStorage.language].submit}/>
			<button className={"button-white mfaActivation__cancel"} onClick={props.handleCancel}>{dictionary[localStorage.language].cancel}</button>
			</div>
		</form>
	</div>;

};

MfaActivationView.propTypes = {
	qrCodeData: PropTypes.string,
	secretKey: PropTypes.string,
	isForce: PropTypes.bool,
	handleInputChange: PropTypes.func,
	handleCancel: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default MfaActivationView;