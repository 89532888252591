const ThumbsUpView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <div className={"thumbsUp"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g transform="translate(-678 -506)">
                <rect className="a" width="24" height="24" transform="translate(678 506)"/>
                <path className="b"
                      d="M696,527h-9a2,2,0,0,1-2-2v-9.99a2.009,2.009,0,0,1,.58-1.41l5.54-5.54a1.469,1.469,0,0,1,1.056-.444,1.5,1.5,0,0,1,1.054.434,1.514,1.514,0,0,1,.41,1.37l-.95,4.58h5.65a3,3,0,0,1,2.76,4.18l-3.26,7.61A2.014,2.014,0,0,1,696,527Zm-15,0a2,2,0,0,1-2-2v-8a2,2,0,0,1,4,0v8A2,2,0,0,1,681,527Z"/>
            </g>
        </svg>
    </div>;

};

ThumbsUpView.propTypes = {};

export default ThumbsUpView;