const Commands = {
    GET_DEVICE_STATUS: "get_device_status",
    SET_DEVICE_SETTING: "set_device_setting",
    GET_DEVICE_SETTING: "get_device_setting",
    RUN_SELF_TEST: "run_self_test",
    GET_FREQUENCY: "get_frequency",
    SET_FREQUENCY: "set_frequency",
    GET_WEIGHT_CALIBRATION_HISTORY: "Get_weight_calibration_history",
    GET_WEIGHT_CALIBRATION_HISTORY_SCALE_LOG: "Get_weight_calibration_history_scale_log",
    GET_MEASUREMENT_RESULT: "get_measurement_result",
    GET_SCALE_WEIGHT: "get_scale_weight",
    REACQUIRE_SCALE_WEIGHT: "reacquire_scale_weight",
    SELF_TEST_GET_DETAILED_RESULT: "self_test_get_detailed_result",
    RUN_SELF_TEST_RESULT: "run_self_test_result"
};

export default Commands;
