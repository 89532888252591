import PropTypes from "prop-types";
import OptionList from "../OptionList/OptionList";

const LanguagesView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <OptionList
		handlerFunction={props.handleLanguageChange}
		options={props.languages}
		withLabel={props.withLabel}
		labelText={props.labelText}
		labelInBox={props.labelInBox}
		showSelected={props.showSelected}
	/>;

};

LanguagesView.propTypes = {
	handleLanguageChange: PropTypes.func,
	languages: PropTypes.array,
	withLabel: PropTypes.bool,
	showSelected: PropTypes.bool,
	labelText: PropTypes.string,
	labelInBox: PropTypes.bool

};

export default LanguagesView;