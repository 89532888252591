import PropTypes from "prop-types";
import SuccessLogoutPopupView from "./SuccessLogoutPopupView";
import Utilities from "../../Libraries/Custom/Utilities";

const SuccessLogoutPopup = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	const handleLogout = () => {
		Utilities.logoutCall();
	};

	//endregion
	return <SuccessLogoutPopupView {...props}
								   handleLogout={handleLogout}
								   msg={Utilities.getErrorMessage(props.successLogoutResponseCode, "", props.page)}/>;

};

SuccessLogoutPopup.propTypes = {
	successLogoutResponseCode: PropTypes.string,
	page: PropTypes.string
};

SuccessLogoutPopup.defaultProps = {
	page: ""
};

export default SuccessLogoutPopup;