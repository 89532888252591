import * as actions from "../actionTypes";

export const saveFacilitySettings = (payload) => ({
	type: actions.SAVE_FACILITY_SETTINGS,
	payload
});

export const saveFacilityLogo = (payload) => ({
	type: actions.SAVE_FACILITY_LOGO,
	payload
});
