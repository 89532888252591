import { useState } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import SendEmailView from "./SendEmailView";
import Utilities from "../../Libraries/Custom/Utilities";
import {sendTempPassword} from "../../../API/authApi";
import {useFlip} from "../../Hooks/useFlip";

const SendEmail = (props) => {

	//region VARIABLES
	const PAGE_PATH = "/SendEmail";
	let [email, setEmail] = useState("");
	let [flipClasses, setFlipClasses, handleAnimationEnd] = useFlip(PAGE_PATH);

	//endregion

	//region FUNCTIONS
	const handleInputChange = (event) => {
		setEmail(event.target.value);
	};

	const promiseResolveSendTempPassword = () => {
		Utilities.customSweetAlertSimple(dictionary[localStorage.language].sendEmailTitle, dictionary[localStorage.language].sendTempPasswordSuccess, "info", false, "", dictionary[localStorage.language].ok);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!props.loading) {
			let emailTemp = Utilities.removeWhitespacesToLowercase(email);

			if (Utilities.isRequiredFieldsEmpty(emailTemp)) {
				Utilities.customSweetAlertSimple("", dictionary[localStorage.language].emptyFieldsClinic, "error", false, "", dictionary[localStorage.language].ok);
			} else if (Utilities.isEmailInValid(emailTemp)) {
				Utilities.customSweetAlertSimple("", dictionary[localStorage.language].emailErrorMessage, "error", false, "", dictionary[localStorage.language].ok);
			} else {
				let resp = await Utilities.makeSingleCallAsync(sendTempPassword, "sendEmail", [emailTemp]);
				if (resp.status === constants.responseStatus.success) {
					promiseResolveSendTempPassword(resp.data);
				} else {
					Utilities.handleFailResponse(resp, [emailTemp], "sendEmail");
				}
			}
		}
	};

	const handleCancel = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setFlipClasses(constants.routes.root);
	};

	//endregion

	return <SendEmailView
		email={email}
		handleInputChange={handleInputChange}
		handleSubmit={handleSubmit}
		handleCancel={handleCancel}
		flipClasses={flipClasses}
		handleAnimationEnd={handleAnimationEnd}
	/>;

};

SendEmail.propTypes = {
	loading: PropTypes.loading
};

const mapStateToProps = (state) => ({
	loading: state.loading
});

export default connect(mapStateToProps, null)(SendEmail);