import DeviceMessage from "../Message/deviceMessage";
import MessageCodec from "../Message/deviceMessageCodec";
import MessageParser from "../Message/deviceMessageParser";
import Utils from "../utils";

class DeviceConnector {
    constructor (device, sendLogs) {
        this.sendLogs = sendLogs;
        if (!DeviceConnector._instance) {
            this.setDefaultAllValues(device);
            DeviceConnector._instance = this;
        }
        return DeviceConnector._instance;
    }

    // Private Methods ———————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    _setConnection = async () => {
        try {
            if (this.isDeviceConnected) {
                const deviceConnection = {
                    isDeviceConnected: true
                };
                this._resolveCurrentTaskWithValue(deviceConnection);
                return;
            }
            const characteristicUUIDV1 = "af20fbac-2518-4998-9af7-af42540731b3";
            const txCharacteristicUUIDV2 = "569a2000-b87f-490c-92cb-11ba5ea5167c";
            const rxCharacteristicUUIDV2 = "569a2001-b87f-490c-92cb-11ba5ea5167c";
            const server = await this.bluetoothDevice.gatt.connect();
            const service = await server.getPrimaryService(this.serviceId);
            this.bluetoothDevice.addEventListener("gattserverdisconnected", this._onDeviceDisconnected);
            if (!this.isSOZOProDevice) {
                const characteristics = await service.getCharacteristics(characteristicUUIDV1);
                this.sozoICharacteristic = characteristics[0];
                await this.sozoICharacteristic.startNotifications();
                this.sozoICharacteristic.addEventListener("characteristicvaluechanged", this._onCharacteristicChanged);
            } else {
                let characteristics = await service.getCharacteristics(txCharacteristicUUIDV2);
                this.sozoProTXCharacteristic = characteristics[0];
                if (characteristics.length > 1) {
                    this.sozoProRXCharacteristic = characteristics[1];
                }
                await this.sozoProTXCharacteristic.startNotifications();
                this.sozoProTXCharacteristic.addEventListener("characteristicvaluechanged", this._onCharacteristicChanged);
                if (characteristics.length === 1) {
                    characteristics = await service.getCharacteristics(rxCharacteristicUUIDV2);
                    this.sozoProRXCharacteristic = characteristics[0];
                }
            }
            this.isDeviceConnected = true;
            // Setting the retry connection count to 0 to make sure that next time we try to retry
            // when there is connection failure. This happens in case of Windows Web BLE were we
            // get frequent connection issues. For example: 'DOMException: GATT Server is disconnected. Cannot retrieve services.
            // (Re)connect first with device.gatt.connect'
            this.retryConnectionCount = 0;
            const deviceConnection = {
                isDeviceConnected: this.isDeviceConnected
            };
            this._resolveCurrentTaskWithValue(deviceConnection);
        } catch (error) {
            this._handleConnectionErrors(error);
        }
    };

    _handleConnectionErrors = (error) => {
        const modifiedError = this._getConnectionError(error);
        this.isDeviceConnected = false;
        const deviceConnection = {
            isDeviceConnected: this.isDeviceConnected,
            error: modifiedError
        };
        this._resolveCurrentTaskWithValue(deviceConnection);
    };

    _getConnectionError = (error) => {
        let modifiedError = error;
        if (typeof error === "string") {
            const message = error;
            modifiedError = {
                name: "networkerror",
                message
            };
        }
        return modifiedError;
    };

    _onDeviceDisconnected = async () => {
        if (this.sozoICharacteristic && this.sozoProTXCharacteristic) {
            this.isSOZOProDevice
                ? this.sozoProTXCharacteristic.removeEventListener("characteristicvaluechanged", this._onCharacteristicChanged)
                : this.sozoICharacteristic.removeEventListener("characteristicvaluechanged", this._onCharacteristicChanged);
        }
        this.isDeviceConnected = false;
    };

    _onCharacteristicChanged = async (event) => {
        try {
            const responseValue = new Uint8Array(event.target.value.buffer);
            for (let position = 0; position < responseValue.length; position++) {
                this.messageParser.setMessageRxByteBuffer(responseValue[position]);
            }
            const isResponseParsed = this.messageParser.isResponseParsed();
            // Todo: The code is commented to handle the chunking logic for SOZOI devices.
            // For SOZO Pro the device doesn't sends us the response for every chunk we send
            // But rather the complete last response. This is not the case for SOZOI device
            // where the device sends response for every chunk. This is the reason the
            // else block in the #region sozoi-chunk-handling is present
            // if (isResponseParsed && this.chunkMessageLength === this.messageQueue.length) {
            //     const messagePackBuffer = this.messageParser.getMessagePackBuffer();
            //     const decoded = this.messageCodec.getDecoded(messagePackBuffer);
            //     this.resolveCurrentTask(decoded);
            // }
            // #region sozoi-chunk-handling
            if (isResponseParsed && this.chunkMessageLength === this.messageQueue.length) {
                const messagePackBuffer = this.messageParser.getMessagePackBuffer();
                const decoded = this.messageCodec.getDecoded(messagePackBuffer);
                this._resolveCurrentTaskWithValue(decoded);
            } else {
                if (this.messageQueue.length > 1 && this.chunkMessageLength < this.messageQueue.length) {
                    this.utils.setSleep(this._getDelay()).then(() => {
                        this.messageParser.setDefaultAllValues();
                        this._sendMessage();
                    });
                }
            }
            // #endregion
        } catch (error) {
            this.rejectCurrentPendingTask(error);
        }
    };

    _sendMessage = () => {
        this.messageIndex += 1;
        this.isSOZOProDevice
            ? this.sozoProRXCharacteristic.writeValueWithResponse(this.messageQueue[this.chunkMessageLength])
                .catch((error) => {
                    this.rejectCurrentPendingTask(error);
                })
            : this.sozoICharacteristic.writeValueWithResponse(this.messageParser.getMessagePackTxBuffer(this.messageQueue[this.chunkMessageLength], this.messageQueue.length, this.messageIndex))
                .catch((error) => {
                    this.rejectCurrentPendingTask(error);
                });

        this.chunkMessageLength++;
        if (this.isSOZOProDevice) {
            if (this.messageQueue.length > 1 && this.chunkMessageLength < this.messageQueue.length) {
                this.utils.setSleep(this._getDelay()).then(() => {
                    this._sendMessage(this.messageQueue[this.chunkMessageLength]);
                });
            }
        }
    };

    _getDelay = () => {
        if (this.device.currentOS.isWindows) {
            return 1000;
        } else {
            return 0;
        }
    };

    _getConnectionDelay = () => {
        if (this.device.currentOS.isWindows || this.device.currentOS.isiOS) {
            return 2000;
        } else {
            return 0;
        }
    };

    _resolveCurrentTaskWithValue = (value) => {
        if (this.resolveCurrentTask !== null) {
            this.resolveCurrentTask(value);
        }
    };

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    setDefaultAllValues = (device) => {
        this.device = device;
        this.messageParser = new MessageParser();
        this.utils = new Utils(device.keys);
        this.deviceMessage = new DeviceMessage();
        this.messageCodec = new MessageCodec();
        this.bluetoothDevice = null;
        this.isSOZOProDevice = null;
        this.serviceId = null;
        this.isDeviceConnected = false;
        this.sozoProTXCharacteristic = null;
        this.sozoProRXCharacteristic = null;
        this.sozoICharacteristic = null;
        this.resolveCurrentTask = null;
        this.rejectCurrentTask = null;
        this.messageQueue = [];
        this.chunkMessageLength = 0;
        this.chunkSize = 143;
        this.retryConnectionCount = 0;
        this.messageIndex = 0;
    };

    getApplicationBuildConfiguration = async () => {
        let applicationBuildConfiguration;
        try {
            applicationBuildConfiguration = await navigator.bluetooth.getApplicationBuildConfiguration();
        } catch (error) {
            applicationBuildConfiguration = {};
        }
        return applicationBuildConfiguration;
    };

    getBluetoothDevices = async () => {
        const sozoIServiceId = "f6ec37db-bda1-46ec-a43a-6d86de88561d";
        const sozoProServiceId = "569a1101-b87f-490c-92cb-11ba5ea5167c";
        const searchFilters = [{ services: [sozoIServiceId] }, { services: [sozoProServiceId] }];
        const devices = await navigator.bluetooth.getBluetoothDevices({ filters: searchFilters });
        return devices;
    };

    setBluetoothDevice = async (bluetoothDevice) => {
        if (bluetoothDevice || !this.bluetoothDevice) {
            const sozoIServiceId = "f6ec37db-bda1-46ec-a43a-6d86de88561d";
            const sozoProServiceId = "569a1101-b87f-490c-92cb-11ba5ea5167c";
            const searchFilters = [{ services: [sozoIServiceId] }, { services: [sozoProServiceId] }];

            if (bluetoothDevice) {
                this.bluetoothDevice = await navigator.bluetooth.setBluetoothDevice({ device: bluetoothDevice }, { deviceId: bluetoothDevice.name });
            } else {
                this.bluetoothDevice = await navigator.bluetooth.requestDevice({ filters: searchFilters });
            }

            this.isSOZOProDevice = this.bluetoothDevice.name.startsWith("SOZOPRO");
            this.chunkSize = this.isSOZOProDevice ? 243 : 143;
            this.serviceId = this.isSOZOProDevice ? sozoProServiceId : sozoIServiceId;
        }
    };

    setConnection = async () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
            if (this.isSOZOProDevice) {
                this._setConnection();
            } else {
                this.utils.setSleep(this._getConnectionDelay()).then(() => {
                    this._setConnection();
                });
            }
        });
    };

    rejectCurrentPendingTask = (error) => {
        if (this.rejectCurrentTask) {
            this.rejectCurrentTask(error);
        }
        this.rejectCurrentTask = null;
    };

    setDisconnection = async () => {
        if (this.bluetoothDevice) {
            if (!this.isSOZOProDevice) {
                await this.sozoICharacteristic.stopNotifications();
                this.sozoICharacteristic.addEventListener("characteristicvaluechanged", this._onCharacteristicChanged);
            } else {
                await this.sozoProTXCharacteristic.stopNotifications();
                this.sozoProTXCharacteristic.addEventListener("characteristicvaluechanged", this._onCharacteristicChanged);
            }
            await this.bluetoothDevice.gatt.disconnect();
        }
    };

    setMessage = (rawData) => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.messageIndex = 0;
            this.rejectCurrentTask = reject;
            this.messageQueue = [];
            this.messageParser.setDefaultAllValues();
            // const data = this.messageParser.getMessagePackTxBuffer(rawData);
            // Todo: The implementation of getMessagePackTxBuffer is done inside the sendMessage.
            // #region getMessagePackTxBuffer-only-for-sozopro
            let data = rawData;
            if (this.isSOZOProDevice) {
                data = this.messageParser.getMessagePackTxBuffer(rawData, 1, 1);
            }
            // let data = this.messageParser.getMessagePackTxBuffer(rawData, 1, 1);
            // #endregion
            if (data.length > 0) {
                const arrayLength = data.length;
                let index = 0;
                while (index < arrayLength) {
                    this.messageQueue.push(data.slice(index, index += this.chunkSize));
                }
                this.chunkMessageLength = 0;
                this._sendMessage();
            }
        });
    };

    resolveTask = () => {
        if (this.resolveCurrentTask !== null) {
            this.resolveCurrentTask();
            this.resolveCurrentTask = null;
            this.isDeviceConnected = false;
        }
    };

}

export default DeviceConnector;
