const PrivacyIconView = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <div className={"privacyIcon"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="55" height="57" viewBox="0 0 55 57">
                <path className="a" d="M17.6,11A3.6,3.6,0,0,0,14,14.6v3.6h7.2V14.6A3.6,3.6,0,0,0,17.6,11Z"
                      transform="translate(8.794 7.995)"/>
                <path className="a"
                      d="M48.424,6.453,26.83,1.055a1.756,1.756,0,0,0-.875,0L4.362,6.453A1.8,1.8,0,0,0,3,8.2V33.391a23.393,23.393,0,0,0,46.786,0V8.2a1.8,1.8,0,0,0-1.362-1.745ZM37.19,40.589a1.8,1.8,0,0,1-1.8,1.8H17.4a1.8,1.8,0,0,1-1.8-1.8v-12.6a1.8,1.8,0,0,1,1.8-1.8h1.8v-3.6a7.2,7.2,0,0,1,14.4,0v3.6h1.8a1.8,1.8,0,0,1,1.8,1.8Z"/>
            </svg>
        </div>
    );

};

PrivacyIconView.propTypes = {};

export default PrivacyIconView;