import Utilities from "../../../Libraries/Custom/Utilities";
import {logout, refreshTokenCall, refreshTokenSSOCall} from "../../../../API/authApi";
import {store} from "../../../../Redux/configureStore";
import {SAVE_USER_TOKEN} from "../../../../Redux/actions/actionTypes";
import {ssoLogout} from "../../../../API/authApi";

let timeoutLogout;
const resolveRefreshToken = (response) => {
    let isSSO = store.getState().facility.isSSO;
    let newToken = isSSO ? response.id_token : response.data.session.token;

    if (newToken !== "_null" || newToken !== null) {
        store.dispatch({type: SAVE_USER_TOKEN, payload: {token: newToken}});
        clearTimeout(timeoutLogout);
        sessionStorage.loginTime = new Date().getTime();
    }
};

const refreshToken = async () => {
    const response = await Utilities.makeSingleCallAsync(refreshTokenCall, "refreshToken", []);

    if (response.status === constants.responseStatus.success) {
        resolveRefreshToken(response.data);
    } else {
        Utilities.handleFailResponse(response, [], "refreshToken");
    }
};

const refreshTokenSSO = async () => {
    const response = await Utilities.makeSingleCallAsync(refreshTokenSSOCall, "refreshTokenSSO", []);

    if (response.status === constants.responseStatus.success) {
        resolveRefreshToken(response.data);
    } else {
        Utilities.handleFailResponse(response, [], "refreshTokenSSO");
    }
};

export const logoutFinalization = () => {
    sessionStorage.clear();
    window.location = window.location.origin;
};

export const logoutCall = async () => {
    let isSSO = store.getState().facility.isSSO;
    if (isSSO) {
        ssoLogout();
    } else {
        Utilities.makeSingleCallAsync(logout, "logout", []);
        logoutFinalization();
    }
};

export function initiateAutoLogoutSequence() {
    clearTimeout(timeoutLogout);
    let sessionExpirationTime = store.getState().facilitySettings.sessionExpirationTime;
    let isSSO = store.getState().facility.isSSO;

    let dateNowMillis = new Date().getTime();
    let sessionExpirationTimeMilli = sessionExpirationTime * 60000;
    let millisForLogout = parseInt(sessionStorage.loginTime) + sessionExpirationTimeMilli;
    let millisForPopup = millisForLogout - constants.logoutPopupTimeBeforeExpiration - dateNowMillis;

    if (dateNowMillis >= millisForLogout) {
        logoutCall();
    } else {
        timeoutLogout = setTimeout(() => {
            Utilities.customSweetAlertTimerForLogout("", "info", millisForLogout - new Date().getTime(), isSSO ? refreshTokenSSO : refreshToken, isSSO);
        }, millisForPopup);
    }
}