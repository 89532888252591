import PropTypes from "prop-types";

const CheckIconView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={"checkIcon"}>
		{props.type === "thin" ?
			<svg xmlns="http://www.w3.org/2000/svg" width="13.229" height="9.922" viewBox="0 0 13.229 9.922">
				<path className="a" d="M11.348,1.323,4.41,8.261,1.882,5.733A1.1,1.1,0,1,0,.323,7.292L3.63,10.6a1.1,1.1,0,0,0,1.559,0l7.717-7.717a1.1,1.1,0,1,0-1.559-1.559Z"
					  transform="translate(0 -1)"/>
			</svg> :
			<svg xmlns="http://www.w3.org/2000/svg" width="13.229" height="9.922" viewBox="0 0 13.229 9.922">
				<path className="a" d="M11.561.44a1.5,1.5,0,0,0-2.124,0L4.5,5.379,2.56,3.441A1.5,1.5,0,0,0,.439,5.562l3,3a1.5,1.5,0,0,0,2.121,0l6-6A1.5,1.5,0,0,0,11.561.44Z"
					  transform="translate(0)"/>
			</svg>}
	</div>;

};
//1310.832 591.5
CheckIconView.propTypes = {
	type: PropTypes.oneOf(["thin", "fat"])
};

export default CheckIconView;