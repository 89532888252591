import {customSweetAlertOperation, customSweetAlertSimple} from "./sweetalert";
import { ssoLogout } from "../../../../API/authApi";
import { postSystemLogs } from "../../../../API/facilityApi";
import { logoutCall } from "./session";
import { store } from "../../../../Redux/configureStore";
import { saveLogoutPopupCode, saveLogoutPopupPage, showLogoutPopup } from "../../../../Redux/actions/Generic/logoutPopupActions";
import dateFormat from "dateformat";
import {makeSingleCallAsync} from "./tools";
import {getPageForMessage, getBuildPrefix, getCurrentOS} from "./business";
import Utilities from "../Utilities";

const debugLogger = (errorLevel = 3) => {
	const datetimeFormat = "ddd, yyyy mmm d HH:mm:ss Z";

	return (functionName, description, detailedDesc) => {
		let timestamp = dateFormat(new Date(), datetimeFormat);
		let newLog;
		if (errorLevel > 2) {
			newLog = {
				"Function Name": functionName,
				"Description": description,
				"Detailed Description": detailedDesc,
				"Error Code": "errorCode",
				"Timestamp": timestamp.toString()
			};
		} else if (errorLevel === 2) {
			newLog = {
				"Function Name": functionName,
				"Description": description,
				"Error Code": "errorCode",
				"Timestamp": timestamp.toString()
			};
		} else {
			newLog = {
				"Function Name": functionName,
				"Error Code": "errorCode",
				"Timestamp": timestamp.toString()
			};
		}

		const envPrevix = window.location.hostname.split(".")[0];
		const currentOS = getCurrentOS();
		const tag = getBuildPrefix(envPrevix, currentOS);

		makeSingleCallAsync(postSystemLogs, "postSystemLogs", [{log: JSON.stringify(newLog), tag: tag, level: errorLevel, type: "ERROR"}], false);
	};
};

const setActionDebugLogger = debugLogger(3);

const handleTimeoutError = (errorObject, sweetAlertFunction, name) => {
	let errorData = {};

	if (errorObject.responseJSON !== undefined && errorObject.responseJSON.errors[0].errorType === "AuthorizerFailureException") {
		const errorCode = parseFloat(errorObject.responseJSON.errors[0].message);
		if (constants.logoutCodes.greenPopup.includes(errorCode)) {
			let page = getPageForMessage(errorCode, name);
			store.dispatch(saveLogoutPopupPage(page));
			store.dispatch(saveLogoutPopupCode(errorCode));
			store.dispatch(showLogoutPopup(true));
		}
	} else {
		errorData.errorMessage = dictionary[localStorage.language].timeout;
		if (name === "generateReport") {
			errorData.errorMessage = dictionary[localStorage.language].reportTimeout;
		}
		errorData.alertConfig = {title: dictionary[localStorage.language].warning, text: errorData.errorMessage, type: "warning", alertFunction: sweetAlertFunction};
	}

	return errorData;
};

/**
 * @static
 * @description Returns an error message based on the provided error code
 */
export const getErrorMessage = (errorCode, extraMessageData, page) => {
	extraMessageData = typeof extraMessageData === "undefined" ? "" : extraMessageData;
	let msg = "";

	if (typeof page === "undefined" || page === "") {
		msg = dictionary[localStorage.language][errorCodes[errorCode]] + extraMessageData;
	} else {
		msg = dictionary[localStorage.language][errorCodes[errorCode]][page] + extraMessageData;
	}
	if (errorCode === null || errorCode === undefined || errorCodes[errorCode] === null || errorCodes[errorCode] === undefined) {
		msg = dictionary[localStorage.language].exception;
	} else if (dictionary[localStorage.language][errorCodes[errorCode]] === dictionary[localStorage.language].exception) {
		msg = dictionary[localStorage.language].errorCode + errorCode + "\n" + dictionary[localStorage.language][errorCodes[errorCode]];
	}
	return msg;
};

/**
 * @static
 * @description Handles null/undefined error description values in order to get logged correctly
 */
export const handleErrorDescription = (value) => {
	let errorDescription = "";

	if (value === null) {
		errorDescription = "null";
	} else if (typeof value === "undefined") {
		errorDescription = "undefined";
	} else {
		errorDescription = value;
	}

	return errorDescription;
};

/**
 * @static
 * @description Handles errors from inside portal, notifies user and updates logs
 */
export const handleError = (errorObject, name, sweetAlertFunction = null, alertMessage = true) => {
	let alertConfig = null;
	let errorMessage = "";

	if (typeof errorObject.statusText === "undefined" || errorObject.statusText === null) {
		setActionDebugLogger("handleError_" + name, errorObject.toString(), errorObject.stack, "0");
		errorMessage = dictionary[localStorage.language].exception;
		alertConfig = {title: "", text: errorMessage, type: "error", alertFunction: sweetAlertFunction};
	} else if (errorObject.responseJSON !== undefined && constants.logoutCodes.greenPopup.includes(errorObject.responseJSON.code)) {
		setActionDebugLogger("handleError_" + name, handleErrorDescription(errorObject.statusText), handleErrorDescription(errorObject.responseText), handleErrorDescription(errorObject.status));
		store.dispatch(saveLogoutPopupCode(errorObject.responseJSON.code));
		store.dispatch(showLogoutPopup(true));
	} else if (errorObject.responseJSON !== undefined && constants.logoutCodes.swalPopup.includes(errorObject.responseJSON.code)) {
		setActionDebugLogger("handleError_" + name, handleErrorDescription(errorObject.statusText), handleErrorDescription(errorObject.responseText), handleErrorDescription(errorObject.status));
		errorMessage = getErrorMessage(errorObject.responseJSON.code);
		alertConfig = {title: "", text: errorMessage, type: "error", alertFunction: () => window.location.pathname !== "/" && logoutCall()};
	} else if (errorObject.status === constants.HTTP403) {
		setActionDebugLogger("handleError_" + name, handleErrorDescription(errorObject.statusText), handleErrorDescription(errorObject.responseText), handleErrorDescription(errorObject.status));
		errorMessage = getErrorMessage(errorObject.responseJSON.code);
		alertConfig = {title: dictionary[localStorage.language].warning, text: errorMessage, type: "warning", alertFunction: sweetAlertFunction};
	} else if (errorObject.statusText === "timeout" || errorObject.statusText === "error") {
		setActionDebugLogger("handleError_" + name, handleErrorDescription(errorObject.statusText), handleErrorDescription(errorObject.responseText), handleErrorDescription(errorObject.status));
		let errorData = handleTimeoutError(errorObject, sweetAlertFunction, name);
		errorMessage = errorData.errorMessage;
		alertConfig = errorData.alertConfig;
	} else if (errorObject.status < window.constants.HTTP200 || errorObject.status >= window.constants.HTTP304) {
		setActionDebugLogger("handleError_" + name, handleErrorDescription(errorObject.statusText), handleErrorDescription(errorObject.responseText), handleErrorDescription(errorObject.status));
		errorMessage = dictionary[localStorage.language].noConnectivity;
		alertConfig = {title: dictionary[localStorage.language].error, text: errorMessage, type: "error", alertFunction: sweetAlertFunction};
	} else {
		setActionDebugLogger("handleError_" + name, JSON.stringify(errorObject), errorObject.stack, "0");
		errorMessage = errorObject.statusText + " " + errorObject.status;
		alertConfig = {title: "", text: errorMessage, type: "error", alertFunction: sweetAlertFunction};
	}

	alertMessage && alertConfig &&
	customSweetAlertOperation({
		title: alertConfig.title,
		text: alertConfig.text,
		type: alertConfig.type,
	}, false, "", dictionary[localStorage.language].ok, alertConfig.alertFunction, false);

	return errorMessage;
};

/**
 * @static
 * @description Handles errors during logout and updates logs
 */
export const handleErrorLogout = (errorObject, name) => {
	setActionDebugLogger("handleError_" + name, handleErrorDescription(errorObject.statusText), handleErrorDescription(errorObject.responseText), handleErrorDescription(errorObject.status));
};

/**
 * @static
 * @description Handles errors caused on server, notifies user, updates logs and logs out user if need be
 */
export const handleFailFromServer = (response, name, extraMessageData, page, sweetAlertFunction = null, alertMessage = true) => {
    let alertConfig = null;
	let errorMessage = getErrorMessage(response.code, extraMessageData, page);

	if (constants.logoutCodes.greenPopup.includes(response.code)) {
		store.dispatch(saveLogoutPopupPage(page));
		store.dispatch(saveLogoutPopupCode(response.code));
		store.dispatch(showLogoutPopup(true));
	} else if (response.response === constants.response.expireToken || constants.logoutCodes.swalPopup.includes(response.code)) {
		alertConfig = {title: "", text: errorMessage, type: "error", alertFunction: () => window.location.pathname !== "/" && logoutCall()};
	} else if (name === "ssoInitialData" || name === "ssoLogin") {
        alertConfig = {title: "", text: errorMessage, type: "error", alertFunction: ssoLogout};
	} else {
        alertConfig = {title: "", text: errorMessage, type: "error", alertFunction: sweetAlertFunction};
	}

	setActionDebugLogger("handleFailFromServer_" + name, "server error " + response.response + "_" + response.code + ": " + errorCodes[response.code], getDetailedDescription(response.code, extraMessageData, page), -1);
    alertMessage && alertConfig &&
    customSweetAlertOperation({
        title: alertConfig.title,
        text: alertConfig.text,
        type: alertConfig.type,
    }, false, "", dictionary[localStorage.language].ok, alertConfig.alertFunction, false);

	return errorMessage;
};

/**
 * @static
 * @description Handles errors caused on cognito, notifies user and updates logs
 */
export const handleErrorFromCognito = (errorDescription, name, sweetAlertFunction) => {
	let results = new RegExp(/[0-9]{5}\./g).exec(errorDescription);
	let errorCode;
	if (!results) {
		errorCode = null;
	} else {
		errorCode = results[0].substring(0, results[0].length - 1);
	}

	setActionDebugLogger("handleFailFromServer_" + name, "cognito error " + errorDescription + errorCode + ": " + errorCodes[errorCode], getDetailedDescription(errorCode), -1);
	customSweetAlertOperation({
		title: "",
		text: getErrorMessage(errorCode),
		type: "error"
	}, false, "", dictionary[localStorage.language].ok, sweetAlertFunction, false);
};

/**
 * @static
 * @description Handles errors caused on device, notifies user and updates logs
 */
export const handleErrorFromDevice = (errorObject, name, currentOS, alertMessage = true) => {
	const errorMessageObject = JSON.parse(errorObject.message);
	const errorCode = currentOS.isWindows ? errorMessageObject.uiCodes.pc : errorMessageObject.uiCodes.tablet;
	const errorMessage = errorCode === "" ? "" : getErrorMessage(errorCode);

	console.log("handleErrorFromDevice", errorObject);
	setActionDebugLogger("handleFailFromDevice_" + name, "device error " + errorCode + ": " + errorCodes[errorCode], errorObject.toString(), -1);

	alertMessage && errorCode && customSweetAlertSimple("", errorMessage, "error", false, "", dictionary[localStorage.language].ok);

	return errorMessage;
};

export const sendLogs = (...args) => {
	let tag = args.shift();
	let log = {
		log: args.join()
	};
	makeSingleCallAsync(postSystemLogs, "postSystemLogs", [{log: JSON.stringify(log), tag: `${tag} DeviceApiDebug`, level: 3, type: "ERROR"}], false);
};

function getDetailedDescription(errorCode, extraMessageData, page) {
	return "ErrorCode: " + errorCode + ". Error Key: " + errorCodes[errorCode] + ". Message: " + getErrorMessage(errorCode, extraMessageData, page);
}
