import moment from "moment";
import dateformat from "dateformat";

/**
 * @static
 * @description Translates date from english to any supported language
 */
export const translateDate = (date) => {
	let dateSplitted = date.split(" ");
	let month = dateSplitted[1];
	let translatedDate = "";

	const findTheMonth = (month) => {

		let months = {
			"Jan": dictionary[localStorage.language].months.january,
			"Feb": dictionary[localStorage.language].months.february,
			"Mar": dictionary[localStorage.language].months.march,
			"Apr": dictionary[localStorage.language].months.april,
			"May": dictionary[localStorage.language].months.may,
			"Jun": dictionary[localStorage.language].months.june,
			"Jul": dictionary[localStorage.language].months.july,
			"Aug": dictionary[localStorage.language].months.august,
			"Sep": dictionary[localStorage.language].months.september,
			"Oct": dictionary[localStorage.language].months.october,
			"Nov": dictionary[localStorage.language].months.november,
			"Dec": dictionary[localStorage.language].months.december
		};
		return months[month];
	};

	switch (localStorage.language) {
		case "en":
		case "en-rGB":
			translatedDate = date;
			break;
		default:
			if (dateSplitted.length === 4) {
				translatedDate = dateSplitted[0] + " " + findTheMonth(month) + " " + dateSplitted[2] + " " + dateSplitted[3];
			} else {
				translatedDate = dateSplitted[0] + " " + findTheMonth(month) + " " + dateSplitted[2];
			}
			break;
	}

	return translatedDate;
};

/**
 * @static
 * @description Translates date to english from any supported language
 */
export const dateToEnglishForDatepicker = (date) => {
	let dateSplitted = date.split(" ");
	let month = dateSplitted[1];
	let months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec"
	];

	month = months.find((m) => {
		return m === month;
	});

	return dateSplitted[0] + " " + month + " " + dateSplitted[2];
};

/**
 * @static
 * @description Returns a date in the selected timezone
 */
export const getDateToTimezone = (value, timezone, format = constants.allDateStyleTimeMoment) => {
	return translateDate(moment(parseInt(value)).tz(timezone).format(format));
};

/**
 * @static
 * @description Returns the timezone offset
 */
export const getTimezoneOffset = (facilityUTCOffset) => {
	return moment().tz(facilityUTCOffset).format("Z").replace(":", "");
};

/**
 * @static
 * @description Returns the date range values
 */
export const getDateRangeValues = (fromDate, toDate, facilityUTCOffset) => {
	let dateRangeValues = [];
	let offset = getTimezoneOffset(facilityUTCOffset);

	dateRangeValues.push(moment(fromDate.format("YYYY-MM-DD") + " 00:00:00.000 " + offset, "YYYY-MM-DD HH:mm:ss.SSS Z").valueOf());
	dateRangeValues.push(moment(toDate.format("YYYY-MM-DD") + " 23:59:59.999 " + offset, "YYYY-MM-DD HH:mm:ss.SSS Z").valueOf());

	return dateRangeValues;
};

export const milliToDate = (date, format = constants.allDateStyle) => {
	return translateDate(dateformat(date, "UTC:" + format));
};

export const milliToDateTimezone = (date, format = constants.allDateStyle,utcOffset) => {
	// moment.tz(date, props.facilityUTCOffset).format("DD MMM YYYY, HH:mm")
	return moment.tz(parseInt(date), utcOffset).format(format);
};

export const dateToAge = (dateTime) => {
	// return moment().diff(dateTime, "years");
	// return Math.round(moment().diff(dateTime, "years",true));
	return parseInt((Date.now()-dateTime)/31556952000);
};