import {useState} from "react";

export const usePasswordVisibility = () => {
	let [passwordType, setPasswordType] = useState("password");

	const togglePassword = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (passwordType === "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	return [passwordType, togglePassword];
};
