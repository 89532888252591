import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.selectedDevice, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_SELECTED_DEVICE :
            nextState = {...state, ...action.payload};
            break;
        case actions.SAVE_DEVICE_PORT :
            nextState = {...state, port: action.payload};
            break;
        case actions.SAVE_SELF_TEST_STATUS :
            nextState.status = {...state.status, selfTestStatus: action.payload};
            break;
        case actions.SAVE_DEVICE_STATUS :
            nextState.status = {...state.status, ...action.payload};
            break;
        case actions.SAVE_DEVICE_KEYS :
            nextState.keys = {...state.keys, ...action.payload};
            break;
        case actions.SAVE_DEVICE_LICENSE_STATUS :
            nextState.licenseStatus = action.payload;
            break;
        case actions.SAVE_DEVICE_BLUETOOTH_NAME :
            nextState.bluetoothName = action.payload;
            break;
        case actions.CLEAR_SELECTED_DEVICE :
            nextState = {...initialState.selectedDevice, keys: state.keys};
            break;
        default:
            nextState = state;
    }
    return nextState;
};