import Utils from "../utils";
import MessageCodec from "./deviceMessageCodec";

class DeviceMessage {
    constructor (device) {
        if (!DeviceMessage._instance) {
            this.extensionCodec = null;
            this.setDefaultAllValues(device);
            DeviceMessage._instance = this;
        }
        return DeviceMessage._instance;
    }

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    setDefaultAllValues = (device) => {
        this.messageCodec = new MessageCodec();
        this.utils = new Utils(device.keys);
        this.bluetoothDevice = null;
        this.isSOZOProDevice = false;
        this.isDeviceConnected = false;
    };

    getAuthenticationInitMessage = (messageIndex, messageType) => {
        const stringChars = this.utils.getRandomAlphaNumericString(43);
        const finalString = "APP:" + stringChars;
        const encryptedMessageBytes = this.utils.encrypt(finalString);
        return this.messageCodec.getAuthentication(encryptedMessageBytes, messageIndex, messageType);
    };

    getAuthenticationChallengeMessage = (toBeDecryptedBytes, messageIndex, messageType) => {
        const decryptedText = this.utils.decrypt(toBeDecryptedBytes);
        const encryptedMessageBytes = this.utils.encrypt(decryptedText);
        return this.messageCodec.getAuthentication(encryptedMessageBytes, messageIndex, messageType);
    };

    getRequestMessage = (messageIndex, messageType, subMessageType) => {
        return this.messageCodec.getRequest(messageIndex, messageType, subMessageType);
    };

    getDeviceStatusMessage = (messageIndex, statusTypes, messageType, subMessageType) => {
        return this.messageCodec.getDeviceStatus(messageIndex, statusTypes, messageType, subMessageType);
    };

    getDeviceSettingMessage = (messageIndex, settingTypes, messageType, subMessageType) => {
        return this.messageCodec.getDeviceSettings(messageIndex, settingTypes, messageType, subMessageType);
    };

    getMeasurementMessage = (messageIndex, messageType, driveChannel, senseChannel) => {
        return this.messageCodec.getMeasurement(messageIndex, messageType, driveChannel, senseChannel);
    };

    getExpectedResponseMessage = (response, messageType) => {
        return this.messageCodec.getExpectedResponse(response, messageType);
    };

    getFirmwareUpdateMessage = (messageIndex, messageType, offset, data) => {
        return this.messageCodec.getFirmwareUpdate(messageIndex, messageType, offset, data);
    };

    getFirmwareUpdateFinishMessage = (messageIndex, messageType, length, crcValue, isSozoProDevice) => {
        return this.messageCodec.getFirmwareBinaryFinish(messageIndex, messageType, length, crcValue, isSozoProDevice);
    };

    getSetDeviceSettingMessage = (messageType, sozoSettings, messageIndex) => {
        return this.messageCodec.getSetDeviceSettings(messageType, sozoSettings, messageIndex);
    };

    getRunSelfTestMessage = (messageType, isSozoProDevice, timestamp, messageIndex) => {
        return this.messageCodec.getRunSelfTestMessage(messageType, isSozoProDevice, timestamp, messageIndex);
    };

    getSetFrequencyListMessage = (messageType, messageIndex, data) => {
        return this.messageCodec.getSetFrequencyListMessage(messageType, messageIndex, data);
    };

    getResetFrequencyListMessage = (messageType, messageIndex) => {
        return this.messageCodec.getResetFrequencyListMessage(messageType, messageIndex);
    };

    getWeightCalibrationHistoryMessage = (messageType, messageIndex) => {
        return this.messageCodec.getWeightCalibrationHistoryMessage(messageType, messageIndex);
    };

    getZeroScaleMessage = (messageType, messageIndex) => {
        return this.messageCodec.zeroScaleMessage(messageType, messageIndex);
    };

    getSelfTestGetDetailedResultMessage = (messageType, messageIndex) => {
        return this.messageCodec.selfTestGetDetailedResultMessage(messageType, messageIndex);
    };
}
export default DeviceMessage;
