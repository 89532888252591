import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import OptionListView from "./OptionListView";
import {useVisible} from "../../Hooks/useVisible";

const OptionList = (props) => {

	//region VARIABLES
	let [ref, isVisible, setIsVisible] = useVisible(false);
	let blurRef = useRef(null);
	//endregion

	//region FUNCTIONS
	const handleVisibility = (event) => {
		event.stopPropagation();
		setIsVisible(!isVisible);
	};

	const handleSelect = (event, selection) => {
		event.stopPropagation();
		props.handlerFunction(selection);
	};

	const handleClose = () => {
		setIsVisible(false);
	};

	const handleBlur = () => {
		setIsVisible(false);
	};

	useEffect(() => {
		if (!isVisible) {
			blurRef.current && blurRef.current.blur();
		}
	}, [isVisible]);
	//endregion

	return <OptionListView
		refNode={ref}
		blurRef={blurRef}
		handleVisibility={handleVisibility}
		handleSelect={handleSelect}
		isVisible={isVisible}
		handleBlur={handleBlur}
		handleClose={handleClose}
		id={props.id}
		options={props.options}
		classes={props.classes}
		boxClasses={props.boxClasses}
		showSelected={props.showSelected}
		hasIcon={props.hasIcon}
		iconPosition={props.iconPosition}
		defaultValue={props.defaultValue}
		defaultSelectable={props.defaultSelectable}
		hasArrowIcon={props.hasArrowIcon}
		withLabel={props.withLabel}
		isRequired={props.isRequired}
		labelText={props.labelText}
		labelInBox={props.labelInBox}
		withRequiredTag={props.withRequiredTag}
		placeholder={props.placeholder}
		popupClasses={props.popupClasses}
		disabled={props.disabled}
	/>;

};

OptionList.propTypes = {
	options: PropTypes.array,
	handlerFunction: PropTypes.func,
	id: PropTypes.string,
	classes: PropTypes.string,
	boxClasses: PropTypes.string,
	showSelected: PropTypes.bool,
	hasIcon: PropTypes.bool,
	iconPosition: PropTypes.string,
	defaultValue: PropTypes.string,
	defaultSelectable: PropTypes.bool,
	isVisible: PropTypes.bool,
	hasArrowIcon: PropTypes.bool,
	handleVisibility: PropTypes.func,
	handleBlur: PropTypes.func,
	handleSelect: PropTypes.func,
	withLabel: PropTypes.bool,
	isRequired: PropTypes.bool,
	labelText: PropTypes.string,
	labelInBox: PropTypes.bool,
	withRequiredTag: PropTypes.bool,
	placeholder: PropTypes.string,
	popupClasses: PropTypes.string,
	disabled: PropTypes.bool
};

OptionListView.defaultProps = {
	options: [],
	handlerFunction: () => null,
	id: "",
	classes: "",
	showSelected: true,
	hasIcon: false,
	iconPosition: "left",
	defaultValue: "",
	defaultSelectable: false,
	isVisible: false,
	hasArrowIcon: true,
	handleVisibility: () => null,
	handleBlur: () => null,
	handleSelect: () => null,
	withLabel: false,
	isRequired: false,
	labelText: "",
	labelInBox: false,
	withRequiredTag: false,
	placeholder: "",
	popupClasses: "",
	disabled: false
};

export default OptionList;