import {useReducer, useState, useEffect} from "react";
import PropTypes from "prop-types";
import Utilities from "../../Libraries/Custom/Utilities";
import "../../Libraries/Custom/ErrorCodes";
import "../../Libraries/Custom/Dictionary";
import "../../Libraries/Custom/Constants";
import {LoginView} from "./LoginView";
import {connect} from "react-redux";
import {store, persistor} from "../../../Redux/configureStore";
import {useHistory} from "react-router";
import {ApiStore} from "../../../API/apiStore";
import {login} from "../../../API/authApi";
import {getInitialData} from "../../../API/authApi";
import {useFlip} from "../../Hooks/useFlip";
import {saveDeviceKeys, saveDevicePort} from "../../../Redux/actions/Device/deviceActions";
import { version } from "../../../../package.json";

const Login = (props) => {
    //region VARIABLES
    const PAGE_PATH = constants.routes.root;

    localStorage.language = localStorage.language || "en";
    const loginEmail = sessionStorage.getItem("loginEmail");
    const [language, setlanguage] = useState(localStorage.language);
    const [inpt, setInpt] = useReducer((state, newState) => ({...state, ...newState}), {
        inputEmail: loginEmail === undefined || loginEmail === null ? "" : loginEmail,
        inputPassword: ""
    });

    const history = useHistory();
    const [flipClasses, setFlipClasses, handleAnimationEnd] = useFlip(PAGE_PATH);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const sharedDataNavigation = queryParams.get("sharedDataNavigation");

    //endregion

    //region FUNCTIONS
    const handleInputChange = (event) => {
        setInpt({[event.target.id]: event.target.value});
    };

    const handleLanguageChange = (selection) => {
        localStorage.language = selection.value;
        setlanguage(selection.value);
    };

    const changePassword = (email) => {
        sessionStorage.setItem("userEmail", email);
        history.push(constants.routes.changePassword + Utilities.getQueryParams());
    };

    const promiseResolveGetInitialData = (response) => {
        try {
            Utilities.setInitialData(response);
            let storeState = store.getState();
            sessionStorage.loginTime = new Date().getTime();
            if (storeState.user.needsPasswordUpdate <= constants.resetPasswordTime_UpperLimit && storeState.user.needsPasswordUpdate > constants.resetPasswordTime_LowerLimit) {
                history.push(constants.routes.expiringPassword);
            } else {
                let activeView = storeState.user.type === constants.userTypes.admin ? constants.activeView.admin : constants.activeView.clinician;
                persistor.flush().then(() => {
                    sessionStorage.setItem("sozoCurentView", activeView);
                    const sharedDataPath = sharedDataNavigation !== null ? constants.routes.sharedData : "";
                    location.href = Utilities.getLocationHref(activeView, storeState.facility, storeState.user.user_id, storeState.facilityLocation.region, sharedDataPath);
                });
            }

        } catch (errorObject) {
            Utilities.handleError(errorObject, "promiseResolveGetInitialData");
        }
    };

    const getInitialDataCall = async () => {
        let response = await Utilities.makeSingleCallAsync(getInitialData, "getInitialData", []);
        if (response.status === constants.responseStatus.success) {
            promiseResolveGetInitialData(response.data);
        } else {
            Utilities.handleFailResponse(response, [], "getInitialData");
        }
    };

    const promiseResolveSignIn = async (responseAuth) => {
        try {
            Utilities.setLoginData(responseAuth, inpt.inputEmail);
            props.saveDeviceKeys(responseAuth.data.deviceEncryptionKeys);
            getInitialDataCall();
        } catch (errorObject) {
            Utilities.handleError(errorObject, "promiseResolveSignIn");
        }
    };

    const resolveSignInResponse = (response, email) => {
        sessionStorage.removeItem("loginEmail");
        if (response.response === constants.response.success) {
            if (response.code === constants.mfaLogin) {
                sessionStorage.setItem("session", response.data.session);
                sessionStorage.setItem("userEmail", email);
                sharedDataNavigation !== null && sessionStorage.setItem("sharedDataNavigation", sharedDataNavigation);

                history.push(constants.routes.setMfaCode);
            } else {
                promiseResolveSignIn(response);
            }
        } else if (response.code === constants.passwordExpiredCode) {
            Utilities.customSweetAlertSelectionOperation({
                title: dictionary[localStorage.language].passwordExpiration,
                text: Utilities.getErrorMessage(response.code),
                type: "info"
            }, true, dictionary[localStorage.language].cancel, dictionary[localStorage.language].setNew, () => changePassword(email), () => {
            }, true);
        } else if (response.code === constants.banned) {
            Utilities.customSweetAlertOperation({
                    title: dictionary[localStorage.language].caution,
                    text: Utilities.getErrorMessage(response.code),
                    type: "warning"
                }, true, dictionary[localStorage.language].ok, dictionary[localStorage.language].setNow,
                () => {
                    history.push(constants.routes.sendEmail);
                },
                () => {
                    history.push(constants.routes.root);
                }, true);
        } else if (response.code === constants.mfaForce) {
            Utilities.setLoginData(response, inpt.inputEmail);
            history.push(constants.routes.mfaSetup);
        }
    };

    const handleLoginClick = async (event) => {
        event.preventDefault();
        let email = Utilities.removeWhitespacesToLowercase(inpt.inputEmail);
        let password = inpt.inputPassword;
        if (!props.loading) {
            if (email === "" || password === "") {
                Utilities.customSweetAlertSimple("", dictionary[localStorage.language].emptyFieldsExtraInfo, "error", false, "", dictionary[localStorage.language].ok);
            } else {
                const client = props.sozoDeviceCommEnabled ? "1" : "2";
                let response = await Utilities.makeSingleCallAsync(login, "login", [email, password, client]);
                if (response.status === constants.responseStatus.success) {
                    resolveSignInResponse(response.data, email);
                } else {
                    if (response.data.code === constants.clientWrongVersionCode) {
                        Utilities.setLoginData(response.data, inpt.inputEmail);
                        setShowUpdatePopup(true);
                    } else {
                        Utilities.handleFailResponse(response, [email, password, client], "login");
                    }
                }
            }
        }
    };

    const handleForgotPasswordClick = (event) => {
        event.preventDefault();
        setFlipClasses(constants.routes.sendEmail);
    };

    const handleActiveDirectoryClick = (event) => {
        event.preventDefault();
        sharedDataNavigation !== null && sessionStorage.setItem("sharedDataNavigation", sharedDataNavigation);
        setFlipClasses(constants.routes.setProviderName);
    };

    useEffect(() => {
        persistor.flush().then(() => persistor.purge());
        sessionStorage.clear();
        ApiStore.init({language});
    }, [language]);
    //endregion

    return (
        <LoginView email={inpt.inputEmail}
                   password={inpt.inputPassword}
                   language={language}
                   flipClasses={flipClasses}
                   showUpdatePopup={showUpdatePopup}
                   appVersion={version}
                   handleInputChange={handleInputChange}
                   handleLanguageChange={handleLanguageChange}
                   handleLoginClick={handleLoginClick}
                   handleForgotPasswordClick={handleForgotPasswordClick}
                   handleActiveDirectoryClick={handleActiveDirectoryClick}
                   handleCloseUpdatePopup={setShowUpdatePopup}
                   handleAnimationEnd={handleAnimationEnd}
        />
    );
};

Login.propTypes = {
    loading: PropTypes.bool,
    currentOS: PropTypes.object,
    applicationBuild: PropTypes.object,
    sozoDeviceCommEnabled: PropTypes.bool,
    saveDevicePort: PropTypes.func,
    saveDeviceKeys: PropTypes.func
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    currentOS: state.currentOS,
    applicationBuild: state.applicationBuild,
    sozoDeviceCommEnabled: state.sozoDeviceCommEnabled
});

const mapDispatchToProps = (dispatch) => ({
    saveDevicePort: (data) => dispatch(saveDevicePort(data)),
    saveDeviceKeys: (data) => dispatch(saveDeviceKeys(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);