import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.sharingLicense, action) => {
	let nextState = {...state};
	switch (action.type) {
		case actions.SAVE_SHARING_LICENSE :
			nextState = {...nextState, ...action.payload};
			break;
		default:
			nextState = state;
	}
	return nextState;
};