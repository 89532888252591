import PropTypes from "prop-types";
import LabelView from "./LabelView";

const Label = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	//endregion

	return <LabelView
		htmlFor={props.htmlFor}
		text={props.text}
		withRequiredTag={props.withRequiredTag}
		isRequired={props.isRequired}
		classes={props.classes}
		labelClasses={props.labelClasses}
	/>;

};

Label.propTypes = {
	htmlFor: PropTypes.string,
	text: PropTypes.string,
	classes:PropTypes.string,
	labelClasses:PropTypes.string,
	withRequiredTag: PropTypes.bool,
	isRequired: PropTypes.bool,
};

Label.defaultProps = {
	htmlFor: "",
	text: "",
	classes: "",
	labelClasses: "",
	withRequiredTag: false,
	isRequired: false,
};

export default Label;