import * as actions from "../actionTypes";

export const saveUser = (payload) => ({
	type: actions.SAVE_USER,
	payload
});

export const saveUserSettings = (payload) => ({
	type: actions.SAVE_USER_SETTINGS,
	payload
});

export const saveUserSession = (payload) => ({
	type: actions.SAVE_USER_SESSION,
	payload
});

export const saveUserToken = (payload) => ({
	type: actions.SAVE_USER_TOKEN,
	payload
});

export const saveUserPreUpdateHasNotice = (payload) => ({
	type: actions.SAVE_USER_PRE_UPDATE_HAS_NOTICE,
	payload
});

export const saveUserPostUpdateHasNotice = (payload) => ({
	type: actions.SAVE_USER_POST_UPDATE_HAS_NOTICE,
	payload
});

