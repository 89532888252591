import * as errorHandlers from "./errorHandlers";
import {setLoaderState} from "./userInterface";
import {callBasedResponse, getExtraMessageData, getPageForMessage} from "./business";
import Utilities from "../Utilities";
import DeviceApi from "../DeviceApi/deviceApi";
import { store } from "../../../../Redux/configureStore";
import {
	saveDeviceStatus,
	saveDeviceLicenseStatus,
	saveDeviceBluetoothName
} from "../../../../Redux/actions/Device/deviceActions";
import {savePairedDeviceList} from "../../../../Redux/actions/Device/pairedDeviceListActions";

/**
 * @static
 * @description Merges two array, and then sorts them
 */
export const mergeAndSortArray = (array1, array2, sort = "desc") => {
	let mergedArray = array1.concat(array2);
	if (sort === "asc") {
		mergedArray.sort((a, b) => {
			return parseInt(a.dateTime) - parseInt(b.dateTime);
		});
	} else {
		mergedArray.sort((a, b) => {
			return parseInt(b.dateTime) - parseInt(a.dateTime);
		});
	}
	return mergedArray;
};

/**
 * @static
 * @description Splits a string after the specified word and returns an array
 */
export const breakStringInTwoParts = (string, word) => {
	let stringArray = string.split(word);
	stringArray[0] += word;
	return stringArray;
};

/**
 * @static
 * @description Removes whites spaces and makes the string to lower case
 */
export const removeWhitespacesToLowercase = (value) => {
	return value.toLowerCase().replace(/\s/g, "");
};

/**
 * @static
 * @description Makes the first letterof the provided string upper case
 */
export const capitalizeFirstLetter = (value) => {
	return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * @static
 * @description Makes the first letterof the provided string upper case
 */
export const lowercaseFirstLetter = (value) => {
	return value.charAt(0).toLowerCase() + value.slice(1);
};

export const flattenObject = (ob) => {
	let toReturn = {};

	for (let i in ob) {
		if (!ob.hasOwnProperty(i)) {
			continue;
		}

		if (typeof ob[i] === "object" && ob[i] !== null) {
			let flatObject = flattenObject(ob[i]);
			for (let x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) {
					continue;
				}
				x = x.replace(/^_/, "");
				i = i.replace(/^_/, "");

				toReturn[i + "_" + x] = flatObject[x];
			}
		} else {
			i = i.replace(/^_/, "");
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};

/**
 * Makes a call to the server
 * @param {function} call - The call to be made, example DatabaseConnectionMapperDAO.togglePatientState
 * @param {string} logsName - The function name to be logged
 * @param {array} data - An array with the data required for the call,example [patientsId, "0"]
 * @param {function} resolveFunction - The function to be called if it is a success, example this.promiseResolveDeletePatient.
 * @param {array} resolveDataArray - The data required by the resolveFunction
 * @param {boolean} showLoader - Display loader or not. Default is true
 * @param {object | function} errorHelperFunction - A function to run if there is an error and we need extra handling out of the normal flow.
 * @example Utilities.makeSingleCall(togglePatientState,"deletePatient",[patientsId, "0"],promiseResolveDeletePatient,[])
 * @returns {Promise<any | never | never>}
 */
let activeCallsWithLoader = 0;
// export const makeSingleCall = (call, logsName, data, resolveFunction, resolveDataArray, showLoader = true, errorHelperFunction = null) => {
// 	activeCallsWithLoader = activeCallsWithLoader < 0 ? 0 : activeCallsWithLoader;
// 	if (showLoader) {
// 		++activeCallsWithLoader;
// 		setLoaderState(true);
// 	}
// 	return new Promise((resolve, reject) => {
// 		call(...data, resolve, reject);
// 	}).then((response) => {
// 		--activeCallsWithLoader;
// 		if (showLoader && activeCallsWithLoader <= 0) {
// 			activeCallsWithLoader = 0;
// 			setLoaderState(false);
// 		}
// 		if (callBasedResponse(logsName, response)) {
// 			resolveFunction(response, ...resolveDataArray);
// 		} else {
// 			let extraMessage = getExtraMessageData(logsName, response, ...data);
// 			let page = getPageForMessage(response.code, logsName);
// 			errorHandlers.handleFailFromServer(response, logsName, extraMessage, page);
// 			if (errorHelperFunction !== null) {
// 				errorHelperFunction();
// 			}
// 		}
// 	}).catch((errorObject) => {
// 		--activeCallsWithLoader;
// 		if (showLoader && activeCallsWithLoader <= 0) {
// 			activeCallsWithLoader = 0;
// 			setLoaderState(false);
// 		}
//
// 		if (logsName === "logout") {
// 			errorHandlers.handleErrorLogout(errorObject, logsName);
// 		} else {
// 			errorHandlers.handleError(errorObject, logsName);
// 		}
// 		if (errorHelperFunction !== null) {
// 			errorHelperFunction();
// 		}
//
// 	});
// };
export const handleFailResponse = (response, data, logsName, sweetAlertFunction = null, alertMessage = true) => {
	let errorMessage = "";
	if (response.status === constants.responseStatus.fail) {
		let extraMessage = getExtraMessageData(logsName, response.data, ...data);
		let page = getPageForMessage(response.data.code, logsName);
		errorMessage = errorHandlers.handleFailFromServer(response.data, logsName, extraMessage, page, sweetAlertFunction, alertMessage);
	} else {
		if (logsName === "logout") {
			errorHandlers.handleErrorLogout(response.data, logsName);
		} else {
			errorMessage = errorHandlers.handleError(response.data, logsName, sweetAlertFunction, alertMessage);
		}
	}
	return errorMessage;
};

export const makeSingleCallAsync = (call, logsName, data, showLoader = true) => {
	activeCallsWithLoader = activeCallsWithLoader < 0 ? 0 : activeCallsWithLoader;
	if (showLoader) {
		++activeCallsWithLoader;
		setLoaderState(true);
	}
	return new Promise((resolve, reject) => {
		call(...data, resolve, reject);
	}).then((response) => {
		if (callBasedResponse(logsName, response)) {
			return {
				data: response,
				status: constants.responseStatus.success
			};
		} else {
			return {
				data: response,
				status: constants.responseStatus.fail
			};
		}
	}).catch((errorObject) => {
		return {
			data: errorObject,
			status: constants.responseStatus.error
		};
	}).finally(() => {
		--activeCallsWithLoader;
		if (showLoader && activeCallsWithLoader <= 0) {
			activeCallsWithLoader = 0;
			setLoaderState(false);
		}
	});
};

/**TODO
 * Needs to change current implementation in order to use this function
 * bussiness logic must be removed from first then().
 * NOT_TESTED
 */
export const makeMultipleCalls = (callInfoArray, logsName, resolveFunction, resolveDataArray) => {
	let calls = [];
	callInfoArray.forEach((item) => {
		calls.push(new Promise((resolve, reject) => {
			item.call(...item.data, resolve, reject);
		}));
	});
	Promise.all(calls).then((response) => {
		let success = true;
		response.forEach((res, i) => {
			if (res.response === constants.response.success) {
				// return true
			} else {
				success = false;
				errorHandlers.handleFailFromServer(response, callInfoArray[i].logsCallName);
			}
		});
		return success;
	}).then((success, response) => {
		if (success) {
			resolveFunction(response, ...resolveDataArray);
		}
	}).catch((errorObject) => {
		errorHandlers.handleError(errorObject, logsName);
	});
};

const getDeepKeys = (object, exludeKeys) => {
	let keys = [];
	for (let key of Object.keys(object)) {
		if (!exludeKeys.includes(key) && typeof object[key] === "object" && !Array.isArray(object[key])) {
			let subKeys = getDeepKeys(object[key], exludeKeys);
			keys = keys.concat(subKeys.map((subKey) => key + "." + subKey));
			keys.push(key);
		}
	}
	return keys;
};
const getObj = (object, keyArr) => {
	let final = [];

	for (let i = 0; i < keyArr.length; i++) {
		let tempArray = keyArr[i].split(".");
		let name = tempArray[tempArray.length - 1];
		let tempOb = {...object};

		for (let j = 0; j < tempArray.length; j++) {
			tempOb = {...tempOb[tempArray[j]]};
		}
		final.push({[name]: tempOb});
	}
	return final;
};

export const extractObjects = (object, exludeKeys) => {
	let keyArr = getDeepKeys(object, exludeKeys);
	let xx = getObj(object, keyArr);
	return xx;
};

export const heightToSystem = (patient, isMetric) => {
	let height;
	if (isMetric) {
		if (patient.metricHeight === null) {
			height = "";
		} else {
			height = patient.metricHeight.toString();
		}
	} else {
		height = {
			feet: patient.imperialHeight === null ? "" : Utilities.imperialHeightFeetShow(patient.imperialHeight),
			inches: patient.imperialHeight === null ? "" : Utilities.imperialHeightInchShow(patient.imperialHeight),
		};
	}
	return height;
};

export const compareTwoObjects = (obj1, obj2) => {
	let diffs = [];
	for (const property in obj1) {
		if (obj1[property] !== obj2[property]) {
			diffs.push(property);
		}
	}
	return diffs;
};

export const getMaxMinSumAvg = (...arrays) => {
	let array = [].concat(...arrays);
	let max = Math.max(...array);
	let min = Math.min(...array);
	let sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
	let avg = sum / array.length;
	return {
		max,
		min,
		sum,
		avg
	};
};

export const getMinMax = (data, offset) => {
	let min, max;
	max = Math.max(...data) + offset;
	min = Math.min(...data) - offset;
	return [min, max];
};

// TODO - Remove after Node 18, since there will be a function to get the reverse index (Array.prototype.findLastIndex)
export const findLastIndex = (array, searchValue, searchKey = null) => {
	return array.slice().reverse().findIndex((item) => {
		let foundIndex;
		if (searchKey === null) {
			foundIndex = item === searchValue;
		} else {
			foundIndex = item[searchKey] === searchValue;
		}
		return foundIndex;
	});
};

const getLicensedBluetoothDevices = async (selectedDevice, closeConnection, facilityData) => {
	const bluetoothDevices = await new DeviceApi(selectedDevice).getSOZOBluetoothDevices(closeConnection);
	const licensedBluetoothDevices = bluetoothDevices.map((bluetoothDevice) => {
		const licenseStatus = Utilities.getDeviceLicenseStatus(bluetoothDevice.name, facilityData.licensedDevicesList, facilityData.acceptAnyDevice);
		return {...bluetoothDevice, licenseStatus };
	});

	store.dispatch(savePairedDeviceList(licensedBluetoothDevices));

	return licensedBluetoothDevices;
};

export const pairWithLastPairedDevice = async (selectedDevice, facilityData, alertMessage = true) => {
	let continueWithNextCommand = false;

	try {
		const lastPairedDevice = localStorage.getItem("lastPairedDevice");

		if (lastPairedDevice !== null) {
			setLoaderState(true);

			const licensedBluetoothDevices = await getLicensedBluetoothDevices(selectedDevice, false, facilityData);

			const lastPairedDevicePayload = JSON.parse(lastPairedDevice);
			const lastPairedDeviceExists = licensedBluetoothDevices.map((device) => device.name).includes(lastPairedDevicePayload.bluetoothName);

			if (lastPairedDeviceExists) {
				const lastPairedLicensedBluetoothDevice = licensedBluetoothDevices.find((device) => device.name === lastPairedDevicePayload.bluetoothName);
				if (selectedDevice.currentOS.isiOS) {
					await new DeviceApi(selectedDevice).setPairing({name: lastPairedDevicePayload.bluetoothName}, true);
				} else {
					await new DeviceApi(selectedDevice).setSOZOBluetoothDevice({name: lastPairedDevicePayload.bluetoothName}, true);
				}

				store.dispatch(saveDeviceStatus({isSOZOProDevice: lastPairedDevicePayload.isSOZOProDevice}));
				store.dispatch(saveDeviceBluetoothName(lastPairedDevicePayload.bluetoothName));
				store.dispatch(saveDeviceLicenseStatus(lastPairedLicensedBluetoothDevice.licenseStatus));

				continueWithNextCommand = true;
			} else {
				if (alertMessage) {
					Utilities.customSweetAlertSimple(dictionary[localStorage.language].error, dictionary[localStorage.language].device_commException, "error", false, "", dictionary[localStorage.language].ok);
				}
				continueWithNextCommand = false;
			}
		}
	} catch (error) {
		Utilities.handleErrorFromDevice(error, "Pair With Last Selected Device", selectedDevice.currentOS, alertMessage);
		continueWithNextCommand = false;
	} finally {
		setLoaderState(false);
	}

	return continueWithNextCommand;
};

export const checkArraysEqual = (array1, array2) => {
	let arraysEqual = false;

	if (array1 instanceof Array && array2 instanceof Array && array1.length === array2.length) {
		arraysEqual = array1.every((value, i) => value === array2[i]);
	}

	return arraysEqual;
};

export const checkArrayHasDuplicates = (array) => {
	let valuesSoFar = {};
	let hasDuplicates = false;

	for (let i = 0; i < array.length; ++i) {
		let value = array[i];
		if (value in valuesSoFar) {
			hasDuplicates = true;
			break;
		}
		valuesSoFar[value] = true;
	}

	return hasDuplicates;
};