import PropTypes from "prop-types";

const LabelView = (props) => {

	//region VARIABLES
	//endregion

	//region FUNCTIONS
	const getRequiredTag = (isRequired) => {
		let classname = isRequired ?
			"labels__requiredTag--required" :
			"labels__requiredTag--optional";
		return <span className={"labels__requiredTag " + classname}>
				{isRequired ? dictionary[localStorage.language].required : dictionary[localStorage.language].optional}
			</span>;
	};
	//endregion

	return <div className={`labels flexRowSpaceBetwnCenter ${props.classes}`}>
		<label htmlFor={props.htmlFor} className={`labels__label ${props.labelClasses}`}>{props.text}</label>
		{props.withRequiredTag && getRequiredTag(props.isRequired)}
	</div>;

};

LabelView.propTypes = {
	htmlFor: PropTypes.string,
	text: PropTypes.string,
	classes: PropTypes.string,
	labelClasses: PropTypes.string,
	withRequiredTag: PropTypes.bool,
	isRequired: PropTypes.bool,
};

export default LabelView;