window.constants = {
	invalidIndex: -1,
	KB: 1024,
	halfKB: 512,
	mrnMaxLength: 100,
	emailMaxLength: 255,
	postalCodeMaxLength: 45,
	oneFootInInches: 12,
	oneWeek: 7,
	twoWeeks: 14,
	threeWeeks: 21,
	maxFrxIndex: 256,
	minWeightMetric: 10,
	maxWeightMetric: 340,
	minWeightImperial: 22,
	maxWeightImperial: 749.6,
	minHeightMetric: 38,
	maxHeightMetric: 251.5,
	minHeightImperial: 15,
	maxHeightImperial: 99,
	oneHourInSecs: 3600,
	fiftyFiveMinutesInSecs: 3300,
	oneSecInMillis: 1000,
	logoutPopupTimeBeforeExpiration: 60000,
	halfSecInMillis: 500,
	oneHourWithCountdown: 3611,
	oneMinInSecs: 60,
	lsbMask: 0xFF,
	minHours: 0,
	maxHours: 23,
	minMinutes: 0,
	maxMinutes: 59,
	minSecs: 0,
	maxSecs: 59,
	minMillis: 0,
	maxMillis: 999,
	HTTP200: 200,
	HTTP304: 304,
	HTTP401: 401,
	HTTP403: 403,
	chartPieLabelDistance: -23,
	hundredPerCent: 100,
	timeoutLogout: 10000,
	timeout: 29000,
	timeoutBig: 29000,
	timeoutXL: 29000,
	lDexRangeLowerBound: -10,
	lDexRangeUpperBound: 10,
	hfDexRangeUpperBound: 60,
	hfDexRangeYellowUpperBound: 51.0,
	hfDexRangeGreenUpperBound: 46.4,
	hfDexRangeGreenLowerBound: 41.5,
	hfDexRangeLowerBound: 38,
	passwordExpiredCode: 50136,
	banned: 50103,
	activePatientMrnExists: 50201,
	inactivePatientMrnExists: 50202,
	createImportExistingMeasurementsInDestination: "50328",
	activePatientDataCombinationExists: 50203,
	inactivePatientDataCombinationExists: 50204,
	emaiExistOnInactiveUser: 50195,
	emailExistInOtherFacility: 50192,
	emailExistOnPatient: 50193,
	emailExistOnActiveUser: 50194,
	lambdaCheckUserEmail: 50191,
	cognitoUsernameExistsException: 10128,
	mfaLogin: 10184,
	mfaForce: 90001,
	clientWrongVersionCode: 50134,
	resetPasswordTime_UpperLimit: 5,
	resetPasswordTime_LowerLimit: 0,
	forbidden403: "User is not authorized to access this resource with an explicit deny",
	baselineNames: {
		ldex: "baseline_ldex_id",
		bilateralLeft: "baseline_bilateral_left_ldex_id",
		bilateralRight: "baseline_bilateral_right_ldex_id",
		bodyComp: "baseline_body_composition_id",
		ffm: "baseline_FFM_id",
		esrdMetric: "baselineRenalMetricECF",
		esrdImperial: "baselineRenalImperialECF",
		hfDex: "baseline_HFDex_id",
		leftArm: "baseline_left_arm_id",
		rightArm: "baseline_right_arm_id",
		leftLeg: "baseline_left_leg_id",
		rightLeg: "baseline_right_leg_id",
	},
	resultQuality: {
		high: 0,
		medium: 1,
		low: 2
	},
	popupChainStates: {
		adminFirstLoginPre: "adminFirstLoginPre",
		adminPreUpdateChanges: "adminPreUpdateChanges",
		adminFirstLoginPost: "adminFirstLoginPost",
		userFirstLoginChanges: "userFirstLoginChanges",
		adminUpdateAccept: "adminUpdateAccept",
		clinicianFirstLoginPre: "clinicianFirstLoginPre",
		clinicianFirstLoginPost: "clinicianFirstLoginPost",
		releaseNotes: "releaseNotes",
		applicationPreUpdate: "applicationPreUpdate",
		applicationPreUpdateChanges: "applicationPreUpdateChanges"
	},
	IfuLink: "https://www.impedimed.com/support/ifu-documents",
	impedimedLink: "https://www.impedimed.com/",
	invalidVersion: 50220,
	invalidCall: 50401,
	tableListTypes: {
		patientList: "patientList",
		clinicianList: "clinicianList",
		restorePatientList: "restorePatientList",
		restoreClinicianList: "restoreClinicianList",
		mergePatientsSource: "mergePatientsSource",
		mergePatientsDestination: "mergePatientsDestination",
		groupPatientsList: "groupPatientsList",
		patientCompactList: "patientCompactList",
		patientImportCompactList: "patientImportCompactList",
		measurementList: "measurementList",
		groupsList: "groupsList",
		tagsList: "tagsList",
		notesList: "notesList",
		tagListForm:"tagListForm",
		tagListFormNoTags:"tagListFormNoTags",
		tagListMeasurementFilter:"tagListMeasurementFilter",
		historyFilter:"historyFilter",
		patientGroupFilter:"patientGroupFilter",
		mergeNoPatients:"mergeNoPatients",
		resultsList: "resultsList",
		calibrationList: "calibrationList",
		calibrationListSub: "calibrationListSub",
		deviceList: "deviceList",
		sharingList: "sharingList",
		sharingReceiverFilter: "sharingReceiverFilter",
		sharedDataMeasurementList: "sharedDataMeasurementList",
	},
	invalidSeach: 40318,
	groupIsDeleted: 50305,
	patientAlreadyInCurrentGroup: 50307,
	patientNotInCurrentGroup: 50308,
	patientIsDeleted: 50309,
	noteLimitsReached: 50271,
	resultsTabTypes: {
		ldex: "Ldex",
		hfdex: "Hfdex",
		renal: "Renal",
		fluid: "Fluid",
		tissue: "Tissue",
		colePlots: "ColePlots",
	},
	dialysisStatus: {
		pre: 0,
		post: 1,
		other: 2,
	},
	resultViewMode: {
		results: "Results",
		history: "History"
	},
	escapeKey: "Escape",
	riskSide: {
		right: 1,
		left: 2
	},
	bodyElement: {
		arm: 1,
		leg: 2
	},
	allDateStyleTime: "d mmm yyyy, HH:MM",
	allDateStyleTimeMoment: "DD MMM YYYY HH:mm",
	allDateStyleTimeMomentComma: "DD MMM YYYY, HH:mm",
	allDateStyle: "d mmm yyyy",
	allDateStyleMoment: "D MMM YYYY",
	styleForDatePicker: "d M yy",
	dateTimeNoteListStyle: "MMM D, HH:mm",
	dateTimeNoteDetailsStyle: "MMM D, YYYY [at] hh:mm A",
	actionBarType: {
		profile: "profile",
		help: "help",
		info: "info",
		settings: "settings",
	},
	response: {
		success: "5UCC3551mp3d1m3d",
		timeout: "T1M30UT1MP3DIM3D",
		expireToken: "T0k3N3xP1R3d"
	},
	activeView: {
		clinician: "Clinician",
		admin: "ClinicITAdministrator"
	},
	toastSettings: {
		success: {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			closeButton: false,
		}
	},
	merge: {
		source: {
			name: "source"
		},
		destination: {
			name: "destination"
		},
	},
	import: {
		shared: {
			name: "source"
		},
		destination: {
			name: "destination"
		},
	},
	editGroupMembers: {
		outOfGroup: {
			name: "outOfGroup",
		},
		inGroup: {
			name: "inGroup",
		},
	},
	groupPatientList: "GroupPatientList",
	patientGroupsList: "PatientGroupsList",
	routes: {
		root: "/",
		//region LOGIN
		sendEmail: "/SendEmail",
		setMfaCode: "/SetMfaCode",
		setProviderName: "/SetProviderName",
		changePassword: "/ChangePassword",
		expiringPassword: "/ExpiringPassword",
		newPassword: "/NewPassword",
		intermediateLogin: "/IntermediateLogin",
		mfaSetup: "/MfaSetup",
		mfaAuthenticate: "/MfaAuthenticate",
		mfaActivation: "/MfaActivation",
		unauthorized: "/Unauthorized",
		//endregion
		//region CLINICIAN
		restorePatients: "/RestorePatients",
		patientDashboard: "/PatientDashboard",
		results: "/Results",
		patientGroups: "/PatientGroups",
		groupPatientList: "/GroupPatientList",
		editGroupMembers: "/EditGroupMembers",
		mergePatients: "/MergePatients",
		importData: "/ImportData",
		analytics: "/Analytics",
		sharedData: "/Sharing",
		//endregion
		//region ADMIN
		groups: "/Groups",
		tags: "/Tags",
		restoreUsers: "/RestoreUsers",
		devices: "/Devices",
		//endregion
	},
	targetUser: {
		self: "self",
		other: "other"
	},
	userTypes: {
		admin: "AdminTypeId",
		clinician: "ClinicianTypeId",
		dual: "DualityTypeId",
	},
	graphType: {
		hfdexResults: "hfdexResults",
		hfdexHistory: "hfdexHistory",
		hfDexTriple: "hfDexTriple",
		hfDexTripleHistory: "hfDexTripleHistory",
		esrdTripleHistory: "esrdTripleHistory",
	},
	graphRange: {
		static: "static",
		dynamic: "dynamic"
	},
	resultMetricKey: "sozoResultMetric",
	resultImperialKey: "sozoResultImperial",
	baselineDatetimeTags: {
		bodyComposition: "baselineBodyComposition",
		rightArm: "baselineRightArm",
		rightLeg: "baselineRightLeg",
		leftArm: "baselineLeftArm",
		leftLeg: "baselineLeftLeg",
		ldex: "baselineLdex",
		bilateralRight: "baselineBilateralRightLdex",
		bilateralLeft: "baselineBilateralLeftLdex",
		hfDex: "baselineHFDex",
		esrdMetric: "baselineRenalMetricECF",
		esrdImperial: "baselineRenalImperial",
	},
	assessmentTypes: {
		ldex: "lDex",
		hfdex: "hfDex",
		hyDex: "hyDex",
		esrd: "esrd",
		bodyComposition: "bodyComposition",
		segmental: "segmental",
		rightArm: "rightArm",
		leftArm: "leftArm",
		rightLeg: "rightLeg",
		leftLeg: "leftLeg",
		colePlots: "colePlots"
	},
	bodyComposition: {
		hydex: "hydex",
		fm: "fm",
		bmi: "bmi",
	},
	windowCollapseSize: 1406,
	groupNameLength: 100,
	deviceNameLength: 48,
	tagNameLength: 48,
	tagListConfig: {
		maxTitleDescCharsToOverflow: 15,
		maxCharsForMeasurementList: 15,
		measurementList: {
			maxTotalChars: 75,
			maxTotalCharsSmall: 55,
		},
		result: {
			maxTotalChars: 300,
			maxTotalCharsSmall: 300,
		}
	},
	logoutCodes: {
		greenPopup: [50220,
			50242,
			50279,
			50137,
			50401,
			50147,
			10180,
			10182],
		swalPopup: [50265,50266,50267,90002]
	},
	firmwareUpdateCodes: {
		firmwareUpToDate: 60215,
		firmwareAvailable: 60107
	},
	appUpdateCodes: {
		appUpdateUpToDate: 50213,
		appUpdateAvailable: 60107
	},
	sharingDataCodes: {
		sharingCreate: 50525,
		sharingDelete: 50523,
		sharingReceiversUPDATE: 50533,
		sharingImportCREATE: 50529,
		sharingImportPatientCREATE: 50530
	},
	searchSaveFor: {
		activePatients: "activePatientList"
	},
	responseStatus:{
		success: "success",
		fail: "fail",
		error: "error"
	},
	hasBilateralUpdate: {
		impacted: "IMPACTED",
		updated: "UPDATED",
		unset: "UNSET"
	},
	sharingType: {
		incoming: "INCOMING",
		outgoing: "OUTGOING"
	},
	sharingStatus: {
		imported: "IMPORTED",
		recalled: "RECALLED",
		new: "NEW",
		old: "OLD"
	},
	patientType: {
		shared: "SHARED",
		sozo: "SOZO"
	},
	moreDetailsType: {
		receivers: "receivers",
		sharingStatus: "sharingStatus",
		patient: "patient"
	},
	newNotesNotify: {
		hasNewNotes: "1"
	},
	inputTypeShareModal: {
		clinic: "clinic",
		recipient: "recipient"
	},
	sharedDataActions: {
		create: "create",
		update: "update"
	},
	deviceLicenseStatus: {
		active: "ACTIVE",
		inactive: "INACTIVE",
		noLicense: "NO_LICENSE",
	},
	measurement: {
		measurementWeight: "measurementWeight",
		measurementInfo: "measurementInfo",
		measurementTutorial: "measurementTutorial",
		measurementTutorialPage2: "measurementTutorialPage2",
		measurementProcess: "measurementProcess",
		measurementDialysisStatus: "measurementDialysisStatus",
		measurementAssessment: "measurementAssessment",
		measurementError: "measurementError"
	},
	takeMeasurementWeightType: {
		grossWeight: "grossWeight",
		netWeight: "netWeight",
		preTare: "preTare"
	},
	sozoProMinValueKg: 22,
	sozoProMaxValueKg: 340,
	preatareMinValue: 0.0,
	pretareMaxValueKg: 10.0,
	sozoMinValueKg: 10,
	sozoMaxValueKg: 340,
	sozoProMinValueLbs: 48.5,
	sozoProMaxValueLbs: 749.6,
	pretareMaxValueLbs: 22.0,
	sozoMinValueLbs: 22.0,
	sozoMaxValueLbs: 749.6,
	sozoPromptToSitValueKg: 170,
	sozoPromptToSitValueLbs: 375,
	sozoProPromptToSitValueKg: 220,
	sozoProPromptToSitValueLbs: 485,
	minimumSupportedResolution: 1024,
	responsiveResolution: 1280,
	sozoProDeviceNamePrefix: "SOZOPRO",
	frequencyList: [3000, 3069, 3140, 3212, 3286, 3362, 3440, 3519, 3600, 3683, 3768, 3855, 3943, 4034, 4127, 4221,
        4320, 4419, 4521, 4625, 4730, 4840, 4952, 5066, 5183, 5302, 5424, 5549, 5677, 5808, 5942, 6079, 6219, 6362,
        6509, 6659, 6812, 6970, 7130, 7294, 7463, 7634, 7809, 7990, 8175, 8363, 8554, 8753, 8955, 9160, 9372, 9588,
        9809, 10035, 10264, 10501, 10744, 10992, 11245, 11504, 11768, 12041, 12319, 12602, 12891, 13188, 13491, 13803,
        14119, 14446, 14782, 15123, 15468, 15823, 16188, 16563, 16942, 17339, 17738, 18141, 18561, 18993, 19427, 19871,
        20336, 20801, 21277, 21775, 22272, 22779, 23310, 23852, 24390, 24953, 25526, 26127, 26720, 27341, 27972, 28612,
        29283, 29940, 30628, 31348, 32077, 32814, 33557, 34335, 35119, 35939, 36765, 37594, 38462, 39370, 40282, 41195,
        42150, 43103, 44101, 45147, 46189, 47226, 48309, 49444, 50569, 51746, 52910, 54127, 55402, 56657, 57971, 59347,
        60698, 62112, 63492, 64935, 66445, 68027, 69565, 71174, 72860, 74488, 76190, 77973, 79840, 81633, 83507, 85470,
        87336, 89485, 91533, 93458, 95694, 97800, 100251, 102564, 104712, 107239, 109589, 112360, 114943, 117647, 120120,
        123077, 125786, 128617, 131579, 134680, 137931, 140845, 144404, 147601, 150943, 154440, 158103, 161943, 165289,
        169492, 173160, 176991, 180995, 185185, 189573, 194175, 198020, 203046, 207254, 212766, 217391, 222222, 227273,
        232558, 238095, 243902, 248447, 254777, 261438, 266667, 272109, 279720, 285714, 291971, 298507, 305344, 312500,
        320000, 327869, 336134, 341880, 350877, 357143, 366972, 373832, 384615, 392157, 404040, 412371, 421053, 430108,
        439560, 449438, 459770, 470588, 481928, 493827, 506329, 519481, 526316, 540541, 555556, 563380, 579710, 588235,
        606061, 615385, 634921, 645161, 666667, 677966, 689655, 714286, 727273, 740741, 754717, 784314, 800000, 816327,
        833333, 851064, 869565, 888889, 909091, 930233, 952381, 975610, 1000000],
	resultNull: {
		"id": null,
		"dateTime": null,
		"age": null,
		"dialysisStatus": null,
		"wholeRightFlag": null,
		"wholeLeftFlag": null,
		"rightArmFlag": null,
		"rightLegFlag": null,
		"leftArmFlag": null,
		"leftLegFlag": null,
		"lDex": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"lDexBaseline": null
		},
		"leftArmRzero": null,
		"leftLegRzero": null,
		"rightArmRzero": null,
		"rightLegRzero": null,
		"height": {
			"value": null,
			"changeFromPrevious": null
		},
		"weight": {
			"value": null,
			"changeFromPrevious": null
		},
		"weightMetric": {
			"value": null,
			"changeFromPrevious": null
		},
		"weightPreTare": {
			"value": null,
			"changeFromPrevious": null
		},
		"bilateralLeftLDex": {
			"value": null,
			"changeFromPrevious": null,
			"bilateralLeftLDexBaseline": null
		},
		"bilateralRightLDex": {
			"value": null,
			"changeFromPrevious": null,
			"bilateralRightLDexBaseline": null
		},
		"wholeHFDex": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null
		},
		"wholeTBW": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeTBWMetric": {
			"value": null,
			"changeFromPrevious": null
		},
		"wholeECF": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"esrdBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeICF": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeHyDex": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null
		},
		"wholeSMM": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeFFM": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeFM": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholePnM": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeBMI": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null
		},
		"wholePhi50": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null
		},
		"wholeATM": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeECM": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"highQBased": null,
				"hfDexBaseline": null,
				"bcBaseline": null
			}
		},
		"wholeBMR": {
			"value": null,
			"changeFromPrevious": null,
			"highQBased": null,
			"hfDexBaseline": null,
			"bcBaseline": null
		},
		"leftArmTBW": {
			"value": null,
			"changeFromPrevious": null,
			"leftArmBaseline": null,
			"percent": null
		},
		"leftArmECF": {
			"value": null,
			"changeFromPrevious": null,
			"leftArmBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"leftArmBaseline": null
			}
		},
		"leftArmICF": {
			"value": null,
			"changeFromPrevious": null,
			"leftArmBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"leftArmBaseline": null
			}
		},
		"leftArmSMM": {
			"value": null,
			"changeFromPrevious": null,
			"leftArmBaseline": null,
			"percent": null
		},
		"leftArmFFM": {
			"value": null,
			"changeFromPrevious": null,
			"leftArmBaseline": null,
			"percent": null
		},
		"leftArmPhi50": {
			"value": null,
			"changeFromPrevious": null,
			"leftArmBaseline": null,
			"percent": null
		},
		"leftLegTBW": {
			"value": null,
			"changeFromPrevious": null,
			"leftLegBaseline": null,
			"percent": null
		},
		"leftLegECF": {
			"value": null,
			"changeFromPrevious": null,
			"leftLegBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"leftLegBaseline": null
			}
		},
		"leftLegICF": {
			"value": null,
			"changeFromPrevious": null,
			"leftLegBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"leftLegBaseline": null
			}
		},
		"leftLegSMM": {
			"value": null,
			"changeFromPrevious": null,
			"leftLegBaseline": null,
			"percent": null
		},
		"leftLegFFM": {
			"value": null,
			"changeFromPrevious": null,
			"leftLegBaseline": null,
			"percent": null
		},
		"leftLegPhi50": {
			"value": null,
			"changeFromPrevious": null,
			"leftLegBaseline": null,
			"percent": null
		},
		"rightArmTBW": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightArmECF": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"rightArmBaseline": null
			}
		},
		"rightArmICF": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"rightArmBaseline": null
			}
		},
		"rightArmSMM": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightArmFFM": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightArmPhi50": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightLegTBW": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightLegECF": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"rightLegBaseline": null
			}
		},
		"rightLegICF": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": {
				"value": null,
				"changeFromPrevious": null,
				"rightLegBaseline": null
			}
		},
		"rightLegSMM": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightLegFFM": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"rightLegPhi50": {
			"value": null,
			"changeFromPrevious": null,
			"rightArmBaseline": null,
			"percent": null
		},
		"wholeRightRZero": null,
		"wholeRightRInf": null,
		"wholeRightRi": null,
		"wholeRightSEE": null,
		"wholeRightR50": null,
		"wholeRightXc50": null,
		"wholeLeftRZero": null,
		"wholeLeftRInf": null,
		"wholeLeftRi": null,
		"wholeLeftSEE": null,
		"wholeLeftR50": null,
		"wholeLeftXc50": null,
		"leftArmRInf": null,
		"leftArmRi": null,
		"leftArmSEE": null,
		"leftLegRInf": null,
		"leftLegRi": null,
		"leftLegSEE": null,
		"rightArmRInf": null,
		"rightArmRi": null,
		"rightArmSEE": null,
		"rightLegRInf": null,
		"rightLegRi": null,
		"rightLegSEE": null
	}
};