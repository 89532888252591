import DeviceMessage from "../Message/deviceMessage";
import MessageCodec from "../Message/deviceMessageCodec";
import MessageParser from "../Message/deviceMessageParser";
import Utils from "../utils";

class DeviceAndroidConnector {
    constructor (device) {
        if (!DeviceAndroidConnector._instance) {
            this.setDefaultAllValues(device);
            this.devicePort = device.port;
            this.devicePort.onmessage = (rawBTMobileResponse) => {
                try {
                    const parsedData = JSON.parse(rawBTMobileResponse.data);
                    switch (parsedData.responseFunctionToExecute) {
                    case "onDevicesRetrievedFromAndroid":
                        this.setBluetoothDevicesFromAndroid(parsedData.result);
                        break;
                    case "onDeviceSetFromAndroid":
                        this.setDeviceSelectedFromAndroid(parsedData.result);
                        break;
                    case "onDeviceConnectedFromAndroid":
                        this.setDeviceConnectedFromAndroid(parsedData.result);
                        break;
                    case "onResponseReceivedFromAndroid":
                        this.setResponseReceivedFromAndroid(parsedData.result);
                        break;
                    case "onDeviceDisconnectedFromAndroid":
                        this.setDeviceDisconnectedFromAndroid(parsedData.result);
                        break;
                    case "onBluetoothSettingOpenedFromAndroid":
                        this.setBluetoothSettingOpenedFromAndroid(parsedData.result);
                        break;
                    case "onErrorReceivedFromAndroid":
                        this.setErrorReceivedFromAndroid(parsedData.result);
                        break;
                    case "onSuccessReceivedFromAndroid":
                        this.setSuccessReceivedFromAndroid(parsedData.result);
                        break;
                    case "onApplicationBuildConfigurationReceivedFromAndroid":
                        this.setApplicationBuildConfiguration(parsedData.result);
                        break;
                    default:
                        break;
                    }
                } catch (error) {
                    this.rejectCurrentTask(error);
                }
            };
            DeviceAndroidConnector._instance = this;
        }
        return DeviceAndroidConnector._instance;
    }

    // Private Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    _prepareData = (rawData) => {
        let preparedData = "[";
        for (let position = 0; position < rawData.length; position++) {
            if (position === rawData.length - 1) {
                preparedData += rawData[position];
            } else {
                preparedData += rawData[position] + ",";
            }
        }
        preparedData = preparedData + "]";
        return preparedData;
    };

    // Public Methods ————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
    setDefaultAllValues = (device) => {
        this.messageParser = new MessageParser();
        this.messageCodec = new MessageCodec();
        this.utils = new Utils(device.keys);
        this.deviceMessage = new DeviceMessage();
        this.bluetoothDevice = null;
        this.isSOZOProDevice = false;
        this.isDeviceConnected = false;
        this.bluetoothDeviceList = null;
    };

    setDeviceSelectedFromAndroid = (parsedDataResult) => {
        this.bluetoothDevice = this.utils.getBTMobileResponse(parsedDataResult);
        this.isSOZOProDevice = this.bluetoothDevice.name.startsWith("SOZOPRO");
        this.resolveCurrentTask();
    };

    setDeviceConnectedFromAndroid = (parsedDataResult) => {
        this.isDeviceConnected = this.utils.getBTMobileResponse(parsedDataResult);
        const deviceConnection = {
            isDeviceConnected: this.isDeviceConnected
        };
        this.resolveCurrentTask(deviceConnection);
    };

    setResponseReceivedFromAndroid = (parsedDataResult) => {
        const response = this.utils.getBTMobileResponse(parsedDataResult);
        const messagePackBuffer = this.messageParser.getMobileMessagePackBuffer(response);
        this.resolveCurrentTask(this.messageCodec.getDecoded(messagePackBuffer));
    };

    setDeviceDisconnectedFromAndroid = (parsedData) => {
        this.isDeviceConnected = false;
        this.resolveCurrentTask(parsedData);
    };

    setBluetoothDevicesFromAndroid = (parsedDataResult) => {
        this.bluetoothDeviceList = this.utils.getBTMobileResponse(parsedDataResult);
        this.resolveCurrentTask(this.bluetoothDeviceList);
    };

    setBluetoothSettingOpenedFromAndroid = (parsedDataResult) => {
        this.resolveCurrentTask(parsedDataResult);
    };
    
    setErrorReceivedFromAndroid = (parsedDataResult) => {
        if (parsedDataResult.data === "10027") {
            this.isDeviceConnected = false;
        }
        this.rejectCurrentTask(parsedDataResult.data);
    };

    setSuccessReceivedFromAndroid = (parsedDataResult) => {
        const response = this.utils.getBTMobileResponse(parsedDataResult);
        this.resolveCurrentTask(response);
    };

    setApplicationBuildConfiguration = (parsedDataResult) => {
        const response = this.utils.getBTMobileResponse(parsedDataResult);
        this.resolveCurrentTask(response);
    };

    getBluetoothDevices = async () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;

            const callFunction = {
                name: "getBluetoothDevices",
                data: "sozo"
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    setBluetoothDevice = async (bluetoothDevice) => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
            if (bluetoothDevice) {
                const callFunction = {
                    name: "setBluetoothDevice",
                    data: bluetoothDevice.name
                };
                this.devicePort.postMessage(JSON.stringify(callFunction));
            } else if (this.bluetoothDevice === null) {
                this.rejectCurrentTask("No bluetooth device selected");
            } else {
                this.resolveCurrentTask();
            }
        });
    };

    setConnection = async () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;

            if (this.isDeviceConnected) {
                const deviceConnection = {
                    isDeviceConnected: true
                };
                this.resolveCurrentTask(deviceConnection);
                return;
            }
            const callFunction = {
                name: "connect",
                data: this.bluetoothDevice.name
            };
            if (this.isSOZOProDevice) {
                this.devicePort.postMessage(JSON.stringify(callFunction));
            } else {
                this.utils.setSleep(2000).then(() => {
                    this.devicePort.postMessage(JSON.stringify(callFunction));
                });
            }
        });
    };

    setDisconnection = () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;

            if (!this.isDeviceConnected) {
                this.resolveCurrentTask();
                return;
            }

            const callFunction = {
                name: "disconnect",
                data: this.bluetoothDevice.name
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    setMessage = async (rawData) => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
            let prepareDataToSend = this._prepareData(rawData);
            const callFunction = {
                name: "sendMessage",
                data: prepareDataToSend
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    openBluetoothSettings = () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
            const callFunction = {
                name: "openBluetoothSettings",
                data: "sozo"
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    setApplicationUpdateBytes = (rawData) => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
            let prepareDataToSend = this._prepareData(rawData);
            const callFunction = {
                name: "setApplicationUpdateBytes",
                data: prepareDataToSend
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    setApplicationUpdateFinish = () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
        
            const callFunction = {
                name: "setApplicationUpdateFinish",
                data: "completed"
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    getApplicationBuildConfiguration = () => {
        return new Promise((resolve, reject) => {
            this.resolveCurrentTask = resolve;
            this.rejectCurrentTask = reject;
        
            const callFunction = {
                name: "getApplicationBuildConfiguration",
                data: "buildConfiguration"
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        });
    };

    resolveTask = () => {
        if (this.resolveCurrentTask !== null) {
            this.resolveCurrentTask();
            this.resolveCurrentTask = null;
            this.isDeviceConnected = false;
            const callFunction = {
                name: "forceDisconnect",
                data: "sozo"
            };
            this.devicePort.postMessage(JSON.stringify(callFunction));
        }
    };

}

export default DeviceAndroidConnector;
