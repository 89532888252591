import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import MfaActivationView from "./MfaActivationView";
import QRCode from "qrcode";
import Utilities from "../../Libraries/Custom/Utilities";
import {enableMFA} from "../../../API/userApi";
import {useHistory} from "react-router";

const MfaActivation = (props) => {

	//region VARIABLES
	let secretKey = sessionStorage.getItem("secretKey");
	let accessToken = sessionStorage.getItem("accessToken");
	let [qrCodeData, setQrCodeData] = useState("");
	let [verificationCode, setVerificationCode] = useState("");
	const history = useHistory();
	//endregion

	//region FUNCTIONS
	const handleInputChange = (value) => {
		setVerificationCode(value);
	};

	const promiseResolveEnableMfa = (response) => {
		props.showSuccessLogoutPopup(response);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!props.loading) {
			let totp = verificationCode;

			if (Utilities.isTotpInvalid(totp)) {
				Utilities.customSweetAlertSimple("", dictionary[localStorage.language].invalidVerificationCode, "error", false, "", dictionary[localStorage.language].ok);
			} else {
				let response = await Utilities.makeSingleCallAsync(enableMFA, "enableMFA", [props.user.userEmail, totp, accessToken]);
				if (response.status === constants.responseStatus.success) {
					promiseResolveEnableMfa(response.data);
				} else {
					Utilities.handleFailResponse(response, [props.user.userEmail, totp, accessToken], "enableMFA");
				}
			}
		}
	};

	const handleCancel = (event) => {
		event.preventDefault();
		if (props.isForce) {
			history.push(constants.routes.root);
		} else {
			props.handleCancel();
		}
	};

	useEffect(() => {
		QRCode.toDataURL("otpauth://totp/MySOZO:" + props.user.userEmail + "?secret=" + secretKey + "&issuer=MySOZO",{margin:0})
			.then((url) => setQrCodeData(url));
	}, []);
	//endregion

	return <MfaActivationView
		secretKey={secretKey}
		qrCodeData={qrCodeData}
		verificationCode={verificationCode}
		handleInputChange={handleInputChange}
		handleCancel={handleCancel}
		handleSubmit={handleSubmit}
		{...props}
	/>;

};

MfaActivation.propTypes = {
	user: PropTypes.object,
	isForce: PropTypes.bool,
	loading: PropTypes.bool,
	handleCancel: PropTypes.func,
	showSuccessLogoutPopup: PropTypes.func,
};

MfaActivation.defaultProps = {
	isForce: false
};

const mapStateToProps = (state) => ({
	user: state.user,
	loading: state.loading
});

export default connect(mapStateToProps, null)(MfaActivation);