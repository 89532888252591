import {store} from "../../../../Redux/configureStore";
import {saveLoading} from "../../../../Redux/actions/Generic/loaderActions";

export const setLoaderState = (newState) => {
	store.dispatch(saveLoading(newState));
};

export const getVisibleAndHiddenTags = (tagsList, forPage) => {
	let count = 0;
	let visibleTags = [];
	let hiddenTags = [];
	const ceiling = window.innerWidth > constants.responsiveResolution ? constants.tagListConfig[forPage].maxTotalChars : constants.tagListConfig[forPage].maxTotalCharsSmall;
	const descOverflowCeiling = ceiling * 0.75;
	const maxLength = constants.tagListConfig.maxCharsForMeasurementList;

	tagsList.forEach((tag) => {
		let step;
		if (tag.title.length <= maxLength) {
			step = tag.title.length + Math.ceil(tag.title.length * 0.1) + 1;
		} else {
			step = maxLength + Math.ceil(maxLength * 0.1) + 1;
		}
		count += step;

		if (count < ceiling) {
			if (count >= descOverflowCeiling) {
				tag.toOverflow = true;
			}
			visibleTags.push(tag);
		} else {
			hiddenTags.push(tag);
		}
	});

	return {
		visibleTags,
		hiddenTags
	};
};