import * as actions from "../../actions/actionTypes";
import initialState from "../initialState";

export default (state = initialState.pairedDeviceList, action) => {
    let nextState = {...state};
    switch (action.type) {
        case actions.SAVE_PAIRED_DEVICE_LIST :
            nextState = action.payload;
            break;
        case actions.CLEAR_PAIRED_DEVICE_LIST :
            nextState = [];
            break;
        default:
            nextState = state;
    }
    return nextState;
};