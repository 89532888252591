import XRegExp from "xregexp";

/**
 * @static
 * @description Checks if input is a number
 */
export const isNumeric = (number) => {
	return !isNaN(parseFloat(+number));
};

/**
 * @static
 * @description Checks if input is a valid phone number
 */
export const isPhoneNumber = (phoneNumber) => {
	return phoneNumber.match(/^[0-9]{4,20}$/g);
};

/**
 * @static
 * @description Checks if input is a valid password
 */
export const isPassword = (password) => {
	return password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!“#\$%&‘\(\)\*\+,-\.\/:;<=>\?_@\[\\\]\^`{\|}~])(?=\S+$).{8,20}$/g);
};

/**
 * @static
 * @description Checks if input is alphanumeric
 */
export const isAlphaNumeric = (alphaNumeric) => {
	return alphaNumeric.match(/^[a-zA-Z0-9]+$/g);
};

/**
 * @static
 * @deprecated
 * @description Checks if input contains letters only
 */
export const isAlphabetic = (alphabetic) => {
	return alphabetic.match(/^([a-zA-Z- 'ÀàÂâÄäÆæÇçÈèÉéÊêËëÎîÏïÔôŒœÙùÛûÜüÖößÁáÌìÍíÒòÓóÚúÑñÜü]){1,45}$/g);
};

/**
 * @static
 * @description Checks if input contains letters and symbols
 */
export const isAlphabeticExtended = (alphabetic) => {
	return alphabetic.match(/^([a-zA-Z0-9-'ÀàÂâÄäÆæÇçÈèÉéÊêËëÎîÏïÔôŒœÙùÛûÜüÖößÁáÌìÍíÒòÓóÚúÑñÜü()#_\-\/%&@:;,. +]){1,45}$/g);
};

/**
 * @static
 * @description Checks if input contains letters and symbols without length restriction.
 */
export const isAlphabeticExtendedNoLength = (alphabetic) => {
	return alphabetic.match(/^([a-zA-Z0-9-'ÀàÂâÄäÆæÇçÈèÉéÊêËëÎîÏïÔôŒœÙùÛûÜüÖößÁáÌìÍíÒòÓóÚúÑñÜü()#_\-\/%&@:;,. +])+$/g);
};

/**
 * @static
 * @description <i>Checks if input is a valid email</i>
 */
export const isEmail = (email) => {
	return email.match(/^[a-zA-Z0-9._-]{1,255}@([a-zA-Z0-9_-]{1,255}\.)*[a-zA-Z0-9_-]{2,255}\.[a-zA-Z]{2,255}$/i);
};

/**
 * @static
 * @description Checks if height is out of range
 */
export const checkHeight = (value, system) => {
	let inRange = "";
	if (isNumeric(value)) {
		if (system === "metric") {
			if (value < window.constants.minHeightMetric || value > window.constants.maxHeightMetric) {
				inRange = "notValid";
			}
		} else {
			if (value < window.constants.minHeightImperial || value > window.constants.maxHeightImperial) {
				inRange = "notValid";
			}
		}
	} else {
		inRange = "Nan";
	}
	return inRange;
};

/**
 * @static
 * @description Returns true if at least one of the provided data is emmpty
 */
export const isRequiredFieldsEmpty = (...data) => {
	return data.some((value) => value === "");
};

/**
 * @static
 * @description Returns true if at least on of the provided data is empty or does not match the regex
 */
export const testAlphabeticExtended = (...data) => {
	return data.some((value) => {
		let returnee;
		if (value.length === 0) {
			returnee = false;
		} else {
			returnee = isAlphabeticExtended(value) === null;
		}
		return returnee;
	});
};

/**
 * @static
 * @description Returns true if the provided email is invalid
 */
export const isEmailInValid = (email) => {
	return email.length > 0 && isEmail(email) === null || email.length > constants.emailMaxLength;
};

/**
 * @static
 * @description Returns true if the provided postal code is invalid
 */
export const isPostalCodeInValid = (postalCode) => {
	return postalCode.length > 0 && isAlphabeticExtended(postalCode) === null || postalCode.length > constants.postalCodeMaxLength;
};

/**
 * @static
 * @description Returns true if the provided lDex selections are valid
 */
export const areLDexSelectionsInValid = (assessmentTypeSelectionLD, bodyElementSelection, riskSideSelection, limbDominanceSelection, measurementType) => {
	return Boolean(Number(assessmentTypeSelectionLD)) && (measurementType === "" || bodyElementSelection.toString() === "0" || riskSideSelection.toString() === "0" || limbDominanceSelection.toString() === "0");
};

/**
 * @static
 * @description Returns true if the provided phone number and phone code are invalid
 */
export const isInvalidPhone = (phoneNumber, countryPhoneCode) => {
	let returnee = [false, ""];
	if (phoneNumber !== "" && !isPhoneNumber(phoneNumber)) {
		returnee = [true, "invalidPhone"];
	} else if (phoneNumber === "" ^ countryPhoneCode === "") {
		returnee = [true, "emptyPhoneNumberOrCode"];
	}
	return returnee;
};

export const isDomainNameInValid = (value) => {
	return !XRegExp("^(?:[^_]|[\\pL\\pM\\pS\\pN\\pP]|[^_])+?$").test(value) || /\s/.test(value) || value.length < 1 || value.length > 32;
};

/**
 * @static
 * @description Returns true if the provided data is only spaces
 */
export const isSpaceOnly = (value) => {
	return /^\s+$/g.test(value);
};

/**
 * @static
 * @description Returns true if the provided totp is invalid
 */
export const isTotpInvalid = (value) => {
	return value.length !== 6 || !isNumeric(value);
};

export const isNullOrUndefined = (value) => {
	return value === null || value === undefined;
};

export const isNumericOneDecimal = (value) => {
	return !isNaN(parseFloat(+value)) && value.match(/^\d+\.?\d?$/);
};