import HelpIconView from "./HelpIconView";

const HelpIcon = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <HelpIconView/>;

};

HelpIcon.propTypes = {};

export default HelpIcon;