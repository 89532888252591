import PropTypes from "prop-types";

const WarningIconView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion
    return (
        <div className={`warningIcon ${props.classes}`}>
            {props.classes === "measurementInfo__warningIcon" ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g id="Group_11104" data-name="Group 11104" transform="translate(-118 -620)">
                        <path id="Path_12549" data-name="Path 12549" d="M-631.1,24320.969l9.159-15.627,8.81,15.627Z" transform="translate(753 -23681)" fill="#f4ea74"/>
                        <g id="report_problem" transform="translate(118 620)">
                            <rect id="Path" width="24" height="24" fill="rgba(0,0,0,0)"/>
                            <path id="Icon" d="M22,19H0L11,0,22,19ZM11,3.99,3.47,17H18.53L11,3.99ZM12,16H10V14h2v2Zm0-4H10V8h2v4Z" transform="translate(1 2)" fill="#2e3040"/>
                        </g>
                    </g>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="31.331" height="30" viewBox="0 0 31.331 30">
                    <g transform="translate(-15122.984 212)">
                        <path className="a"
                            d="M30.228,30.109,16.215,3.357a.663.663,0,0,0-1.128,0L1.073,30.109a.637.637,0,0,0,.564.933H29.664a.637.637,0,0,0,.564-.933Z"
                            transform="translate(15122.999 -214.042)" />
                        <path className="b"
                            d="M23.274,27.649a1.274,1.274,0,1,1,1.274-1.274A1.274,1.274,0,0,1,23.274,27.649Zm.666-4.987H22.633L22,9.487h2.575Z"
                            transform="translate(15115.376 -212.843)" />
                    </g>
                </svg>}
        </div>
    );

};

WarningIconView.defaultProps = {
	classes: "",
};

WarningIconView.propTypes = {
    classes: PropTypes.string,
};

export default WarningIconView;