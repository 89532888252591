import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import IntermediateLoginView from "./IntermediateLoginView";
import cognitoData from "../../../../cognitoData.json";
import Utilities from "../../Libraries/Custom/Utilities";
import {fetchTokenFromCognito, getInitialData, ssoLogin, ssoLogout} from "../../../API/authApi";
import {ApiStore} from "../../../API/apiStore";
import {persistor, store} from "../../../Redux/configureStore";
import {saveFacility} from "../../../Redux/actions/Facility/facilityActions";
import {saveFacilityLicense} from "../../../Redux/actions/Facility/facilityLicenseActions";
import {saveFacilityLocation} from "../../../Redux/actions/Facility/facilityLocationActions";
import {saveFacilitySettings} from "../../../Redux/actions/Facility/facilitySettingsActions";
import {saveFacilityTimezone} from "../../../Redux/actions/Facility/facilityTimezoneActions";
import {saveUser, saveUserSession, saveUserSettings} from "../../../Redux/actions/User/userActions";
import {saveChangelog} from "../../../Redux/actions/Generic/changelogActions";
import {NODE_URL} from "../../../API/config";
import {customSweetAlertOperation} from "../../Libraries/Custom/utils/sweetalert";
import {saveDeviceKeys} from "../../../Redux/actions/Device/deviceActions";
import {useHistory} from "react-router";
import { version } from "../../../../package.json";

const getParameterByName = (name) => {
	let results = new RegExp("[?&]" + name.replace(/[\[\]]/g, "\\$&") + "(=([^&#]*)|&|#|$)").exec(location.href);
	let result;
	if (!results) {
		result = null;
	} else if (!results[2]) {
		result = "";
	} else {
		result = decodeURIComponent(results[2].replace(/\+/g, " "));
	}
	return result;
};

const IntermediateLogin = (props) => {

	//region VARIABLES
	const [showUpdatePopup, setShowUpdatePopup] = useState(false);
	const [devicePort, setDevicePort] = useState(null);
	const [currentOS, setCurrentOS] = useState({});
	const history = useHistory();

	const language = localStorage.getItem("language");
	const isCollapsed = window.innerWidth <= constants.windowCollapseSize;
	const providerName = localStorage.getItem("providerName");
	//endregion

	//region FUNCTIONS
	const handleMessage = async (event) => {
		const validOrigin = Utilities.validateOrigin(event.target.location.origin);
		const isEventDevicePort = event.data === "DEVICE_PORT";

		if (validOrigin && isEventDevicePort) {
			const _devicePort = event.ports[0];
			const callFunction = {
				name: "setPortReceived",
				data: "Device Port Received"
			};

			if (_devicePort) {
				_devicePort.postMessage(JSON.stringify(callFunction));
				setDevicePort(_devicePort);
				window.removeEventListener("message", handleMessage);
			}
		}
	};

	const promiseResolveGetInitialData = (response, tokens, endPoints, region, userId) => {
		localStorage.removeItem("applicationVersionName");
		try {
			props.saveFacility({...response.data.facility});
			props.saveFacilityLicense({...response.data.facility.license});
			props.saveFacilityLocation({...response.data.facility.location, region});
			props.saveFacilitySettings({...response.data.facility.facilitySettings});
			props.saveFacilityTimezone({...response.data.facility.location.timezone});

			let user;
			let settings = {};
			let session = {};
			if (response.data.facility.clinician === undefined) {
				user = ({...response.data.facility.clinicITAdministrator});
				session = {
					...response.data.facility.clinicITAdministrator.session,
					endpointsList: endPoints,
					token: tokens.id_token,
					refreshToken: tokens.refresh_token
				};
			} else if (response.data.facility.clinicITAdministrator === undefined) {
				user = ({...response.data.facility.clinician});
				settings = {...response.data.facility.clinician.settings};
				session = {
					...response.data.facility.clinician.session,
					endpointsList: endPoints,
					token: tokens.id_token,
					refreshToken: tokens.refresh_token
				};
			} else {
				user = ({...response.data.facility.clinician, ...response.data.facility.clinicITAdministrator});
				settings = {...response.data.facility.clinician.settings};
				session = {
					...response.data.facility.clinician.session,
					endpointsList: endPoints,
					token: tokens.id_token,
					refreshToken: tokens.refresh_token
				};
			}

			props.saveUser({...user, user_id: userId});
			props.saveUserSettings({...settings});
			props.saveUserSession({...session});

			let storeState = store.getState();
			sessionStorage.loginTime = new Date().getTime();
			let activeView = storeState.user.type === constants.userTypes.admin ? constants.activeView.admin : constants.activeView.clinician;
			persistor.flush().then(() => {
				sessionStorage.setItem("sozoCurentView", activeView);
				const sharedDataNavigation = sessionStorage.getItem("sharedDataNavigation");
				const sharedDataPath = sharedDataNavigation !== null ? constants.routes.sharedData : "";

				location.href = Utilities.getLocationHref(activeView, storeState.facility, storeState.user.user_id, storeState.facilityLocation.region, sharedDataPath);
				sessionStorage.removeItem("sharedDataNavigation");
			});

		} catch (errorObject) {
			Utilities.handleError(errorObject, "promiseResolveGetInitialData");
		}
	};

	const getInitialDataCall = async (userId, region, tokens, endPoints) => {
		let response = await Utilities.makeSingleCallAsync(getInitialData, "ssoInitialData", []);
		if (response.status === constants.responseStatus.success) {
			promiseResolveGetInitialData(response.data, tokens, endPoints, region, userId);
		} else {
			Utilities.handleFailResponse(response, [], "ssoInitialData");
		}
	};

	const setLoginData = (responseAuth, tokens) => {
		props.saveFacility({...responseAuth.data.facility});
		props.saveChangelog({...responseAuth.data.htmlChangeLog});

		let user, endPoints;
		if (responseAuth.data.facility.clinician === undefined) {
			user = {...responseAuth.data.facility.clinicITAdministrator};
			endPoints = responseAuth.data.facility.clinicITAdministrator.session ? {...responseAuth.data.facility.clinicITAdministrator.session.endpointsList} : {};
		} else if (responseAuth.data.facility.clinicITAdministrator === undefined) {
			user = {...responseAuth.data.facility.clinician};
			endPoints = responseAuth.data.facility.clinician.session ? {...responseAuth.data.facility.clinician.session.endpointsList} : {};
		} else {
			user = {...responseAuth.data.facility.clinician, ...responseAuth.data.facility.clinicITAdministrator};
			endPoints = responseAuth.data.facility.clinician.session || responseAuth.data.facility.clinicITAdministrator.session ?
				{...responseAuth.data.facility.clinician.session.endpointsList, ...responseAuth.data.facility.clinicITAdministrator.session.endpointsList} : {};
		}

		ApiStore.init({
			language: language,
			userId: user.user_id,
			region: responseAuth.data.facility.location.region,
			tokens: {
				token: tokens.id_token,
				refreshToken: tokens.refresh_token
			},
			endpointsList: endPoints,
			version: responseAuth.data.facility.facilitysCurrentVersion
		});

		return {user, endPoints};
	};

	const resolveSsoLogin = async (responseAuth, tokens) => {
		const loginData = setLoginData(responseAuth, tokens);
		props.saveDeviceKeys(responseAuth.data.deviceEncryptionKeys);
		getInitialDataCall(loginData.user.user_id, responseAuth.data.facility.location.region, tokens, loginData.endPoints);
	};

	const resolveFetchTokenFromCognito = async (response) => {
		ApiStore.init({
			language: language,
			tokens: {
				token: response.id_token
			},
		});

		const client = props.sozoDeviceCommEnabled ? "1" : "2";
		let authResponse = await Utilities.makeSingleCallAsync(ssoLogin, "ssoLogin", [client]);
		if (authResponse.status === constants.responseStatus.success) {
			resolveSsoLogin(authResponse.data, response);
		} else {
			if (authResponse.data.code === constants.clientWrongVersionCode) {
				setLoginData(authResponse.data, response);
				setShowUpdatePopup(true);
			} else {
				Utilities.handleFailResponse(authResponse, [], "ssoLogin");
			}
		}
	};
	//endregion

	useEffect(() => {
		const didMount = async () => {
			const errorDescriptionCognito = getParameterByName("error_description");
			const serverError = getParameterByName("error");
			const errorCode = Utilities.getErrorCodeFromUrl(errorDescriptionCognito);

			if (errorCode === null) {
				const code = getParameterByName("code");
				if (serverError === "server_error" && errorDescriptionCognito.trim() === "Error in SAML response processing: Invalid email address format.") {
					customSweetAlertOperation({
						title: "",
						text: dictionary[localStorage.language].wrongEmailFormat,
						type: "error"
					}, false, "", dictionary[localStorage.language].ok, () => {
						Utilities.logoutFinalization();
					}, false);
				} else if (serverError === "server_error") {
					customSweetAlertOperation({
						title: "",
						text: dictionary[localStorage.language].exception,
						type: "error"
					}, false, "", dictionary[localStorage.language].ok, () => {
						Utilities.logoutFinalization();
					}, false);
				} else {
					let resp = await Utilities.makeSingleCallAsync(fetchTokenFromCognito, "fetchTokenFromCognito", [code]);
					if (resp.status === constants.responseStatus.success) {
						resolveFetchTokenFromCognito(resp.data);
					} else {
						Utilities.handleFailResponse(resp, [code], "fetchTokenFromCognito");
					}
				}

			} else if (errorCode === "99999") {
				const identityProvider = "identity_provider=" + providerName;
				const redirectUri = "redirect_uri=" + NODE_URL + "IntermediateLogin";
				const path = "https://" + cognitoData.customDomain + "/oauth2/authorize?"
					+ identityProvider + "&" + redirectUri + "&response_type=code&client_id=" + cognitoData.clientId + "&scope=email+openid";
				location.href = path;
			} else {
				Utilities.handleErrorFromCognito(errorDescriptionCognito, "fetchTokenFromCognito", ssoLogout);
			}
		};

		if (currentOS.isAndroid && props.sozoDeviceCommEnabled) {
			if (devicePort !== null) {
				didMount();
			}
		} else {
			didMount();
		}

	}, [devicePort, currentOS]);

	useEffect(() => {
		const _currentOS = Utilities.getCurrentOS();
		if (_currentOS.isAndroid) {
			// Add nativeShell query parameter to the path in order to be able to retrieve the devicePort which will be used to sideload the updated app.
			history.push({
				pathname: window.location.pathname,
				search: window.location.search + "&nativeShell=true"
			});
		}
		setCurrentOS(_currentOS);

		window.addEventListener("message", handleMessage);
		return () => window.removeEventListener("message", handleMessage);
	}, []);

	return <IntermediateLoginView showUpdatePopup={showUpdatePopup}
								  appVersion={version}
								  isCollapsed={isCollapsed}
								  devicePort={devicePort}
								  currentOS={currentOS}
								  handleCloseUpdatePopup={setShowUpdatePopup}/>;
};

IntermediateLogin.propTypes = {
	applicationBuild: PropTypes.object,
	sozoDeviceCommEnabled: PropTypes.bool,
	saveFacility: PropTypes.func,
	saveFacilityLicense: PropTypes.func,
	saveFacilityLocation: PropTypes.func,
	saveFacilitySettings: PropTypes.func,
	saveFacilityTimezone: PropTypes.func,
	saveUser: PropTypes.func,
	saveUserSettings: PropTypes.func,
	saveUserSession: PropTypes.func,
	saveChangelog: PropTypes.func,
	saveDeviceKeys: PropTypes.func,
	saveCurrentOS: PropTypes.func,
};

const mapStateToProps = (state) => ({
	saveCurrentOS: state.saveCurrentOS,
	sozoDeviceCommEnabled: state.sozoDeviceCommEnabled
});

const mapDispatchToProps = (dispatch) => ({
	saveFacility: (data) => dispatch(saveFacility(data)),
	saveFacilityLicense: (data) => dispatch(saveFacilityLicense(data)),
	saveFacilityLocation: (data) => dispatch(saveFacilityLocation(data)),
	saveFacilitySettings: (data) => dispatch(saveFacilitySettings(data)),
	saveFacilityTimezone: (data) => dispatch(saveFacilityTimezone(data)),
	saveUser: (data) => dispatch(saveUser(data)),
	saveUserSettings: (data) => dispatch(saveUserSettings(data)),
	saveUserSession: (data) => dispatch(saveUserSession(data)),
	saveChangelog: (data) => dispatch(saveChangelog(data)),
	saveDeviceKeys: (data) => dispatch(saveDeviceKeys(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntermediateLogin);