import Utilities from "../Utilities";

const roundFrxDataList=(frxDataList, frxDataTargetList) => {
	for (let i = 0; i < frxDataList.length; i++) {
		frxDataTargetList.push([
			Utilities.roundDecimals(frxDataList[i].r, 2),
			Utilities.roundDecimals(frxDataList[i].xc, 2)
		]);
	}
};

export const mapFrxData=(data,isImported) => {
	let leftArmMeasured= [];
	let leftArmFitted= [];
	let leftArmTdCorrected= [];
	let leftLegMeasured= [];
	let leftLegFitted= [];
	let leftLegTdCorrected= [];
	let leftBodyMeasured= [];
	let leftBodyFitted= [];
	let leftBodyTdCorrected= [];
	let rightArmMeasured= [];
	let rightArmFitted= [];
	let rightArmTdCorrected= [];
	let rightLegMeasured= [];
	let rightLegFitted= [];
	let rightLegTdCorrected= [];
	let rightBodyMeasured= [];
	let rightBodyFitted= [];
	let rightBodyTdCorrected= [];

	if (isImported) {
		if (data.leftArmFrx !== null) {
			roundFrxDataList(data.leftArmFrx, leftArmMeasured);
		}
		if (data.leftLegFrx !== null) {
			roundFrxDataList(data.leftLegFrx, leftLegMeasured);
		}
		if (data.rightArmFrx !== null) {
			roundFrxDataList(data.rightArmFrx,rightArmMeasured);
		}
		if (data.rightLegFrx !== null) {
			roundFrxDataList(data.rightLegFrx,rightLegMeasured);
		}

	} else {
		for (let i = 0; i < data.leftArmFrx.length; i++) {
			leftArmMeasured.push([
				Utilities.roundDecimals(data.leftArmFrx[i].r, 2),
				Utilities.roundDecimals(data.leftArmFrx[i].xc, 2)
			]);
			leftArmFitted.push([
				Utilities.roundDecimals(data.leftArmFittedFrx[i].r, 2),
				Utilities.roundDecimals(data.leftArmFittedFrx[i].xc, 2)

			]);
			leftArmTdCorrected.push([
				Utilities.roundDecimals(data.leftArmTdCorrectedFrx[i].r, 2),
				Utilities.roundDecimals(data.leftArmTdCorrectedFrx[i].xc, 2)

			]);
			leftLegMeasured.push([
				Utilities.roundDecimals(data.leftLegFrx[i].r, 2),
				Utilities.roundDecimals(data.leftLegFrx[i].xc, 2)
			]);
			leftLegFitted.push([
				Utilities.roundDecimals(data.leftLegFittedFrx[i].r, 2),
				Utilities.roundDecimals(data.leftLegFittedFrx[i].xc, 2)
			]);
			leftLegTdCorrected.push([
				Utilities.roundDecimals(data.leftLegTdCorrectedFrx[i].r, 2),
				Utilities.roundDecimals(data.leftLegTdCorrectedFrx[i].xc, 2)
			]);
			leftBodyMeasured.push([
				Utilities.roundDecimals(data.wholeLeftFrx[i].r, 2),
				Utilities.roundDecimals(data.wholeLeftFrx[i].xc, 2)
			]);
			leftBodyFitted.push([
				Utilities.roundDecimals(data.wholeLeftFittedFrx[i].r, 2),
				Utilities.roundDecimals(data.wholeLeftFittedFrx[i].xc, 2)
			]);
			leftBodyTdCorrected.push([
				Utilities.roundDecimals(data.wholeLeftTdCorrectedFrx[i].r, 2),
				Utilities.roundDecimals(data.wholeLeftTdCorrectedFrx[i].xc, 2)
			]);
			rightArmMeasured.push([
				Utilities.roundDecimals(data.rightArmFrx[i].r, 2),
				Utilities.roundDecimals(data.rightArmFrx[i].xc, 2)
			]);
			rightArmFitted.push([
				Utilities.roundDecimals(data.rightArmFittedFrx[i].r, 2),
				Utilities.roundDecimals(data.rightArmFittedFrx[i].xc, 2)
			]);
			rightArmTdCorrected.push([
				Utilities.roundDecimals(data.rightArmTdCorrectedFrx[i].r, 2),
				Utilities.roundDecimals(data.rightArmTdCorrectedFrx[i].xc, 2)
			]);
			rightLegMeasured.push([
				Utilities.roundDecimals(data.rightLegFrx[i].r, 2),
				Utilities.roundDecimals(data.rightLegFrx[i].xc, 2)
			]);
			rightLegFitted.push([
				Utilities.roundDecimals(data.rightLegFittedFrx[i].r, 2),
				Utilities.roundDecimals(data.rightLegFittedFrx[i].xc, 2)
			]);
			rightLegTdCorrected.push([
				Utilities.roundDecimals(data.rightLegTdCorrectedFrx[i].r, 2),
				Utilities.roundDecimals(data.rightLegTdCorrectedFrx[i].xc, 2)
			]);
			rightBodyMeasured.push([
				Utilities.roundDecimals(data.wholeRightFrx[i].r, 2),
				Utilities.roundDecimals(data.wholeRightFrx[i].xc, 2)
			]);
			rightBodyFitted.push([
				Utilities.roundDecimals(data.wholeRightFittedFrx[i].r, 2),
				Utilities.roundDecimals(data.wholeRightFittedFrx[i].xc, 2)
			]);
			rightBodyTdCorrected.push([
				Utilities.roundDecimals(data.wholeRightTdCorrectedFrx[i].r, 2),
				Utilities.roundDecimals(data.wholeRightTdCorrectedFrx[i].xc, 2)
			]);
		}
	}
	return {
		leftArmMeasured,
		leftArmFitted,
		leftArmTdCorrected,
		leftLegMeasured,
		leftLegFitted,
		leftLegTdCorrected,
		leftBodyMeasured,
		leftBodyFitted,
		leftBodyTdCorrected,
		rightArmMeasured,
		rightArmFitted,
		rightArmTdCorrected,
		rightLegMeasured,
		rightLegFitted,
		rightLegTdCorrected,
		rightBodyMeasured,
		rightBodyFitted,
		rightBodyTdCorrected,
	};
};
