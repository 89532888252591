import ThumbsUpView from "./ThumbsUpView";

const ThumbsUp = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <ThumbsUpView/>;

};

ThumbsUp.propTypes = {};

export default ThumbsUp;