import PropTypes from "prop-types";
import Input from "../../Components/Input/Input";

const SendEmailView = (props) => {

	//region VARIABLES

	//endregion

	//region FUNCTIONS
	//endregion

	return <div className={`loginBox sendEmail ${props.flipClasses}`} onAnimationEnd={props.handleAnimationEnd}>
		<h2 className={"sectionTitle sendEmail__title"}>{dictionary[localStorage.language].sendEmailTitle}</h2>
		<form className={"sendEmail__form"}>
			<p className={"sendEmail__description"}>{dictionary[localStorage.language].resetPasswordDescription}</p>
			<Input
				id={"inputEmail"}
				inputClasses={""}
				withLabel={true}
				labelInBox={true}
				labelText={dictionary[localStorage.language].email}
				value={props.email}
				placeholder={dictionary[localStorage.language].enterEmail}
				onChange={props.handleInputChange}
			/>
			<p className={"sendEmail__subParagraph"}>{dictionary[localStorage.language].forgotPasswordExtra}</p>
			<input className={"button-blue sendEmail__submit"} type={"submit"} onClick={props.handleSubmit} value={dictionary[localStorage.language].submit}/>
			<button className={"button-white sendEmail__cancel"} onClick={(event) => props.handleCancel(event)}>{dictionary[localStorage.language].cancel}</button>
		</form>
	</div>;

};

SendEmailView.propTypes = {
	email: PropTypes.string,
	handleInputChange: PropTypes.func,
	handleSubmit: PropTypes.func,
	handleCancel: PropTypes.func,
	flipClasses: PropTypes.string,
	handleAnimationEnd: PropTypes.func,
};

export default SendEmailView;