import * as actions from "../actionTypes";

export const saveFacility = (payload) => ({
	type: actions.SAVE_FACILITY,
	payload
});

export const saveFacilitySsoStatus = (payload) => ({
	type: actions.SAVE_FACILITY_SSO_STATUS,
	payload
});

