import swal from "sweetalert";
import {getVersionForUrl} from "./business";
import {logoutCall} from "./session";

let countDownTimer;
/**
 * @static
 * @description Returns the image url for the icon based on the type
 */
const getSweetAlertIcon = (type) => {
	let imageUrl = null;
	if (type === "warning") {
		imageUrl = "/" + getVersionForUrl() + "-customer-static/UI/images/warningIcon.svg";
	} else if (type === "error") {
		imageUrl = "/" + getVersionForUrl() + "-customer-static/UI/images/errorIcon.svg";
	}
	return imageUrl;
};

const getTitle = (title, type) => {
	let finalTitle = title;
	if (finalTitle === "") {
		if (type === "error") {
			finalTitle = dictionary[localStorage.language].error;
		} else if (type === "warning") {
			finalTitle = dictionary[localStorage.language].caution;
		}
	}
	return finalTitle;
};

/**
 * @static
 * @description Creates a simple SweetAlert
 */
export const customSweetAlertSimple = (title, text, type, showCancelButton, cancelButtonText, confirmButtonText) => {
	let imageUrl = getSweetAlertIcon(type);
	let finalTitle = getTitle(title, type);
	swal({
			title: finalTitle,
			text: text,
			icon: imageUrl === null ? "" : imageUrl,
			closeOnClickOutside: false,
			buttons: {
				cancel: {
					text: cancelButtonText,
					visible: showCancelButton
				},
				confirm: {
					text: confirmButtonText
				}
			},
			closeModal: true
		}
	);
};

/**
 * @static
 * @description Creates a SweetAlert with a confirm function
 */
export const customSweetAlertOperation = (basicInfoObj, showCancelButton, cancelButtonText, confirmButtonText, functionOperation, closeOnConfirm) => {
	let imageUrl = getSweetAlertIcon(basicInfoObj.type);
	let finalTitle = getTitle(basicInfoObj.title, basicInfoObj.type);
	let isEsc = "";
	document.addEventListener("keydown", isEscape);

	function isEscape(event) {
		isEsc = event.key === constants.escapeKey;
	}

	swal({
		title: finalTitle,
		text: basicInfoObj.text,
		icon: imageUrl === null ? "" : imageUrl,
		closeOnClickOutside: false,
		buttons: {
			cancel: {
				text: cancelButtonText,
				visible: showCancelButton,
				value: "0",
			},
			confirm: {
				text: confirmButtonText,
				value: "1"
			}
		},
		className: imageUrl === null ? "swal-noicon" : "",
		closeModal: closeOnConfirm
	}).then((value) => {
		if (isEsc) {
			functionOperation();
		} else {
			switch (value) {
				case "0":
					break;
				case "1":
					functionOperation();
					break;
				default:
					return null;
			}
		}
	});
};

/**
 * @static
 * @description Creates a SweetAlert with a confirm and cancel function
 */
export const customSweetAlertSelectionOperation = (basicInfoObj, showCancelButton, cancelButtonText, confirmButtonText, functionOperationSelectionTrue, functionOperationSelectionFalse, closeOnConfirm) => {
	let imageUrl = getSweetAlertIcon(basicInfoObj.type);
	let finalTitle = getTitle(basicInfoObj.title, basicInfoObj.type);
	let isEsc = "";
	document.addEventListener("keydown", isEscape);

	function isEscape(event) {
		isEsc = event.key === constants.escapeKey;
	}

	swal({
		title: finalTitle,
		text: basicInfoObj.text,
		icon: imageUrl === null ? "" : imageUrl,
		closeOnClickOutside: false,
		buttons: {
			cancel: {
				text: cancelButtonText,
				visible: showCancelButton,
				value: "0",
			},
			confirm: {
				text: confirmButtonText,
				value: "1"
			}
		},
		className: imageUrl === null ? "swal-noicon" : "",
		closeModal: closeOnConfirm
	}).then((value) => {
		document.removeEventListener("keydown", isEscape);
		if (cancelButtonText !== dictionary[localStorage.language].cancel && confirmButtonText !== dictionary[localStorage.language].cancel && isEsc) {
			return null;
		} else {
			switch (value) {
				case "0":
					functionOperationSelectionFalse();
					break;
				case "1":
					functionOperationSelectionTrue();
					break;
				default:
					return null;
			}
		}

	});
};

/**
 * @static
 * @description Creates a SweetAlert with multiple buttons
 */
export const customSweetAlertRecalcHeight = (basicInfoObj, showCancelButton, cancelButtonText, confirmButtonText, functionOperationSelectionTrue, functionOperationSelectionFalse) => {
	let imageUrl = getSweetAlertIcon(basicInfoObj.type);
	let finalTitle = getTitle(basicInfoObj.title, basicInfoObj.type);
		swal({
		className: "swal-recalc",
		title: finalTitle,
		text: basicInfoObj.text,
		icon: imageUrl === null ? "" : imageUrl,
		closeOnEsc: false,
		closeOnClickOutside: false,
		buttons: {
			all: {text: confirmButtonText, value: "all"},
			futureOnly: {text: cancelButtonText, value: "futureOnly"},
			cancelUpdate: {text: dictionary[localStorage.language].cancel, value: "cancel"}
		},
	}).then((value) => {
		switch (value) {
			case "all":
				functionOperationSelectionTrue(value);
				break;
			case "futureOnly":
				functionOperationSelectionFalse(value);
				break;
			case "cancel":
			default:
				return null;
		}
	});
};

/**
 * @static
 * @description Creates a SweetAlert to notify the user for upcoming logout
 */
export const customSweetAlertTimerForLogout = (title, type, milliseconds, rejectFunction, isSSO) => {
	clearInterval(countDownTimer);
	let countDownSeconds = parseInt(milliseconds / window.constants.oneSecInMillis);
	let displayText = dictionary[localStorage.language].sessionExpirationMsg + " #min:#sec";
	let imageUrl = getSweetAlertIcon(type);
	if (type !== "warning") {
		title = "";
	}

	const ssoWarningMessage = isSSO ? `<br><p class="swal-text-warning">${dictionary[localStorage.language].ssoLogoutWarning}</p>` : "";
	countDownTimer = setInterval(() => {
		countDownSeconds--;
		if (countDownSeconds < 0) {
			jQuery(".swal-text-countdown").text(displayText.replace(/#min/, "--").replace(/#sec/, "--"));
			clearInterval(countDownTimer);
			swal.close();
		}
		jQuery(".swal-text-countdown").text(displayText.replace(/#min/, parseInt(countDownSeconds / window.constants.oneMinInSecs).toString() < 10 ? "0" + parseInt(countDownSeconds / window.constants.oneMinInSecs).toString().toString() : parseInt(countDownSeconds / window.constants.oneMinInSecs).toString()).replace(/#sec/, countDownSeconds % window.constants.oneMinInSecs < 10 ? "0" + (countDownSeconds % window.constants.oneMinInSecs).toString() : (countDownSeconds % window.constants.oneMinInSecs).toString()));
	}, window.constants.oneSecInMillis);

	const content = document.createElement("span");
	content.innerHTML = "<p class='swal-text-countdown'>" + displayText.replace(/#min/, parseInt(countDownSeconds / window.constants.oneMinInSecs).toString() < 10 ? "0" + parseInt(countDownSeconds / window.constants.oneMinInSecs).toString().toString() : parseInt(countDownSeconds / window.constants.oneMinInSecs).toString()).replace(/#sec/, countDownSeconds % window.constants.oneMinInSecs < 10 ? "0" + (countDownSeconds % window.constants.oneMinInSecs).toString() : (countDownSeconds % window.constants.oneMinInSecs).toString()) + "</p>" + ssoWarningMessage;
	content.className = "swal-text-content";

	swal({
		title: dictionary[localStorage.language].signOut,
		content: content,
		icon: imageUrl === null ? "" : imageUrl,
		timer: countDownSeconds * window.constants.oneSecInMillis,
		closeOnClickOutside: false,
		buttons: {
			remainLoggedIn: {
				visible: true,
				text: dictionary[localStorage.language].remainLogged,
				value: "1"
			},
			confirm: {
				text: dictionary[localStorage.language].signOut,
				value: "0"
			}
		},
		className: imageUrl === null ? "swal-noicon" : "",
		closeModal: true
	}).then((value) => {
			clearInterval(countDownTimer);
			switch (value) {
				case "1":
					swal.close();
					rejectFunction();
					break;
				case "0":
					logoutCall();
					break;
				default:
					swal.close();
					logoutCall();
					break;
			}
		}
	);
};

/**
 * @static
 * @description Creates a SweetAlert with a confirm function using custom html
 */
export const customSweetAlertOperationContent = (basicInfoObj, showCancelButton, cancelButtonText, confirmButtonText, functionOperation, closeOnConfirm) => {
	let imageUrl = getSweetAlertIcon(basicInfoObj.type);
	let finalTitle = getTitle(basicInfoObj.title, basicInfoObj.type);
	let isEsc = "";
	document.addEventListener("keydown", isEscape);

	function isEscape(event) {
		isEsc = event.key === constants.escapeKey;
	}

	const content = document.createElement("span");
	content.innerHTML = basicInfoObj.content;
	content.className = "swal-text-content";

	swal({
		title: finalTitle,
		content: content,
		icon: imageUrl === null ? "" : imageUrl,
		closeOnClickOutside: false,
		buttons: {
			cancel: {
				text: cancelButtonText,
				visible: showCancelButton,
				value: "0",
			},
			confirm: {
				text: confirmButtonText,
				value: "1"
			}
		},
		className: imageUrl === null ? "swal-noicon" : "",
		closeModal: closeOnConfirm
	}).then((value) => {
		if (isEsc) {
			functionOperation();
		} else {
			switch (value) {
				case "0":
					break;
				case "1":
					functionOperation();
					break;
				default:
					return null;
			}
		}
	});
};