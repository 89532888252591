import * as validation from "./utils/validation";
import * as math from "./utils/math";
import * as date from "./utils/date";
import * as errorHandlers from "./utils/errorHandlers";
import * as sweetalert from "./utils/sweetalert";
import * as tools from "./utils/tools";
import * as business from "./utils/business";
import * as coleplots from "./utils/colePlots";
import * as refRanges from "./utils/refRanges";
import * as history from "./utils/history";
import * as session from "./utils/session";
import * as userInterface from "./utils/userInterface";

/**
 * @fileOverview Utilities provides general functions that are used throughout the whole project
 * @module Utilities
 * @memberOf Libraries
 */

const Utilities = {
	//region BUSINESS
	getSoftwareVersion: business.getSoftwareVersion,
	getSozoEnvironmentAndBuildDate: business.getSozoEnvironmentAndBuildDate,
	riskElementSelection: business.riskElementSelection,
	canShowLdex: business.canShowLdex,
	canShowBodyComposition: business.canShowBodyComposition,
	canShowSegmental: business.canShowSegmental,
	canShowHfdex: business.canShowHfdex,
	canShowRenal: business.canShowRenal,
	getQueryParams: business.getQueryParams,
	getVersionForUrl: business.getVersionForUrl,
	getUserRole: business.getUserRole,
	getUserRoleArray: business.getUserRoleArray,
	getKeyFromId: business.getKeyFromId,
	getGroupDetails: business.getGroupDetails,
	filterLowQualityMeasurements: business.filterLowQualityMeasurements,
	splitMeasurementsList: business.splitMeasurementsList,
	getStatusText: business.getStatusText,
	getLocationHref: business.getLocationHref,
	getCurrentView: business.getCurrentView,
	getErrorCodeFromUrl: business.getErrorCodeFromUrl,
	getPageForMessage: business.getPageForMessage,
	getGender: business.getGender,
	getColePlotQuality: business.getColePlotQuality,
	getFactorForFluids: business.getFactorForFluids,
	getTickPositions: business.getTickPositions,
	getTextFromLymphSelections: business.getTextFromLymphSelections,
	getPlotBands: business.getPlotBands,
	getLegendExtraValues: business.getLegendExtraValues,
	formatResultData: business.formatResultData,
	hasLastAssessment: business.hasLastAssessment,
	setLoginData: business.setLoginData,
	setInitialData: business.setInitialData,
	prepareBaselineResults: business.prepareBaselineResults,
	getCurrentOS: business.getCurrentOS,
	isSOZODeviceCommEnabled: business.isSOZODeviceCommEnabled,
	validateOrigin: business.validateOrigin,
	getDeviceLicenseStatus: business.getDeviceLicenseStatus,
	validateDeviceLicense: business.validateDeviceLicense,
	getBuildPrefix: business.getBuildPrefix,
	//endregion
	//region VALIDATION
	isNumeric: validation.isNumeric,
	isNumericOneDecimal: validation.isNumericOneDecimal,
	isPhoneNumber: validation.isPhoneNumber,
	isPassword: validation.isPassword,
	isAlphaNumeric: validation.isAlphaNumeric,
	isAlphabetic: validation.isAlphabetic,
	isAlphabeticExtended: validation.isAlphabeticExtended,
	isAlphabeticExtendedNoLength: validation.isAlphabeticExtendedNoLength,
	isEmail: validation.isEmail,
	checkHeight: validation.checkHeight,
	isRequiredFieldsEmpty: validation.isRequiredFieldsEmpty,
	testAlphabeticExtended: validation.testAlphabeticExtended,
	isEmailInValid: validation.isEmailInValid,
	isPostalCodeInValid: validation.isPostalCodeInValid,
	areLDexSelectionsInValid: validation.areLDexSelectionsInValid,
	isInvalidPhone: validation.isInvalidPhone,
    isDomainNameInValid: validation.isDomainNameInValid,
	isSpaceOnly: validation.isSpaceOnly,
	isTotpInvalid: validation.isTotpInvalid,
	isNullOrUndefined: validation.isNullOrUndefined,
	//endregion
	//region USER INTERFACE
	setLoaderState: userInterface.setLoaderState,
	getVisibleAndHiddenTags: userInterface.getVisibleAndHiddenTags,
	//endregion
	//region TOOLS
	mergeAndSortArray: tools.mergeAndSortArray,
	breakStringInTwoParts: tools.breakStringInTwoParts,
	removeWhitespacesToLowercase: tools.removeWhitespacesToLowercase,
	capitalizeFirstLetter: tools.capitalizeFirstLetter,
	lowercaseFirstLetter: tools.lowercaseFirstLetter,
	flattenObject: tools.flattenObject,
	pairWithLastPairedDevice: tools.pairWithLastPairedDevice,
	// makeSingleCall: tools.makeSingleCall,
	makeSingleCallAsync: tools.makeSingleCallAsync,
	handleFailResponse: tools.handleFailResponse,
	extractObjects: tools.extractObjects,
	heightToSystem: tools.heightToSystem,
	compareTwoObjects: tools.compareTwoObjects,
	getMaxMinSumAvg: tools.getMaxMinSumAvg,
	getMinMax: tools.getMinMax,
	findLastIndex: tools.findLastIndex,
	checkArraysEqual: tools.checkArraysEqual,
	checkArrayHasDuplicates: tools.checkArrayHasDuplicates,
	//endregion
	//region SWEETALERT
	customSweetAlertSimple: sweetalert.customSweetAlertSimple,
	customSweetAlertOperation: sweetalert.customSweetAlertOperation,
	customSweetAlertSelectionOperation: sweetalert.customSweetAlertSelectionOperation,
	customSweetAlertRecalcHeight: sweetalert.customSweetAlertRecalcHeight,
	customSweetAlertTimerForLogout: sweetalert.customSweetAlertTimerForLogout,
	customSweetAlertOperationContent: sweetalert.customSweetAlertOperationContent,
	//endregion
	//region MATH
	roundDecimals: math.roundDecimals,
	imperialHeight: math.imperialHeight,
	imperialHeightFeetShow: math.imperialHeightFeetShow,
	imperialHeightInchShow: math.imperialHeightInchShow,
	imperialWeight: math.imperialWeight,
	imperialEvenWeight: math.imperialEvenWeight,
	imperialWater: math.imperialWater,
	toMetricWeight: math.toMetricWeight,
	//endregion
	//region ERROR HANDLERS
	handleErrorDescription: errorHandlers.handleErrorDescription,
	handleError: errorHandlers.handleError,
	handleErrorLogout: errorHandlers.handleErrorLogout,
	handleFailFromServer: errorHandlers.handleFailFromServer,
    handleErrorFromCognito: errorHandlers.handleErrorFromCognito,
	handleErrorFromDevice: errorHandlers.handleErrorFromDevice,
	getErrorMessage: errorHandlers.getErrorMessage,
	sendLogs: errorHandlers.sendLogs,
	//endregion
	//region DATE
	translateDate: date.translateDate,
	dateToEnglishForDatepicker: date.dateToEnglishForDatepicker,
	getDateToTimezone: date.getDateToTimezone,
	getTimezoneOffset: date.getTimezoneOffset,
	getDateRangeValues: date.getDateRangeValues,
	milliToDate: date.milliToDate,
	milliToDateTimezone: date.milliToDateTimezone,
	dateToAge: date.dateToAge,
	//endregion
	//region REFERENCE RANGES
	getRefRangesTBW: refRanges.getRefRangesTBW,
	getRefRangesECF: refRanges.getRefRangesECF,
	getRefRangesICF: refRanges.getRefRangesICF,
	getRefRangesHyDex: refRanges.getRefRangesHyDex,
	getRefRangesSMM: refRanges.getRefRangesSMM,
	getRefRangesFM: refRanges.getRefRangesFM,
	getRefRangesFFM: refRanges.getRefRangesFFM,
	getRefRangesBMI: refRanges.getRefRangesBMI,
	getRefRangesPhaseAngle: refRanges.getRefRangesPhaseAngle,
	//endregion
	//region COLEPLOTS
	mapFrxData:coleplots.mapFrxData,
	//endregion
	//region HISTORY
	getContainerConfig: history.getContainerConfig,
	getGraphSettingsBC: history.getGraphSettingsBC,
	getGraphSettingsHFDex: history.getGraphSettingsHFDex,
	getGraphSettingsLDex: history.getGraphSettingsLDex,
	getHistoryBasicSettings: history.getHistoryBasicSettings,
	getLDexMaxMin: history.getLDexMaxMin,
	getHFDexMaxMin: history.getHFDexMaxMin,
	getPointFormatForHFDex: history.getPointFormatForHFDex,
	getAxisRangesForESRD: history.getAxisRangesForESRD,
	getTitleForConfig: history.getTitleForConfig,
	getAxisOffsetMax: history.getAxisOffsetMax,
	getAxisOffsetMin: history.getAxisOffsetMin,
	//endregion
	//region SESSION
	initiateAutoLogoutSequence: session.initiateAutoLogoutSequence,
	logoutFinalization: session.logoutFinalization,
	logoutCall: session.logoutCall
	//endregion
};
export default Utilities;