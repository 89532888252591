import SettingsIconView from "./SettingsIconView";

const SettingsIcon = () => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return <SettingsIconView/>;

};

SettingsIcon.propTypes = {};

export default SettingsIcon;