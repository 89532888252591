import { Fragment } from "react";
import PropTypes from "prop-types";
import InputCode from "../../Components/InputCode/InputCode";
import PrivacyIcon from "../../Components/SVGs/PrivacyIcon/PrivacyIcon";
import UpdatePopupChain from "../../Components/UpdatePopupChain/UpdatePopupChain";

const SetMfaCodeView = (props) => {

    //region VARIABLES
    //endregion

    //region FUNCTIONS
    //endregion

    return (
        <Fragment>
            <div className={"setMfaCode"}>
                <div className={"setMfaCode__box"}>
                    <PrivacyIcon/>
                    <h1 className={"setMfaCode__title"}>{dictionary[localStorage.language].enterMfaCode}</h1>
                    <div className={"setMfaCode__form"}>
                        <form onSubmit={(event) => props.handleSetMfaCodeClick(event)}>
                            <InputCode inputLength={6} setCode={props.handleInputChange}/>

                            <input className={"setMfaCode__button button-blue"} value={dictionary[localStorage.language].submit}
                                   type="submit"/>
                            <button className={"setMfaCode__button button-white"} onClick={props.handleCancelClick}>{dictionary[localStorage.language].cancel}</button>
                        </form>
                    </div>
                </div>
            </div>
            <UpdatePopupChain chainState={constants.popupChainStates.applicationPreUpdate}
                              showPopup={props.showUpdatePopup}
                              appVersion={props.appVersion}
                              handleClose={() => props.handleCloseUpdatePopup(false)}/>
        </Fragment>
    );

};

SetMfaCodeView.propTypes = {
    mfaCode: PropTypes.string,
    appVersion: PropTypes.string,
    showUpdatePopup: PropTypes.bool,
    handleInputChange: PropTypes.func,
    handleSetMfaCodeClick: PropTypes.func,
    handleCancelClick: PropTypes.func,
    handleCloseUpdatePopup: PropTypes.func
};

export default SetMfaCodeView;