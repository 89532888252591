import { useState } from "react";
import PasswordPolicyView from "./PasswordPolicyView";

const PasswordPolicy = () => {

	//region VARIABLES
	let [tooltipHidden, setTooltipHidden] = useState(true);
	//endregion

	//region FUNCTIONS
	const handleTooltipDisplay = (tooltipHidden) => {
		setTooltipHidden(tooltipHidden);
	};
	//endregion

	return <PasswordPolicyView
		handleTooltipDisplay={handleTooltipDisplay}
		tooltipHidden={tooltipHidden}
	/>;

};

PasswordPolicy.propTypes = {};

export default PasswordPolicy;